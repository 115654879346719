import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-add-record',
  templateUrl: './add-record.component.html',
  styleUrls: ['./add-record.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddRecordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddRecordComponent),
      multi: true
    }
  ]
})
export class AddRecordComponent implements OnInit {
  // Property form
  //submitted = false;
  formUser: FormGroup;

  goals: any = [];
  categories: any = [];
  valuechain: any = [];
  isSubCategory: boolean = false;
  isEdit: boolean = false;
  supportedDocuments: any[] = [];
  //support_docs: any = [new SupportDocuments()];
  isCarbonOffsetTonnes: boolean = false;
  isCarbonOffsetUnits: boolean = false;
  @Input() item: boolean = true;
  subscriptions: Subscription[] = [];
  @Input() submitted: boolean;
  company_id: any = "";
  branch_id: any = "";
  currencyName: any;

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal,
    private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    let user = this.sessionSt.retrieve('userinfo');
    if (user != null) {
      this.branch_id = user.company.id;
      this.company_id = user.company.parent_id;
    }


    // GET CURRENCY NAME OF THE CORPORATE BRANCH
    this._service.getCurrencyNameById(user.company.reporting_currency).subscribe((response: any) => {
      if (response.success) {
        this.currencyName = response.result[0].name;
        // SET DEFAULT CURRENCY
        this.formUser.controls['activity_currency_measurement_unit'].setValue(response.result[0].name, { onlySelf: true });
      }
    });

    // GET GOALS
    this._service.getCRGoals(1).subscribe(
      (response: any) => {
        if (response.success) {
          this.goals = response.result;
        }
      })
    // GET CATEGORIES
    this._service.getCRCategories(1).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
        }
      })
    // GET VALUE CHAIN
    this._service.getCRValueChain().subscribe(
      (response: any) => {
        if (response.success) {
          this.valuechain = response.result;
        }
      })

    this.formUser = this.fb.group({
      id: [''],
      car_actual: [''],
      goal_id: ['', Validators.required],
      goal_value_id: ['', Validators.required],
      category_id: ['', Validators.required],
      category_activity_id: ['', Validators.required],
      activity_description: [''],
      activity_quantity: ['', Validators.required],
      activity_unit: ['', Validators.required],
      activity_emmission_factor: ['', Validators.required],
      ghg_emmission_tonnes: [''],
      ghg_emmission_unit: ['', Validators.required],
      // is_ghg_param: ['', Validators.required],
      group2: [''],
      financial: [''],
      facility_decription: [''],
      value_chain_main_id: ['', Validators.required],
      value_chain_id: [''],
      departments: [''],
      building: [''],
      activity_data: [''],
      calculate_GHG: [''],
      building_description: [''],
      sustainable_procurement: [''],
      green_tarrif_tonnes: [''],
      administartion: ['', Validators.required],
      emp_number: ['', Validators.required],
      category_activity_other: [''],
      support_documents: this.fb.array([]),
      // Removed Required
      ghg_carbon_offset_tonnes: [''],
      ghg_net_carbon_offset_tonnes: [''],
      ghg_carbon_offset_units: [''],
      //
      activity_currency_measurement_unit: ['', Validators.required],
      cr_type: 1,
      activity_total_investment: ['0.00', Validators.required],
      activity_investment_mark_cost: ['0.00', Validators.required],
      activity_finance_investment_sellable_value: ['0.00'],
      generate_green_tariff: [''],
      activity_quality_value: [''],
      ghg_emmission_factor_parameter_desc: ['', Validators.required],
      ghg: [''],
      target_month_date: ['', Validators.required],
      // carbon_offset: [''],
      // green_tariffs: [''],
      green_travel_carbon_offset: [],
      activity_value_cost: ['', Validators.required],
      number_of_days_per_week: ['']
    });

    // SET GHG EMMISSION UNITS DEFAULT VALUE
    this.formUser.get('ghg_emmission_unit')?.setValue('Tonnes CO2e');
    // ADD Default Document
    this.addSupportDocuments();

    //Edit Car Record
    this.getCarDataById(this.route.snapshot.params.id, 1);

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.formUser.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }
  get f() { return this.formUser.controls; }


  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    // if (value) {
    //   this.value = value;
    // }

    // if (value === null) {
    //   this.proposerSection.reset();
    // }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.formUser.valid ? null : { proposer: { valid: false } };
  }

  /*
  * GET GHG & TARGET ON GOAL CHANGE
  * Climate Change Mitigation ---- 44444444-4444-4444-4444-444444444441
  * Climate Change Adaptation ---- 44444444-4444-4444-4444-444444444442
  */
  goalValues: any = [];
  goalValueLabel: string = '';
  onGoalChange(event: any) {
    this.formUser.controls['activity_currency_measurement_unit'].setValue(this.currencyName, { onlySelf: true });
    let selectedGoal = this.goals.filter((x: any) => x.id == event.value);
    if (selectedGoal.length > 0) {
      this.goalValues = selectedGoal[0].goal_values;
    }
    let category = this.formUser.get('category_id')?.value;
    if (event.value == '44444444-4444-4444-4444-444444444441') {
      if (category == '33333333-3333-3333-3333-333333333335') {
        this.formUser.controls['category_activity_id'].setValue("31111111-1111-1111-1111-111111111131", { onlySelf: true });


      }
      if (category == '33333333-3333-3333-3333-333333333332') {
        this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
      }
      this.goalValueLabel = 'Green House Gas (GHG)';
    } else {
      if (category == '33333333-3333-3333-3333-333333333335') {
        this.formUser.controls['category_activity_id'].setValue("31111111-1111-1111-1111-111111111130", { onlySelf: true });
      }
      if (category == '33333333-3333-3333-3333-333333333332') {
        this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
      }
      this.goalValueLabel = 'Target';
    }
  }

  /*
  * GOAL VALUE CHANGE
  */
  onGoalValueChange(event: any) {
    this.activateActivityData();
  }

  /*
  * GET ACTIVITY VALUES ON CATEGORY CHANGE
  */
  categoryValues: any = [];
  onCategoryChange(event: any) {
    let selectedCat = this.categories.filter((x: any) => x.id == event.value);
    if (selectedCat.length > 0) {
      this.categoryValues = selectedCat[0].category_values;
    }

    this.activateActivityData();
  }

  /*
  * CALCULATE EMMISSION TONNES
  */
  calculateGHGEmmissionTonnes() {
    let value = Number(this.formUser.get('activity_quantity')?.value) * Number(this.formUser.get('activity_emmission_factor')?.value);
    this.formUser.get('ghg_emmission_tonnes')?.setValue(value);
    if (value > 0) {
      this.formUser.get('ghg_emmission_factor_parameter_desc')?.setValue(this.formUser.get('ghg_emmission_unit')?.value + " " + value);
    }
  }
  /*
  * GET VALUE CHAIN VALUES
  */
  valuechainValues: any = [];
  subCategoryValues: any = [];
  value_chain_infra_label: string = '';
  onValueChainChange(event: any) {
    if (event.value == '66666666-6666-6666-6666-666666666662') {
      this.value_chain_infra_label = 'Corporate Departments/Units';
    }
    else if (event.value == '66666666-6666-6666-6666-666666666663') {
      this.value_chain_infra_label = 'Firm Infrastructure';
    }
    let selectedvaluechain = this.valuechain.filter((x: any) => x.id == event.value);
    if (selectedvaluechain.length > 0) {
      this.valuechainValues = selectedvaluechain[0].value_chain_values;
    }
  }
  /*
  * ACTIVITIES CHANGE
  */
  onActivitiesChange(event: any) {
    this.activateActivityData();
  }

  /*
  * Activate Activity Data
  * 33333333-3333-3333-3333-333333333335 - Programmes / Initiatives
  * 33333333-3333-3333-3333-333333333332 - Products  and  Services
  * 31111111-1111-1111-1111-111111111132 - Category Value/Employees Smart  Working
  * 31111111-1111-1111-1111-111111111133 - Employees commuting
  * 33333333-3333-3333-3333-333333333334 - Philanthropic
  * 33333333-3333-3333-3333-333333333331 - Policy and  Regulation
  * 33333333-3333-3333-3333-333333333336 - Information and Knowledge  Management
  * 33333333-3333-3333-3333-333333333337 - Finance and Investment -Consumption/Buy
  * 33333333-3333-3333-3333-333333333333 - Finance and Investment -Production/Sales
  * 33333333-3333-3333-3333-333333333338 - Employees Smart Working
  * 33333333-3333-3333-3333-333333333339 - Employees Commuting Travels
  * 33333333-3333-3333-3333-333333333341 - Climate Finance and Investment -Consumption/Buy
  * 33333333-3333-3333-3333-333333333340 - Climate Finance and Investment -Production/Sales
  */
  activity_fields: boolean = false;
  calculated_formular_options: boolean = false;
  calculated_formular_label: string = '';
  emmission_parameter_desc_label: string = '';
  radio_parameter_desc_label: string = '';
  valueChainFields: boolean = false;
  employeeDetails: boolean = false;
  greenTravelCarbon: boolean = false;
  emmission_factor: string = 'Activity Emission Factor';
  carbon_offset_label: string = '';
  isCurrencyMeasurementUnit: boolean = true;
  finance_and_investment_sale_buy_label: string = '';
  ghg_emission_parameter_desc: string = '';
  activity_quantity_value_label: string = '';
  activity_value_measurement_unit_label: string = '';

  //New Conditions Added As Per Yemi's Feedback
  isActivityQuantity: boolean = true;
  isActivityQuantityMeasurement: boolean = true;
  isActivityEmissionFactor: boolean = true;
  isFormEnabled: boolean = true;
  isGHGEmission: boolean = true;

  isGHG_EFPD: boolean = false;
  isCarbonOffset_EFPD: boolean = false;
  isGT_EFPD: boolean = false;
  isGTCF_EFPD: boolean = false;
  isEmissionUnit: boolean = false;
  isActivityCost: boolean = false;
  isNumberOfDaysPerWeek: boolean = false;


  activateActivityData() {
    let category = this.formUser.get('category_id')?.value;
    let category_values = this.formUser.get('category_activity_id')?.value;
    let goal_values = this.formUser.get('goal_value_id')?.value;
    let goal_id = this.formUser.get('goal_id')?.value;

    this.valueChainFields = true;
    this.employeeDetails = false;
    this.greenTravelCarbon = false;
    this.activity_fields = true;
    this.calculated_formular_options = true;
    this.isGHGEmission = true;



    switch (category) {
      case '33333333-3333-3333-3333-333333333335':
        if (goal_id == '44444444-4444-4444-4444-444444444441') {
          this.formUser.controls['category_activity_id'].setValue("31111111-1111-1111-1111-111111111131", { onlySelf: true });
        }
        else if (goal_id == '44444444-4444-4444-4444-444444444442') {
          this.formUser.controls['category_activity_id'].setValue("31111111-1111-1111-1111-111111111130", { onlySelf: true });
        }
        if (goal_values == '11111111-1111-1111-1111-111111111118' && category_values == '31111111-1111-1111-1111-111111111132') {
          this.calculated_formular_label = 'Carbon Offsets';
          this.emmission_parameter_desc_label = 'Carbon Offsets Units';
          this.radio_parameter_desc_label = 'Carbon Offsets Parameters Description';
          this.employeeDetails = true;
          this.formUser.get("administartion")?.enable();
          this.formUser.get("emp_number")?.enable();
          this.formUser.get("activity_currency_measurement_unit")?.disable();
          this.isCurrencyMeasurementUnit = false;
          this.formUser.get("ghg_carbon_offset_tonnes")?.disable();
          this.formUser.get("ghg_net_carbon_offset_tonnes")?.disable();
          this.formUser.get("ghg_carbon_offset_units")?.disable();
        } else {
          this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
          this.emmission_parameter_desc_label = 'Green House Gas (GHG) Units ';
          this.radio_parameter_desc_label = 'GHG/Carbon Offset Emission factor Parameters Description';
          this.employeeDetails = true;
          this.formUser.get("administartion")?.enable();
          this.formUser.get("emp_number")?.enable();
          // this.formUser.get("activity_currency_measurement_unit")?.enable();
          // this.isCurrencyMeasurementUnit = true;
          this.formUser.get("ghg_carbon_offset_tonnes")?.enable();
          this.formUser.get("ghg_net_carbon_offset_tonnes")?.enable();
          this.formUser.get("ghg_carbon_offset_units")?.enable();
        }
        if (category_values == '31111111-1111-1111-1111-111111111133') {
          this.greenTravelCarbon = true;
          let selectedCategoryValue = this.categoryValues.filter((x: any) => x.id == category_values);
          this.subCategoryValues = selectedCategoryValue[0].sub_categories;
          this.employeeDetails = true;
          this.formUser.get("administartion")?.enable();
          this.formUser.get("emp_number")?.enable();
          this.formUser.get("activity_currency_measurement_unit")?.disable();
          this.isCurrencyMeasurementUnit = false;
          this.formUser.get("ghg_carbon_offset_tonnes")?.disable();
          this.formUser.get("ghg_net_carbon_offset_tonnes")?.disable();
          this.formUser.get("ghg_carbon_offset_units")?.disable();
        } else {
          this.greenTravelCarbon = false;
          this.employeeDetails = true;
          this.formUser.get("administartion")?.enable();
          this.formUser.get("emp_number")?.enable();
        }
        if (goal_values == '11111111-1111-1111-1111-111111111111' && category_values == '31111111-1111-1111-1111-111111111131') {
          this.isCarbonOffsetTonnes = true;
          this.isCarbonOffsetUnits = true;
          this.carbon_offset_label = "Carbon Offset Units";
          this.employeeDetails = false;
          this.formUser.get("administartion")?.disable();
          this.formUser.get("emp_number")?.disable();

        }
        else if (goal_values == '21111111-1111-1111-1111-111111111115' && category_values == '31111111-1111-1111-1111-111111111130') {
          this.isCarbonOffsetTonnes = true;
          this.isCarbonOffsetUnits = true;
          this.carbon_offset_label = "Carbon Offset Units";
          this.employeeDetails = false;
          // Previous data added
          // this.formUser.get("administartion")?.enable();
          // this.formUser.get("emp_number")?.enable();
          this.formUser.get("administartion")?.disable();
          this.formUser.get("emp_number")?.disable();
          this.radio_parameter_desc_label = "Carbon Offset Parameters Description";
        }
        else {
          this.isCarbonOffsetTonnes = false;
          this.isCarbonOffsetUnits = false;
          this.employeeDetails = true;
          this.formUser.get("administartion")?.enable();
          this.formUser.get("emp_number")?.enable();
          //this.formUser.get("activity_currency_measurement_unit")?.enable();
        }
        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        // Added According to new requirement
        this.isActivityQuantity = true;
        this.activity_quantity_value_label = "Activity Quantity Value";
        this.isActivityQuantityMeasurement = true;
        this.isActivityEmissionFactor = false;

        this.formUser.get("activity_quantity")?.enable();
        this.formUser.get("activity_unit")?.enable();
        this.formUser.get("activity_emmission_factor")?.disable();

        this.formUser.get("activity_currency_measurement_unit")?.disable();
        this.isCurrencyMeasurementUnit = false;
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        this.formUser.get("value_chain_main_id")?.enable();


        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = true;
        this.formUser.get("carbon_offset")?.enable();

        //All Other Disable
        this.isGHG_EFPD = true;
        this.formUser.get("ghg")?.enable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();

        this.formUser.get("green_tarrif_tonnes")?.disable();

        this.formUser.get("ghg_emmission_unit")?.enable();

        this.isEmissionUnit = true;

        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();

        this.activity_value_measurement_unit_label = "Activity Value Measurement Unit";

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();
        break;
      case '33333333-3333-3333-3333-333333333332':

        this.emmission_parameter_desc_label = 'Green House Gas (GHG) Units ';
        this.radio_parameter_desc_label = 'GHG/Carbon Offset Emission factor Parameters Description';
        if (goal_values == '21111111-1111-1111-1111-111111111112' && category_values == '31111111-1111-1111-1111-111111111116') {
          this.isCarbonOffsetUnits = true;
          this.carbon_offset_label = "Green Tariff Unit";
          this.radio_parameter_desc_label = 'Green  Tarriffs ParametersDe scription';
        }
        else {
          this.isCarbonOffsetUnits = false;
        }
        this.formUser.get("activity_currency_measurement_unit")?.disable();
        this.isCurrencyMeasurementUnit = false;

        if (goal_id == '44444444-4444-4444-4444-444444444441') {
          this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        }
        else if (goal_id == '44444444-4444-4444-4444-444444444442') {
          this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        }

        // Added According to new requirement
        this.isActivityQuantity = true;
        this.activity_quantity_value_label = "Activity Quantity Value";
        this.isActivityQuantityMeasurement = true;
        this.isActivityEmissionFactor = false;

        this.formUser.get("activity_quantity")?.enable();
        this.formUser.get("activity_unit")?.enable();
        this.formUser.get("activity_emmission_factor")?.disable();
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        this.formUser.get("value_chain_main_id")?.enable();

        if (category_values == '31111111-1111-1111-1111-111111111116') {
          // New Field added for Emission Factor Parameter Description
          this.isCarbonOffset_EFPD = false;
          this.formUser.get("carbon_offset")?.disable();

          //All Other Disable
          this.isGHG_EFPD = false;
          this.formUser.get("ghg")?.disable();

          this.isGT_EFPD = true;
          this.formUser.get("green_tariffs")?.enable();

          this.isGTCF_EFPD = false;
          this.formUser.get("green_travel_carbon_offset")?.disable();
          this.formUser.get("green_tarrif_tonnes")?.enable();
          this.formUser.get("activity_total_investment")?.disable();

        }
        else {
          // New Field added for Emission Factor Parameter Description
          this.isCarbonOffset_EFPD = false;
          this.formUser.get("carbon_offset")?.disable();

          //All Other Disable
          this.isGHG_EFPD = false;
          this.formUser.get("ghg")?.disable();

          this.isGT_EFPD = false;
          this.formUser.get("green_tariffs")?.disable();

          this.isGTCF_EFPD = false;
          this.formUser.get("green_travel_carbon_offset")?.disable();
        }

        this.formUser.get("ghg_emmission_unit")?.disable();
        this.isEmissionUnit = false;

        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();
        this.activity_value_measurement_unit_label = "Activity Value Measurement Unit";

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();
        break;
      case '33333333-3333-3333-3333-333333333334':
        //this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        this.emmission_parameter_desc_label = 'Green House Gas (GHG) Units ';
        this.radio_parameter_desc_label = 'Carbon Offset Parameters Description';
        //this.radio_parameter_desc_label = 'GHG/Carbon Offset Emission factor Parameters Description';
        //this.formUser.get("activity_currency_measurement_unit")?.enable();
        //this.isCurrencyMeasurementUnit=true;


        this.formUser.controls['value_chain_main_id'].setValue("66666666-6666-6666-6666-666666666662", { onlySelf: true });
        let selectedvaluechain = this.valuechain.filter((x: any) => x.id == "66666666-6666-6666-6666-666666666662");
        if (selectedvaluechain.length > 0) {
          this.valuechainValues = selectedvaluechain[0].value_chain_values;
        }
        this.value_chain_infra_label = 'Corporate Departments/Units';

        //Hide Measurement and Activity Quanity As per new feedback
        this.isCurrencyMeasurementUnit = false;
        this.isActivityQuantity = false;
        this.isActivityQuantityMeasurement = false;
        this.isActivityEmissionFactor = false;
        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_currency_measurement_unit")?.disable();
        this.formUser.get("activity_emmission_factor")?.disable();
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        this.formUser.get("value_chain_main_id")?.disable();


        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = true;
        this.formUser.get("carbon_offset")?.enable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();
        this.formUser.get("green_tarrif_tonnes")?.disable();

        this.formUser.get("ghg_emmission_unit")?.disable();
        this.isEmissionUnit = false;

        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();
        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();
        break;
      case '33333333-3333-3333-3333-333333333340':
        this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.emmission_parameter_desc_label = 'Green Tarrif Unit';
        this.radio_parameter_desc_label = '  Green  Tarriffs  Parameters  Description  ';
        //this.emmission_factor = 'Quantity Finance  & Investment Sales Green Tarrifs (Tonnes CO2e)';
        this.emmission_factor = 'Finance & Investment Sales Green Tariffs (Tonnes CO2e)';
        this.formUser.get("activity_currency_measurement_unit")?.enable();
        this.isCurrencyMeasurementUnit = true;
        this.formUser.get("activity_investment_mark_cost")?.enable();
        this.formUser.get("activity_total_investment")?.enable();
        this.finance_and_investment_sale_buy_label = "Activity Finance & Investment Sales Value";

        // Added According to new requirement
        this.isActivityQuantity = false;
        this.isActivityQuantityMeasurement = false;
        this.isActivityEmissionFactor = true;

        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_emmission_factor")?.enable();
        this.isFormEnabled = false;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();
        this.isGHGEmission = false;

        this.formUser.get("value_chain_main_id")?.enable();

        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = false;
        this.formUser.get("carbon_offset")?.disable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = true;
        this.formUser.get("green_tariffs")?.enable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();

        this.formUser.get("ghg_emmission_unit")?.disable();
        this.isEmissionUnit = false;


        // Activity Value Cost 
        this.isActivityCost = false;
        this.formUser.get("activity_value_cost")?.disable();

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();

        break;
      case '33333333-3333-3333-3333-333333333331':
        //this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        this.emmission_parameter_desc_label = 'Green House  Gas (GHG) Emission Unit';
        this.emmission_factor = 'Green House  Gas (GHG)  Emission ';
        //this.formUser.get("activity_currency_measurement_unit")?.enable();
        //this.isCurrencyMeasurementUnit = true;
        if (goal_values == '11111111-1111-1111-1111-111111111111' && category_values == '31111111-1111-1111-1111-111111111113') {
          //this.isCarbonOffsetUnits = true;
          this.isCarbonOffsetTonnes = true;
          this.carbon_offset_label = "Carbon Offset Units";
          this.radio_parameter_desc_label = 'Carbon  Offset  Parameters  Description';
        }
        else {
          this.isCarbonOffsetUnits = false;
          this.radio_parameter_desc_label = 'Green House  Gas  (GHG) Parameters  Description ';
        }
        //Hide Measurement and Activity Quanity As per new feedback
        this.isCurrencyMeasurementUnit = false;
        this.isActivityQuantity = false;
        this.isActivityQuantityMeasurement = false;
        this.isActivityEmissionFactor = false;
        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_currency_measurement_unit")?.disable();
        this.formUser.get("activity_emmission_factor")?.disable();
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        this.formUser.get("value_chain_main_id")?.enable();

        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = true;
        this.formUser.get("carbon_offset")?.enable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();

        this.formUser.get("green_tarrif_tonnes")?.disable();

        this.formUser.get("ghg_emmission_unit")?.enable();
        this.isEmissionUnit = true;
        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();

        break;
      case '33333333-3333-3333-3333-333333333336':
        //this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        this.emmission_parameter_desc_label = 'Green House Gas (GHG) Emission Unit';
        this.radio_parameter_desc_label = 'Green House  Gas  (GHG) Parameters  Description ';
        this.emmission_factor = 'Green House  Gas (GHG)  Emission ';
        //this.formUser.get("activity_currency_measurement_unit")?.enable();
        //this.isCurrencyMeasurementUnit = true;

        //Hide Measurement and Activity Quanity As per new feedback
        this.isCurrencyMeasurementUnit = false;
        this.isActivityQuantity = false;
        this.isActivityQuantityMeasurement = false;
        this.isActivityEmissionFactor = false;
        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_currency_measurement_unit")?.disable();
        this.formUser.get("activity_emmission_factor")?.disable();
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();

        this.formUser.get("value_chain_main_id")?.enable();


        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = true;
        this.formUser.get("carbon_offset")?.enable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();

        this.formUser.get("green_tarrif_tonnes")?.disable();

        this.formUser.get("ghg_emmission_unit")?.disable();
        this.isEmissionUnit = false;
        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();
        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();

        break;
      case '33333333-3333-3333-3333-333333333341':
        this.finance_and_investment_sale_buy_label = "Activity Finance & Investment Buy Value";
        //this.emmission_factor = 'Quantity Finance  & Investment Sales Green Tarrifs (Tonnes CO2e)';
        this.emmission_factor = 'Finance & Investment Buy Green Tariffs (Tonnes CO2e)';
        this.formUser.get("activity_total_investment")?.disable();


        // Added According to new requirement
        this.isActivityQuantity = false;
        this.isActivityQuantityMeasurement = false;
        this.isActivityEmissionFactor = true;

        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_emmission_factor")?.enable();
        //this.activity_fields = true;
        this.isFormEnabled = true;

        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();
        this.isGHGEmission = false;

        this.formUser.get("value_chain_main_id")?.enable();

        this.isCurrencyMeasurementUnit = true;
        this.formUser.get("activity_currency_measurement_unit")?.enable();

        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = false;
        this.formUser.get("carbon_offset")?.disable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = true;
        this.formUser.get("green_tariffs")?.enable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();
        this.formUser.get("green_tarrif_tonnes")?.disable();

        this.formUser.get("ghg_emmission_unit")?.disable();
        this.isEmissionUnit = false;

        // Activity Value Cost 
        this.isActivityCost = false;
        this.formUser.get("activity_value_cost")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.enable();

        this.isNumberOfDaysPerWeek = false;
        this.formUser.get("number_of_days_per_week")?.disable();

        break;
      case '33333333-3333-3333-3333-333333333338':
        this.formUser.controls['goal_value_id'].setValue("11111111-1111-1111-1111-111111111118", { onlySelf: true });
        //this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        this.radio_parameter_desc_label = 'GHG/Carbon Offset Emission Factor Parameters Description';
        this.isFormEnabled = true;
        this.greenTravelCarbon = false;
        this.employeeDetails = true;
        this.formUser.get("administartion")?.enable();
        this.formUser.get("emp_number")?.enable();
        // if (goal_id == '44444444-4444-4444-4444-444444444441') {
        //   this.calculated_formular_label = 'Carbon Offset Tonnes CO2e';
        // }
        //this.emmission_parameter_desc_label = 'Green House Gas (GHG) Emission Unit';
        this.emmission_parameter_desc_label = 'Green House Gas (GHG) Emission Unit';

        this.isActivityQuantityMeasurement = true;
        this.formUser.get("activity_unit")?.enable();



        this.formUser.controls['value_chain_main_id'].setValue("66666666-6666-6666-6666-666666666662", { onlySelf: true });
        let selectedvaluechaindata = this.valuechain.filter((x: any) => x.id == "66666666-6666-6666-6666-666666666662");
        if (selectedvaluechaindata.length > 0) {
          this.valuechainValues = selectedvaluechaindata[0].value_chain_values;
        }
        this.value_chain_infra_label = 'Corporate Departments/Units';
        this.formUser.get("value_chain_main_id")?.disable();

        this.isCurrencyMeasurementUnit = false;
        this.formUser.get("activity_currency_measurement_unit")?.disable();


        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = true;
        this.formUser.get("carbon_offset")?.enable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = false;
        this.formUser.get("green_travel_carbon_offset")?.disable();
        this.formUser.get("activity_emmission_factor")?.disable();
        this.isActivityEmissionFactor = false;
        this.formUser.get("green_tarrif_tonnes")?.enable();

        //this.isGHGEmission=false;

        this.formUser.get("ghg_emmission_unit")?.enable();
        this.isEmissionUnit = true;
        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();

        this.isActivityQuantity = true;
        this.activity_quantity_value_label = "Activity Quantity Value / Distance Travels (Return)";
        this.activity_value_measurement_unit_label = "Activity Value Measurement Unit / Mileage";
        this.formUser.get("activity_quantity")?.enable();

        this.isNumberOfDaysPerWeek = true;
        this.formUser.get("number_of_days_per_week")?.enable();

        break;
      case '33333333-3333-3333-3333-333333333339':
        this.formUser.controls['goal_value_id'].setValue("11111111-1111-1111-1111-111111111118", { onlySelf: true });
        this.calculated_formular_label = 'Green House Gas( GHG) Tonnes CO2e';
        this.radio_parameter_desc_label = 'GHG/Carbon Offset Emission Factor Parameters Description';
        this.isFormEnabled = true;
        this.greenTravelCarbon = true;
        this.employeeDetails = true;
        this.formUser.get("administartion")?.enable();
        this.formUser.get("emp_number")?.enable();

        this.isActivityQuantityMeasurement = true;
        this.formUser.get("activity_unit")?.enable();

        this.formUser.get("value_chain_main_id")?.enable();
        this.emmission_parameter_desc_label = 'Green House Gas (GHG) Emission Unit';

        this.isCurrencyMeasurementUnit = false;
        this.formUser.get("activity_currency_measurement_unit")?.disable();


        // New Field added for Emission Factor Parameter Description
        this.isCarbonOffset_EFPD = false;
        this.formUser.get("carbon_offset")?.disable();

        //All Other Disable
        this.isGHG_EFPD = false;
        this.formUser.get("ghg")?.disable();

        this.isGT_EFPD = false;
        this.formUser.get("green_tariffs")?.disable();

        this.isGTCF_EFPD = true;
        this.formUser.get("green_travel_carbon_offset")?.enable();

        this.formUser.controls['value_chain_main_id'].setValue("66666666-6666-6666-6666-666666666662", { onlySelf: true });
        let selectedvaluechaingreen = this.valuechain.filter((x: any) => x.id == "66666666-6666-6666-6666-666666666662");
        if (selectedvaluechaingreen.length > 0) {
          this.valuechainValues = selectedvaluechaingreen[0].value_chain_values;
        }
        this.value_chain_infra_label = 'Corporate Departments/Units';
        this.formUser.get("value_chain_main_id")?.disable();

        this.formUser.get("green_tarrif_tonnes")?.enable();

        this.formUser.get("ghg_emmission_unit")?.enable();
        this.isEmissionUnit = true;
        this.formUser.get("activity_total_investment")?.disable();
        this.formUser.get("activity_investment_mark_cost")?.disable();

        // Activity Value Cost 
        this.isActivityCost = true;
        this.formUser.get("activity_value_cost")?.enable();

        this.isActivityQuantity = true;
        this.activity_quantity_value_label = "Activity Quantity Value / Distance Travels (Return)";

        this.formUser.get("activity_emmission_factor")?.disable();
        this.isActivityEmissionFactor = false;

        this.activity_value_measurement_unit_label = "Activity Value Measurement Unit / Mileage";
        this.formUser.get("activity_quantity")?.enable();


        this.isNumberOfDaysPerWeek = true;
        this.formUser.get("number_of_days_per_week")?.enable();

        break;
      default:
        this.activity_fields = false;
        this.calculated_formular_options = false;
        this.valueChainFields = false;
        this.employeeDetails = false;
        this.formUser.get("administartion")?.disable();
        this.formUser.get("emp_number")?.disable();
        // this.formUser.get("activity_currency_measurement_unit")?.enable();
        // this.isCurrencyMeasurementUnit = true;
        this.isFormEnabled = true;
        break;
    }
  }


  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: any = null;
  getUploadedFile(files: any) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  myFiles: any = [];
  getUploadedDocuments(files: any, i: any) {
    this.fileToUpload = files.item(0);
    //this.fileName = this.fileToUpload.name;
    const companies = this.formUser.get('support_documents') as FormArray;
    //this.companyDetails.static_docs[i].file_name = this.fileToUpload.name;
    for (var j = 0; j < files.length; j++) {
      this.myFiles.push(files[j]);
    }
  }

  // onSave() {
  //   this.submitted = true;
  //   if (this.formUser.invalid) {
  //     console.log(this.formUser.controls);
  //     return;
  //   }
  //   console.log(this.myFiles.length);
  //   let data = this.formUser.getRawValue();
  //   console.log(data);
  //   data.file_name = this.fileToUpload;
  //   data.finacial_cost = 20.00;
  //   let formData = new FormData();


  //   for (var i = 0; i < this.myFiles.length; i++) {
  //     if (this.myFiles[i]) {
  //       formData.append("uploaded_files_" + i, this.myFiles[i]);
  //     }
  //   }

  //   data.is_procurement_cost = 0;

  //   if (data.activity_procurement_cost != null) {
  //     data.activity_procurement_cost = data.activity_procurement_cost;
  //   }
  //   else {
  //     data.activity_procurement_cost = 0.00;
  //   }
  //   if (data.ghg_emission_tonnes != null) {
  //     data.ghg_emission_tonnes = data.ghg_emission_tonnes;
  //   }
  //   else {
  //     data.ghg_emission_tonnes = 0;
  //   }

  //   if (data.ghg_emission_unit != null) {
  //     data.ghg_emission_unit = data.ghg_emission_unit;
  //   }
  //   else {
  //     data.ghg_emission_unit = "USD";
  //   }

  //   if (data.product_service_green_tarrif != null) {
  //     data.product_service_green_tarrif = data.product_service_green_tarrif;
  //   }
  //   else {
  //     data.product_service_green_tarrif = 0;
  //   }

  //   if (data.carbon_offset_tonnes != null) {
  //     data.carbon_offset_tonnes = data.carbon_offset_tonnes;
  //   }
  //   else {
  //     data.carbon_offset_tonnes = 0;
  //   }

  //   if (data.is_carbon_offset_param != null) {
  //     data.is_carbon_offset_param = data.is_carbon_offset_param;
  //   }
  //   else {
  //     data.is_carbon_offset_param = 0;
  //   }

  //   formData.append("totaldocscount", this.myFiles.length);
  //   formData.append("id", data.id);
  //   formData.append("file_name", data.file_name);
  //   formData.append("goal_id", data.goal_id);
  //   formData.append("goal_value_id", data.goal_value_id);
  //   formData.append("category_id", data.category_id);
  //   formData.append("category_activity_id", data.category_activity_id);
  //   formData.append("category_activity_other", data.category_activity_other);
  //   formData.append("activity_other_description", data.activity_other_description);
  //   formData.append("activity_description", data.activity_description);
  //   formData.append("activity_quantity", data.activity_quantity);
  //   formData.append("activity_unit", data.activity_unit);
  //   formData.append("is_procurement_cost", data.is_procurement_cost);
  //   formData.append("activity_procurement_cost", data.activity_procurement_cost);

  //   formData.append("ghg_emission_unit", data.ghg_emission_unit);
  //   // formData.append("is_ghg_param", data.is_ghg_param);
  //   formData.append("ghg_param_desc", data.ghg_param_desc);
  //   formData.append("product_service_green_tarrif", data.green_tarrif_tonnes);
  //   if(this.calculated_formular_label == 'Carbon Offset Tonnes CO2e'){
  //     formData.append("carbon_offset_tonnes", data.ghg_emmission_tonnes);
  //   }
  //   // else if(data.activity_investment_mark_cost !=null &&  data.activity_investment_mark_cost > 0){
  //   //   formData.append("ghg_emission_tonnes", data.activity_investment_mark_cost);
  //   // }
  //   else{
  //     formData.append("ghg_emission_tonnes", data.ghg_emmission_tonnes);
  //   }

  //   if(data.ghg !=null && data.ghg > 0){
  //     formData.append("ghg_emission_tonnes", data.ghg);
  //   }



  //   formData.append("carbon_offset_unit", data.carbon_offset_unit);
  //   formData.append("is_carbon_offset_param", data.is_carbon_offset_param);
  //   formData.append("carbon_offset_desc", data.carbon_offset_desc);
  //   formData.append("finacial_cost", data.finacial_cost);
  //   formData.append("value_chain_main_id", data.value_chain_main_id);
  //   formData.append("value_chain_id", data.value_chain_id);
  //   formData.append("building", data.building);
  //   formData.append("facility_decription", data.facility_decription);
  //   if((this.emmission_factor == "Finance & Investment Buy Green Tariffs (Tonnes CO2e)") || (this.emmission_factor =="Finance & Investment Sales Green Tariffs (Tonnes CO2e)")){
  //     formData.append("product_service_green_tarrif", data.activity_emmission_factor);
  //   }

  //   formData.append("administartion", data.administartion);
  //   formData.append("emp_number", data.emp_number);
  //   formData.append("cr_type", data.cr_type);
  //   formData.append("ghg", data.ghg);
  //   formData.append("carbon_offset", data.carbon_offset);
  //   formData.append("green_tariffs", data.green_tariffs);
  //   formData.append("green_travel_carbon_offset", data.green_travel_carbon_offset);
  //   let formattedDate = formatDate(data.target_month_date, 'yyyy-MM-dd', 'en-US');
  //   formData.append("target_month_datetime", formattedDate);
  //   formData.append("company_id", this.company_id);
  //   formData.append("branch_id", this.branch_id);


  //   this._service.saveCarData(formData)?.subscribe(
  //     (response: any) => {
  //       if (response.success) {
  //         this._snackBar.open(response.message, '', {
  //           duration: 5000,
  //           panelClass: 'text-success'
  //         });
  //         this.formUser.reset();
  //         //window.location.reload();
  //       } else {
  //         this._snackBar.open(response.message, '', {
  //           duration: 5000,
  //           panelClass: 'text-danger'
  //         });
  //       }
  //     },
  //     (error: any) => {
  //       this._snackBar.open('Failed to save data. Please try again later.', '', {
  //         duration: 5000,
  //         panelClass: 'text-danger'
  //       });
  //     }
  //   );
  // }
  target_month_date: Date;
  private createDocument(): FormGroup {
    return this.fb.group({
      document_path: [''],
      file_name: [''],
    });
  }
  public addSupportDocuments(): void {
    const documents = this.formUser.get('support_documents') as FormArray;
    documents.push(this.createDocument());
  }

  supportDocuments(): FormArray {
    return this.formUser.get("support_documents") as FormArray
  }
  removeDocument(i: number) {
    this.supportDocuments().removeAt(i);
  }
  //Get Car Data By Id
  getCarDataById(id: any, cr_type: any) {
    this._service.getCarById(id, cr_type).subscribe(
      (response: any) => {
        if (response.success) {
          this.isEdit = true;

          this.formUser.patchValue({
            id: response.result.id,
            goal_id: response.result.goal_id,
            goal_value_id: response.result.goal_value_id,
            category_id: response.result.category_id,
            category_activity_id: response.result.category_activity_id,
            activity_description: response.result.activity_description,
            activity_quantity: response.result.activity_quantity,
            activity_unit: response.result.activity_unit,
            // is_ghg_param: response.result.is_ghg_param,
            value_chain_main_id: response.result.value_chain_main_id,
            value_chain_id: response.result.value_chain_id,
            facility_decription: response.result.facility_decription,
            building: response.result.building,
            green_tarrif_tonnes: response.result.product_service_green_tarrif,
            ghg_emission_unit: response.result.ghg_emission_unit,
            activity_emmission_factor: response.result.activity_emmission_factor,
            administartion: response.result.administartion,
            emp_number: response.result.emp_number,
            target_month_date: response.result.target_month_datetime,
            ghg_emmission_factor_parameter_desc: response.result.ghg_emmission_factor_parameter_desc,
            carbon_offset_tonnes: response.result.carbon_offset_tonnes,
            activity_value_cost: response.result.activity_value_cost,
            number_of_days_per_week: response.result.number_of_days_per_week
          });
          if (response.result.category_id == '33333333-3333-3333-3333-333333333331') {
            this.formUser.get('ghg_emmission_tonnes')?.setValue(response.result.carbon_offset_tonnes);
          }
          this.rowData = response.result.supported_documents;

          this.getGoalValuesOnEditByGoalId(response.result.goal_id);

          this.getActivityOnEditByCategoryId(response.result.category_id);

          this.getValueChainByMainValueChainId(response.result.value_chain_main_id);

          this.activateActivityData();

          // let value = Number(response.result.activity_quantity) * Number(response.result.activity_emmission_factor);
          // this.formUser.get('ghg_emmission_tonnes')?.setValue(value);

        }
      });
  }

  getGoalValuesOnEditByGoalId(goal_id: any) {

    let selectedGoal = this.goals.filter((x: any) => x.id == goal_id);
    if (selectedGoal.length > 0) {
      this.goalValues = selectedGoal[0].goal_values;
    }

    if (goal_id == '44444444-4444-4444-4444-444444444441') {
      this.goalValueLabel = 'GHG';
    } else {
      this.goalValueLabel = 'Target';
    }
  }

  getActivityOnEditByCategoryId(category_id: any) {
    let selectedCat = this.categories.filter((x: any) => x.id == category_id);
    if (selectedCat.length > 0) {
      this.categoryValues = selectedCat[0].category_values;
    }
  }
  getValueChainByMainValueChainId(value_chain_id: any) {
    let selectedvaluechain = this.valuechain.filter((x: any) => x.id == value_chain_id);
    if (selectedvaluechain.length > 0) {
      this.valuechainValues = selectedvaluechain[0].value_chain_values;
    }
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api?.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 500, filter: false },
    {
      headerName: null,
      width: 100, filter: false, type: 'rightAligned',
      cellRenderer: function (params: any) {
        // let links = '<a target="_blank" href="' + environment.imageURL + params.data.download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  download_path="' + params.data.download_path + '"></i></a>';
        let links = '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        return links;
      }
    }
  ];
  rowData: any;


  /*
* GRID BUTTONS ACTION
*/
  documentsEditMode: boolean = false;
  documentID: string = '';
  docTitle: string = '';
  onGridRowClicked(e: any) {
    if (e) {
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this._service.downloadDocs(e.data);
            break;
          }
      }
    }
  }

  /*
* Help Text PopUp Modal
*/
  msg = '';
  helpTextPopup(modal: any, content: any) {
    this.msg = content;
    this.modalService.open(modal, { centered: true });
  }
  onClickGreenTariff(event: any, helpTextPopupModal: any) {
    if (event.target.value == '2') {
      this.msg = 'Enter value into CR record line.';
      this.modalService.open(helpTextPopupModal, { centered: true });
      this.isFormEnabled = false;
    }
  }
  /*
* CALCULATE TOTAL FINANCE AND INVESTMENT VALUE
*/
  calculateFinanceAndInvestment() {
    let value = Number(this.formUser.get('activity_total_investment')?.value) - Number(this.formUser.get('activity_investment_mark_cost')?.value);
    this.formUser.get('activity_finance_investment_sellable_value')?.setValue(value);
  }
  addminussign(event: any) {
    if (this.calculated_formular_label == 'Carbon Offset Tonnes CO2e') {
      if (event.target.value) {
        this.formUser.controls['ghg_emmission_tonnes'].setValue("-" + event.target.value);
      }
    }
    else if (this.calculated_formular_label == 'Green House Gas( GHG) Tonnes CO2e') {
      if (event.target.value) {
        this.formUser.controls['ghg_emmission_tonnes'].setValue(event.target.value);
      }
    }
    else {
      if (event.target.value) {
        this.formUser.controls['green_travel_carbon_offset'].setValue("-" + event.target.value);
      }
    }
  }
  addminussignGreenTravel(event: any) {
    if (event.target.value) {
      this.formUser.controls['green_travel_carbon_offset'].setValue("-" + event.target.value);
    }
  }
  addminussignGreenTariff(event: any) {
    if (event.target.value) {
      this.formUser.controls['green_tarrif_tonnes'].setValue("-" + event.target.value);
    }
  }


  addValidation() {
    this.formUser.get('goal_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('goal_id')?.updateValueAndValidity();

    this.formUser.get('goal_value_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('goal_value_id')?.updateValueAndValidity();

    this.formUser.get('category_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('category_id')?.updateValueAndValidity();

    this.formUser.get('category_activity_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('category_activity_id')?.updateValueAndValidity();

    this.formUser.get('activity_quantity')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_quantity')?.updateValueAndValidity();

    this.formUser.get('activity_unit')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_unit')?.updateValueAndValidity();

    // this.formUser.get('activity_emmission_factor')?.setValidators([Validators.required]); // 6. Clear All Validators
    // this.formUser.get('activity_emmission_factor')?.updateValueAndValidity();

    this.formUser.get('administartion')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('administartion')?.updateValueAndValidity();

    this.formUser.get('emp_number')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('emp_number')?.updateValueAndValidity();

    this.formUser.get('value_chain_main_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('value_chain_main_id')?.updateValueAndValidity();

    this.formUser.get('activity_currency_measurement_unit')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_currency_measurement_unit')?.updateValueAndValidity();

    this.formUser.get('activity_total_investment')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_total_investment')?.updateValueAndValidity();

    this.formUser.get('activity_value_cost')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_value_cost')?.updateValueAndValidity();

    this.formUser.get('activity_total_investment')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_total_investment')?.updateValueAndValidity();

    this.formUser.get('activity_investment_mark_cost')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_investment_mark_cost')?.updateValueAndValidity();

    this.formUser.get('ghg_emmission_factor_parameter_desc')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('ghg_emmission_factor_parameter_desc')?.updateValueAndValidity();

    this.formUser.get('target_month_date')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('target_month_date')?.updateValueAndValidity();

    this.formUser.get('number_of_days_per_week')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('number_of_days_per_week')?.updateValueAndValidity();
  }

  removeValidation() {
    this.formUser.get('goal_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('goal_id')?.updateValueAndValidity();

    this.formUser.get('goal_value_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('goal_value_id')?.updateValueAndValidity();

    this.formUser.get('category_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('category_id')?.updateValueAndValidity();

    this.formUser.get('category_activity_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('category_activity_id')?.updateValueAndValidity();

    this.formUser.get('activity_quantity')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_quantity')?.updateValueAndValidity();

    this.formUser.get('activity_unit')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_unit')?.updateValueAndValidity();

    this.formUser.get('activity_emmission_factor')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_emmission_factor')?.updateValueAndValidity();

    this.formUser.get('administartion')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('administartion')?.updateValueAndValidity();

    this.formUser.get('emp_number')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('emp_number')?.updateValueAndValidity();

    this.formUser.get('value_chain_main_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('value_chain_main_id')?.updateValueAndValidity();

    this.formUser.get('activity_currency_measurement_unit')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_currency_measurement_unit')?.updateValueAndValidity();

    this.formUser.get('activity_total_investment')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_total_investment')?.updateValueAndValidity();

    this.formUser.get('activity_value_cost')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_value_cost')?.updateValueAndValidity();

    this.formUser.get('activity_investment_mark_cost')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_investment_mark_cost')?.updateValueAndValidity();

    this.formUser.get('ghg_emmission_factor_parameter_desc')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('ghg_emmission_factor_parameter_desc')?.updateValueAndValidity();

    this.formUser.get('target_month_date')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('target_month_date')?.updateValueAndValidity();

    this.formUser.get('number_of_days_per_week')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('number_of_days_per_week')?.updateValueAndValidity();
  }
  onSave() {
    this.submitted = true;
    this.removeValidation();
    this.saveData(1);

  }

  onSubmit() {
    this.submitted = true;
    this.addValidation();
    this.saveData(2);
  }

  saveData(status: any) {
    //this.submitted = true;
    if (this.formUser.invalid) {
      //console.log(this.formUser.controls);
      return;
    }
    //console.log(this.myFiles.length);
    let data = this.formUser.getRawValue();
    //console.log(data);
    data.file_name = this.fileToUpload;
    data.finacial_cost = 20.00;
    let formData = new FormData();


    for (var i = 0; i < this.myFiles.length; i++) {
      if (this.myFiles[i]) {
        formData.append("uploaded_files_" + i, this.myFiles[i]);
      }
    }

    //data.is_procurement_cost = 0;

    if (data.activity_procurement_cost != null) {
      data.activity_procurement_cost = data.activity_procurement_cost;
    }
    else {
      data.activity_procurement_cost = 0.00;
    }
    if (data.ghg_emission_tonnes != null) {
      data.ghg_emission_tonnes = data.ghg_emission_tonnes;
    }
    else {
      data.ghg_emission_tonnes = 0;
    }

    if (data.ghg_emmission_unit != null) {
      data.ghg_emmission_unit = data.ghg_emmission_unit;
    }
    else {
      data.ghg_emission_unit = "";
    }

    if (data.product_service_green_tarrif != null) {
      data.product_service_green_tarrif = data.product_service_green_tarrif;
    }
    else {
      data.product_service_green_tarrif = 0;
    }

    if (data.carbon_offset_tonnes != null) {
      data.carbon_offset_tonnes = data.carbon_offset_tonnes;
    }
    else {
      data.carbon_offset_tonnes = 0;
    }

    if (data.is_carbon_offset_param != null) {
      data.is_carbon_offset_param = data.is_carbon_offset_param;
    }
    else {
      data.is_carbon_offset_param = 0;
    }

    formData.append("totaldocscount", this.myFiles.length);
    formData.append("id", data.id ? data.id : "");
    formData.append("file_name", data.file_name);
    formData.append("goal_id", data.goal_id);
    formData.append("goal_value_id", data.goal_value_id);
    formData.append("category_id", data.category_id);
    formData.append("category_activity_id", data.category_activity_id);
    formData.append("category_activity_other", data.category_activity_other);
    formData.append("activity_other_description", data.activity_other_description);
    formData.append("activity_description", data.activity_description);
    formData.append("activity_quantity", data.activity_quantity);
    formData.append("activity_unit", data.activity_unit);
    //formData.append("is_procurement_cost", data.is_procurement_cost);
    formData.append("activity_procurement_cost", data.activity_procurement_cost);

    formData.append("ghg_emission_unit", data.ghg_emmission_unit);
    // formData.append("is_ghg_param", data.is_ghg_param);
    formData.append("ghg_param_desc", data.ghg_param_desc);
    formData.append("product_service_green_tarrif", data.green_tarrif_tonnes);
    if (this.calculated_formular_label == 'Carbon Offset Tonnes CO2e') {
      formData.append("carbon_offset_tonnes", data.ghg_emmission_tonnes);
    }
    // else if(data.activity_investment_mark_cost !=null &&  data.activity_investment_mark_cost > 0){
    //   formData.append("ghg_emission_tonnes", data.activity_investment_mark_cost);
    // }
    else {
      formData.append("ghg_emission_tonnes", data.ghg_emmission_tonnes);
    }

    if (data.ghg != null && data.ghg > 0) {
      formData.append("ghg_emission_tonnes", data.ghg);
    }



    formData.append("carbon_offset_unit", data.carbon_offset_unit);
    formData.append("is_carbon_offset_param", data.is_carbon_offset_param);
    formData.append("carbon_offset_desc", data.carbon_offset_desc);
    formData.append("finacial_cost", data.finacial_cost);
    formData.append("value_chain_main_id", data.value_chain_main_id);
    formData.append("value_chain_id", data.value_chain_id);
    formData.append("building", data.building);
    formData.append("facility_decription", data.facility_decription);
    if ((this.emmission_factor == "Finance & Investment Buy Green Tariffs (Tonnes CO2e)") || (this.emmission_factor == "Finance & Investment Sales Green Tariffs (Tonnes CO2e)")) {
      formData.append("product_service_green_tarrif", data.activity_emmission_factor);
    }

    formData.append("administartion", data.administartion);
    formData.append("emp_number", data.emp_number);
    formData.append("cr_type", "1");
    formData.append("ghg", data.ghg);
    formData.append("carbon_offset", data.carbon_offset);
    formData.append("green_tariffs", data.green_tariffs);
    if (data.green_travel_carbon_offset != null) {
      formData.append("carbon_offset_tonnes", data.green_travel_carbon_offset);
    }

    let formattedDate = formatDate(data.target_month_date, 'yyyy-MM-dd', 'en-US');
    formData.append("target_month_datetime", formattedDate);
    formData.append("company_id", this.company_id);
    formData.append("branch_id", this.branch_id);
    formData.append("status", status);
    formData.append("ghg_emmission_factor_parameter_desc", data.ghg_emmission_factor_parameter_desc);

    formData.append("total_finance_investment_sale", data.activity_total_investment);
    if (this.finance_and_investment_sale_buy_label == "Activity Finance & Investment Buy Value") {
      formData.append("activity_finance_investment_buy", data.activity_investment_mark_cost);
    }
    else {
      formData.append("activity_finance_investment_sale", data.activity_investment_mark_cost);
    }

    formData.append("balance_finance_investment_sale", data.activity_finance_investment_sellable_value);

    formData.append("is_procurement_cost", data.sustainable_procurement);

    formData.append("activity_value_cost", data.activity_value_cost);

    formData.append("number_of_days_per_week", data.number_of_days_per_week);

    this._service.saveCarData(formData)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
          this.formUser.reset();
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }

}
