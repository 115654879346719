import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  submitted = false;
  company_id: string = "";
  isEdit: boolean = false;
  currencyList: any = []
  financialYearList: any = []
  carYearList: any = []
  countriesList: any[] = [];
  continentsList: any[] = [];
  defaultReportingYear: string = '7axyc0bb-b933-4f28-bc70-b5cc47fafb95';
  defaultCSCPPReportingYear: string = '6axyc0bb-b933-4f28-bc70-b5cc47fafb95';

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private sessionSt: LocalStorageService, private router: Router) { }

  formUser = this.fb.group({
    name: ['', Validators.required],
    main_contact_first_name: ['', Validators.required],
    main_contact_last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    website: [''],
    address_1: ['', Validators.required],
    address_2: [''],
    address_3: [''],
    county: ['', Validators.required],
    postcode: ['', Validators.required],
    country: ['', Validators.required],
    phone: ['', Validators.required],
    //industry: ['', Validators.required],
    locality: ['', Validators.required],
    continent: ['', Validators.required],
    reporting_currency: ['', Validators.required],
    financial_reporting_year: ['', Validators.required],
    car_reporting_year: ['', Validators.required],
    created_at: [''],
    id: '',
    address_id: '',
    status:0,
  });

  ngOnInit(): void {

    this.formUser.controls['financial_reporting_year'].setValue(this.defaultReportingYear, { onlySelf: true });
    this.formUser.controls['car_reporting_year'].setValue(this.defaultCSCPPReportingYear, { onlySelf: true });
    this._service.getCurrency().subscribe((response: any) => {
      if (response.success) {
        this.currencyList = response.result;
      }
    });

    this._service.getReportingYearValues().subscribe((response: any) => {
      if (response.success) {
        this.financialYearList = response.result;
      }
    });


    let userinfo = this.sessionSt.retrieve('userInfo');



    //Get All Countries List
    // this._service.getCountriesList().subscribe(
    //   (response: any) => {
    //     if(response.success){
    //       this.countriesList = response.result;
    //     }
    //   });

    //Get All Continent List
    this._service.getContinentList().subscribe(
      (response: any) => {
        if (response.success) {
          this.continentsList = response.result;
          response.result.forEach((res: any) => {
            if (res.code == userinfo.address.continent) {
              this.countriesList = res.countries;
            }
          });
        }
      });

    this.company_id = this.route.snapshot.params.company_id;
    this._service.getComapnyById(this.company_id).subscribe(
      (response: any) => {
        if (response.success) {
          this.isEdit = true;
          //this.company = response.result;

          this.formUser.controls['county'].setValue(response.result.address.county, { onlySelf: true });
          this.formUser.controls['continent'].setValue(response.result.address.continent, { onlySelf: true });
          this.formUser.controls['country'].setValue(response.result.address.country, { onlySelf: true });
          this.formUser.controls['postcode'].setValue(response.result.address.postcode, { onlySelf: true });
          this.formUser.controls['reporting_currency'].setValue(response.result.reporting_currency, { onlySelf: true });
        }
      }

    )
    
    this._service.getComapnyById(this.route.snapshot.params.id).subscribe(
      (response: any) => {
        if (response.success) {
          this.isEdit = true;
          //this.company = response.result;

          this.formUser.controls['county'].setValue(response.result.address.county, { onlySelf: true });
          this.formUser.controls['continent'].setValue(response.result.address.continent, { onlySelf: true });
          this.formUser.controls['country'].setValue(response.result.address.country, { onlySelf: true });
          this.formUser.controls['postcode'].setValue(response.result.address.postcode, { onlySelf: true });
          this.formUser.controls['reporting_currency'].setValue(response.result.reporting_currency, { onlySelf: true });

          this.onChangeContinent(response.result.address.continent);
          if (response.result.address.country != null && this.countriesList.length > 0) {
            this.onCountryChangeGetCountryCode(response.result.address.country);
          }

          this.formUser.patchValue({
            id: response.result.id,
            name: response.result.name,
            main_contact_first_name: response.result.main_contact_first_name,
            main_contact_last_name: response.result.main_contact_last_name,
            email: response.result.email,
            website: response.result.website,
            //industry:response.result.industry,
            locality: response.result.locality,
            postcode: response.result.address.postcode,
            address_1: response.result.address.address1,
            address_2: response.result.address.address2,
            address_3: response.result.address.address3,
            county: response.result.address.county,
            country: response.result.address.country,
            phone: response.result.phone,
            address_id: response.result.address.id,
            reporting_currency: response.result.reporting_currency,
            car_reporting_year: response.result.car_reporting_year,
            financial_reporting_year: response.result.financial_reporting_year,
            continent: response.result.address.continent,
            created_at: formatDate(response.result.created_at, 'yyyy-MM-dd', 'en-US'),
            status: response.result.status,
          });
        }
      }

    )
  }

  get f() { return this.formUser.controls; }

  onSave() {
    this.submitted = true;
    if (this.formUser.invalid) {
      this.scrollToError();
      return;
    }

    let data = this.formUser.getRawValue();
    //Role Id Changed from Corporate to Branch
    data.role_id = '3b4e4105-463e-456e-8e5e-97ac397168e5';
    data.status = 1;
    data.parent_id = this.company_id;
    this._service.saveData(data)?.subscribe
      (
        (response: any) => {
          if (response.success) {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
            this.router.navigate(['/branches', this.company_id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        },
        (error: any) => {
          this._snackBar.open('Failed to save data. Please try again later.', '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      );
  }


  scrollTo(el: any): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }
  onChangeContinent(event: any) {
    this.continentsList.forEach((res: any) => {
      if (res.code == event) {
        this.countriesList = res.countries;
      }
    });

  }
  phoneCode: string;
  onCountryChangeGetCountryCode(event: any) {
    if (this.countriesList.length > 0) {
      this.countriesList.forEach((res: any) => {
        if (res.code == event) {
          this.phoneCode = "+" + res.phonecode;
        }

      });
    }
  }
}

