import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router,ActivatedRoute } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cer-analysis',
  templateUrl: './cer-analysis.component.html',
  styleUrls: ['./cer-analysis.component.css']
})
export class CerAnalysisComponent implements OnInit {

  constructor(private _service: SharedService, private sessionSt: LocalStorageService,private fb: FormBuilder) { }

  formCerReport: FormGroup;
  yearsList:any=[];
  branchList:any=[];
  company_id:string;
  submitted = false;

  isJanCurrentTab = false;
  isFabCurrentTab = false;
  isMarchCurrentTab = false;
  isAprilTab = false;
  isMayCurrentTab = false;
  isJuneCurrentTab = false;
  isJulyCurrentTab = false;
  isAugustCurrentTab = false;
  isSeptCurrentTab = false;
  isOctCurrentTab = false;
  isNoveCurrentTab = false;
  isDeceCurrentTab = false;
  is3MonthTab = false;
  is6MonthTab = false;
  is12MonthTab = false;

  isTabularTab = false;
  isChartTab = false;


  isCERSDGTab = false;
  isCERActivityTargetTab = false;
  isCERActivityMarkEcoFfficientTab = false;
  isCERActivityMarkEcoFfficientCategoryTab = false;
  isCERActivityMarkEcoFriendlyCategoryTab = false;
  isCERActivityMarkCircularCategoryTab = false;
  isCERActivityMarkRenewwalCategoryTab = false;
  isCERActivityMarkRecycleCategoryTab = false;
  isCERActivityMarkConsumptionCategoryTab = false;

  ngOnInit(): void {
    let user = this.sessionSt.retrieve('userinfo');
    if(user !=null){
      this._service.getComapnyBranchesById(user.company_id)?.subscribe(
        (response: any) => {
          this.branchList = response.result;
        },
      );
    }
    this._service.getYearList()?.subscribe(
      (response: any) => {
        this.yearsList = response.result;
      },
    );


    this.formCerReport = this.fb.group({
      cer_type:[''],
      cer_month:[''],
      year:['', Validators.required],
      branch:['', Validators.required],
      report_type:['', Validators.required],
      month_name:['', Validators.required]
    })

  }
  get f() { return this.formCerReport.controls; }
  div_1:boolean=true;
  div_2:boolean=true;
  div_3:boolean=true;
  div_4:boolean=true;
  div_5:boolean=true;
  div_6:boolean=true;
  div_7:boolean=true;
  div_8:boolean=true;
  div_9:boolean=false;
  div_10:boolean=false;
  div_11:boolean =false;
  div_12:boolean =true;

  cerPerformance(){
    this.div_2 = false;
    this.div_3 = false;
    this.div_4 = false;
    this.div_5 = false;
    this.div_6 = false;
    this.div_7 = false;
    this.div_8 = false;
    this.div_12 = false;
    this.div_9 = true;
    this.div_10 = true;
    this.div_11 = true;
    this.formCerReport.get("cer_type")?.setValue(1);

    this.isCERSDGTab = true;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;
  }
  cersdgPerformance(){
    this.formCerReport.get("cer_type")?.setValue(2);
    this.div_1=false
    this.div_2=true;
    this.div_3=false;
    this.div_4=false;
    this.div_5=false;
    this.div_6= false;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = true;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;

  }
  cerActivity(){
    this.formCerReport.get("cer_type")?.setValue(3);
    this.div_1=false
    this.div_2=false;
    this.div_3=true;
    this.div_4=false;
    this.div_5=false;
    this.div_6= false;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = true;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;

  }
  cerOption4(){
    this.formCerReport.get("cer_type")?.setValue(4);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=true;
    this.div_5=false;
    this.div_6= false;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = true;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;

  }
  cerOption5(){
    this.formCerReport.get("cer_type")?.setValue(5);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=false;
    this.div_5=true;
    this.div_6= false;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = true;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;
  }
  cerOption6(){
    this.formCerReport.get("cer_type")?.setValue(6);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=false;
    this.div_5=false;
    this.div_6= true;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = true;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;
  }
  cerOption7(){
    this.formCerReport.get("cer_type")?.setValue(7);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=false;
    this.div_5=false;
    this.div_6= false;
    this.div_7=true;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = true;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = false;
  }
  cerOption8(){
    this.formCerReport.get("cer_type")?.setValue(8);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=false;
    this.div_5=false;
    this.div_6= false;
    this.div_7=false;
    this.div_8=true;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = false;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = true;
    this.isCERActivityMarkConsumptionCategoryTab = false;
  }
  cerOption9(){
    this.formCerReport.get("cer_type")?.setValue(9);
    this.div_1=false
    this.div_2=false;
    this.div_3=false;
    this.div_4=false;
    this.div_5=false;
    this.div_6= false;
    this.div_7=false;
    this.div_8=false;
    this.div_9 = true;
    this.div_10=true;
    this.div_11 = true;
    this.div_12 = true;

    this.isCERSDGTab = false;
    this. isCERActivityTargetTab = false;
    this.isCERActivityMarkEcoFfficientTab = false;
    this.isCERActivityMarkEcoFfficientCategoryTab = false;
    this.isCERActivityMarkEcoFriendlyCategoryTab = false;
    this.isCERActivityMarkCircularCategoryTab = false;
    this.isCERActivityMarkRenewwalCategoryTab = false;
    this.isCERActivityMarkRecycleCategoryTab = false;
    this.isCERActivityMarkConsumptionCategoryTab = true;
  }
  csrdata:any=[];

  CEReportData(){
    this.submitted = true;
    if (this.formCerReport.invalid) {
      console.log(this.formCerReport.controls)
      this.scrollToError();
      return;
    }
    let tdata = this.formCerReport.getRawValue();
    console.log(tdata);
    let data: any = '';
    data = {
      start_date:"",
      end_date: "",
      cer_type:tdata.cer_type,
      cer_month:tdata.cer_month,
      year:tdata.year,
      branch_id:tdata.branch,
      report_type:tdata.report_type
    };
    this._service.getCerAnalysisData(data)?.subscribe(
      (response: any) => {
           var blob=new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
           var link=document.createElement('a');
           link.href=window.URL.createObjectURL(blob);
           link.download="cer_analysis.xlsx";
           link.click(); 
      },
    );
  }
  onSubmit(){
    this.CEReportData();
  }
  reset(){
    window.location.reload();
  }
  cermonth(event:any){
     this.formCerReport.get("cer_month")?.setValue(event.currentTarget.innerText);
     this.formCerReport.get("month_name")?.setValue(event.currentTarget.innerText);
     if (event.currentTarget.innerText == "January") {
      this.isJanCurrentTab = true;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "February") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = true;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "March") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = true;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "April") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = true;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "May") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = true;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "June") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = true;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "July") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = true;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "August") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = true;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "September") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = true;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "October") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = true;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "November") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = true;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "December") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = true;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "3 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = true;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "6 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = true;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "12 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = true;
    }
    else {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
    }
  }
  reportType(event:any){
    this.formCerReport.get("report_type")?.setValue(event.currentTarget.innerText);
    if (event.currentTarget.innerText == "Tabular Analysis") {
      this.isTabularTab = true;
      this.isChartTab = false;
    }
    else {
      this.isTabularTab = false;
      this.isChartTab = true;
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
     this.scrollTo(firstElementWithError);
   }
   scrollTo(el:any): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
