import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css']
})
export class FinancialComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private modalService: NgbModal, config: NgbModalConfig, private _service: SharedService,
    private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar, private sessionSt: LocalStorageService) { }

  isSuperAdmin: boolean;
  isCompanyUser: boolean;
  customerId: any;

  branchData: any = [];
  company_id: any;

  showAddButton: boolean;
  isActive: boolean;

  ngOnInit(): void {

    this.company_id = this.route.snapshot.params.company_id;
    this.sessionSt.store('company_id', this.company_id);
    this.customerId = this.sessionSt.retrieve('customer_id');


    let userinfo = this.sessionSt.retrieve('userInfo');
    if (userinfo != null) {
      if (userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.isSuperAdmin = false;
      }
      else {
        this.isSuperAdmin = true;
      }
      if (userinfo.company.id == this.company_id && userinfo.company.parent_id == null) {
        this.isCompanyUser = true;
      }
      else {
        this.isCompanyUser = false;
      }

    }
    else {
      this.isSuperAdmin = true;
    }

    this._service.getFinancialListByCompanyId(this.company_id).subscribe(
      (response: any) => {
        let userinfo = this.sessionSt.retrieve('userInfo');
        if (userinfo != null) {
          response.result.forEach((res: any) => {
            if (userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5"
              || userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5"
              || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5") {
              res.isAdmin = false;
            }
            else {
              res.isAdmin = true;
            }
            if (userinfo.company.id == this.company_id && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
              res.isCompanyUser = true;
            }
            else {
              res.isCompanyUser = false;
            }
            if (userinfo.company.id != null && userinfo.company.parent_id != null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
              res.isBranchUser = true;
            }
            else {
              res.isBranchUser = false;
            }
          })
        }

        this.branchData = response.result;
      }
    );

    this._service.getFiancialDataByCompanyId(this.company_id).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.result.status == 1) {
            this.isActive = true;
          }
          else {
            this.isActive = false;
          }
        }
      }
    )

  }

  //Grid For Branch Data
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api?.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Company Name', sortable: true, field: 'name', width: 107
    },
    {
      headerName: 'Number Of Branches', sortable: true, field: 'branch_number', width: 107
    },
    {
      headerName: 'Price', sortable: true, filter: false, field: 'price', width: 140
    },
    {
      headerName: 'Customer Discount', sortable: true, field: 'new_customer_discount', width: 107
    },
    {
      headerName: 'Action', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        if ((params.data.isAdmin || params.data.isCompanyUser) && (params.data.status == 1)) {
          links += '<a  title="Edit Financial" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }
        else if (params.data.isAdmin || params.data.isCompanyUser){
          links += '<a  title="View Financial" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-eye" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }
        // if(params.data.isAdmin || params.data.isCompanyUser){
        //   links += '<a title="Delete Financial" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
        // }

        return links;
      }
    },
  ];
  /*
   * GRID BUTTONS ACTION
   */
  msg: string = '';
  branch_id: string = '';
  rowIndex: number = 0;

  onGridRowClicked(e: any, content: any) {
    if (e) {
      //let branch_id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.branch_id = e.data.id;
      this.rowIndex = e.rowIndex;
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/add_financial', this.company_id, e.data.id]);
            break;
          }
        case "users":
          {
            this.router.navigate(['/user_list', e.data.id]);
            break;
          }
        case "delete":
          {
            this.msg = 'Are you sure you want to delete this branch?';
            this.modalService.open(content, { centered: true });
            break;
          }
      }
    }
  }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'modal-default', size: 'md', centered: true });
  }

  /*
* DELETE COMPANY
*/
  deleteCompany(branch_id: any, rowIndex: any) {
    let data: any = '';
    data = {
      id: branch_id
    };

    this._service.deleteCompany(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.branchData.splice(rowIndex, 1);
            this.gridOptions.api?.setRowData(this.branchData)
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
