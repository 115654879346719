<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Add Contract</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group" *ngIf="isUserList">
                    <div class="col-md-3">
                        <label>User List<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">

                        <mat-form-field class="w-100">
                            <mat-select formControlName="contract_user_id"
                                class="form-control form-control-alternative" name="contract_user_id" required
                                [ngClass]="{ 'is-invalid': (f.contract_user_id.touched || submitted) && f.contract_user_id.errors?.required }" >
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let u of userList" [value]="u.id">
                                    {{u.first_name}} {{u.last_name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.contract_user_id.touched || submitted) && f.contract_user_id.errors?.required">
                           user is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Upload Contract File</label>
                    </div>
                    <div class="col-md-5">
                        <div class="custom-file">
                            <input type="file" (change)="getContractUploadedFile($event.target.files)"
                                class="custom-file-input" id="customFile" accept="application/pdf">
                            <label class="vertical custom-file-label" for="customFile">{{contarctfileName}}</label>
                        </div>
                        <small>Please upload PDF file</small>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contract Notes<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <textarea formControlName="contract_notes" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.contract_notes.touched || submitted) && f.contract_notes.errors?.required }"></textarea>
                        <div class="invalid-feedback"
                            *ngIf="(f.contract_notes.touched || submitted) && f.contract_notes.errors?.required">
                            Notes is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contract Status</label>
                    </div>
                    <div class="col-md-5">
                        <div class="col-md-6">
                            <mat-checkbox formControlName="status">
                                Is Contract Approved
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                        <button  type="button"[routerLink]="['/view_contract',company_id]"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>