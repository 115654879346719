<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Enviourmental Responsibility (CER) Performance Report</h2>
        </div>
    </div>
</div>

<!-- <div class="container-fluid mt-4">
        <div class="card shadow">
            <div class="card-body">
                <div class="form">
                    <div class="col-12" id="content">
                        <div class="dash-box"><a href="#">CER Performance</a></div>
                        <div class="dash-box"><a href="#">CER-SDG Goals</a></div>
                        <div class="dash-box"><a href="#">CER -Activity Targets</a></div>
                        <div class="dash-box"><a href="#">Policy and Regulation</a></div>
                        <div class="dash-box"><a href="#">Product and Services</a></div>
                        <div class="dash-box"><a href="#">Finance and Investment</a></div>
                        <div class="dash-box"><a href="#">Philanthropic</a></div>
                        <div class="dash-box"><a href="#">Programmes/Initiatives</a></div>
                        <div class="dash-box"><a href="#">Information and Knowledge Management</a></div>
                        <div class="dash-box"><a href="#">CES -Activity Mark</a></div>
                        <div class="dash-box"><a href="#">Sustainable Procurement</a></div>
                        <div class="dash-box"><a href="#">Eco-efficent</a></div>
                        <div class="dash-box"><a href="#">Eco-friendly</a></div>
                        <div class="dash-box"><a href="#">Sustainability</a></div>
                        <div class="dash-box"><a href="#">Circular economy</a></div>
                        <div class="dash-box"><a href="#">Renewable</a></div>
                        <div class="dash-box"><a href="#">Recyclable</a></div>
                        <div class="dash-box"><a href="#">Financial Cost/Expenditure</a></div>
                        <div class="dash-box"><a href="#">Deliverables/Benefits</a></div>
                        <div class="dash-box"><a href="#">Corporate Department/Units</a></div>
                        <div class="dash-box"><a href="#">Firm Infrastructure</a></div>
                        <div class="dash-box"><a href="#">January</a></div>
                        <div class="dash-box"><a href="#">February</a></div>
                        <div class="dash-box"><a href="#">March</a></div>
                        <div class="dash-box"><a href="#">April</a></div>
                        <div class="dash-box"><a href="#">May</a></div>
                        <div class="dash-box"><a href="#">June</a></div>
                        <div class="dash-box"><a href="#">July</a></div>
                        <div class="dash-box"><a href="#">August</a></div>
                        <div class="dash-box"><a href="#">September</a></div>
                        <div class="dash-box"><a href="#">October</a></div>
                        <div class="dash-box"><a href="#">November</a></div>
                        <div class="dash-box"><a href="#">December</a></div>
                        <div class="dash-box"><a href="#">4 Month</a></div>
                        <div class="dash-box"><a href="#">6 Month</a></div>
                        <div class="dash-box"><a href="#">12 Month</a></div>
                        <div class="dash-box"><a href="#">Report Order Confirmation</a></div>
                        <div class="dash-box"><a href="#">Report</a></div>
                        <br style="clear:both" />
                    </div>
                  </div>
            </div>
        </div>
    </div>  -->

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <div class="form" [formGroup]="formCerReport">


                <div class="row">

                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Years</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="year" class="form-control form-control-alternative"
                                        name="year" required
                                        [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let y of yearsList" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.year.touched || submitted) && f.year.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Branch</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="branch" class="form-control form-control-alternative"
                                        name="branch" required
                                        [ngClass]="{ 'is-invalid': (f.branch.touched || submitted) && f.branch.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let b of branchList" [value]="b.id">
                                            {{b.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.branch.touched || submitted) && f.branch.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" id="content">
                    <div *ngIf="div_1" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCERPerformanceTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="csrPerformance();">CER Performance</a></div>
                    </div>
                    <div *ngIf="div_2" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCERSDGGoalTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="csrsdgPerformance();">CER-SDG Goals</a></div>
                    </div>
                    <div *ngIf="div_3" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCERActivityTargetTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="csrActivity();">CER -Activity Targets</a></div>
                    </div>
                    <div *ngIf="div_4" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCERActivityCategoryTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="csrOption4();">CER - Activity Categories</a></div>
                    </div>
                    <div *ngIf="div_5" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCERValueChainTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="csrOption5();">CER -Value Chain Impact</a></div>
                    </div>
                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJanCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">January</a></div>
                        <input formControlName="month_name" name="month_name" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.month_name.touched || submitted) && f.month_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.month_name.touched || submitted) && f.month_name.errors?.required">
                            Month is required
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isFabCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">February</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMarchCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">March</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAprilTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">April</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMayCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">May</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJuneCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">June</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJulyCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">July</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAugustCurrentTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="cermonth($event)">August</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isSeptCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">September</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isOctCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">October</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isNoveCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">November</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isDeceCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">December</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is3MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">3 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is6MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">6 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is12MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cermonth($event)">12 Month</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row div_10" id="content" *ngIf="div_10">
                    <div class="col-md-2">
                        <div class="dash-box"><a href="javascript:void(0)" (click)="onSubmit()">Report</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row mt-1">
                    <div class="col-md-2">
                        <button type="button" class="btn btn-save" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>