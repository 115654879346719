import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router,ActivatedRoute } from "@angular/router";
import { SharedService } from '../../../services/shared.service';
import { environment } from '../../../../environments/environment';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-reseller',
  templateUrl: './reseller.component.html',
  styleUrls: ['./reseller.component.css']
})
export class ResellerComponent implements OnInit {
  submitted = false;
  checkedProduct: any[] = [];
  productList:[]
  selectProductList:[]
  isChecBox:boolean;
  isEdit:boolean=false;
  imagePath: string = environment.imageURL;
  logoPath:any;
  countriesList: any[] = [];
  continentsList: any[] = [];

  formUser = this.fb.group({
    name: ['', Validators.required],
    main_contact_first_name: ['', Validators.required],
    main_contact_last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    website: ['', Validators.required],
    address_1: ['', Validators.required],
    address_2: [''],
    address_3: [''],
    county: ['', Validators.required],
    continent: ['', Validators.required],
    postcode: ['', Validators.required],
    country: ['', Validators.required],
    phone: ['', Validators.required],
    products:this.fb.array([], Validators.required),
    id:'',
    address_id:'',
    logo_path:[''],
    created_at: [''],
    //agent_date: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar,
    private _service: SharedService, private router: Router,private route: ActivatedRoute) { }
  
    ngOnInit(): void {

      //Get All Product List
      this._service.getProductList().subscribe(
        (response: any) => {
          this.productList = response.result;
        });

      //Edit Reseller
      this.getResellerDataById(this.route.snapshot.params.id);

      //Get All Countries List
      // this._service.getCountriesList().subscribe(
      //   (response: any) => {
      //     if(response.success){
      //       this.countriesList = response.result;
      //     }
      //   });

      //Get All Continent List
      this._service.getContinentList().subscribe(
        (response: any) => {
          if(response.success){
            this.continentsList = response.result;
          }
        });


    }
  
    get f() { return this.formUser.controls; }
  
  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: any = null;
  getUploadedFile(files:any) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

    //Save Data
    onSave(){
      this.submitted = true;
      if (this.formUser.invalid) {
        this.scrollToError();
        return;
      }

      let data = this.formUser.getRawValue();
      data.role_id ='4b4e4105-463e-456e-8e5e-97ac397168e5';
      data.status = 1,
      data.product_ids = this.checkedProduct.toString();
      
      data.logo_path = this.fileToUpload;
      let formData = new FormData();
  
      formData.append("id",data.id);
      formData.append("logo_path",data.logo_path);
      formData.append("main_contact_first_name",data.main_contact_first_name);
      formData.append("main_contact_last_name",data.main_contact_last_name);
      formData.append("email",data.email);
      formData.append("website",data.website);
      formData.append("name",data.name);
      formData.append("address_1",data.address_1);
      formData.append("address_2",data.address_2 ? data.address_2:'');
      formData.append("address_3",data.address_3 ? data.address_3:'');
      formData.append("county",data.county);
      formData.append("postcode",data.postcode);
      formData.append("country",data.country);
      formData.append("continent",data.continent);
      formData.append("phone",data.phone);
      formData.append("address_id",data.address_id);
      formData.append("role_id",data.role_id);
      formData.append("product_ids", data.products.toString());


      this._service.saveData(formData)?.subscribe(
          (response: any) => {
            if (response.success) {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-success'
              });
            } else {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-danger'
              });
            }
          },
          (error:any) => {
            this._snackBar.open('Failed to save data. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });     
          }
        );
    }
    
    // Edit Reseller Data
    getResellerDataById(reseller_id:any){
      this._service.getComapnyById(reseller_id).subscribe(
        (response: any) => {
          if (response.success) {
           
            this.selectProductList = response.result.company_product;
            this.isEdit = true;
            this.logoPath = response.result.logo_path;

            // Checkbox Checked
          this.productList.forEach((res:any) => {
            this.selectProductList.forEach((spd:any)=>{
              if(res.id == spd.product_id){
                res.isChecBox = true;
                this.checkedProduct.push(spd.product_id);
              }
            })
          });


          this.onChangeContinent(response.result.address.continent);

            this.formUser.patchValue({
              name:response.result.name,
              main_contact_first_name:response.result.main_contact_first_name,
              main_contact_last_name:response.result.main_contact_last_name,
              phone: response.result.phone,
              email:response.result.email,
              website:response.result.website,
              id:response.result.id,
              postcode:response.result.address.postcode,
              address_1:response.result.address.address1,
              address_2:response.result.address.address2,
              address_3:response.result.address.address3,
              county:response.result.address.county,
              country:response.result.address.country,
              continent:response.result.address.continent,
              address_id:response.result.address.id,
              logo_path:response.result.logo_path,
              created_at:formatDate(response.result.created_at, 'yyyy-MM-dd', 'en-US')
            });
          }
        }
      )
    }
    // Get Product Checked
    updateChkbxArray(id:any, isChecked:any) {
      const products: FormArray = this.formUser.get('products') as FormArray;
      if (isChecked) {
        products.push(new FormControl(id));
      } else {
        let i: number = 0;
        products.controls.forEach((item: any) => {
          if (item.value == id) {
            products.removeAt(i);
            return;
          }
          i++;
        });
      }
    }

    
    scrollTo(el:any): void {
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
      this.scrollTo(firstElementWithError);
    }

    /***
 * Get Countries List on the base of the continet
 */
     onChangeContinent(event:any){
      this.continentsList.forEach((res:any) => {
        if(res.code == event){
         this.countriesList = res.countries;
        }
       });
      
    }

    phoneCode:string;
    onCountryChangeGetCountryCode(event:any){
     if(this.countriesList.length >0){
      this.countriesList.forEach((res:any)=>{
        if(res.code == event){
          this.phoneCode = "+"+res.phonecode;
        }
        
      });
     }
    }
  }
  
