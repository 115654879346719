<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Climate Action Responsibility (CAR) Performance Report</h2>
        </div>
    </div>
</div>

<!-- <div class="container-fluid mt-4">
        <div class="card shadow">
            <div class="card-body">
                <div class="form">
                    <div class="col-12" id="content">
                        <div class="dash-box"><a href="#">CAR Performance</a></div>
                        <div class="dash-box"><a href="#">Cimate Change Mitigation</a></div>
                        <div class="dash-box"><a href="#">Climate Change Adaptation</a></div>
                        <div class="dash-box"><a href="#">Policy and Regulation</a></div>
                        <div class="dash-box"><a href="#">Products and Services</a></div>
                        <div class="dash-box"><a href="#">Climate Finance and Investment</a></div>
                        <div class="dash-box"><a href="#">Philanthropic</a></div>
                        <div class="dash-box"><a href="#">Programmes / Initiatives</a></div>
                        <div class="dash-box"><a href="#">Information and Knowledge Management</a></div>
                        <div class="dash-box"><a href="#">GHG Emmission</a></div>
                        <div class="dash-box"><a href="#">Building Emmission</a></div>
                        <div class="dash-box"><a href="#">Facility Emmission</a></div>
                        <div class="dash-box"><a href="#">Carbon Offset</a></div>
                        <div class="dash-box"><a href="#">Green Tariffs</a></div>
                        <div class="dash-box"><a href="#">Corporate Department/Unit</a></div>
                        <div class="dash-box"><a href="#">Sustainable Procurement</a></div>
                        <div class="dash-box"><a href="#">Financial Cost</a></div>
                        <div class="dash-box"><a href="#">Financial Saving</a></div>
                        <div class="dash-box"><a href="#">Procurement Cost</a></div>
                        <div class="dash-box"><a href="#">January</a></div>
                        <div class="dash-box"><a href="#">February</a></div>
                        <div class="dash-box"><a href="#">March</a></div>
                        <div class="dash-box"><a href="#">April</a></div>
                        <div class="dash-box"><a href="#">May</a></div>
                        <div class="dash-box"><a href="#">June</a></div>
                        <div class="dash-box"><a href="#">July</a></div>
                        <div class="dash-box"><a href="#">August</a></div>
                        <div class="dash-box"><a href="#">September</a></div>
                        <div class="dash-box"><a href="#">October</a></div>
                        <div class="dash-box"><a href="#">November</a></div>
                        <div class="dash-box"><a href="#">December</a></div>
                        <div class="dash-box"><a href="#">4 Month</a></div>
                        <div class="dash-box"><a href="#">6 Month</a></div>
                        <div class="dash-box"><a href="#">12 Month</a></div>
                        <div class="dash-box"><a href="#">Report Order Confirmation</a></div>
                        <div class="dash-box"><a href="#">Report</a></div>
                        <br style="clear:both" />
                    </div>
                  </div>
            </div>
        </div>
    </div>  -->


<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <div class="form" [formGroup]="formCarReport">


                <div class="row">

                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Years</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="year" class="form-control form-control-alternative"
                                        name="year" required
                                        [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let y of yearsList" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.year.touched || submitted) && f.year.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Branch</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="branch" class="form-control form-control-alternative"
                                        name="branch" required
                                        [ngClass]="{ 'is-invalid': (f.branch.touched || submitted) && f.branch.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let b of branchList" [value]="b.id">
                                            {{b.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.branch.touched || submitted) && f.branch.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div *ngIf="div_1" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCARPerformanceTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carPerformance();">CAR Performance</a></div>
                    </div>
                    <div *ngIf="div_2" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCARActivityCategoryTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carActivityCategories();">CAR-Activity Categories</a>
                        </div>
                    </div>
                    <div *ngIf="div_3" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCARProductsAndServicesTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carProducts();">CAR Products and Services</a></div>
                    </div>
                    <div *ngIf="div_4" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCARFinanceInvestmentTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carFinanceInvestment();">Climate Finance and
                                Investment</a></div>
                    </div>
                    <div *ngIf="div_5" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCAREmployeeSmartWorkingTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carEmployeeSmartWorking();">CAR -Employees Smart
                                Working</a></div>
                    </div>
                    <div *ngIf="div_6" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCAREmployeeCummutingTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carEmployeeCommutingTravels();">CAR -Employees
                                Commuting Travels</a></div>
                    </div>
                    <div *ngIf="div_7" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCARValueChainTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carChainImpact();">CAR -Value Chain Impact</a></div>
                    </div>
                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJanCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">January</a></div>
                        <input formControlName="month_name" name="month_name" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.month_name.touched || submitted) && f.month_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.month_name.touched || submitted) && f.month_name.errors?.required">
                            Month is required
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isFabCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">February</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMarchCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">March</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAprilTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">April</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMayCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">May</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJuneCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">June</a></div>
                    </div>



                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJulyCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">July</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAugustCurrentTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="carmonth($event)">August</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isSeptCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">September</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isOctCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">October</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isNoveCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">November</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isDeceCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">December</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is3MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">3 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is6MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">6 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is12MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="carmonth($event)">12 Month</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row div_10" id="content" *ngIf="div_10">
                    <div class="col-md-2">
                        <div class="dash-box"><a href="javascript:void(0)" (click)="onSubmit()">Report</a></div>
                    </div>
                    <br style="clear:both" />
                </div>
                <div class="row mt-1">
                    <div class="col-md-2">
                        <button type="button" class="btn btn-save" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>