import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Business } from '../../../model/business/business.model';
import { BusinessService } from './business.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmDialogService } from '../../../confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../../services/shared.service';
import { formatDate } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
  submitted = false;
  company = new Business();
  businessList: any[] = [];
  checkedProduct: any[] = [];
  productList: any[] = [];
  selectProductList: any[] = [];
  isChecBox: boolean
  isEdit: boolean = false;
  roles: any[] = [];
  imagePath: string = environment.imageURL;
  logoPath: any;
  countriesList: any[] = [];
  continentsList: any[] = [];
  branchNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  currencyList: any = []
  financialYearList: any = []
  carYearList: any = []
  defaultReportingYear: string = '7axyc0bb-b933-4f28-bc70-b5cc47fafb95';
  priceList: any = [];
  isCompanyUser: boolean = false;
  industries: any[] = [];
  valuechain: any = [];
  valuechainValues: any = [];

  //CORPORATE USER
  is_contact_system_corporate: any
  is_email_use_for_corporate: any
  is_address_use_for_corporate: any
  is_corporate_department:any

  corporate_user_first_name: string = ""
  corporate_user_last_name: string = ""
  corporate_user_email: string = ""
  corporate_user_address1: string = ""
  corporate_user_address2: string = ""
  corporate_user_address3: string = ""
  corporate_user_id: string = ""
  isCorporateUser: boolean = false;

  constructor(private fb: FormBuilder, private businessService: BusinessService, private router: Router,
    private confirmDialogService: ConfirmDialogService, private route: ActivatedRoute, private _snackBar: MatSnackBar, private _service: SharedService, private sessionSt: LocalStorageService) { }


  formUser = this.fb.group({

    //role_id:'2b4e4105-463e-456e-8e5e-97ac397168e5',
    status: 0,
    main_contact_first_name: ['', Validators.required],
    main_contact_last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    website: ['', Validators.required],
    name: ['', Validators.required],
    address_1: ['', Validators.required],
    address_2: [''],
    address_3: [''],
    county: ['', Validators.required],
    continent: ['', Validators.required],
    postcode: ['', Validators.required],
    country: ['', Validators.required],
    phone: ['', Validators.required],
    role_id: ['', Validators.required],
    created_at: [''],
    id: '',
    address_id: '',
    products: this.fb.array([], Validators.required),
    logo_path: [''],
    branch_number: ['', Validators.required],
    industry: ['', Validators.required],
    // locality: ['', Validators.required],
    reporting_currency: ['', Validators.required],
    financial_reporting_year: ['', Validators.required],
    car_reporting_year: ['', Validators.required],
    //contract_doc_path:[],
    price_id: [''],
    //contract_notes:['', Validators.required],
    price: ['', Validators.required],
    new_customer_discount: ['', Validators.required],
    negotiated_discount: ['', Validators.required],
    plan_discount: ['', Validators.required],
    total_subscription_per_month: ['', Validators.required],
    industry_sector_id: ['', Validators.required],

    is_contact_system_corporate_administrator: ['', Validators.required],
    is_email_use_for_administrator_purposes: ['', Validators.required],
    is_address_use_for_administrator_office: ['', Validators.required],
    corporate_administrator_first_name: ['', Validators.required],
    corporate_administrator_last_name: ['', Validators.required],
    corporate_administrator_email: ['', Validators.required],
    corporate_administrator_address1: [''],
    corporate_administrator_address2: [''],
    corporate_administrator_address3: [''],
    corporate_user_id: '',
    process_status_id: '',
    // is_corporate_department_administrator: ['', Validators.required],
    corporate_department_unit_id: ['', Validators.required],
  });

  ngOnInit(): void {
    // GET VALUE CHAIN
    this._service.getCRValueChain().subscribe(
      (response: any) => {
        if (response.success) {
          this.valuechain = response.result.filter((x: any) => x.id == '66666666-6666-6666-6666-666666666662');
          this.valuechainValues = this.valuechain[0].value_chain_values;
        }
      })

    //Get All Industries
    this._service.getIndustries().subscribe(
      (response: any) => {
        if (response.success) {
          this.industries = response.result;
        }
      });

    let userinfo = this.sessionSt.retrieve('userInfo');
    if (userinfo && userinfo.id) {
      if (userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") {
        if (userinfo.company.id != "" && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
          this.isCompanyUser = true;
        }
        else {
          this.isCompanyUser = false;
        }
      }
      else {
        this.isCompanyUser = false;
      }
    }


    this.formUser.controls['financial_reporting_year'].setValue(this.defaultReportingYear, { onlySelf: true });
    this.formUser.controls['car_reporting_year'].setValue(this.defaultReportingYear, { onlySelf: true });
    this._service.getCurrency().subscribe((response: any) => {
      if (response.success) {
        this.currencyList = response.result;
      }
    });

    this._service.getReportingYearValues().subscribe((response: any) => {
      if (response.success) {
        this.financialYearList = response.result;
      }
    });

    //Get All Roles
    this._service.getRoles().subscribe(
      (response: any) => {
        if (response.success) {
          this.roles = response.result;
        }
      })

    //Get All Product List
    this._service.getProductList().subscribe(
      (response: any) => {
        if (response.success) {
          this.productList = response.result;
        }
      });

    //Get All Countries List
    // this._service.getCountriesList().subscribe(
    //   (response: any) => {
    //     if(response.success){
    //       this.countriesList = response.result;
    //     }
    //   });

    //Get All Continent List
    this._service.getContinentList().subscribe(
      (response: any) => {
        if (response.success) {
          this.continentsList = response.result;
        }
      });

    // Edit Company/Business By Id
    this.businessService.getComapnyById(this.route.snapshot.params.id).subscribe(
      (response: any) => {
        if (response.success) {

          this.company = response.result;
          this.selectProductList = response.result.company_product
          this.isEdit = true;
          this.logoPath = response.result.logo_path;

          // Checkbox Checked
          if ((this.productList.length > 0) && (this.selectProductList.length > 0)) {
            this.productList.forEach((res: any) => {
              this.selectProductList.forEach((spd: any) => {
                if (res.id == spd.product_id) {
                  res.isChecBox = true;
                  this.checkedProduct.push(spd.product_id);
                  this.updateChkbxArray(spd.product_id, true);
                }
              })
            });
          }



          this.onChangeContinent(response.result.address.continent);

          this.getAllPriceByCountryId(response.result.address.country);


          if (response.result.corporate_user != null) {
            this.isCorporateUser = true;

            this.is_contact_system_corporate = response.result.corporate_user.is_contact_system_corporate_administrator;
            this.is_email_use_for_corporate = response.result.corporate_user.is_email_use_for_administrator_purposes;
            this.is_address_use_for_corporate = response.result.corporate_user.is_address_use_for_administrator_office;
            this.is_corporate_department = response.result.corporate_user.is_corporate_department_administrator;

            this.corporate_user_first_name = response.result.corporate_user.corporate_administrator_first_name;
            this.corporate_user_last_name = response.result.corporate_user.corporate_administrator_last_name;
            this.corporate_user_email = response.result.corporate_user.corporate_administrator_email;
            this.corporate_user_address1 = response.result.corporate_user.corporate_administrator_address1;
            this.corporate_user_address2 = response.result.corporate_user.corporate_administrator_address2;
            this.corporate_user_address3 = response.result.corporate_user.corporate_administrator_address3;
            this.corporate_user_id = response.result.corporate_user.id;
          }


          this.formUser.patchValue({
            corporate_department_unit_id: this.company.corporate_department_unit_id,
            name: this.company.name,
            main_contact_first_name: this.company.main_contact_first_name,
            main_contact_last_name: this.company.main_contact_last_name,
            phone: this.company.phone,
            email: this.company.email,
            website: this.company.website,
            logo_path: response.result.logo_path,
            id: this.company.id,
            process_status_id: this.company.process_status_id,
            role_id: response.result.role_id,
            postcode: response.result.address.postcode,
            address_1: response.result.address.address1 != 'null' ? response.result.address.address1 : "",
            address_2: response.result.address.address2 != 'null' ? response.result.address.address2 : "",
            address_3: response.result.address.address3 != 'null' ? response.result.address.address3 : "",
            county: response.result.address.county,
            country: response.result.address.country,
            continent: response.result.address.continent,
            address_id: response.result.address.id,
            created_at: formatDate(response.result.created_at, 'yyyy-MM-dd', 'en-US'),
            industry: response.result.industry,
            industry_sector_id: response.result.industry_sector_id,
            // locality:response.result.locality,
            reporting_currency: response.result.reporting_currency,
            car_reporting_year: response.result.car_reporting_year,
            financial_reporting_year: response.result.financial_reporting_year,
            branch_number: this.company.branch_number,
            //contract_doc_path: response.result.contract_doc_path,
            price_id: response.result.price_id,
            //contract_notes:response.result.contract_notes,
            price: response.result.price,
            new_customer_discount: response.result.new_customer_discount,
            negotiated_discount: response.result.negotiated_discount,
            plan_discount: response.result.plan_discount,
            total_subscription_per_month: response.result.total_subscription_per_month,

            corporate_user_id: this.corporate_user_id,
            is_contact_system_corporate_administrator: this.is_contact_system_corporate,
            is_email_use_for_administrator_purposes: this.is_email_use_for_corporate,
            is_address_use_for_administrator_office: this.is_address_use_for_corporate,
            is_corporate_department_administrator:this.is_corporate_department,

            corporate_administrator_first_name: this.corporate_user_first_name,
            corporate_administrator_last_name: this.corporate_user_last_name,
            corporate_administrator_email: this.corporate_user_email,
            corporate_administrator_address1: this.corporate_user_address1,
            corporate_administrator_address2: this.corporate_user_address3,
            corporate_administrator_address3: this.corporate_user_address3,

            status : this.company.status

          });
        }
      }
    )
  }



  get f() { return this.formUser.controls; }

  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: any = null;
  getUploadedFile(files: any) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  /*
* Get Uploaded Logo
*/
  contarctfileName: string = '';
  contractfileToUpload: any = null;
  getContractUploadedFile(files: any) {
    this.contractfileToUpload = files.item(0);
    this.contarctfileName = this.contractfileToUpload.name;
  }
  //Save Data
  onSave() {
    this.submitted = true;


    if (this.isCorporateUser) {
      let IscorporateAdministrator = this.formUser.get('is_contact_system_corporate_administrator')?.value;
      if (IscorporateAdministrator == 2) {
        this.formUser.get("corporate_administrator_first_name")?.enable();
        this.formUser.get("corporate_administrator_last_name")?.enable();
      }
      else {
        this.formUser.get("corporate_administrator_first_name")?.disable();
        this.formUser.get("corporate_administrator_last_name")?.disable();
      }


      let IsCorporateAdministratorEmail = this.formUser.get('is_email_use_for_administrator_purposes')?.value;

      if (IsCorporateAdministratorEmail == 2) {
        this.formUser.get("corporate_administrator_email")?.enable();
      }
      else {
        this.formUser.get("corporate_administrator_email")?.disable();
      }
      // this.formUser.get("is_contact_system_corporate_administrator")?.enable();
      // this.formUser.get("is_email_use_for_administrator_purposes")?.enable();
      // this.formUser.get("is_address_use_for_administrator_office")?.enable();

      // this.formUser.get("corporate_administrator_first_name")?.enable();
      // this.formUser.get("corporate_administrator_last_name")?.enable();
      // this.formUser.get("corporate_administrator_email")?.enable();
    }
    else {
      this.formUser.get("is_contact_system_corporate_administrator")?.disable();
      this.formUser.get("is_email_use_for_administrator_purposes")?.disable();
      this.formUser.get("is_address_use_for_administrator_office")?.disable();

      this.formUser.get("corporate_administrator_first_name")?.disable();
      this.formUser.get("corporate_administrator_last_name")?.disable();
      this.formUser.get("corporate_administrator_email")?.disable();
    }

    // let IsCorporateDepartment = this.formUser.get('is_corporate_department_administrator')?.value;
    // if (IsCorporateDepartment == 2) {
    //   this.formUser.get("corporate_department_unit_id")?.enable();
    // }
    // else {
    //   this.formUser.get("corporate_department_unit_id")?.disable();
    // }

    if (this.formUser.invalid) {
      this.scrollToError();
      return;
    }

    let data = this.formUser.getRawValue();
    data.product_ids = data.products.toString();
    data.logo_path = this.fileToUpload;
    data.contract_doc_path = this.contractfileToUpload;
    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("logo_path", data.logo_path);
    formData.append("main_contact_first_name", data.main_contact_first_name);
    formData.append("main_contact_last_name", data.main_contact_last_name);
    formData.append("email", data.email ? data.email : '');
    formData.append("website", data.website ? data.website : '');
    formData.append("name", data.name ? data.name : '');
    formData.append("address_1", data.address_1);
    formData.append("address_2", data.address_2 ? data.address_2 : '');
    formData.append("address_3", data.address_3 ? data.address_3 : '');
    formData.append("county", data.county);
    formData.append("postcode", data.postcode);
    formData.append("country", data.country);
    formData.append("continent", data.continent);
    formData.append("phone", data.phone);
    formData.append("address_id", data.address_id);
    formData.append("role_id", data.role_id);
    formData.append("product_ids", data.products.toString());
    formData.append("process_status_id", data.process_status_id);

    formData.append("branch_number", data.branch_number);
    formData.append("industry", data.industry);
    // formData.append("locality", data.locality);
    formData.append("reporting_currency", data.reporting_currency);
    formData.append("car_reporting_year", data.car_reporting_year);
    formData.append("financial_reporting_year", data.financial_reporting_year);
    //formData.append("contract_doc_path", data.contract_doc_path);

    formData.append("price_id", data.price_id);
    //formData.append("contract_notes", data.contract_notes);
    formData.append("price", data.price);
    formData.append("new_customer_discount", data.new_customer_discount);
    formData.append("negotiated_discount", data.negotiated_discount);
    formData.append("plan_discount", data.plan_discount);
    formData.append("total_subscription_per_month", data.total_subscription_per_month);
    formData.append("industry_sector_id", data.industry_sector_id);


    if (this.isEdit) {
      formData.append("corporate_user_id", data.corporate_user_id);
      formData.append("is_contact_system_corporate_administrator", data.is_contact_system_corporate_administrator);
      formData.append("is_email_use_for_administrator_purposes", data.is_email_use_for_administrator_purposes);
      formData.append("is_address_use_for_administrator_office", data.is_address_use_for_administrator_office);

      formData.append("corporate_administrator_first_name", data.corporate_administrator_first_name ? data.corporate_administrator_first_name : '');
      formData.append("corporate_administrator_last_name", data.corporate_administrator_last_name ? data.corporate_administrator_last_name : '');
      formData.append("corporate_administrator_email", data.corporate_administrator_email ? data.corporate_administrator_email : '');

      formData.append("corporate_administrator_address1", data.corporate_administrator_address1 ? data.corporate_administrator_address1 : '');
      formData.append("corporate_administrator_address2", data.corporate_administrator_address2 ? data.corporate_administrator_address2 : '');
      formData.append("corporate_administrator_address3", data.corporate_administrator_address3 ? data.corporate_administrator_address3 : '');

      // formData.append("is_corporate_department_administrator", data.is_corporate_department_administrator);
      formData.append("corporate_department_unit_id", data.corporate_department_unit_id);
      formData.append("status", data.status);
    }


    this.businessService.saveBusinessData(formData)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
          this.router.navigate(['/business_list'])
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }

      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );

  }

  // Get Product Checked
  updateChkbxArray(id: any, isChecked: any) {
    const products: FormArray = this.formUser.get('products') as FormArray;
    if (isChecked) {
      products.push(new FormControl(id));
    } else {
      let i: number = 0;
      products.controls.forEach((item: any) => {
        if (item.value == id) {
          products.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  scrollTo(el: any): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  /***
   * Get Countries List on the base of the continet
   */
  onChangeContinent(event: any) {
    this.continentsList.forEach((res: any) => {
      if (res.code == event) {
        this.countriesList = res.countries;
      }
    });

  }

  phoneCode: string;
  onCountryChangeGetCountryCode(event: any) {
    if (this.countriesList.length > 0) {
      this.countriesList.forEach((res: any) => {
        if (res.code == event) {
          this.phoneCode = "+" + res.phonecode;
        }

      });
    }
  }

  getAllPriceByCountryId(value: any) {
    //Get All Branches List
    this._service.getPriceListByCountryId(value).subscribe(
      (response: any) => {
        if (response.success) {
          this.priceList = response.result;
        }
      });
  }

  getPriceByUserInfo(event: any) {
    //Get All Branches List
    let selectedvaluechaingreen = this.priceList.filter((x: any) => x.id == event.value);
    this.formUser.controls['price'].setValue(selectedvaluechaingreen[0].price + '.00', { onlySelf: true });
  }
  onClickContactAdmin(event: any) {
    if (event == '2') {
      this.formUser.get("corporate_administrator_first_name")?.enable();
      this.formUser.get("corporate_administrator_last_name")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_first_name")?.disable();
      this.formUser.get("corporate_administrator_last_name")?.disable();
    }
  }
  onClickEmailAdmin(event: any) {
    if (event == '2') {
      this.formUser.get("corporate_administrator_email")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_email")?.disable();
    }
  }
}
