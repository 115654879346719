import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cer-records',
  templateUrl: './cer-records.component.html',
  styleUrls: ['./cer-records.component.scss']
})
export class CerRecordsComponent implements OnInit {

  // Property form
  formUser = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    email: ['', Validators.required],
    active: ['1'],
    password: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    password_confirmation: ['', [Validators.required]],
    operator_code: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
    is_admin: ['0', Validators.required],
    exec_code: ['', [Validators.required]]
  });

  carDataList:[];  

  constructor(private fb: FormBuilder, private _service: SharedService, 
    private router: Router,
    private route: ActivatedRoute,private sessionSt: LocalStorageService, 
    private modalService: NgbModal, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getAllCarData();
  }

  //Grid For Branch Data
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api?.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Goal', sortable: true,field: 'name', width: 107,
      cellRenderer: function (params:any) {
        if (params.data.goal) {
          return params.data.goal.name;
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'GHG', width: 107,
      cellRenderer: function (params:any) {
        if (params.data.goal_value) {
          return params.data.goal_value.name;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Category',width: 107,
      cellRenderer: function (params:any) {
        if (params.data.category) {
          return params.data.category.name;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Activity',width: 107,
      cellRenderer: function (params:any) {
        if (params.data.category_value) {
          return params.data.category_value.name;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Action', width: 50,
      cellRenderer: function (params:any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];

  /*
  * GRID BUTTONS ACTION
  */
    msg: string = '';
    company_id: string = '';
    rowIndex: number = 0;

    onGridRowClicked(e: any,content:any) {
      if (e) {
        this.company_id = e.data.id;
        this.rowIndex = e.rowIndex;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "edit":
          {
            this.router.navigate(['/cr_record', e.data.id]);
            break;
          }
        }
      }
    }

/** Get ALL Leads Detail
  **/
  getAllCarData(){
    this._service.getCARList().subscribe(
      (response: any) => {
        if(response.success){
          this.carDataList = response.result;
        }
      }
    );
  }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'modal-default', size: 'md', centered: true });
  }
}
