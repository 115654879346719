<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Financial Responsibility (CFR) Performance Analysis</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">

    <div class="card shadow">

        <div class="card-body">
            <div class="form" [formGroup]="formCfrReport">
                <div class="row">

                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Years</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="year" class="form-control form-control-alternative"
                                        name="year" required
                                        [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let y of yearsList" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.year.touched || submitted) && f.year.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Branch</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="branch" class="form-control form-control-alternative"
                                        name="branch" required
                                        [ngClass]="{ 'is-invalid': (f.branch.touched || submitted) && f.branch.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let b of branchList" [value]="b.id">
                                            {{b.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.branch.touched || submitted) && f.branch.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="row" id="content">
                    <div *ngIf="div_1" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCsrGoalsTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrGovernance();">Corporate Responsibilities &
                                Sustainability (CRS) Governance - Goals</a>
                        </div>
                    </div>
                    <div *ngIf="div_2" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCfrShareTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrOptimumShareTarget();">CFR-Value Chain Optimum
                                Share /Public Value Target</a>
                        </div>
                    </div>
                    <div *ngIf="div_3" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCfrProfitTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrOptimumProfitTarget();">CFR-Value Chain Optimum
                                Profit/Surplus Target </a>
                        </div>
                    </div>
                    <div *ngIf="div_4" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCfrNonSustainableTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrBrownCategory();">CFR-Activity Unsustainable / Brown Category </a>
                        </div>
                    </div>
                    <div *ngIf="div_5" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCfrSustainabilityGreenTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrGreenCategory();">CFR–Activity Sustainability / Green Category</a>
                        </div>
                    </div>
                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJanCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="cfrmonth($event)">January</a>
                        </div>
                        <input formControlName="month_name" name="month_name" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.month_name.touched || submitted) && f.month_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.month_name.touched || submitted) && f.month_name.errors?.required">
                            Month is required
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isFabCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">February</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMarchCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">March</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAprilTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">April</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMayCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">May</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJuneCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">June</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJulyCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">July</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAugustCurrentTab) ? 'active':''"><a
                                href="javascript:void(0)" (click)="cfrmonth($event)">August</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isSeptCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">September</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isOctCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">October</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isNoveCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">November</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isDeceCurrentTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">December</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is3MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">3 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is6MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">6 Month</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is12MonthTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="cfrmonth($event)">12 Month</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row" id="content" *ngIf="div_11">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isTabularTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="reportType($event)">Tabular Analysis</a></div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isChartTab) ? 'active':''"><a href="javascript:void(0)"
                                (click)="reportType($event)">Chart Analysis</a></div>
                        <input formControlName="report_type" name="report_type" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.report_type.touched || submitted) && f.report_type.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.report_type.touched || submitted) && f.report_type.errors?.required">
                            Report Type is required
                        </div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row div_10" id="content" *ngIf="div_10">
                    <div class="col-md-2">
                        <div class="dash-box"><a href="javascript:void(0)" (click)="onSubmit()">Report</a></div>
                    </div>

                    <br style="clear:both" />
                </div>
                <div class="row mt-1">
                    <div class="col-md-2">
                        <button type="button" class="btn btn-save" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>