<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <!-- <h2>Corporate Financial Responsibility (CFR) Performance -Plan Record</h2> -->
            <h2>Corporate Responsibilities (CR) Performance -Plan Record</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Target Month Date</label>
                    </div>
                    <div class="col-md-5">
                        <!-- <input type="text" formControlName="target_month_date"
                            class="form-control form-control-alternative datepicker" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker"  (dateChange)="addEvent($event)"
                            [ngClass]="{ 'is-invalid': (f.target_month_date.touched || submitted) && f.target_month_date.errors?.required }">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(f.target_month_date.touched || submitted) && f.target_month_date.errors?.required">
                            This field is required
                        </div> -->

                        <select class="form-control"  (change)="yearChange($event.target.value)">
                            <option value="">--Select Year--</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                        </select>

                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Plan Type</label>
                    </div>
                    <!-- <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="plan_id" class="form-control form-control-alternative"
                                name="plan_id" required (selectionChange)="onPlanChange($event)"
                                [ngClass]="{ 'is-invalid': (f.plan_id.touched || submitted) && f.plan_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let plan of plantype" [value]="plan.id">
                                    {{plan.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.plan_id.touched || submitted) && f.plan_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div> -->
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="sub_plan_id" class="form-control form-control-alternative"
                                name="sub_plan_id" required (selectionChange)="onSublanChange($event)"
                                [ngClass]="{ 'is-invalid': (f.sub_plan_id.touched || submitted) && f.sub_plan_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let spt of subPlanType" [value]="spt.id">
                                    {{spt.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.sub_plan_id.touched || submitted) && f.sub_plan_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group" *ngIf="f.plan_id.value">
                    <div class="col-md-3">
                        <label>Sub Plan Type</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="sub_plan_id" class="form-control form-control-alternative"
                                name="sub_plan_id" required (selectionChange) = "onSublanChange($event)"
                                [ngClass]="{ 'is-invalid': (f.sub_plan_id.touched || submitted) && f.sub_plan_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let spt of subPlanType" [value]="spt.id">
                                    {{spt.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.sub_plan_id.touched || submitted) && f.sub_plan_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group" *ngIf="f.sub_plan_id.value">
                    <div class="col-md-3">
                        <label>{{cost_performance_label}}</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="cost_performance" class="form-control form-control-alternative"
                                name="cost_performance" required (selectionChange)="onCostPerformanceChange()"
                                [ngClass]="{ 'is-invalid': (f.cost_performance.touched || submitted) && f.cost_performance.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option [value]="1">Annual Total</mat-option>
                                <mat-option [value]="2">Monthly</mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.cost_performance.touched || submitted) && f.cost_performance.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.cost_performance.value == '1'">
                    <div class="col-md-3">
                        <label>{{cost_performance_label}} (Annual Total)</label>
                    </div>
                    <div class="col-md-5">
                        <input type="number" name="cost_performance_annual_total" appNumbers
                            formControlName="cost_performance_annual_total" required min="0"
                            (change)="onCostPerformance($event.target.value)"
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.cost_performance_annual_total.touched || submitted) && f.cost_performance_annual_total.errors?.required }">
                        <div class="invalid-feedback"
                            *ngIf="(f.cost_performance_annual_total.touched || submitted) && f.cost_performance_annual_total.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.cost_performance.value == '2' && isAnnualTotal">
                    <div class="col-md-3">
                        <label>{{cost_performance_label}} (Annual Total)</label>
                    </div>
                    <div class="col-md-5">
                        <input type="number" name="cost_performance_annual_total" appNumbers disabled="disabled"
                            formControlName="cost_performance_annual_total" required min="0"
                            (change)="onCostPerformance($event.target.value)"
                            class="form-control form-control-alternative">
                    </div>
                </div>

                <!-- <div class="row formFieldsBg form-group"
                    *ngIf="f.plan_id.value =='11111111-1111-1111-1111-111111111112'">
                    <div class="col-md-3">
                        <label>CFR-Plan Record</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="cfr_plan_id" class="form-control form-control-alternative"
                                name="cfr_plan_id" required (selectionChange)="onCFRPlanChange($event)"
                                [ngClass]="{ 'is-invalid': (f.cfr_plan_id.touched || submitted) && f.cfr_plan_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let mnth of monthArray | keyvalue" [value]="mnth.key">
                                    {{mnth.value}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.cfr_plan_id.touched || submitted) && f.cfr_plan_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div> -->

                <div *ngIf="f.cost_performance.value">
                    <div formArrayName="cr_months" *ngFor="let mnth of monthArr.controls; let i=index">
                        <div [formGroupName]="i">
                            <div class="row formFieldsBg form-group">
                                <div class="col-md-3">
                                    <label>{{cost_performance_label}} (Month {{i+1}})</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="hidden" formControlName="month_number" name="month_number"
                                        [value]="i+1" appNumbers>
                                    <input type="number" name="value" appNumbers formControlName="value" required
                                        min="0" (change)="onChangeMonthlyValue($event)"
                                        class="form-control form-control-alternative">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.sub_plan_id.value">
                    <div class="col-md-3">
                        <label>Finance and Investment Added to Plan</label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="IsFinanceAndInvestmet" formControlName="IsFinanceAndInvestmet"
                        [value]="1"> Yes
                        <input type="radio" name="IsFinanceAndInvestmet" formControlName="IsFinanceAndInvestmet"
                        [value]="2"> No
                        <div class="invalid-feedback"
                            *ngIf="(f.IsFinanceAndInvestmet.touched || submitted) && f.IsFinanceAndInvestmet.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div>
                    <app-cfr [item]="currentItem" formControlName="car_data" [submitted]="submitted"
                        *ngIf="f.sub_plan_id.value=='33333333-3333-3333-3333-333333333331'"></app-cfr>
                    <app-csr [item]="currentItem" formControlName="car_data" [submitted]="submitted"
                        *ngIf="f.sub_plan_id.value=='33333333-3333-3333-3333-333333333332'"></app-csr>
                    <app-cer [item]="currentItem" formControlName="car_data" [submitted]="submitted"
                        *ngIf="f.sub_plan_id.value=='33333333-3333-3333-3333-333333333333'"></app-cer>
                    <!-- <app-add-record [item]="currentItem" formControlName="car_data" *ngIf="f.sub_plan_id.value=='33333333-3333-3333-3333-333333333334'"></app-add-record> -->
                </div>


                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-save" (click)="onSave()">Save</button>
                        <button type="button" class="btn btn-save" (click)="onSubmit(helpTextPopupModal)">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
    <ng-template #helpTextPopupModal let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="msg"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
    <!-- <p>
    Form is {{formUser.valid ? 'Valid' : 'Invalid'}}
</p> 
<pre>
            {{formUser.value | json}}
            </pre> -->