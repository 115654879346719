<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Social Responsibility (CSR) Performance -Plan Record</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body cr-data-hegiht">
            <div class="form">

              </div>
        </div>
    </div>
</div>
