<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Responsibilities (CR) Performance -Actual Daily Data</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <select class="form-control" (change)="yearChange($event.target.value)">
                        <option value="">--Select Year--</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                    </select>
                </div>
            </div>
            <br/>
            <table>
                <thead>
                    <tr>
                        <th colspan="4">Activities Data Entry Date</th>
                        <th colspan="5">    <form [formGroup]="formUser" (ngSubmit)="onClear()">
                            <button type="button" class="btn btn-new-primary" (click)="onClear()">Clear</button>
                        </form></th>
                        <!-- {{todaydate | date: 'dd-MM-yyyy'}} -->
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Categories</td>
                        <td class="td-text-center">Entry Count</td>
                        <td colspan="2" class="td-text-center">CFR</td>
                        <td class="td-text-center">CSR</td>
                        <td class="td-text-center">CER</td>
                        <td colspan="3" class="td-text-center">CAR</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Saving</td>
                        <td>Cost</td>
                        <td>Cost</td>
                        <td>Cost</td>
                        <td>Carbon Offset</td>
                        <td>Green Tariffs</td>
                        <td>GHG</td>
                    </tr>
                    <tr *ngFor="let dailyData of dailyDataList; let i=index">
                        <td>{{dailyData.name}}</td>
                        <td class="td-text-center">
                            {{dailyData.totalEntryCount}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.cfr_saving}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.cfr_cost}}
                        </td>

                        <td class="td-text-center">
                            {{dailyData.csr_cost}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.cer_cost}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.carbon_offset}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.green_tariffs}}
                        </td>
                        <td class="td-text-center">
                            {{dailyData.ghg}}
                        </td>

                    </tr>
                    <tr>
                        <th>Total:</th>
                        <td class="td-text-center">
                            {{totalentryCount}}
                        </td>
                        <td class="td-text-center">
                            {{cfrSavingVal}}
                        </td>
                        <td class="td-text-center">
                            {{cfrcosttotal}}
                        </td>

                        <td class="td-text-center">
                            {{csrCostVal}}
                        </td>
                        <td class="td-text-center">
                            {{cerCostVal}}
                        </td>
                        <td class="td-text-center">
                            {{carbonOffsetVal}}
                        </td>
                        <td class="td-text-center">
                            {{greenTariffVal}}
                        </td>
                        <td class="td-text-center">
                            {{ghgCalVal}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>