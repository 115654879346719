<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            Create Register User
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Role<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="group_id"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.group_id.touched || submitted) && f.group_id.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let group of userGroupList" [value]="group.id">
                                    {{group.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.group_id.touched || submitted) && f.group_id.errors?.required">
                            Role is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Registered User First Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="first_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.first_name.touched || submitted) && f.first_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.first_name.touched || submitted) && f.first_name.errors?.required">
                            Registered user name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Registered User Last Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="last_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.last_name.touched || submitted) && f.last_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.last_name.touched || submitted) && f.last_name.errors?.required">
                            Registered user name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>ESGMS Username<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="username" class="form-control" (change)="getUserName($event)" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                            <div *ngIf="f.username.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Email Address<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="isEdit">
                    <div class="col-md-3">
                        <label>Password Management<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="password" type="password" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.password.touched || submitted) && f.password.errors?.required">
                            Password is required
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                        <button type="button"[routerLink]="['/esgmsuserslist']"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>