import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-agent-leads-list',
  templateUrl: './agent-lead-list.component.html',
  styleUrls: ['./agent-lead-list.component.css']
})
export class AgentLeadListComponent implements OnInit {

  
  constructor(private fb: FormBuilder, private _service: SharedService, 
    private router: Router,
    private route: ActivatedRoute,private sessionSt: LocalStorageService, 
    private modalService: NgbModal, private _snackBar: MatSnackBar) {}

    leadsList:[]
    countriesList:[]



  ngOnInit(): void {
    this.getAllLeadsData();
        //Get All Countries List
        this._service.getCountriesList().subscribe(
          (response: any) => {
            if (response.success) {
              this.countriesList = response.result;
            }
          });
  }

    //Grid For Branch Data
    gridOptions: GridOptions = {
      onGridReady: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    };
    columnDefs = [
      {
        headerName: 'Name', sortable: true,field: 'name', width: 107
      },
      {
        headerName: 'Country', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.country_name !=null) {
            return params.data.country_name;
          } 
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Contact Name', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.main_contact_first_name) {
            return params.data.main_contact_first_name + ' ' + params.data.main_contact_last_name;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Email', sortable: true,field: 'email', width: 107
      },
      {
        headerName: 'Website', sortable: true,field: 'website', width: 107
      },
      {
        headerName: 'Phone', sortable: true,field: 'phone', width: 107
      },
      {
        headerName: 'Process Status', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.process_status_id == "1ad8c0bb-b933-4f28-bc70-b5cc47fafb95") {
            return "Update";
          } else if(params.data.process_status_id == "2ad8c0bb-b933-4f28-bc70-b5cc47fafb95") {
            return "Pending";
          }
          else if(params.data.process_status_id == "3ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Contact-Awaiting response";
          }
          else if(params.data.process_status_id == "4ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Contact-Response received";
          }
          else if(params.data.process_status_id == "5ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Saved";
          }
          else if(params.data.process_status_id == "6ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Processed";
          }
          else if(params.data.process_status_id == "7ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Process Suspended";
          }
          else if(params.data.process_status_id == "8ad8c0bb-b933-4f28-bc70-b5cc47fafb95"){
            return "Process Archived";
          }
          else{
            return "Form Recevied";
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Action', width: 50,
        cellRenderer: function (params:any) {
          let links = '';
          links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          if(params.data.process_status_id != "6ad8c0bb-b933-4f28-bc70-b5cc47fafb95" || params.data.process_status_id != "4ad8c0bb-b933-4f28-bc70-b5cc47fafb95" ){
            links += '<a  title="Send Email" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-email" data-action-type="sendemail" id="' + params.data.id + '"></i></a>';
          }
          // links += '<a title="Active User" style="color:#fff;cursor: pointer;" class="btn btn-sm btn-primary" data-action-type="active">Active</a>';
          return links;
        }
      },
    ];


      /*
  * GRID BUTTONS ACTION
  */
      msg: string = '';
      company_id: string = '';
      rowIndex: number = 0;
  
      onGridRowClicked(e: any,content:any) {
        if (e) {
          this.company_id = e.data.id;
          this.rowIndex = e.rowIndex;
          let actionType = e.event.target.getAttribute("data-action-type");
          switch (actionType) {
            case "edit":
            {
              this.router.navigate(['/edit_lead', e.data.id]);
              break;
            }
            case "sendemail":
            {
              this.msg = 'Are you sure you want to send email ?';
              this.modalService.open(content, { centered: true });
              break;
            }
          }
        }
      }

  /*
 * Active Lead User
 */
  UpdateLeadCompanyStatus(company_id:any, rowIndex:any) {
    let data: any = '';
    data = {
      id: company_id,
      status:1
    };

    this._service.UpdateLeadStatus(data)
      ?.subscribe(
      (response: any) => {
        if (response.success) {
          this.modalService.dismissAll();
          this.leadsList.splice(rowIndex, 1);
          this.gridOptions.api?.setRowData(this.leadsList)
          this.getAllLeadsData();
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });

        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }

      });
    }

      /*
 * Active Lead User
 */
  SendEmail(company_id:any) {
    let data: any = '';
    data = {
      id: company_id,
    };

    this._service.SendEmmailToLead(data)
      ?.subscribe(
      (response: any) => {
        if (response.success) {
          this.modalService.dismissAll();
          this.getAllLeadsData();
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });

        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }

      });
    }
    
    /*
    * Get ALL Leads Detail
     * 
     */
    getAllLeadsData(){
      let userinfo = this.sessionSt.retrieve('userInfo');
      if(userinfo !=null){
        this._service.getAgentLeadsByCompanyId(userinfo.company.id).subscribe(
          (response: any) => {
            if(response.success){
              this.leadsList = response.result;
            }
          }
        );
      }
    }

}
