<div class="header bg-gradient-primary">
    <div class="container-fluid">
      <div class="header-body">
      </div>
    </div>
  </div>
  <!-- Page content -->
  <div class="container-fluid">
    <!-- Table -->
    <!-- <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header border-0">
            <h3 class="mb-0">Branches <a class="btn btn-sm btn-success float-right" [routerLink]="['/user_account']">Add Branch</a></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-1">
      <ag-grid-angular
      style="width: 100%; height: 380px; background-color: #3b6c54 !important"
      class="ag-theme-bootstrap"
      [gridOptions]="gridOptions"
      [rowData]="branchData"
      [columnDefs]="columnDefs"
      animateRows
      pagination
      paginationPageSize = 10
      rowHeight = 27
      headerHeight=35
      (rowClicked)='onGridRowClicked($event)'
      >
    </ag-grid-angular>
    </div> -->
  </div>

  <!-- Page content -->
<div class="container-fluid mt-4">
  <div class="card shadow">
      <div class="card-header border-0">
          <div class="row align-items-center">
              <div class="col">
                  <h3 class="mb-0">Branches</h3>
              </div>

              <div class="col text-right">
                  <a class="btn btn-sm btn-primary" *ngIf="isSuperAdmin && customerId=='2b4e4105-463e-456e-8e5e-97ac397168e5'" [routerLink]="['/business_list']">Back To List</a>
                  <a class="btn btn-sm btn-primary" *ngIf="isSuperAdmin && customerId=='5b4e4105-463e-456e-8e5e-97ac397168e5'" [routerLink]="['/agent_list']">Back To List</a>
                  <a class="btn btn-sm btn-primary" *ngIf="isSuperAdmin && customerId=='4b4e4105-463e-456e-8e5e-97ac397168e5'" [routerLink]="['/reseller_list']">Back To List</a>
                  <a class="btn btn-sm btn-primary" *ngIf="isSuperAdmin || isCompanyUser" [routerLink]="['/branch',company_id]">Add New Branch</a>
              </div>
          </div>
      </div>
      <div class="card-body p-1">
          <ag-grid-angular
          style="width: 100%; height: 380px; background-color: #3b6c54 !important"
          class="ag-theme-bootstrap"
          [gridOptions]="gridOptions"
          [rowData]="branchData"
          [columnDefs]="columnDefs"
          animateRows
          pagination
          paginationPageSize = 10
          rowHeight = 27
          headerHeight=35
          suppressHorizontalScroll= true
          (rowClicked)='onGridRowClicked($event,content,emailcontent)'
          >
        </ag-grid-angular>
        </div>
  </div>
</div>

  <ng-template #coModal let-c="close" let-d="dismiss">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">User Form</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body formUser">
        <form [formGroup]="formUser" (ngSubmit)="onSave()">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Full Name</small>
                <input formControlName="name" type="text" placeholder="" class="form-control form-control-alternative"
                  ngbAutofocus />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Operator Code</small>
                <input formControlName="operator_code" type="text" placeholder=""
                  class="form-control form-control-alternative" />
                <div *ngIf="submitted && f.operator_code.errors" class="invalid-feedback">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Exec Code</small>
                <input formControlName="exec_code" type="text" placeholder=""
                  class="form-control form-control-alternative" />
                <div *ngIf="submitted && f.exec_code.errors" class="invalid-feedback">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Email
                </small>
                <input formControlName="email" type="email" placeholder="" class="form-control form-control-alternative"
                  autocomplete="off" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  The field is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" formControlName="is_admin" id="is_admin" type="checkbox" value="0">
                <label class="custom-control-label" for="is_admin">
                  <span>Is Admin</span>
                </label>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6" *ngIf="(f && f.id.value > 0)">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" formControlName="active" id="active" type="checkbox" value="1">
                <label class="custom-control-label" for="active">
                  <span>Is Active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!(f.id.value > 0)">
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Password</small>
                <input formControlName="password" type="password" placeholder=""
                  class="form-control form-control-alternative" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  This field is required and must be between 8 and 30 characters, contain 1
                  uppercase, 1 Number and
                  1 special character.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <small class="d-block text-uppercase font-weight-bold ">Confirm Password</small>
                <input formControlName="password_confirmation" type="password" placeholder=""
                  class="form-control form-control-alternative" />
                <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                  The Passwords do not match.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
      </div>
    </div>
  </ng-template>
  <ng-template #content let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="deleteCompany(branch_id,rowIndex)">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #emailcontent let-modal>
  <div class="modal-body">
      {{msg}}
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger btn-sm" (click)="sendEmail(branch_id,rowIndex)">Yes</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">No</button>
  </div>
</ng-template>