import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-business-account-management',
  templateUrl: './business-account-management.component.html',
  styleUrls: ['./business-account-management.component.css']
})
export class BusinessAccountManagementComponent implements OnInit {

  businessList:[]


  constructor(private fb: FormBuilder, private _service: SharedService, private router: Router,
    private route: ActivatedRoute,private sessionSt: LocalStorageService, private modalService: NgbModal, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    
    this.getCompanyList();
  }

    //Grid For Branch Data
    gridOptions: GridOptions = {
      onGridReady: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    };
    columnDefs = [
      {
        headerName: 'Name', sortable: true,field: 'name', width: 107
      },
      {
        headerName: 'Contact Name', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.main_contact_first_name) {
            return params.data.main_contact_first_name + ' ' + params.data.main_contact_last_name;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Email', sortable: true,field: 'email', width: 107
      },
      {
        headerName: 'Website', sortable: true,field: 'website', width: 107
      },
      {
        headerName: 'Phone', sortable: true,field: 'phone', width: 107
      },
      {
        headerName: 'Status', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.status == 1) {
            return "Active";
          } else {
            return "Inactive";
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Action', width: 100,
        cellRenderer: function (params:any) {
          let links = '<a  title="Edit Company" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-eye" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a title="View Branchs" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-source-branch" data-action-type="branches" id="' + params.data.id + '"></i></a>';
          links += '<a title="View Users" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-account-multiple-outline" data-action-type="users" id="' + params.data.id + '"></i></a>';
          links += '<a title="Delete Company" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
          return links;
        }
      },
    ];


  /*
  * GRID BUTTONS ACTION
  */
    msg: string = '';
    company_id: string = '';
    rowIndex: number = 0;

    onGridRowClicked(e: any,content:any) {
      if (e) {
        this.company_id = e.data.id;
        this.rowIndex = e.rowIndex;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "edit":
            {
              this.router.navigate(['/business', e.data.id]);
              break;
            }
          case "branches":
          {
            this.router.navigate(['/branches', e.data.id]);
            this.sessionSt.store('customer_id','2b4e4105-463e-456e-8e5e-97ac397168e5');
            this.sessionSt.store('company_id', e.data.id);
            break;
          }
          case "users":
            {
              this.router.navigate(['/user_list', e.data.id]);
              this.sessionSt.store('customer_id','2b4e4105-463e-456e-8e5e-97ac397168e5');
              break;
            }
          case "delete":
            {
              this.msg = 'Are you sure you want to delete this company?';
              this.modalService.open(content, { centered: true });
              break;
            }
        }
      }
    }

 /*
 * DELETE COMPANY
 */
  deleteCompany(company_id:any, rowIndex:any) {
    let data: any = '';
    data = {
      id: company_id
    };

    this._service.deleteCompany(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            // this.businessList.splice(rowIndex, 1);
            // this.gridOptions.api?.setRowData(this.businessList)
            this.getCompanyList();
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Get Company List
  */
 getCompanyList(){
  let company_id = '2b4e4105-463e-456e-8e5e-97ac397168e5';
  let userinfo = this.sessionSt.retrieve('userInfo');
  this._service.getBusinessDetails(userinfo.company.id).subscribe(
     (response: any) => {
       this.businessList = response.result;
     }
   );
 }

}
