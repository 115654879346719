import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-corporate-plan',
  templateUrl: './corporate-plan.component.html',
  styleUrls: ['./corporate-plan.component.css']
})
export class CorporatePlanComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) { }

  formUser: FormGroup;
  plantype: any = [];
  planList: any = [];
  ngOnInit(): void {
    // GET PLANS
    this._service.getPlanData().subscribe(
      (response: any) => {
        if (response.success) {
          this.plantype = response.result;
          let selectedPlan = this.plantype.filter((x: any) => x.id == '11111111-1111-1111-1111-111111111111');
          if (selectedPlan.length > 0) {
            this.subPlanType = selectedPlan[0].cr_subplan_type;
          }
        }
      });

      this.formUser = this.fb.group({
        id: [''],
        plan_id: [''],
        sub_plan_id: ['', Validators.required],
        cost_performance: ['', Validators.required],
        cost_performance_annual_total: ['', Validators.required],
        cfr_plan_id: [''],
        goal_id: [''],
        goal_value_id: [''],
        category_id: [''],
        activity_mark_id: [''],
        activity_mark_cost: ['0:00'],
        car_data: [],
        month_number_1:[''],
        month_number_2:[''],
        month_number_3:[''],
        month_number_4:[''],
        month_number_5:[''],
        month_number_6:[''],
        month_number_7:[''],
        month_number_8:[''],
        month_number_9:[''],
        month_number_10:[''],
        month_number_11:[''],
        month_number_12:[''],
        target_month_date: [''],
      });
  
  }
  get f() { return this.formUser.controls; }
  subPlanType: any = [];
  onPlanChange() {
    let selectedPlan = this.plantype.filter((x: any) => x.id == '11111111-1111-1111-1111-111111111111');
    if (selectedPlan.length > 0) {
      this.subPlanType = selectedPlan[0].cr_subplan_type;
    }
  }


    /**
   * SHOW HIDE TEXT BOX ON CHANGE COST TYPE
   * 
   */

  /**
   * 22222222-2222-2222-2222-222222222221 - CFR-Cost Performance
   * 22222222-2222-2222-2222-222222222222 - CFR–Saving  Performance
   * 22222222-2222-2222-2222-222222222223 - CSR–Cost  Performance
   * 22222222-2222-2222-2222-222222222225 - CER–Cost  Performance
   * 22222222-2222-2222-2222-222222222226 - CAR-Carbon Offset Performance
   * 22222222-2222-2222-2222-222222222227 - CAR-Green Tariff Performance
   * 22222222-2222-2222-2222-222222222228 - CAR-GHG Performance
   */

   cost_performance_label: string = '';

   onSublanChange(event: any) {
     let planType = event.value;
     this.getCorporatePlanByPlanId(planType);
     switch (planType) {
       case '22222222-2222-2222-2222-222222222221':
         this.cost_performance_label = "CFR-Cost Performance";
         break;
       case '22222222-2222-2222-2222-222222222222':
         this.cost_performance_label = "CFR–Saving  Performance";
         break;
       case '22222222-2222-2222-2222-222222222223':
         this.cost_performance_label = "CSR–Cost  Performance";
         break;
       case '22222222-2222-2222-2222-222222222225':
         this.cost_performance_label = "CER–Cost  Performance";
         break;
       case '22222222-2222-2222-2222-222222222226':
         this.cost_performance_label = "CAR-Carbon Offset Tonnes CO2e Performance";
         break;
       case '22222222-2222-2222-2222-222222222227':
         this.cost_performance_label = "CAR-Green Tariff Tonnes CO2e Performance";
         break;
       case '22222222-2222-2222-2222-222222222228':
         this.cost_performance_label = "CAR-GHG Tonnes CO2e Performance";
         break;
     }
   }
   
   getCorporatePlanByPlanId(planId:any){
    this._service.getCorporatePlanDataByPLanId(planId,this.selectedDate)?.subscribe(
      (response: any) => {
        if (response.success) {
          this.formUser.patchValue({
            id:response.result.id,
            cost_performance:response.result.cost_performance,
            cost_performance_annual_total:response.result.total_annual_cost_performace,
            month_number_1:response.result.First,
            month_number_2:response.result.Second,
            month_number_3:response.result.Third,
            month_number_4:response.result.Forth,
            month_number_5:response.result.Fifth,
            month_number_6:response.result.Sixth,
            month_number_7:response.result.Seventh,
            month_number_8:response.result.Eight,
            month_number_9:response.result.Nineth,
            month_number_10:response.result.Tenth,
            month_number_11:response.result.Eleventh,
            month_number_12:response.result.Twelveth
           });
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }
  selectedDate:any= Date;
  addEvent(event:any) {
    console.log(event.target.value);
    this.selectedDate = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
  }
}
