import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class LeadUserRegistrationService {


  constructor(private httpClient: HttpClient, private sharedService: SharedService) { }



  requestpassword(data: any) {
    return this.sharedService.postAPI('requestpassword', data);
  }

  resetpassword(data: any) {
    return this.sharedService.postAPI('resetpassword', data);
    // let url = this.sharedService.apiURL() + 'resetpassword';
    // const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    // return this.httpClient.post(url, JSON.stringify(data),{headers: headers})
  }
  //Get Products List
  getProductList() {
    return this.sharedService.getAPI('product');
  }
  //Get Roles
  getRoles() {
    return this.sharedService.getAPI('roles');
  }
  // GET COUNTRIES LIST
  getCountriesList() {
    return this.sharedService.getAPI('countries');
  }
  // GET CONTINENT LIST
  getContinentList() {
    return this.sharedService.getAPI('continents');
  }
  // Save Lead User
  saveLeadUserData(data: any) {
    //return this.sharedService.postAPI('company', data);
    return this.sharedService.postFormDataWithoutLogin('lead', data);
  }

  //GET BUSINESS ACCOUNT TYPE
  getBusinessAccountType() {
    return this.sharedService.getAPI('getBusinessAccountType');
  }
}
