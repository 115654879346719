
<!-- Page content -->
<div class="container  pb-5 container-height container-width">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-5 my-auto" style="padding-top: 43px;">
        <div class="">
          <div class="px-lg-5 py-lg-5 mt-3">
            <div class="text-center mb-4">
              <h1 class="mt-5">Reset Password</h1>
            </div>
            <form role="form" class="login_from" [formGroup]="resetPassowrdForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <span><img src="assets/img/password-icon.png"></span>
                <input class="form-control" placeholder="Password" type="password"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
              <div class="form-group">
                    <span><img src="assets/img/password-icon.png"></span>
                    <input type="password" name="confirm_password" formControlName="confirm_password"
                    class="form-control" placeholder="Confirm Password"
                    [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }">

                    <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                        <div *ngIf="f.confirm_password.errors.required">Confirm password field is required</div>
                        <div *ngIf="f.confirm_password.errors.mustMatch">Passwords must match.</div>
                    </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4">Submit</button>
              </div>
            </form>
            
          </div>
        </div>
  
      </div>
    </div>
    <p class="text-center about text-dark">
      ESGMS Powers Corporate Sustainability and Climate Protection Performance Information for Planet Earth Sustainable Present & Future
    </p>
  </div>
