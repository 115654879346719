<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            Create Branch Account
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.name.touched || submitted) && f.name.errors?.required">
                            Branch name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Contact First Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_first_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required">
                            First name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Contact Last Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_last_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required">
                            Last name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Email Address<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-5">
                        <input type="email" name="email" class="form-control form-control-alternative"
                            formControlName="email" required
                            pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                            [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors?.required && f.email.errors?.pattern }">
                        <div class="invalid-feedback" *ngIf="f.email.touched || submitted">
                            <div *ngIf="f.email.errors?.required">Email is required.</div>
                            <div *ngIf="f.email.errors?.pattern">Please provide a valid email address</div>
                        </div>
                    </div> -->
                    <div class="col-md-5">
                        <input type="text" class="form-control form-control-alternative"
                            formControlName="email" required
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Website</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="website" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Address 1<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_1" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.address_1.touched || submitted) && f.address_1.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.address_1.touched || submitted) && f.address_1.errors?.required">
                            Address 1 is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Address 2</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_2" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Address 3</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_3" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Industry<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="industry" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.industry.touched || submitted) && f.industry.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.industry.touched || submitted) && f.industry.errors?.required">
                            Industryis required
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Locality<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="locality" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.locality.touched || submitted) && f.locality.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.locality.touched || submitted) && f.locality.errors?.required">
                            Locality is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>County/State<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="county" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.county.touched || submitted) && f.county.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.county.touched || submitted) && f.county.errors?.required">
                            County is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Zip/Postcode<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="postcode" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.postcode.touched || submitted) && f.postcode.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.postcode.touched || submitted) && f.postcode.errors?.required">
                            Postcode is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Continent<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="continent"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.continent.touched || submitted) && f.continent.errors?.required }" (selectionChange)="onChangeContinent($event.value)">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let cnt of continentsList" [value]="cnt.code">
                                    {{cnt.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.continent.touched || submitted) && f.continent.errors?.required">
                            Continent is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Country<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="country" (selectionChange) = "onCountryChangeGetCountryCode($event.value)" 
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let country of countriesList" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.country.touched || submitted) && f.country.errors?.required">
                            Country is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Phone Number<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-1">
                        <input class="form-control form-control-alternative" type="text" value=" {{phoneCode}}" disabled/>
                    </div> -->
                    <div class="col-md-5">
                        <div class="input-group">
                            <div class="vertical-bg-color input-group-prepend">
                                <span class="input-group-text text-white" style="background-color: #1e6195 !important;">{{phoneCode}}</span>
                            </div>
                            <input formControlName="phone" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required">
                                Phone is required
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Reporting Currency<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-5">
                        <input formControlName="reporting_currency" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required">
                            Reporting currency is required
                        </div>
                    </div> -->
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="reporting_currency"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                                    {{currency.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required">
                            Reporting currency is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Company Financial Reporting Year<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-5">
                        <input formControlName="financial_reporting_year" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required">
                            Company financial reporting year is required
                        </div>
                    </div> -->
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="financial_reporting_year"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let fy of financialYearList" [value]="fy.id">
                                    {{fy.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required">
                            Company financial reporting year is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>CSCPP Reporting Year<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-5">
                        <input formControlName="car_reporting_year" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required">
                            CAR reporting year is required
                        </div>
                    </div> -->
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="car_reporting_year"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let cary of financialYearList" [value]="cary.id">
                                    {{cary.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required">
                            CAR reporting year is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="isEdit">
                    <div class="col-md-3">
                        <label>Date</label>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group datepicker_field">
                            <input type="text" readonly name="created_at" formControlName="created_at"
                                class="form-control form-control-alternative datepicker">
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                        <button type="button"[routerLink]="['/branches',company_id]"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>