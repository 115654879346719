import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router,ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {

  isSuperAdmin:boolean;
  isCompanyUser:boolean;
  customerId:any;

  constructor(private fb: FormBuilder,
    private modalService: NgbModal, config: NgbModalConfig, private _service: SharedService, 
    private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar, private sessionSt: LocalStorageService) { }

  branchData: any = [];
  company_id:any;
  ngOnInit(): void {
    this.company_id = this.route.snapshot.params.id;
    this.sessionSt.store('company_id',this.company_id);
    this.customerId = this.sessionSt.retrieve('customer_id');


    let userinfo = this.sessionSt.retrieve('userInfo');
    if(userinfo !=null){
      if(userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5" 
      || userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5" 
      || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"){
        this.isSuperAdmin = false;
      }
      else{
        this.isSuperAdmin = true;
      }
      if(userinfo.company.id ==this.company_id && userinfo.company.parent_id ==null){
        this.isCompanyUser = true;
      }
      else{
        this.isCompanyUser = false;
      }

    }
    else{
      this.isSuperAdmin = true;
    }

    this._service.getBranchListByCompanyId(this.company_id).subscribe(
      (response: any) => {
        let userinfo = this.sessionSt.retrieve('userInfo');
        if(userinfo !=null){
          response.result.forEach((res:any) => {
              if(userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5" 
            || userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5" 
            || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
            || userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5"){
              res.isAdmin = false;
            }
            else{
              res.isAdmin = true;
            }
            if(userinfo.company.id ==this.company_id && userinfo.company.parent_id ==null && userinfo.group_user_linking.group_id =='5dd8c0bb-b933-4f28-bc70-b5cc47fafb95'){
              res.isCompanyUser = true;
            }
            else{
              res.isCompanyUser = false;
            }
            if(userinfo.company.id !=null && userinfo.company.parent_id !=null && userinfo.group_user_linking.group_id =='5dd8c0bb-b933-4f28-bc70-b5cc47fafb95'){
              res.isBranchUser = true;
            }
            else{
              res.isBranchUser = false;
            }
          })
        }
        if(userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5"){
          this.branchData = response.result.filter((x: any) => x.id == userinfo.company.id);
        }
        else{
          this.branchData = response.result;
        }
        
      }
    );

  }

    //Grid For Branch Data
    gridOptions: GridOptions = {
      onGridReady: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    };
    columnDefs = [
      {
        headerName: 'Branch Name', sortable: true,field: 'name', width: 107
      },
      {
        headerName: 'Contact Name', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.main_contact_first_name) {
            return params.data.main_contact_first_name + ' ' + params.data.main_contact_last_name;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Email', sortable: true, filter: false, field: 'email', width: 140
      },
      {
        headerName: 'Phone', sortable: true,field: 'phone', width: 107
      },
      {
        headerName: 'Status', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.status == 1) {
            return "Active";
          } else {
            //This Needs to changes in the future changed now on 05-11-2022
            //return "Inactive";
            return "Active";
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Action', width: 100,
        cellRenderer: function (params:any) {
          let links = '';
          if(params.data.isAdmin || params.data.isCompanyUser){
            links += '<a  title="Edit Branch" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          }
          if(params.data.isAdmin || params.data.isCompanyUser || params.data.isBranchUser){
            links += '<a title="View Users" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-account-multiple-outline" data-action-type="users" id="' + params.data.id + '"></i></a>';
          }
          if(params.data.isAdmin || params.data.isCompanyUser){
            links += '<a title="Delete Branch" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
          }
          
          return links;
        }
      },
    ];
 /*
  * GRID BUTTONS ACTION
  */
    msg: string = '';
    branch_id: string = '';
    rowIndex: number = 0;

    onGridRowClicked(e: any,content:any,emailcontent:any) {
      if (e) {
        //let branch_id = e.event.target.getAttribute("id");
        let actionType = e.event.target.getAttribute("data-action-type");
        this.branch_id = e.data.id;
        this.rowIndex = e.rowIndex;
        switch (actionType) {
            case "edit":
            {
              this.router.navigate(['/branch',this.company_id, e.data.id]);
              break;
            }
            case "users":
            {
              this.router.navigate(['/user_list', e.data.id]);
              break;
            }
            case "delete":
            {
              this.msg = 'Are you sure you want to delete this branch?';
              this.modalService.open(content, { centered: true });
              break;
            }
            case "email":
              {
                this.msg = 'Are you sure you want to send email?';
                this.modalService.open(emailcontent, { centered: true });
                break;
              }
        }
      }
    }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'modal-default', size: 'md', centered: true });
  }

   /*
 * DELETE COMPANY
 */
   deleteCompany(branch_id:any, rowIndex:any) {
    let data: any = '';
    data = {
      id: branch_id
    };

    this._service.deleteCompany(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.branchData.splice(rowIndex, 1);
            this.gridOptions.api?.setRowData(this.branchData)
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

     /*
 * DELETE COMPANY
 */
     sendEmail(branch_id:any, rowIndex:any) {
      let data: any = '';
      data = {
        id: branch_id
      };
  
      this._service.sendEmailToBranch(data)
        ?.subscribe(
          (response: any) => {
            if (response.success) {
              this.modalService.dismissAll();
              this.gridOptions.api?.setRowData(this.branchData)
              this._snackBar.open(response.message, '', {
                duration: 5000
              });
  
            } else {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-danger'
              });
            }
  
          });
    }
}
