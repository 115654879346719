<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            Create Report
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Account Number</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="account_number" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.account_number.touched || submitted) && f.account_number.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.account_number.touched || submitted) && f.account_number.errors?.required">
                            Account number is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Register User name</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="username" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.username.touched || submitted) && f.username.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.username.touched || submitted) && f.username.errors?.required">
                            Username is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Subscription</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="user_subscription" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.user_subscription.touched || submitted) && f.user_subscription.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.user_subscription.touched || submitted) && f.user_subscription.errors?.required">
                            User subscription is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Access Per Employee</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="user_access" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.user_access.touched || submitted) && f.user_access.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.user_access.touched || submitted) && f.user_access.errors?.required">
                            User access per employee is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>User Prompt Entries Reporting</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="user_prompt_entries" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.user_prompt_entries.touched || submitted) && f.user_prompt_entries.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.user_prompt_entries.touched || submitted) && f.user_prompt_entries.errors?.required">
                            User prompt entries reporting is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contact Vendor</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="contact_vendor" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.contact_vendor.touched || submitted) && f.contact_vendor.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.contact_vendor.touched || submitted) && f.contact_vendor.errors?.required">
                            Contact vendor is required
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12 col-sm-12">
                        <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>