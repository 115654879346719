import { DailyData } from './daily-data';
export class CRDailyData {
    id: string = '';
    daily_data: any = [];
    entry_count_total:any=0;
    cfr_saving_total:any=0;
    cfr_cost_total:any=0;
    csr_cost_total:any=0;
    cer_cost_total:any=0;
    carbon_offset_total:any=0;
    green_tarrif_total:any=0;
    ghg_calculation_total:any=0;
 }