

export class Business {
    id: string = "";
    name:string ="";
    main_contact_first_name:string="";
    main_contact_last_name:string ="";
    email:string="";
    website:string="";
    address1: string= "";
    address2:string= "";
    address3:string= "";
    address4:string= "";
    county:string= "";
    country:string= "";
    postcode:string= "";
    phone:string="";
    object_id:string= "";
    status:string= "";
    business_account_type:string= "";
    branch_number:string= "";
    process_status_id: string = "";
    corporate_department_unit_id = ""
  }

