import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-internal',
  templateUrl: './internal.component.html',
  styleUrls: ['./internal.component.css']
})
export class InternalComponent implements OnInit {

  constructor(private _service: SharedService, private sessionSt: LocalStorageService, private fb: FormBuilder) { }

  branchList: any = [];
  formCfrReport: FormGroup;
  submitted = false;

  public barChartLegend = false;
  public barChartType = 'bar';
  public monitorData: any;

  countriesList: [];

  ngOnInit(): void {
    let user = this.sessionSt.retrieve('userinfo');
    // if (user != null) {
    //   this._service.getComapnyBranchesById(user.company_id)?.subscribe(
    //     (response: any) => {
    //       this.branchList = response.result;
    //     },
    //   );
    // }
    this.formCfrReport = this.fb.group({
      branch: [''],
      country: [''],
    })

    let data: any = '';
    data = {
      branch_id: "",
      country: ""
    };
    this._service.getCRTrackerInternalData(data)?.subscribe(
      (response: any) => {
        this.corporateBarChartData = [
          { data: [response.result.CFR, response.result.CSR, response.result.CER, response.result.CAR] },
        ];
      },
    );

    this._service.getCRTrackerInternalCorporateData(data)?.subscribe(
      (response: any) => {
        this.barChartData = [
          { data: [response.result.CFR, response.result.CSR, response.result.CER, response.result.CAR] },
        ];
      },
    );

    //Get All Continent List
    this._service.getCountriesList().subscribe(
      (response: any) => {
        if (response.success) {
          this.countriesList = response.result;
        }
      });

  }
  get f() { return this.formCfrReport.controls; }

  // barChart
  public barChartData: Array<any> = [
    { data: [0, 0, 0, 0] },
  ];
  public corporateBarChartData: Array<any> = [
    { data: [0, 0, 0, 0] },
  ];

  public barChartLabels: Array<string> = [
    'CFR',
    'CSR',
    'CER',
    'CAR',
  ];

  public corporateBarChartLabels: Array<string> = [
    'CFR',
    'CSR',
    'CER',
    'CAR',
  ];

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
          // OR //
          beginAtZero: true,
          max: 100   // minimum value will be 0.
        }
      }]
    }
  };

  public barChartColors: Array<Object> = [{
    backgroundColor: ['red', 'yellow', 'lime', 'blue']
  }];

  CFReportData() {
    this.submitted = true;
    if (this.formCfrReport.invalid) {
      return;
    }
    let tdata = this.formCfrReport.getRawValue();
    let data: any = '';
    data = {
      branch_id: tdata.branch,
      country: tdata.country,
    };
    this._service.getCRTrackerInternalData(data)?.subscribe(
      (response: any) => {
        this.corporateBarChartData = [
          { data: [response.result.CFR, response.result.CSR, response.result.CER, response.result.CAR] },
        ];
      },
    );

    this._service.getCRTrackerInternalCorporateData(data)?.subscribe(
      (response: any) => {
        this.barChartData = [
          { data: [response.result.CFR, response.result.CSR, response.result.CER, response.result.CAR] },
        ];
      },
    );
  }

  onSubmit() {
    this.CFReportData();
  }
  getAllBranchesByCountryId(event:any){
    //Get All Branches List
    this._service.getBranchListByCountryId(event.value).subscribe(
      (response: any) => {
        if (response.success) {
          this.branchList = response.result;
        }
      });
  }
}
