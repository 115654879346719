import { Component, AfterViewInit, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
//declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  submenu: any = [];
  isSuperAdmin: boolean;
  menunumber: number = 0;

  public sidebarnavItems: RouteInfo[] = [];
  // this is for the open close
  addExpandClass(element: string, i: number, menuAccessNumber: number) {
    if (menuAccessNumber != 14) {
      this.menunumber = menuAccessNumber;
    } else {
      this.menunumber = 0;
    }

    this.sidebarnavItems.forEach((res: any) => {
      if (res.hasParentMenu && res.menuAccessNumber == menuAccessNumber) {
        this.menunumber = res.menuAccessNumber;
      }
    });
    
    let routes = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    if (menuAccessNumber != 14) {
      routes.forEach((res: any, index: number) => {
        if (index == i) {
          this.submenu[index] = true;
        }
      })
    }  else {
      routes.forEach((res: any, index: number) => {
        this.submenu[index] = false;
      })
    }
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.submenu[i] = false;
    }
    else {
      this.showMenu = element;
      this.submenu[i] = true;
    }
  }

  // For Agent and Company User
  addAgentCompanyExpandClass(element: string, i: number, menuAccessNumber: number) {
    if (menuAccessNumber != 21) {
      this.menunumber = menuAccessNumber;
    } else {
      this.menunumber = 0;
    }

    this.sidebarnavItems.forEach((res: any) => {
      if (res.hasParentMenu && res.menuAccessNumber == menuAccessNumber) {
        this.menunumber = res.menuAccessNumber;
      }
    });
    
    let routes = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    if (menuAccessNumber != 21) {
      routes.forEach((res: any, index: number) => {
        if (index == i) {
          this.submenu[index] = true;
        }
      })
    }  else {
      routes.forEach((res: any, index: number) => {
        this.submenu[index] = false;
      })
    }
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.submenu[i] = false;
    }
    else {
      this.showMenu = element;
      this.submenu[i] = true;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private sessionSt: LocalStorageService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    let userinfo = this.sessionSt.retrieve('userInfo');
    if (userinfo != null) {
      if (userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
        || userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.isSuperAdmin = false;

        //Menu Access Check For User Base
        this.sidebarnavItems.forEach((res: any) => {
          res.isAdmin = false;
          res.isAgent = false;
          if (userinfo.company.parent_id != null) {
            res.companyId = userinfo.company.parent_id;
          }
          else {
            res.companyId = userinfo.company.id;
          }
          if (userinfo.company.id != "" && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            res.isCompanyUser = true;
          }
          else {
            res.isCompanyUser = false;
          }
          if (userinfo.company.id != null && userinfo.company.parent_id != null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            res.isBranchUser = true;
          }
          else {
            res.isBranchUser = false;
          }
          if (userinfo.company.id != "" && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            res.isStaffUser = true;
          }
          else if (userinfo.company.id != null && userinfo.company.parent_id != null && userinfo.group_user_linking.group_id == '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
            res.isStaffUser = true;
          }
          else {
            res.isStaffUser = false;
          }
        });


      }
      else if (userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5" && userinfo.company.role_id == "5b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.isSuperAdmin = false;
        this.sidebarnavItems.forEach((res: any) => {
          res.isAgent = true;
          res.isAdmin = false;
          res.isCompanyUser = false;
          res.isBranchUser = false;
          res.isStaffUser = false;
        });
      }
      else {
        this.isSuperAdmin = true;
        this.sidebarnavItems.forEach((res: any) => {
          res.isAdmin = true;
          res.isCompanyUser = false;
          res.isBranchUser = false;
          res.isStaffUser = false;
          res.isAgent = false;
        });
      }
    }
    else {
      this.isSuperAdmin = true;
    }
  }
}
