import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-quarter-data-report',
  templateUrl: './quarter-data-report.component.html',
  styleUrls: ['./quarter-data-report.component.css']
})
export class QuarterDataReportComponent implements OnInit {

  quarterData:any=[];
  Q1Total: any = 0;
  Q2Total: any = 0;
  Q3Total: any = 0;
  Q4Total: any = 0;
  GrandTotal:any =0;


  constructor(private _service: SharedService) { }

  ngOnInit(): void {
    // GET GOALS
    this._service.getQuarterDataList().subscribe(
      (response: any) => {
        if (response.success) {
          this.quarterData = response.result;
          if(this.quarterData.length >0){
            this.quarterData.forEach((res: any, index: any) =>{
        
              this.Q1Total = this.roundTo(this.Q1Total, 2) + this.roundTo(res.Q1, 2);
              this.Q2Total = this.roundTo(this.Q2Total, 2) + this.roundTo(res.Q2, 2);
              this.Q3Total = this.roundTo(this.Q3Total, 2) + this.roundTo(res.Q3, 2);
              this.Q4Total = this.roundTo(this.Q4Total, 2) + this.roundTo(res.Q4, 2);

              this.GrandTotal = this.roundTo(this.GrandTotal,2) + this.roundTo(res.catTotal, 2);

             });
          }
        }
      })
  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };
}
