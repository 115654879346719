<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <!-- <h2>Corporate Financial Responsibility (CFR) Performance -Plan Record</h2> -->
            <h2>Corporate Responsibilities (CR) Performance -Plan Record</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Target Month Date</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="target_month_date"
                            class="form-control form-control-alternative datepicker" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker"  (dateChange)="addEvent($event)">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Plan Type</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="sub_plan_id" class="form-control form-control-alternative"
                                name="sub_plan_id" required (selectionChange)="onSublanChange($event)">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let spt of subPlanType" [value]="spt.id">
                                    {{spt.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="f.sub_plan_id.value && f.cost_performance_annual_total.value !=''">
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}}</label>
                        </div>
                        <div class="col-md-5">
                            <mat-form-field class="w-100">
                                <mat-select formControlName="cost_performance"
                                    class="form-control form-control-alternative" name="cost_performance" required>
                                    <mat-option [value]="">-Select-</mat-option>
                                    <mat-option [value]="1">Annual Total</mat-option>
                                    <mat-option [value]="2">Monthly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="f.cost_performance.value == '1'">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Annual Total)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="cost_performance_annual_total" appNumbers
                                formControlName="cost_performance_annual_total" min="0"
                                class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="f.cost_performance.value == '2'">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Annual Total)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="cost_performance_annual_total" appNumbers disabled="disabled"
                                formControlName="cost_performance_annual_total" min="0"
                                class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 1)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_1" appNumbers formControlName="month_number_1"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 2)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_2" appNumbers formControlName="month_number_2"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 3)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_3" appNumbers formControlName="month_number_3"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 4)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_4" appNumbers formControlName="month_number_4"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 5)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_5" appNumbers formControlName="month_number_5"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 6)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_6" appNumbers formControlName="month_number_6"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 7)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_7" appNumbers formControlName="month_number_7"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 8)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_8" appNumbers formControlName="month_number_8"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 9)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_9" appNumbers formControlName="month_number_9"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 10)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_10" appNumbers formControlName="month_number_10"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 11)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_11" appNumbers formControlName="month_number_11"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>{{cost_performance_label}} (Month 12)</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="month_number_12" appNumbers formControlName="month_number_12"
                                min="0" class="form-control form-control-alternative">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>