import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-financial',
  templateUrl: './account-financial.component.html',
  styleUrls: ['./account-financial.component.css']
})
export class AccountFinancialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
