<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Social Responsibility (CSR) - Report</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <table>
                <thead>
                    <tr>
                        <th colspan="6">2022</th>
                        <th colspan="6">2023</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="background-color: #008000;">
                        <td>Category</td>
                        <td>Q1</td>
                        <td>Q2</td>
                        <td>Q3</td>
                        <td>Q4</td>
                        <td>Total</td>
                        <td>Q1</td>
                        <td>Q2</td>
                        <td>Q3</td>
                        <td>Q4</td>
                        <td>Total</td>
                    </tr>
                    <tr *ngFor="let qdata of quarterData; let i=index">
                        <td>{{qdata.category_name}}</td>
                        <td>
                            {{qdata.Q1}}
                        </td>
                        <td>
                            {{qdata.Q2}}
                        </td>
                        <td>
                            {{qdata.Q3}}
                        </td>

                        <td>
                            {{qdata.Q4}}
                        </td>
                        <td style="background-color: yellow;">
                            {{qdata.catTotal}}
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>

                    </tr>
                    <tr style="background-color: yellow;">
                        <th>Total:</th>
                        <td>
                            {{Q1Total}}
                        </td>
                        <td>
                            {{Q2Total}}
                        </td>
                        <td>
                            {{Q3Total}}
                        </td>

                        <td>
                            {{Q4Total}}
                        </td>
                        <td>
                           {{GrandTotal}}
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>