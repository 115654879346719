import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormArray,FormControl, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import { ConfirmDialogService } from '../../../../confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../../../services/shared.service';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-esgmsuser',
  templateUrl: './esgmsuser.component.html',
  styleUrls: ['./esgmsuser.component.css']
})
export class EsgmsuserComponent implements OnInit {

  submitted = false;
  checkedProduct: any[] = [];
  productList:any[] =[];
  selectProductList:any[] =[];
  userGroupList:any[]=[];
  isChecBox:boolean
  branch_id:any;
  company_id:any="";
  userNameVal:string="";
  isEdit:boolean;
  formUser: FormGroup;
  valuechain: any = [];
  valuechainValues:any = [];

  constructor(private fb: FormBuilder, private router: Router,
    private confirmDialogService: ConfirmDialogService, private route: ActivatedRoute, 
    private _snackBar: MatSnackBar,private _service: SharedService, private sessionSt: LocalStorageService) {}
    

    
    ngOnInit(): void {

      let userinfo = this.sessionSt.retrieve('userInfo');
      
      //Get All Product List
      this._service.getUserGroups().subscribe(
        (response: any) => {
          if(response.success){
            this.userGroupList = response.result;
          }
        });

        this.company_id = this.sessionSt.retrieve('company_id');
        this.branch_id = this.route.snapshot.params.branch_id;

        // Get Compnay Detail By Id
        this._service.getComapnyById(this.branch_id).subscribe(
          (response: any) => {
            if(response.success){
              this.formUser.controls['branch_name'].setValue(response.result.name);
              this.formUser.controls['county'].setValue(response.result.address.county);
              //this.formUser.controls['department'].setValue(response.result.corporate_department_unit_id);
            }
          });

        this._service.getProductList().subscribe(
          (response: any) => {
            if(response.success){
              this.productList = response.result;
            }
          });

         // GET VALUE CHAIN
          this._service.getCRValueChain().subscribe(
            (response: any) => {
              if (response.success) {
                this.valuechain = response.result.filter((x: any) => x.id == '66666666-6666-6666-6666-666666666662');
                this.valuechainValues = this.valuechain[0].value_chain_values;
              }
            })

        
          this.formUser = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            // employee_number: ['', Validators.required],
            // department: ['', Validators.required],
            username: ['', [Validators.required, Validators.email]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            group_id: ['', Validators.required],
            id:'',
          });


          
          // if(userinfo.company.branch_number !=null){
          //   this.formUser.controls['employee_number'].setValue("ESGMS000"+ (userinfo.company.branch_number + 1));
          // }
          // else{
          //   this.formUser.controls['employee_number'].setValue("ESGMS0001");
          // }
          
          
        if(this.route.snapshot.params.id !=null){
          this.isEdit = false;
          this.formUser.controls.password.disable();
          this.formUser.controls.username.disable();
        }
        else{
          this.isEdit = true;
          this.formUser.controls.password.enable();
          this.formUser.controls.username.enable();
          this.formUser.controls.email.enable();
        }  

        // Edit User By Id
        this._service.getUserById(this.route.snapshot.params.id).subscribe(
          (response: any) => {
            if (response.success) {
              this.formUser.controls.email.disable();
              this.isEdit = false;

              this.formUser.patchValue({
                first_name: response.result.user.userinfo.first_name,
                last_name: response.result.user.userinfo.last_name,
                // employee_number:response.result.user.userinfo.employee_number,
                // department:response.result.user.userinfo.department,
                id:response.result.user.id,
                username:response.result.user.username,
                email:response.result.user.username,
                group_id:response.result.user.group_user_linking.group_id,
                created_at:formatDate(response.result.user.created_at, 'yyyy-MM-dd', 'en-US')
              });
            }
          }
        )
    }

  
    get f() { return this.formUser.controls; }
  
    //Save Data
    onSave(){
      this.submitted = true;
      if (this.formUser.invalid) {
        this.scrollToError();
        return;
      }

      let data = this.formUser.getRawValue();
      data.status = 1;
      
      this._service.saveESGMSUser(data)?.subscribe(
          (response: any) => {
            if (response.success) {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-success'
              });
              this.router.navigate(['/user_list', this.branch_id]);
            } else {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-danger'
              });
            }
  
          },
          (error:any) => {
            this._snackBar.open('Failed to save data. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });     
          }
        );

    }
  
        // Get Product Checked
        getUserName(event:any) {
          this.formUser.patchValue({email:event.target.value});
        }
        scrollTo(el:any): void {
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
        scrollToError(): void {
         const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
          this.scrollTo(firstElementWithError);
        }
}
