<nav class="sidebar-nav pt-3">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems; let i=index;"              
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'" 
            [ngClass]="(menunumber==sidebarnavItem.menuAccessNumber || menunumber == 0 || sidebarnavItem.menuAccessNumber ==1 
            || sidebarnavItem.menuAccessNumber ==2 || sidebarnavItem.menuAccessNumber ==4 
            || sidebarnavItem.menuAccessNumber ==14 || sidebarnavItem.menuAccessNumber ==21)&& ((sidebarnavItem.isBranchUser)
             && (sidebarnavItem.menuAccessNumber ==2 || sidebarnavItem.menuAccessNumber ==21) || 
            (sidebarnavItem.isAdmin && sidebarnavItem.menuAccessNumber ==1 || sidebarnavItem.menuAccessNumber ==4)  
            || ((sidebarnavItem.isStaffUser) && (sidebarnavItem.menuAccessNumber ==12 
            || sidebarnavItem.menuAccessNumber ==13 ||sidebarnavItem.menuAccessNumber ==14 
            || sidebarnavItem.menuAccessNumber ==26
            || sidebarnavItem.menuAccessNumber ==25  || sidebarnavItem.menuAccessNumber ==10 
            || sidebarnavItem.menuAccessNumber ==27))
            || ((sidebarnavItem.isCompanyUser) && (sidebarnavItem.menuAccessNumber == 21 || sidebarnavItem.menuAccessNumber == 19 || sidebarnavItem.menuAccessNumber == 20))
            ||((sidebarnavItem.isAgent) && (sidebarnavItem.menuAccessNumber == 21 || sidebarnavItem.menuAccessNumber == 17 || sidebarnavItem.menuAccessNumber == 18))) ? 'sidebar-item':''"
            >
<!-- 
            <div *ngIf="(sidebarnavItem.isCompanyUser || sidebarnavItem.isAgent) && (sidebarnavItem.menuAccessNumber ==21)">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path,sidebarnavItem.companyId] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">

                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}} <i class="mdi mdi-menu-down" *ngIf="sidebarnavItem.submenu.length > 0"></i></span>
                </a>
            </div> -->
            <div *ngIf="(sidebarnavItem.isBranchUser) && (sidebarnavItem.menuAccessNumber ==2)">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path,sidebarnavItem.companyId] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">

                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}} <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0"></span>
                </a>
            </div>
            <div *ngIf="(sidebarnavItem.isAdmin) && (sidebarnavItem.menuAccessNumber ==1  || sidebarnavItem.menuAccessNumber ==4)">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">

                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}} <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0"></span>
                </a>
            </div>
            <div [ngClass]="(menunumber==sidebarnavItem.menuAccessNumber || menunumber == 0) ? 'd-block': (sidebarnavItem.menuAccessNumber != 21)?'d-none':'d-block'"
            
            *ngIf="(sidebarnavItem.isCompanyUser) && (sidebarnavItem.menuAccessNumber == 19 
            || sidebarnavItem.menuAccessNumber == 20 || sidebarnavItem.menuAccessNumber == 21)">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addAgentCompanyExpandClass(sidebarnavItem.title, i,sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">

                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}} <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0"></span>
                </a>
            </div>
            <!-- acesss= {{sidebarnavItem.menuAccessNumber}}
            menu= {{menunumber}} -->
            <!--Menu Access Removed for isBranchUser user -->
            <div [ngClass]="(menunumber==sidebarnavItem.menuAccessNumber || menunumber == 0) ? 'd-block': (sidebarnavItem.menuAccessNumber != 14)?'d-none':'d-block'"
            *ngIf="(sidebarnavItem.isStaffUser) 
            && (sidebarnavItem.menuAccessNumber !=1 && sidebarnavItem.menuAccessNumber ==25 
            || sidebarnavItem.menuAccessNumber ==10 || sidebarnavItem.menuAccessNumber ==14 
            || sidebarnavItem.menuAccessNumber ==12 || sidebarnavItem.menuAccessNumber ==13 
            || sidebarnavItem.menuAccessNumber ==26 || sidebarnavItem.menuAccessNumber ==27)">
            <!-- <div *ngIf="(sidebarnavItem.isReseller)&&(sidebarnavItem.isStaffUser || sidebarnavItem.isBranchUser)"> -->
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">
                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}}  <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0" style="padding-left: 8px;"></span>
                </a>
            </div>


            <div [ngClass]="(menunumber==sidebarnavItem.menuAccessNumber || menunumber == 0) ? 'd-block': (sidebarnavItem.menuAccessNumber != 21)?'d-none':'d-block'"
            *ngIf="(sidebarnavItem.isBranchUser) 
            && (sidebarnavItem.menuAccessNumber ==21)">
            <!-- <div *ngIf="(sidebarnavItem.isReseller)&&(sidebarnavItem.isStaffUser || sidebarnavItem.isBranchUser)"> -->
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">
                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}}  <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0" style="padding-left: 8px;"></span>
                </a>
            </div>

            <div [ngClass]="(menunumber==sidebarnavItem.menuAccessNumber || menunumber == 0) ? 'd-block': (sidebarnavItem.menuAccessNumber != 21)?'d-none':'d-block'"
            *ngIf="(sidebarnavItem.isAgent) && (sidebarnavItem.menuAccessNumber ==21 || sidebarnavItem.menuAccessNumber ==17 || sidebarnavItem.menuAccessNumber ==18 )">
                <a class="sidebar-link waves-effect waves-dark"
                    [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                    [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                    (click)="addAgentCompanyExpandClass(sidebarnavItem.title, i, sidebarnavItem.menuAccessNumber)"
                    [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    
                    <img *ngIf="sidebarnavItem.imagePath" src="{{sidebarnavItem.imagePath}}">

                    <i *ngIf="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i>
                
                    <span class="hide-menu main-menu-font" style="padding-left: 5px;">{{sidebarnavItem.title}} <img src="assets/images/icon-arrow-down.png" *ngIf="sidebarnavItem.submenu.length > 0"></span>
                </a>
            </div>

            <ul *ngIf="sidebarnavItem.submenu.length > 0 && submenu[i] && isSuperAdmin && sidebarnavItem.menuAccessNumber != 25">
                <li *ngFor="let menu of sidebarnavItem.submenu">
                    <a class="sidebar-link waves-effect waves-dark"
                        [routerLink]="menu.class === '' ? [menu.path] : null"
                        [ngClass]="[menu.class]" *ngIf="!menu.extralink;"
                        (click)="addExpandClass(menu.title,0,menu.menuAccessNumber)"
                        [routerLinkActive]="menu.submenu.length != 0 ? '' : 'active'">

                        <i [ngClass]="[menu.icon]"></i>

                        <span class="hide-menu">{{menu.title}}</span>
                    </a>
                </li>
            </ul>
            

            <ul *ngIf="(sidebarnavItem.submenu.length > 0) && (submenu[i]) && (sidebarnavItem.isCompanyUser) 
                && (sidebarnavItem.menuAccessNumber == 19 
                || sidebarnavItem.menuAccessNumber == 20)">
                <li *ngFor="let menu of sidebarnavItem.submenu">
                    <a class="sidebar-link waves-effect waves-dark"
                        [routerLink]="menu.class === '' ? [menu.path] : null"
                        [ngClass]="[menu.class]" *ngIf="!menu.extralink;"
                        (click)="addAgentCompanyExpandClass(menu.title,0,menu.menuAccessNumber)"
                        [routerLinkActive]="menu.submenu.length != 0 ? '' : 'active'">
                        
                        <i [ngClass]="[menu.icon]"></i>

                        <span class="hide-menu" *ngIf="menu.title == 'Business Account' || menu.title == 'Agent Account'" style="font-weight: bold;">{{menu.title}}</span>
                        <span class="hide-menu" *ngIf="menu.title != 'Business Account' && menu.title != 'Agent Account'">{{menu.title}}</span>
                    </a>
                </li>
            </ul>
            <!---Menu Access Removed for Admin Branch User isBranchUser-->
            <ul *ngIf="sidebarnavItem.submenu.length > 0 && submenu[i] && (sidebarnavItem.isStaffUser) 
                && (sidebarnavItem.menuAccessNumber == 25 || sidebarnavItem.menuAccessNumber == 10 || sidebarnavItem.menuAccessNumber == 12 || sidebarnavItem.menuAccessNumber == 13)">
                <li *ngFor="let menu of sidebarnavItem.submenu">
                    <a class="sidebar-link waves-effect waves-dark"
                        [routerLink]="menu.class === '' ? [menu.path] : null"
                        [ngClass]="[menu.class]" *ngIf="!menu.extralink;"
                        (click)="addExpandClass(menu.title,0,menu.menuAccessNumber)"
                        [routerLinkActive]="menu.submenu.length != 0 ? '' : 'active'">

                        <i [ngClass]="[menu.icon]"></i>

                        <span class="hide-menu">{{menu.title}}</span>
                    </a>
                </li>
            </ul>

            <ul *ngIf="(sidebarnavItem.submenu.length > 0) && (submenu[i]) && (sidebarnavItem.isAgent) && (sidebarnavItem.menuAccessNumber == 17 || sidebarnavItem.menuAccessNumber == 18)">
                <li *ngFor="let menu of sidebarnavItem.submenu">
                    <a class="sidebar-link waves-effect waves-dark"
                        [routerLink]="menu.class === '' ? [menu.path] : null"
                        [ngClass]="[menu.class]" *ngIf="!menu.extralink;"
                        (click)="addAgentCompanyExpandClass(menu.title,0,menu.menuAccessNumber)"
                        [routerLinkActive]="menu.submenu.length != 0 ? '' : 'active'">

                        <i [ngClass]="[menu.icon]"></i>
                        <span class="hide-menu" *ngIf="menu.title == 'Business Account'" style="font-weight: bold;">{{menu.title}}</span>
                        <span class="hide-menu" *ngIf="menu.title != 'Business Account'">{{menu.title}}</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</nav>