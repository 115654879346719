import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-reseller-list',
  templateUrl: './reseller-list.component.html',
  styleUrls: ['./reseller-list.component.css']
})
export class ResellerListComponent implements OnInit {
  resellerList:[]
  
  constructor(private _service: SharedService, private router: Router,
    private route: ActivatedRoute, private modalService: NgbModal, 
    private _snackBar: MatSnackBar,private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    this.getCompanyList();
  }

    //Grid For Branch Data
    gridOptions: GridOptions = {
      onGridReady: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    };
    columnDefs = [
      {
        headerName: 'Name', sortable: true,field: 'name', width: 107
      },
      {
        headerName: 'Contact Name', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.main_contact_first_name) {
            return params.data.main_contact_first_name + ' ' + params.data.main_contact_last_name;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Email', sortable: true,field: 'email', width: 107
      },
      {
        headerName: 'Website', sortable: true,field: 'website', width: 107
      },
      {
        headerName: 'Status', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.status == 1) {
            return "Active";
          } else {
            return "Inactive";
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Action', width: 100,
        cellRenderer: function (params:any) {
          let links = '<a  title="Edit Branch" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a title="View Branchs" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-source-branch" data-action-type="branches" id="' + params.data.id + '"></i></a>';
          links += '<a title="View Users" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-account-multiple-outline" data-action-type="users" id="' + params.data.id + '"></i></a>';
          links += '<a title="Delete Reseller" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
          return links;
        }
      },
    ];


  /*
  * GRID BUTTONS ACTION
  */
      msg: string = '';
      company_id: string = '';
      rowIndex: number = 0;
  
      onGridRowClicked(e: any,content:any) {
        if (e) {
          this.company_id = e.data.id;
          this.rowIndex = e.rowIndex;
          let actionType = e.event.target.getAttribute("data-action-type");
          switch (actionType) {
              case "edit":
              {
                this.router.navigate(['/reseller', e.data.id]);
                break;
              }
              case "branches":
              {
                this.router.navigate(['/branches', e.data.id]);
                this.sessionSt.store('company_id', e.data.id);
                this.sessionSt.store('customer_id','4b4e4105-463e-456e-8e5e-97ac397168e5');
                break;
              }
              case "users":
              {
                this.router.navigate(['/user_list', e.data.id]);
                this.sessionSt.store('customer_id','4b4e4105-463e-456e-8e5e-97ac397168e5');
                break;
              }
              case "delete":
              {
                this.msg = 'Are you sure you want to delete this reseller?';
                this.modalService.open(content, { centered: true });
                break;
              }
          }
        }
      }

       /*
 * DELETE COMPANY
 */
  deleteCompany(company_id:any, rowIndex:any) {
    let data: any = '';
    data = {
      id: company_id
    };

    this._service.deleteCompany(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.getCompanyList();
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }
  /*
  * Get Company List
  */
  getCompanyList(){
    let company_id = '4b4e4105-463e-456e-8e5e-97ac397168e5';
    this._service.getCompanyDetails(company_id).subscribe(
       (response: any) => {
         this.resellerList = response.result;
       }
     );
   }
}
