import { Injectable } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  // Get Company By Id
  getComapnyById(company_id:any) {
    return this.sharedService.getAPI('company/' + company_id);
  }

  // Get Company All Data
  getCompanyDetails() {
    return this.sharedService.getAPI('companies');
  }

  // Save Business Quote
  saveBusinessData(data:any) {
    //return this.sharedService.postAPI('company', data);
    return this.sharedService.postFormData('company', data);
  }
}

