<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Business - Actual records</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Business Records</h3>
                </div>
                <!-- <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/business']">Add New Company</a>
                </div> -->
            </div>
        </div>
        <div class="card-body p-1">
            <ag-grid-angular
            style="width: 100%; height: 380px; background-color: #3b6c54 !important"
            class="ag-theme-bootstrap"
            [gridOptions]="gridOptions"
            [rowData]="businessList"
            [columnDefs]="columnDefs"
            animateRows
            pagination
            paginationPageSize = 10
            rowHeight = 27
            headerHeight=35
            (rowClicked)='onGridRowClicked($event,content,emailcontent)'
            >
          </ag-grid-angular>
          </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="deleteCompany(company_id,rowIndex)">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #emailcontent let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="SendEmail(company_id,rowIndex)">Yes</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">No</button>
    </div>
</ng-template>