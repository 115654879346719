import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-add-financial',
  templateUrl: './add-financial.component.html',
  styleUrls: ['./add-financial.component.css']
})
export class AddFinancialComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private sessionSt: LocalStorageService, private router: Router) { }

  branchNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  industries: any[] = [];
  submitted = false;
  company_id: string = "";
  priceList: any = [];
  isActive: boolean;

  formUser = this.fb.group({
    //object_id: ['', Validators.required],
    branch_number: ['', Validators.required],
    price_id: ['', Validators.required],
    price: ['', Validators.required],
    new_customer_discount: ['', Validators.required],
    negotiated_discount: ['', Validators.required],
    plan_discount: ['', Validators.required],
    total_subscription_per_month: ['', Validators.required],
    id: '',
    agreed_date: [''],
    end_date: [''],
    status: [''],
    reason_for_end:[]
  });

  ngOnInit(): void {
    //Get All Industries
    this.company_id = this.route.snapshot.params.company_id;
    this._service.getIndustries().subscribe(
      (response: any) => {
        if (response.success) {
          this.industries = response.result;
        }
      });

    this.getAllPriceByCountryId('GB');


    // Edit Company/Business By Id
    this._service.getFiancialDataById(this.route.snapshot.params.id).subscribe(
      (response: any) => {
        if (response.success) {

          this.getPriceByUserInfo(response.result.price_id);
          if (response.result.status == 1) {
            this.isActive = true;
          }
          else {
            this.isActive = false;
          }

          this.formUser.patchValue({
            branch_number: response.result.branch_number,
            price_id: response.result.price_id,
            price: response.result.price,
            new_customer_discount: response.result.new_customer_discount,
            negotiated_discount: response.result.negotiated_discount,
            plan_discount: response.result.plan_discount,
            total_subscription_per_month: response.result.total_subscription_per_month,
            id: response.result.id,
            agreed_date: response.result.agreed_date,
            end_date: response.result.end_date,
            status: response.result.status,
            reason_for_end:response.result.reason_for_end
          });
        }
      }
    )


  }
  get f() { return this.formUser.controls; }

  onSave() {
    this.submitted = true;
    if (this.formUser.invalid) {
      return;
    }

    let data = this.formUser.getRawValue();
    if (data.end_date != null && data.end_date != '') {
      data.status = 2;
    }
    data.object_id = this.company_id;
    this._service.saveFinancialData(data)?.subscribe
      (
        (response: any) => {
          if (response.success) {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
            this.router.navigate(['/finacial', this.company_id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        },
        (error: any) => {
          this._snackBar.open('Failed to save data. Please try again later.', '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      );
  }

  getAllPriceByCountryId(value: any) {
    //Get All Branches List
    this._service.getPriceListByCountryId(value).subscribe(
      (response: any) => {
        if (response.success) {
          this.priceList = response.result;
        }
      });
  }
  getPriceByUserInfo(value: any) {
    //Get All Branches List
    let selectedvaluechaingreen = this.priceList.filter((x: any) => x.id == value);
    this.formUser.controls['price'].setValue(selectedvaluechaingreen[0].price + '.00', { onlySelf: true });
  }
}
