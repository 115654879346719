<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Sustainability and Climate Protection Performance (CS& CPP) Analysis</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <div class="form" [formGroup]="formCSCPPReport">


                <div class="row">

                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Years</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="year" class="form-control form-control-alternative"
                                        name="year" required
                                        [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let y of yearsList" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.year.touched || submitted) && f.year.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12" id="content">
                    <div class="dash-box"><a href="javascript:void(0)">Annual CS & CPP</a></div>
                </div> -->
                <div class="row" id="content">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isCSCPPTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="CSCPPAnnual($event)">Annual CS & CPP</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isTabularTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="reportType($event)">Tabular Analysis</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isChartTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="reportType($event)">Chart Analysis</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is12MonthTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="btnmonth($event)">12 month</a>
                        </div>
                    </div>
                   


                    <br style="clear:both" />
                </div>
                <div class="row" id="content">
                    <div class="col-md-2">
                        <div class="dash-box">
                            <a href="javascript:void(0)" (click)="onSubmit()">Report</a>
                        </div>
                    </div>
                    
                    <br style="clear:both" />
                </div>
                <div class="row mt-1">
                    <div class="col-md-2">
                        <button type="button" class="btn btn-save" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>