<!-- Page content -->
<div class="container-fluid mt--7">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Corporate Social Responsibility (CSR) Report</h3>
                </div>
                <!-- <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/pages/add_records']">Add record</a>
                </div> -->
            </div>
        </div>
        <div class="row">
            <!-- column -->
            <div class="col-lg-8">
                <div class="table-responsive">
                    <!-- Projects table -->
                    <table class="table align-items-center table-flush">
                        <thead class="thead-success">
                            <tr class="tr-success-light">
                                <th></th>
                                <th colspan="5" class="text-center">2017</th>
                            </tr>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Q1</th>
                                <th scope="col">Q2</th>
                                <th scope="col">Q3</th>
                                <th scope="col">Q4</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> No Poverty </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Zero hunger </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Good Health & well being </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> No Poverty </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Zero hunger </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Good Health & well being </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> No Poverty </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Zero hunger </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> Good Health & well being </td>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="td-total">Total</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 80,232</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="table-responsive">
                    <!-- Projects table -->
                    <table class="table align-items-center table-flush">
                        <thead class="thead-success">
                            <tr class="tr-success-light">
                                <th colspan="5" class="text-center">2018</th>
                            </tr>
                            <tr>
                                <th scope="col">Q1</th>
                                <th scope="col">Q2</th>
                                <th scope="col">Q3</th>
                                <th scope="col">Q4</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                            <tr>
                                <td> £ 3,234</td>
                                <td> £ 4,432</td>
                                <td> £ 5,542</td>
                                <td> £ 1,233</td>
                                <td class="td-total"> £ 14,232</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 14,232</th>
                                <th class="td-total"> £ 80,232</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>