

<!-- Page content -->
<div class="container  pb-5 container-height container-width">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-5 my-auto" style="padding-top: 40px;">
        <div class="">
          <div class="px-lg-5 py-lg-5 mt-3">
            <div class="text-center mb-4">
              <h1 class="mt-5">Forgot Password</h1>
            </div>
            <form role="form" class="login_from" [formGroup]="forgotPassowrdForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-0">
                <!-- <div class="input-group input-group-alternative"> -->
                <!-- <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                  </div> -->
                <span><img src="assets/img/username-icon.png"></span>
                <input type="email" name="email" formControlName="email" class="form-control form-control-lg border-left-0"
                placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <!-- </div> -->
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  
                </div>
                <div class="col-6 text-right">
                    <a routerLink="/" class="text-dark"><i class="mdi mdi-arrow-left"></i>Back to login</a>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4">Submit</button>
              </div>
            </form>
            
          </div>
        </div>
  
      </div>
    </div>
    <p class="text-center about text-dark">
      ESGMS Powers Corporate Sustainability and Climate Protection Performance Information for Planet Earth Sustainable Present & Future
    </p>
  </div>