<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Responsibilities (CR) Record In Progress</h2>
        </div>
    </div>
</div>

<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <!-- <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
            </div>
        </div> -->
        <div class="card-body p-1">
            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54 !important"
                class="ag-theme-bootstrap" [gridOptions]="gridOptions4" [rowData]="allPlanData" [columnDefs]="columnDefs4"
                animateRows pagination paginationPageSize=10 rowHeight=27 headerHeight=35
                (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>
        </div>
    </div>
</div>
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <!-- <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div> -->
                <!-- <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/cr_record']">Add record</a>
                </div> -->
            </div>
        </div>
        <div class="card-body p-1">
            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54 !important"
                class="ag-theme-bootstrap" [gridOptions]="gridOptions" [rowData]="cfrDataList" [columnDefs]="columnDefs"
                animateRows pagination paginationPageSize=10 rowHeight=27 headerHeight=35
                (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>

            <!-- <table>
                <thead>
                    <tr>
                        <th class="td-text-center">
                            Activities Data Entry Date
                        </th>
                        <th class="td-text-center">
                            CFR-Cost Performance Data
                        </th>
                        <th class="td-text-center">
                            Owner ESGMS No
                        </th>
                        <th class="td-text-center">
                            Update
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cfrdata of allCFRData; let i = index;">
                        <td class="td-text-center">
                            {{ cfrdata.created_at | date : 'dd-MM-yyyy' }}
                        </td>
                        <td class="td-text-center">
                            {{ cfrdata.cost_performance_annual_total}}
                        </td>
                        <td class="td-text-center">
                            {{ cfrdata.esgms_number_prefix}}{{cfrdata.esgms_number}}
                        </td>
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </div>
</div>
<div class="container-fluid mt-4">
    <div class="card shadow">
        <!-- <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
            </div>
        </div> -->
        <div class="card-body p-1">
            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54 !important"
                class="ag-theme-bootstrap" [gridOptions]="gridOptions1" [rowData]="cardataList" [columnDefs]="columnDefs1"
                animateRows pagination paginationPageSize=10 rowHeight=27 headerHeight=35
                (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <!-- <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
            </div>
        </div> -->
        <div class="card-body p-1">
            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54 !important"
                class="ag-theme-bootstrap" [gridOptions]="gridOptions2" [rowData]="csrdataList" [columnDefs]="columnDefs2"
                animateRows pagination paginationPageSize=10 rowHeight=27 headerHeight=35
                (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="card shadow">
        <!-- <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
            </div>
        </div> -->
        <div class="card-body p-1">
            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54 !important"
                class="ag-theme-bootstrap" [gridOptions]="gridOptions3" [rowData]="cerdataList" [columnDefs]="columnDefs3"
                animateRows pagination paginationPageSize=10 rowHeight=27 headerHeight=35
                (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="deleteCompany(company_id,rowIndex)">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>