import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 1,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/#',
    title: 'Main Navigation',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 26,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/dashboard',
    title: 'Monitor',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 14,
    imagePath: 'assets/images/icon-monitor.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/dashboard',
    //title: 'Sales Leeds/Prospects Feed',
    title: 'Dashboard',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 21,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/branches',
    title: 'Branches',
    //icon: 'mdi mdi-source-branch',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 2,
    imagePath: 'assets/images/branch-account-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/cr_records_list',
    title: 'CR Record',
    icon: 'mdi mdi-note-text',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 3,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/leads',
    title: 'Leads',
    icon: 'mdi mdi-account-multiple',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 9,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },

  {
    path: '/#',
    title: 'Accounts Registration',
    //icon: 'mdi mdi-account-settings-variant',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 4,
    imagePath: 'assets/images/icon-accounts-registration.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/leads',
        title: 'Leads',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 5,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/business_list',
        title: 'Business',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 6,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/reseller_list',
      //   title: 'Reseller',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 7,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      {
        path: '/agent_list',
        title: 'Agent',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 8,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/esgmsuserslist',
        title: 'ESGMS User',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 8,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
    ]
  },

  {
    path: '/plan_cfr_data',
    title: 'CR Record',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 25,
    imagePath: 'assets/images/cr-record-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/plan_cfr_data',
        //title: 'CR Plan CFR Data',
        //title: 'CR Plan Data',
        title: 'Branch Plan Data',
        icon: '',
        class: '',
        extralink: false,
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
        submenu: [],
      },
      {
        path: '/corporate_plan',
        //title: 'CR Plan CFR Data',
        title: 'Corporate Plan Data',
        icon: '',
        class: '',
        extralink: false,
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
        submenu: [],
      },
      {
        path: '/cr_data',
        title: 'Branch Data Daily',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cr_daily_data',
        title: 'Corporate Data Daily',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cr_data_progress',
        title: 'CR Data In Progress   ',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cfr_data',
        title: 'Actual CFR Data',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/csr_data',
        title: 'Actual CSR Data',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/plan_csr_data',
      //   title: 'Plan CSR Data',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin:false,
      //   isBranchUser:false,
      //   isStaffUser:false,
      //   isCompanyUser:false,
      //   companyId:'',
      //   menuAccessNumber:25,
      //   imagePath:'',
      //   isAgent:false,
      //   isReseller:false,
      //   menunumber:0,
      //   hasParentMenu: true,
      // },
      {
        path: '/cer_data',
        title: 'Actual CER Data',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/plan_cer_data',
      //   title: 'Plan CER Data',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin:false,
      //   isBranchUser:false,
      //   isStaffUser:false,
      //   isCompanyUser:false,
      //   companyId:'',
      //   menuAccessNumber:25,
      //   imagePath:'',
      //   isAgent:false,
      //   isReseller:false,
      //   menunumber:0,
      //   hasParentMenu: true,
      // },
      {
        path: '/cr_record',
        title: 'Actual CAR Data',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 25,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/plan_car_data',
      //   title: 'Plan CAR Data',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin:false,
      //   isBranchUser:false,
      //   isStaffUser:false,
      //   isCompanyUser:false,
      //   companyId:'',
      //   menuAccessNumber:25,
      //   imagePath:'',
      //   isAgent:false,
      //   isReseller:false,
      //   menunumber:0,
      //   hasParentMenu: true,
      // },
    ]
  },
  {
    //path: '/pages/cer_records',
    path: '/#',
    title: 'CR Tracker',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 13,
    imagePath: 'assets/images/cr-tracker-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/crtracker/internal',
        title: 'Internal',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 13,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/crtracker/external',
        title: 'External',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 13,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/commuter_travel',
      //   title: 'Commuter Travel',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 13,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
    ]

  },
  {
    path: '/#',
    title: 'CR Report',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 10,
    imagePath: 'assets/images/cr-report-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/cfr_report',
        title: 'CFR Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 10,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/csr_report',
        title: 'CSR Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 10,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cer_report',
        title: 'CER Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 10,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/car_report',
        title: 'CAR Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 10,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      }
    ]
  },
  {
    path: '/#',
    title: 'CR Analysis',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 12,
    imagePath: 'assets/images/icon-car-analysis.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/cfr_analysis',
        title: 'CFR Analysis',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 12,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/csr_analysis',
        title: 'CSR Analysis',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 12,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cer_analysis',
        title: 'CER Analysis',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 12,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cr_analysis',
        title: 'CAR Analysis',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 12,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/cs_cpp_analysis',
        title: 'CS CPP Analysis',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 12,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      }
    ]
  },
  {
    path: '/#',
    title: 'CR Statutory/Annual',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 15,
    imagePath: 'assets/images/cr-statutory-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/#',
    title: 'Download Report',
    icon: '',
    class: '',
    extralink: false,
    submenu: [],
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 16,
    imagePath: 'assets/images/download-report-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
  },
  {
    path: '/#',
    title: 'Accounts Registration',
    //icon: 'mdi mdi-account-settings-variant',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 18,
    imagePath: 'assets/images/icon-accounts-registration.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/business_account',
        title: 'Business Account',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 18,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/agentleads',
        title: 'Leads',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 18,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      // {
      //   path: '/business_list',
      //   title: 'List Business',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 18,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
    ]
  },
  {
    path: '/#',
    title: 'Account Management',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 17,
    imagePath: 'assets/images/account-management-icon.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      {
        path: '/account_financials',
        title: 'Account Financials',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 17,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/account_languages',
        title: 'Account Languages',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 17,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/account_access',
        title: 'Account Access',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 17,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/account_report',
        title: 'Account Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 17,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
      {
        path: '/account_service_desk',
        title: 'Account Service Desk',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        isAdmin: false,
        isBranchUser: false,
        isStaffUser: false,
        isCompanyUser: false,
        companyId: '',
        menuAccessNumber: 17,
        imagePath: '',
        isAgent: false,
        isReseller: false,
        menunumber: 0,
        hasParentMenu: true,
      },
    ]
  },
  {
    path: '/business_account',
    //title: 'Accounts Registration',
    title: 'Business Account',
    //icon: 'mdi mdi-account-settings-variant',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 19,
    imagePath: 'assets/images/icon-accounts-registration.png',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: true,
    submenu: [
      // {
      //   path: '/business_account',
      //   title: 'Business Account',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      // {
      //   path: '/business',
      //   title: 'Add Business',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      // {
      //   path: '/business_list',
      //   title: 'List Business',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      // {
      //   path: '/#',
      //   title: 'Agent Account',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      // {
      //   path: '/agent',
      //   title: 'Add Agent',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
      // {
      //   path: '/agent_list',
      //   title: 'List Agent',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   isAdmin: false,
      //   isBranchUser: false,
      //   isStaffUser: false,
      //   isCompanyUser: false,
      //   companyId: '',
      //   menuAccessNumber: 19,
      //   imagePath: '',
      //   isAgent: false,
      //   isReseller: false,
      //   menunumber: 0,
      //   hasParentMenu: true,
      // },
    ]
  },
  // {
  //   path: '/#',
  //   title: 'Account Management',
  //   icon: '',
  //   class: '',
  //   extralink: false,
  //   isAdmin: false,
  //   isBranchUser: false,
  //   isStaffUser: false,
  //   isCompanyUser: false,
  //   companyId: '',
  //   menuAccessNumber: 20,
  //   imagePath: 'assets/images/account-management-icon.png',
  //   isAgent: false,
  //   isReseller: false,
  //   menunumber: 0,
  //   hasParentMenu: true,
  //   submenu: [
  //     {
  //       path: '/account_search',
  //       title: 'Account Search',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/account_financials',
  //       title: 'Account Financials',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/account_languages',
  //       title: 'Account Languages',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/account_access',
  //       title: 'Account Access',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/account_report',
  //       title: 'Account Report',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/account_service_desk',
  //       title: 'Account Service Desk',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     },
  //     {
  //       path: '/business_account',
  //       title: 'Account Data Management',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       isAdmin: false,
  //       isBranchUser: false,
  //       isStaffUser: false,
  //       isCompanyUser: false,
  //       companyId: '',
  //       menuAccessNumber: 20,
  //       imagePath: '',
  //       isAgent: false,
  //       isReseller: false,
  //       menunumber: 0,
  //       hasParentMenu: true,
  //     }
  //   ]
  // },
  {
    path: '/ecomarkets',
    title: 'Ecomarkets',
    icon: '',
    class: '',
    extralink: false,
    isAdmin: false,
    isBranchUser: false,
    isStaffUser: false,
    isCompanyUser: false,
    companyId: '',
    menuAccessNumber: 27,
    imagePath: '',
    isAgent: false,
    isReseller: false,
    menunumber: 0,
    hasParentMenu: false,
    submenu: []
  },
];
