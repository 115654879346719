<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Social Responsibility (CSR) Performance Report</h2>
        </div>
    </div>
</div>

<div class="container-fluid mt-4">

    <div class="card shadow">

        <div class="card-body">
            <div class="form" [formGroup]="formCsrReport">
                <div class="row">

                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Years</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="year" class="form-control form-control-alternative"
                                        name="year" required
                                        [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let y of yearsList" [value]="y">
                                            {{y}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.year.touched || submitted) && f.year.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Branch</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="branch" class="form-control form-control-alternative"
                                        name="branch" required
                                        [ngClass]="{ 'is-invalid': (f.branch.touched || submitted) && f.branch.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let b of branchList" [value]="b.id">
                                            {{b.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.branch.touched || submitted) && f.branch.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>



                <div class="row" id="content">
                    <div *ngIf="div_1" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isPerformanceTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrPerformance();">CSR
                                Performance</a>
                        </div>
                        <input type="hidden" name="csr_type" formControlName="csr_type" [value]="1"
                            class="form-control form-control-alternative">
                    </div>
                    <div *ngIf="div_2" class="col-md-2">
                        <div class="dash-box" [ngClass]="(isSDGGoalsTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrsdgPerformance();">CSR-SDG
                                Goals</a>
                        </div>
                        <input type="hidden" name="csr_type" formControlName="csr_type" [value]="2"
                            class="form-control form-control-alternative">
                    </div>
                    <div *ngIf="div_3" class="col-md-2">
                        <div class="dash-box" [ngClass]="(iscsrActivityTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrActivity();">CSR
                                -Activity
                                Targets</a>
                        </div>
                    </div>
                    <div *ngIf="div_4" class="col-md-2">
                        <div class="dash-box" [ngClass]="(iscsrActivityCategoryTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrOption4();">CSR -
                                Activity
                                Categories</a>
                        </div>
                    </div>
                    <div *ngIf="div_5" class="col-md-2">

                        <div class="dash-box" [ngClass]="(iscsrValueChainTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrOption5();">CSR -Value
                                Chain
                                Impact</a>
                        </div>
                    </div>
                    <br style="clear:both" />
                </div>

                <div class="row" *ngIf="isMonth">
                    <span style="color: red">Please selet month</span>
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <!-- <span style="float: left;padding-right: 10px;">9</span> -->
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJanCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">January</a>
                        </div>
                        <input formControlName="month_name" name="month_name" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.month_name.touched || submitted) && f.month_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.month_name.touched || submitted) && f.month_name.errors?.required">
                            Month is required
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isFabCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">February</a>
                        </div>
                        <input formControlName="month_name" name="month_name" type="hidden" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.month_name.touched || submitted) && f.month_name.errors?.required }" />
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMarchCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">March</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAprilTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">April</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isMayCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">May</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJuneCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">June</a>
                        </div>
                    </div>
                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isJulyCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">July</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isAugustCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">August</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isSeptCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">September</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isOctCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">October</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isNoveCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">November</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(isDeceCurrentTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">December</a>
                        </div>
                    </div>

                </div>
                <div class="row" id="content" *ngIf="div_9">
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is3MonthTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">3 Month</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is6MonthTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">6 Month</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="dash-box" [ngClass]="(is12MonthTab) ? 'active':''">
                            <a href="javascript:void(0)" (click)="csrmonth($event)">12 Month</a>
                        </div>
                    </div>
                </div>
                <div class="row div_10" id="content" *ngIf="div_10">
                    <!-- <span style="float: left;padding-right: 10px;">10</span> -->
                    <!-- <div class="dash-box"><a href="javascript:void(0)">Report Order Confirmation</a></div> -->
                    <div class="col-md-2">
                        <div class="dash-box">
                            <a href="javascript:void(0)" (click)="onSubmit()">Report</a>
                        </div>
                    </div>
                    <br style="clear:both" />
                </div>
                <div class="row mt-1">
                    <div class="col-md-2">
                        <button type="button" class="btn btn-save" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>