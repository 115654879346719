<!-- Page content -->
<div class="container  pb-5">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 my-auto">
            <div class="">
                <div class="px-lg-5 py-lg-5 mt-3">
                    <div class="text-center mb-4">
                        <h1 class="mt-3">Business User Registration</h1>
                    </div>
                    <!-- <div class="header bg-gradient-primary">
                <div class="container-fluid">
                    <div class="header-body text-center">
                        Business Account Registration
                    </div>
                </div>
            </div> -->
                    <!-- Page content -->
                    <div class="container-fluid mt-4">
                        <div class="card shadow">
                            <div class="card-body">
                                <form [formGroup]="formUser" (ngSubmit)="onSave()">
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Customer<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="role_id"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.role_id.touched || submitted) && f.role_id.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let role of roles" [value]="role.id">
                                                        {{role.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.role_id.touched || submitted) && f.role_id.errors?.required">
                                                    Customer is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Contact First Name<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="main_contact_first_name" type="text" placeholder=""
                                                required class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required">
                                                Contact First name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Contact Last Name<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="main_contact_last_name" type="text" placeholder=""
                                                required class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required">
                                                Contact Last name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Is-Contact System Corporate Administrator ?<span
                                                class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="radio" name="is_contact_system_corporate_administrator"
                                                formControlName="is_contact_system_corporate_administrator" [value]="1" 
                                                (change)="onClickContactAdmin(1)">
                                            Yes
                                            <input type="radio" name="is_contact_system_corporate_administrator"
                                                formControlName="is_contact_system_corporate_administrator" [value]="2" 
                                                (change)="onClickContactAdmin(2)">
                                            No
                                            <div class="radio-btn-cls"
                                                *ngIf="(f.is_contact_system_corporate_administrator.touched || submitted) && f.is_contact_system_corporate_administrator.errors?.required">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.is_contact_system_corporate_administrator.value == '2'">
                                        <div class="row formFieldsBg form-group"  >
                                            <div class="col-md-4">
                                                <label>System Corporate Administrator Contact First Name<span
                                                        class="mandatory-field-color">*</span></label>
                                            </div>
                                            <div class="col-md-8">
                                                <input formControlName="corporate_administrator_first_name" type="text" placeholder=""
                                                    required class="form-control form-control-alternative"
                                                    [ngClass]="{ 'is-invalid': (f.corporate_administrator_first_name.touched || submitted) && f.corporate_administrator_first_name.errors?.required }" />
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.corporate_administrator_first_name.touched || submitted) && f.corporate_administrator_first_name.errors?.required">
                                                    Contact First name is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row formFieldsBg form-group">
                                            <div class="col-md-4">
                                                <label>System Corporate Administrator Contact Last Name<span class="mandatory-field-color">*</span></label>
                                            </div>
                                            <div class="col-md-8">
                                                <input formControlName="corporate_administrator_last_name" type="text" placeholder=""
                                                    required class="form-control form-control-alternative"
                                                    [ngClass]="{ 'is-invalid': (f.corporate_administrator_last_name.touched || submitted) && f.corporate_administrator_last_name.errors?.required }" />
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.corporate_administrator_last_name.touched || submitted) && f.corporate_administrator_last_name.errors?.required">
                                                    Contact Last name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Corporate Department/Unit<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                    
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="corporate_department_unit_id"
                                                    class="form-control form-control-alternative" name="corporate_department_unit_id" required
                                                    [ngClass]="{ 'is-invalid': (f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required }" >
                                                    <mat-option [value]="">-Select-</mat-option>
                                                    <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                                        {{v.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                *ngIf="(f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required">
                                                Corporate department/unit is required
                                            </div>
                                            </mat-form-field>
                    
                    
                                            <!-- <input formControlName="department" type="text" placeholder="" required
                                                class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.department.touched || submitted) && f.department.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.department.touched || submitted) && f.department.errors?.required">
                                                Corporate department/unit is required
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Email Address<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <!-- <div class="col-md-5">
                                    <input type="email" name="email" class="form-control form-control-alternative"
                                        formControlName="email" required
                                        pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                        [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors?.required && f.email.errors?.pattern }">
                                    <div class="invalid-feedback" *ngIf="f.email.touched || submitted">
                                        <div *ngIf="f.email.errors?.required">Email is required.</div>
                                        <div *ngIf="f.email.errors?.pattern">Please provide a valid email
                                            address</div>
                                    </div>
                                </div> -->
                                        <div class="col-md-8">
                                            <input type="text" class="form-control form-control-alternative"
                                                formControlName="email" required
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Is-Email Use For Administration Purposes ?<span
                                                class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="radio" name="is_email_use_for_administrator_purposes"
                                                formControlName="is_email_use_for_administrator_purposes" [value]="1"
                                                (change)="onClickEmailAdmin(1)">
                                            Yes
                                            <input type="radio" name="is_email_use_for_administrator_purposes"
                                                formControlName="is_email_use_for_administrator_purposes" [value]="2"
                                                (change)="onClickEmailAdmin(2)">
                                            No
                                            <div class="radio-btn-cls"
                                                *ngIf="(f.is_email_use_for_administrator_purposes.touched || submitted) && f.is_email_use_for_administrator_purposes.errors?.required">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group" *ngIf="f.is_email_use_for_administrator_purposes.value == '2'">
                                        <div class="col-md-4">
                                            <label>Corporate Branch Email Address<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control form-control-alternative" name="corporate_administrator_email"
                                                formControlName="corporate_administrator_email" required
                                                [ngClass]="{ 'is-invalid': submitted && f.corporate_administrator_email.errors }">
                                            <div *ngIf="submitted && f.corporate_administrator_email.errors" class="invalid-feedback">
                                                <div *ngIf="f.corporate_administrator_email.errors.required">Email is required</div>
                                                <div *ngIf="f.corporate_administrator_email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Website</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="website" type="text" placeholder=""
                                                class="form-control form-control-alternative"/>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Company/Business Name<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="name" type="text" placeholder="" required
                                                class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.name.touched || submitted) && f.name.errors?.required">
                                                Business name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Address 1</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="address_1" type="text" placeholder=""
                                                class="form-control form-control-alternative" />
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Address 2</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="address_2" type="text" placeholder=""
                                                class="form-control form-control-alternative" />
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Address 3</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="address_3" type="text" placeholder=""
                                                class="form-control form-control-alternative" />
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Is Address for Corporate Office/ Branch ?<span
                                                class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="radio" name="is_address_use_for_administrator_office"
                                                formControlName="is_address_use_for_administrator_office" [value]="1">
                                            Yes
                                            <input type="radio" name="is_address_use_for_administrator_office"
                                                formControlName="is_address_use_for_administrator_office" [value]="2">
                                            No
                                            <div class="radio-btn-cls"
                                                *ngIf="(f.is_address_use_for_administrator_office.touched || submitted) && f.is_address_use_for_administrator_office.errors?.required">
                                                This field is required
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.is_address_use_for_administrator_office.value == '2'">
                                        <div class="row formFieldsBg form-group">
                                            <div class="col-md-4">
                                                <label>Corporate Office/ Branch Address 1</label>
                                            </div>
                                            <div class="col-md-8">
                                                <input formControlName="corporate_administrator_address1" type="text" placeholder=""
                                                    class="form-control form-control-alternative" />
                                            </div>
                                        </div>
                                        <div class="row formFieldsBg form-group">
                                            <div class="col-md-4">
                                                <label>Corporate Office/ Branch Address 2</label>
                                            </div>
                                            <div class="col-md-8">
                                                <input formControlName="corporate_administrator_address2" type="text" placeholder=""
                                                    class="form-control form-control-alternative" />
                                            </div>
                                        </div>
                                        <div class="row formFieldsBg form-group">
                                            <div class="col-md-4">
                                                <label>Corporate Office/ Branch Address 3</label>
                                            </div>
                                            <div class="col-md-8">
                                                <input formControlName="corporate_administrator_address3" type="text" placeholder=""
                                                    class="form-control form-control-alternative" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Is Corporate Department for Administrator  ?<span
                                                class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="radio" name="is_corporate_department_administrator"
                                                formControlName="is_corporate_department_administrator" [value]="1">
                                            Yes
                                            <input type="radio" name="is_corporate_department_administrator"
                                                formControlName="is_corporate_department_administrator" [value]="2">
                                            No
                                            <div class="radio-btn-cls"
                                                *ngIf="(f.is_corporate_department_administrator.touched || submitted) && f.is_corporate_department_administrator.errors?.required">
                                                This field is required
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div *ngIf="f.is_corporate_department_administrator.value == '2'">
                                        <div class="row formFieldsBg form-group">
                                            <div class="col-md-4">
                                                <label>Corporate Department/Unit<span class="mandatory-field-color">*</span></label>
                                            </div>
                                            <div class="col-md-8">
                        
                                                <mat-form-field class="w-100">
                                                    <mat-select formControlName="corporate_department_unit_id"
                                                        class="form-control form-control-alternative" name="corporate_department_unit_id" required
                                                        [ngClass]="{ 'is-invalid': (f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required }" >
                                                        <mat-option [value]="">-Select-</mat-option>
                                                        <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                                            {{v.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <div class="invalid-feedback"
                                                    *ngIf="(f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required">
                                                    Corporate department/unit is required
                                                </div>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Industry Sector<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="industry_sector_id"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.industry_sector_id.touched || submitted) && f.industry_sector_id.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let industry of industries"
                                                        [value]="industry.id">
                                                        {{industry.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.industry_sector_id.touched || submitted) && f.industry_sector_id.errors?.required">
                                                    Industry sector is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Industry<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="industry" type="text" placeholder="" required
                                                class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.industry.touched || submitted) && f.industry.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.industry.touched || submitted) && f.industry.errors?.required">
                                                Industry is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>County/State<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="county" type="text" placeholder="" required
                                                class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.county.touched || submitted) && f.county.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.county.touched || submitted) && f.county.errors?.required">
                                                County is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Zip/Postcode<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName="postcode" type="text" placeholder=""
                                                class="form-control form-control-alternative" 
                                                [ngClass]="{ 'is-invalid': (f.postcode.touched || submitted) && f.postcode.errors?.required }"/>
                                                <div class="invalid-feedback"
                                                *ngIf="(f.postcode.touched || submitted) && f.postcode.errors?.required">
                                                County is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Continent<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="continent"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.continent.touched || submitted) && f.continent.errors?.required }" (selectionChange)="onChangeContinent($event.value)">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let cnt of continentsList" [value]="cnt.code">
                                                        {{cnt.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.continent.touched || submitted) && f.continent.errors?.required">
                                                    Continent is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Country<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="country"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let country of countriesList"
                                                        [value]="country.code">
                                                        {{country.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.country.touched || submitted) && f.country.errors?.required">
                                                    Country is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Branch Located in Country<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="branch_number"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.branch_number.touched || submitted) && f.branch_number.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let branch of branchNumbers" [value]="branch">
                                                        {{branch}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.branch_number.touched || submitted) && f.branch_number.errors?.required">
                                                    Branch is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Total Number Of Employee<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="total_number_of_employee"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.total_number_of_employee.touched || submitted) && f.total_number_of_employee.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let emp of employeeList" [value]="emp.id">
                                                        {{emp.display_info}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.total_number_of_employee.touched || submitted) && f.total_number_of_employee.errors?.required">
                                                    Number of users is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Phone Number<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input name="phone" formControlName="phone" type="text" placeholder=""
                                                required class="form-control form-control-alternative"
                                                [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors?.required }" />
                                            <div class="invalid-feedback"
                                                *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required">
                                                Phone is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Products Option<span class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="col-md-6" *ngFor="let product of productList; let i=index"
                                                formArrayName="products">
                                                <mat-checkbox required
                                                    (change)="updateChkbxArray(product.id, $event.checked)"
                                                    value="{{product.id}}" [checked]="product.isChecBox">
                                                    {{product.name}}
                                                </mat-checkbox>
                                            </div>
                                            <div class="text-danger" style="font-size: 13px;"
                                                *ngIf="(this.formUser.controls['products'].touched || submitted) && this.formUser.controls['products'].errors?.required">
                                                Please select a product.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Reporting Currency<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="reporting_currency"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let currency of currencyList"
                                                        [value]="currency.id">
                                                        {{currency.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required">
                                                    Reporting currency is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Company Financial Reporting Year<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="financial_reporting_year"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let fy of financialYearList" [value]="fy.id">
                                                        {{fy.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required">
                                                    Company financial reporting year is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>CSCPP Reporting Year<span
                                                    class="mandatory-field-color">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="car_reporting_year"
                                                    class="form-control form-control-alternative" required
                                                    [ngClass]="{ 'is-invalid': (f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required }">
                                                    <mat-option [value]="">--Select--</mat-option>
                                                    <mat-option *ngFor="let cary of financialYearList"
                                                        [value]="cary.id">
                                                        {{cary.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required">
                                                    CAR reporting year is required
                                                </div>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group">
                                        <div class="col-md-4">
                                            <label>Upload Logo</label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="custom-file">
                                                <input type="file" (change)="getUploadedFile($event.target.files)"
                                                    class="custom-file-input" id="customFile">
                                                <label class="vertical custom-file-label"
                                                    for="customFile">{{fileName}}</label>
                                            </div>
                                            <small>Please upload the logo of size 180*100 for best resolution.</small>
                                        </div>
                                        <div class="col-md-2" *ngIf="isEdit" style="padding-top: 1px;">
                                            <img [src]="imagePath + logoPath" class="img-fluid"
                                                style="width: 50px;height: 50px;">
                                        </div>
                                    </div>
                                    <div class="row formFieldsBg form-group" *ngIf="isEdit">
                                        <div class="col-md-4">
                                            <label>Date Added</label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group datepicker_field">
                                                <input type="text" readonly name="created_at"
                                                    formControlName="created_at"
                                                    class="form-control form-control-alternative datepicker">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-1" *ngIf="isFormSubmittedByUser">
                                        <div class="">
                                            <button type="button" class="btn btn-new-primary"
                                                (click)="onSave()">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <p class="text-center about text-dark">
        ESGMS Powers ESG - Corporate Performance for Planet Earth Sustainable Present & Future
    </p>
</div>