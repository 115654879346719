<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Account Financial</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">

                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Number of Branches<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="branch_number"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.branch_number.touched || submitted) && f.branch_number.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let branch of branchNumbers" [value]="branch">
                                    {{branch}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.branch_number.touched || submitted) && f.branch_number.errors?.required">
                            Branch number is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.branch_number.value > 0">
                    <div class="col-md-3">
                        <label>Number Of Users<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="price_id"
                                class="form-control form-control-alternative" required (selectionChange)="getPriceByUserInfo($event.value)"
                                [ngClass]="{ 'is-invalid': (f.price_id.touched || submitted) && f.price_id.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let price of priceList" [value]="price.id">
                                    {{price.display_info}} ({{price.price}}/Per month)
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.price_id.touched || submitted) && f.price_id.errors?.required">
                            Number of users is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="f.price_id.value !=null">
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Price<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="price" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.price.touched || submitted) && f.price.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.price.touched || submitted) && f.price.errors?.required">
                                Price is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>New Customer Discount<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="new_customer_discount" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.new_customer_discount.touched || submitted) && f.new_customer_discount.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.new_customer_discount.touched || submitted) && f.new_customer_discount.errors?.required">
                                Customer discount is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Negotiated Discount<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="negotiated_discount" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.negotiated_discount.touched || submitted) && f.negotiated_discount.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.negotiated_discount.touched || submitted) && f.negotiated_discount.errors?.required">
                                Negotiated discount is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Plan Discount<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="plan_discount" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.plan_discount.touched || submitted) && f.plan_discount.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.plan_discount.touched || submitted) && f.plan_discount.errors?.required">
                                Negotiated discount is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Total Subscription Per Month<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="total_subscription_per_month" type="text" placeholder="" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.total_subscription_per_month.touched || submitted) && f.total_subscription_per_month.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.total_subscription_per_month.touched || submitted) && f.total_subscription_per_month.errors?.required">
                                Negotiated discount is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Agreed Date</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="agreed_date"
                            class="form-control form-control-alternative datepicker" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker"
                            [ngClass]="{ 'is-invalid': (f.agreed_date.touched || submitted) && f.agreed_date.errors?.required }">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(f.agreed_date.touched || submitted) && f.agreed_date.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>End Date</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="end_date"
                            class="form-control form-control-alternative datepicker" matInput
                            (focus)="myEndDatepicker.open()" [matDatepicker]="myEndDatepicker">
                        <mat-datepicker-toggle [for]="myEndDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myEndDatepicker></mat-datepicker>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.end_date.value !=null">
                    <div class="col-md-3">
                        <label>Reason For Ending</label>
                    </div>
                    <div class="col-md-5">
                        <textarea formControlName="reason_for_end" name="reason_for_end"
                            class="form-control form-control-alternative"></textarea>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Status<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="status"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.status.touched || submitted) && f.status.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option [value]="1">Active</mat-option>
                                <mat-option [value]="2">Inactive</mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.status.touched || submitted) && f.status.errors?.required">
                            Branch number is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-new-primary" (click)="onSave()" *ngIf="(isActive) || (f.status.value == '' || f.status.value == 1)">Save</button>
                        <button  type="button"[routerLink]="['/financial',company_id]"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>