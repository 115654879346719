import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CRDailyData } from '../cr/cr-daily-data';


@Component({
  selector: 'app-corporate-daily-data',
  templateUrl: './corporate-daily-data.component.html',
  styleUrls: ['./corporate-daily-data.component.css']
})
export class CorporateDailyDataComponent implements OnInit {

  constructor(private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) { }

  formUser: FormGroup;
  submitted = false;
  categories: any = [];
  cr_daily: FormArray;
  dailyDataDetails = new CRDailyData();
  categoryId: string;
  dailyDataList: any = [];

  totalentryCount: Number = 0;
  cfrSavingVal: any = 0;
  cfrcosttotal: any = 0;
  csrCostVal: any = 0;
  cerCostVal: any = 0;

  carbonOffsetVal: Number = 0;
  greenTariffVal: Number = 0;
  ghgCalVal: number = 0;

  todaydate: Date = new Date;

  ngOnInit(): void {
    // GET CATEGORIES
    this._service.getCRCategories(2).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
          this.categories.forEach((res: any, index: any) => {
            if (res.name != null) {
              this.dailyDataDetails.daily_data.push({
                category_name: res.name,
                entry_count: "0",
                cfr_saving: "0",
                cfr_cost: "0",
                csr_cost: "0",
                cer_cost: "0",
                carbon_offset: "0",
                green_tariffs: "0",
                ghg_calculation: "0",
                entry_count_total: "0",
                category_id: res.id,
              });
            }
          });
        }
      });



    // GET CATEGORIES
    this._service.getCRDailytDataList().subscribe(
      (response: any) => {
        if (response.success) {
          this.dailyDataList = response.result;
          if (this.dailyDataList.length > 0) {
            this.dailyDataList.forEach((res: any, index: any) => {
              this.totalentryCount = Number(this.totalentryCount) + Number(res.totalEntryCount);

              this.cfrSavingVal = this.roundTo(this.cfrSavingVal, 2) + this.roundTo(res.cfr_saving, 2);
              this.cfrcosttotal = this.roundTo(this.cfrcosttotal, 2) + this.roundTo(res.cfr_cost, 2);
              this.csrCostVal = this.roundTo(this.csrCostVal, 2) + this.roundTo(res.csr_cost, 2);
              this.cerCostVal = this.roundTo(this.cerCostVal, 2) + this.roundTo(res.cer_cost, 2);

              this.carbonOffsetVal = Number(this.carbonOffsetVal) + Number(res.carbon_offset);
              this.greenTariffVal = Number(this.greenTariffVal) + Number(res.green_tariffs);
              this.ghgCalVal = Number(this.ghgCalVal) + Number(res.ghg);
            });
          }

        }
      })
  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  onClear() {
    this._service.clearDailyData('')?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });

          this._service.getDailytDataList(new Date().getFullYear()).subscribe(
            (response: any) => {
              if (response.success) {
                this.dailyDataList = response.result;
                this.totalentryCount = 0;

                this.cfrSavingVal = 0;
                this.cfrcosttotal = 0;
                this.csrCostVal = 0;
                this.cerCostVal = 0;

                this.carbonOffsetVal = 0;
                this.greenTariffVal = 0;
                this.ghgCalVal = 0;

              }
            })
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }
}
