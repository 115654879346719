<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Reseller Account Registration</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contact First Name</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_first_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required">
                            Contact First name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contact Last Name</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_last_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required">
                            Contact Last name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Email Address</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control form-control-alternative"
                            formControlName="email" required
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Website</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="website" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.website.touched || submitted) && f.website.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.website.touched || submitted) && f.website.errors?.required">
                            Website is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Reseller Name</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.name.touched || submitted) && f.name.errors?.required">
                            Reseller name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 1</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_1" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.address_1.touched || submitted) && f.address_1.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.address_1.touched || submitted) && f.address_1.errors?.required">
                            Address 1 is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 2</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_2" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 3</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_3" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>County/State</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="county" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.county.touched || submitted) && f.county.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.county.touched || submitted) && f.county.errors?.required">
                            County is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Zip/Postcode</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="postcode" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.postcode.touched || submitted) && f.postcode.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.postcode.touched || submitted) && f.postcode.errors?.required">
                            Postcode is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Continent<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="continent"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.continent.touched || submitted) && f.continent.errors?.required }" (selectionChange)="onChangeContinent($event.value)">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let cnt of continentsList" [value]="cnt.code">
                                    {{cnt.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.continent.touched || submitted) && f.continent.errors?.required">
                            Continent is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Country<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="country"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let country of countriesList" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.country.touched || submitted) && f.country.errors?.required">
                            Country is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Phone Number</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="phone" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required">
                            Phone is required
                        </div>
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Products Option</label>
                    </div>
                    <div class="col-md-5">
                        <mat-radio-group class="row" aria-label="Select an option" name="products"
                            formControlName="products" required>
                            <div class="col-md-6">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="1">ESGMS - CAR </mat-radio-button>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="2">CR - Tracker</mat-radio-button>
                                </div>
                            </div>
                        </mat-radio-group>
                        <div class="invalid-feedback" style="font-size: 13px;"
                            *ngIf="(f.products.touched || submitted) && f.products.errors?.required">
                            Please select a product.
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Products Option</label>
                    </div>
                    <div class="col-md-5">
                        <div class="col-md-6" *ngFor="let product of productList; let i=index" formArrayName="products">
                            <mat-checkbox required 
                                (change)="updateChkbxArray(product.id, $event.checked)"  value="{{product.id}}" [checked]="product.isChecBox">
                                    {{product.name}}
                            </mat-checkbox>
                        </div>
                       <div class="text-danger" style="font-size: 13px;"
                        *ngIf="(this.formUser.controls['products'].touched || submitted) && this.formUser.controls['products'].errors?.required">
                            Please select a product.
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Upload Logo</label>
                    </div>
                    <div class="col-md-5">
                        <div class="custom-file">
                            <input type="file" (change)="getUploadedFile($event.target.files)"
                                class="custom-file-input" id="customFile">
                            <label class="vertical custom-file-label" for="customFile">{{fileName}}</label>
                        </div>
                        <small>Please upload the logo of size 180*100 for best resolution.</small>
                    </div>
                    <div class="col-md-2" *ngIf="isEdit" style="padding-top: 1px;">
                        <img [src]="imagePath + logoPath" class="img-fluid" style="width: 50px;height: 50px;">                          
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Date Added</label>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group datepicker_field">
                            <input type="text" readonly required name="agent_date" formControlName="agent_date"
                                class="form-control form-control-alternative datepicker" matInput
                                [ngClass]="{ 'is-invalid': (f.agent_date.touched || submitted) && f.agent_date.errors?.required }"
                                (focus)="agDatepicker.open()" [matDatepicker]="agDatepicker">
                            <mat-datepicker-toggle [for]="agDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #agDatepicker></mat-datepicker>
                            <div class="invalid-feedback"
                                *ngIf="(f.agent_date.touched || submitted) && f.agent_date.errors?.required">
                                Date is required
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group" *ngIf="isEdit">
                    <div class="col-md-3">
                        <label>Date Added</label>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group datepicker_field">
                            <input type="text" readonly name="created_at" formControlName="created_at"
                                class="form-control form-control-alternative datepicker">
                        </div>
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="">
                        <button type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                        <button type="button"[routerLink]="['/reseller_list']"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>