<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb main-header-sub-header">
    <div class="row">

        <div class="col-4 align-self-center" *ngIf="isLogo">
            <!-- <h4 class="page-title font-14 text-uppercase font-medium mb-0">{{pageInfo?.title}}</h4> -->
            <!-- <h3 class="page-title font-medium mb-0">Parent Company: <img src="{assets/img/NHS-logo.png}" alt="homepage"
                    class="parent-copmany" /></h3> -->

            <!-- <h3 class="page-title font-medium mb-0">Corporate: <img [src]="imagePath + logo_path" alt="homepage"
                        class="parent-copmany" /></h3> -->
                        <div class="row">
                            <div class="col-3">
                                <h3 class="page-title font-medium mb-0"><img [src]="imagePath + logo_path" alt="homepage"
                                    class="parent-copmany" /> </h3>
                            </div>

                        </div>

            <div class="row">
                <div class="col-12">
                    <!-- <span *ngIf="isCompanyUser" style="padding-left: 15px; font-weight: bold;"><p style="padding-left: 15px; font-weight: bold;">Country of Operation :{{countryName}}</p></span> -->
                    <!-- <span *ngIf="isBranchUser || isCompanyUser || isBranchStaff"><p style="padding-top:5px;font-weight: bold;">Country of Operation :{{countryName}}</p></span> -->
                </div>
            </div>
                    
        </div>
        
        <div class="col-4 align-self-center" *ngIf="!isLogo">
            <div class="row">
                <div class="col-3">
                    <h3 class="page-title font-medium mb-0" *ngIf="(isCompanyUser || isBranchUser || isBranchStaff) && (companyName !=null)">{{companyName}}  <span *ngIf="countryName">- {{countryName}}</span></h3>
                </div>
            </div>
        </div>
        <!-- <div class="col-4 align-self-center" *ngIf="!isLogo && isCompanyUser"> -->
            <!-- <h4 class="page-title font-14 text-uppercase font-medium mb-0">{{pageInfo?.title}}</h4> -->
            <!-- <h3 class="page-title font-medium mb-0">Corporate: <img src="assets/img/NHS-logo.png" alt="homepage"
                    class="parent-copmany" /></h3> -->
        <!-- </div> -->
        <div class="col-4 align-self-center pt-3">
            <p *ngIf="isSuperAdmin" style="text-align: center;">ESGMS Accounts and Products Management</p>

            <!-- <p style="text-align: center;" *ngIf="isCompanyUser">Branch : New Mill </p> -->
            <p style="text-align: center;" *ngIf="isCompanyUser">Account and User Management</p>
            <!-- <p style="text-align: center;" *ngIf="isBranchUser">{{companyName}}</p> -->
            <p style="text-align: center;" *ngIf="isBranchUser"><span style="font-weight: bold;">{{branchName}} - {{countryName}}</span><br/>Branches and User Management</p>
            <p style="text-align: center;" *ngIf="isBranchStaff"><span style="font-weight: bold;">{{branchName}} - {{countryName}}</span></p>
        </div>
        <div class="col-4">
            <ul class="navbar-nav float-right" style="padding-top: 13px;">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <li class="nav-item search-box">
                    <!-- <form role="search" class="app-search d-none d-md-block mr-3" *ngIf="router.url != '/'">
                        <input type="text" placeholder="Search..." class="form-control mt-0">
                        <a href="" class="active">
                            <i class="fa fa-search"></i>
                        </a>
                    </form> -->
                </li>
                
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right" *ngIf="isLoggedIn">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span style="color: #fff;font-weight: bold;">{{userType}}: {{userName}} </span><img
                            src="assets/images/users/user-icon.png" alt="user" class="rounded-circle" width="31">
                    </a>
                    <div class="dropdown-menu user-dd" ngbDropdownMenu>
                        <!-- <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
                        <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item" [routerLink]="['/logout']">
                            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                        <div class="dropdown-divider"></div>
                        <!-- <div class="p-l-30 p-10">
                            <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
                        </div> -->
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div style="clear: both;"></div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->