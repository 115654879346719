import { Component, OnInit, HostListener ,SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//declare var $: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, private sessionSt: LocalStorageService) { }

  public innerWidth: number = 0;
  public defaultSidebar: string = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';
  public isLoggedIn: boolean = true;

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  private setLoginUser ()
  {
    let user = this.sessionSt.retrieve('userinfo');
    let url = this.router.url;
    let urlParams = url.split('/');
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();

    // if (url == "/") {
    //   if(user !=null){
    //     if (user.id) {
    //       this.isLoggedIn = true;
    //     } else {
    //       this.isLoggedIn = false;
    //     }
    //   }
    // } else {
    //   if(urlParams.length > 0 && urlParams[1] == 'auth') {
    //     this.isLoggedIn = false;
    //   } else {
    //     this.isLoggedIn = true;
    //   }
    // }
  }
  ngOnInit() {
    this.setLoginUser();
}
  ngOnChanges(changes: SimpleChanges) {
    this.setLoginUser();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }
}
