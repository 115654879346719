import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-cfr',
  templateUrl: './cfr.component.html',
  styleUrls: ['./cfr.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfrComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfrComponent),
      multi: true
    }
  ]
})
export class CfrComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal,
    private sessionSt: LocalStorageService) { }

  formUser: FormGroup;
  goals: any = [];
  categories: any = [];
  valuechain: any = [];
  targets: any = [];
  activitymark: any = [];
  //submitted = false;
  @Input() item: boolean = true;
  subscriptions: Subscription[] = [];
  @Input() submitted: boolean;
  company_id: any = "";
  branch_id: any = "";
  currencyName: any;

  ngOnInit(): void {

    let user = this.sessionSt.retrieve('userinfo');
    if (user != null) {
      this.branch_id = user.company.id;
      this.company_id = user.company.parent_id;
    }

    //console.log("AMAN" + this.item);
    // GET GOALS
    // 54444444-4444-4444-4444-444444444451 - Optimum Profit/Surplus
    // 54444444-4444-4444-4444-444444444452 - Optimum Share/Public  Value
    this._service.getCRGoals(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.goals = response.result;
        }
      })
    // GET CATEGORIES
    this._service.getCRCategories(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
        }
      })
    // GET VALUE CHAIN
    this._service.getCRValueChain().subscribe(
      (response: any) => {
        if (response.success) {
          this.valuechain = response.result;
        }
      })

    // GET ACTIVITY TARGET
    this._service.getCSRTargetActivity(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.targets = response.result;
        }
      })

    // GET ACTIVITY MARK
    this._service.getCSRActivityMark(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.activitymark = response.result;
        }
      })

    this.formUser = this.fb.group({
      id: [''],
      goal_id: ['', Validators.required],
      goal_value_id: ['', Validators.required],
      category_id: ['', Validators.required],
      category_activity_id: ['', Validators.required],
      activity_description: [''],
      activity_quantity: ['0.00', Validators.required],
      activity_unit: ['', Validators.required],
      facility_decription: [''],
      value_chain_main_id: ['', Validators.required],
      value_chain_id: [''],
      building: [''],
      green_tarrif_tonnes: [''],
      administartion: [''],
      support_documents: this.fb.array([]),
      activity_measurement_unit: ['', Validators.required],
      //is_value_chain: ['', Validators.required],
      activity_mark_id: ['', Validators.required],
      activity_quality: [''],
      activity_value_cost: ['0.00', Validators.required],
      activity_total_investment: ['0.00', Validators.required],
      activity_investment_mark_cost: ['0.00', Validators.required],
      // activity_finance_investment_sellable_value: ['0.00'],
      activity_value_saving: ['0.00', Validators.required],
      target_month_date: ['', Validators.required]
      //cr_type: 4
      //cr_type:2
    });


    // GET CURRENCY NAME OF THE CORPORATE BRANCH
    this._service.getCurrencyNameById(user.company.reporting_currency).subscribe((response: any) => {
      if (response.success) {
        this.currencyName = response.result[0].name;
        // SET DEFAULT CURRENCY
        this.formUser.controls['activity_measurement_unit'].setValue(response.result[0].name, { onlySelf: true });
      }
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.formUser.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // ADD Default Document
    this.addSupportDocuments();

    //Edit Car Record
    this.getCarDataById(this.route.snapshot.params.id, 2);
  }

  get f() { return this.formUser.controls; }


  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    // if (value) {
    //   this.value = value;
    // }

    // if (value === null) {
    //   this.proposerSection.reset();
    // }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.formUser.valid ? null : { proposer: { valid: false } };
  }

  /*
* GET GHG & TARGET ON GOAL CHANGE
* Optimum Profit/Surplus ---- 54444444-4444-4444-4444-444444444451
* Optimum Share/Public Value ---- 54444444-4444-4444-4444-444444444452
*/
  goalValues: any = [];
  goalValueLabel: string = '';
  isActivityValueCost: boolean = false;
  isActivityValueSaving: boolean = false;
  onGoalChange(event: any) {
    let selectedGoal = this.goals.filter((x: any) => x.id == event.value);
    if (selectedGoal.length > 0) {
      this.goalValues = selectedGoal[0].goal_values;
    }
    this.formUser.controls['activity_measurement_unit'].setValue(this.currencyName, { onlySelf: true });

    if (event.value == '54444444-4444-4444-4444-444444444451') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e7", { onlySelf: true });
      this.formUser.controls['activity_mark_id'].disable();
      this.isActivityValueCost = true;
      this.isActivityValueSaving = false;
      this.formUser.get("activity_value_cost")?.enable();
      this.formUser.get("activity_value_saving")?.disable();
    } else if (event.value == '54444444-4444-4444-4444-444444444452') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e5", { onlySelf: true });
      this.formUser.controls['activity_mark_id'].disable();
      this.isActivityValueCost = false;
      this.isActivityValueSaving = true;
      this.formUser.get("activity_value_saving")?.enable();
      this.formUser.get("activity_value_cost")?.disable();
    }
  }

  /*
* GET ACTIVITY VALUES ON CATEGORY CHANGE
*/
  categoryValues: any = [];
  onCategoryChange(event: any) {
    let selectedCat = this.categories.filter((x: any) => x.id == event.value);
    if (selectedCat.length > 0) {
      this.categoryValues = selectedCat[0].category_values;
    }

    this.activateActivityData();
  }

  /*
  * ACTIVITIES CHANGE
  */
  onActivitiesChange(event: any) {
    this.activateActivityData();
  }
  /*
* Activate Activity Data
* 33333333-3333-3333-3333-333333333331 - Policy and  Regulation
* 33333333-3333-3333-3333-333333333332 - Products  and  Services
* 33333333-3333-3333-3333-333333333335 - Programmes / Initiatives
*/
  valueChainFields: boolean = false;
  isActivityQuantity: boolean = false;
  isActivityMeasurementUnit: boolean = false;
  quality_value_label: string = '';
  quantity_value_label: string = '';
  mark_cost_label: string = '';
  isQualityValue: boolean = true;
  activateActivityData() {
    let category = this.formUser.get('category_id')?.value;
    let category_values = this.formUser.get('category_activity_id')?.value;
    let goal_values = this.formUser.get('goal_value_id')?.value;

    //this.valueChainFields = true;
    switch (category) {
      case '33333333-3333-3333-3333-333333333335':
        this.quality_value_label = "Activity Value Quality";
        this.quantity_value_label = "Activity Quantity Value";
        this.isActivityQuantity = true;
        this.isActivityMeasurementUnit = true;
        if (!this.isEdit) {
          this.formUser.controls['value_chain_main_id'].setValue("");
        }

        //this.formUser.controls['activity_mark_id'].setValue("");
        this.formUser.get("activity_investment_mark_cost")?.disable();
        this.formUser.get("activity_quantity")?.enable();
        this.formUser.get("activity_unit")?.enable();
        this.formUser.get("activity_total_investment")?.disable();
        //this.formUser.get("activity_value_cost")?.enable();
        this.isQualityValue = false;

        this.value_chain_infra_label = 'Corporate Departments/Units';
        break;
      case '33333333-3333-3333-3333-333333333332':
        this.quality_value_label = "Activity Quality Value";
        this.quantity_value_label = "Activity Quantity Value";
        this.isActivityQuantity = true;
        this.isActivityMeasurementUnit = true;
        if (!this.isEdit) {
          this.formUser.controls['value_chain_main_id'].setValue("");
        }
        if (category_values == '41111111-1111-1111-1111-111111111116') {
          this.formUser.controls['activity_mark_id'].setValue("1b4e4105-463e-456e-8e5e-97ac397168e5", { onlySelf: true });
        }
        this.formUser.get("activity_investment_mark_cost")?.disable();
        this.formUser.get("activity_quantity")?.enable();
        this.formUser.get("activity_unit")?.enable();
        this.formUser.get("activity_total_investment")?.disable();
        //this.formUser.get("activity_value_cost")?.enable();
        this.isQualityValue = false;
        break;
      case '33333333-3333-3333-3333-333333333331':
        this.quality_value_label = "Activity Quality Value";
        this.quantity_value_label = "Activity Quantity Value";
        this.isActivityQuantity = false;
        this.isActivityMeasurementUnit = false;
        if (!this.isEdit) {
          this.formUser.controls['value_chain_main_id'].setValue("");
        }

        this.formUser.get("activity_investment_mark_cost")?.disable();
        this.formUser.get("activity_quantity")?.disable();
        this.formUser.get("activity_unit")?.disable();
        this.formUser.get("activity_total_investment")?.disable();
        // this.formUser.get("activity_quality")?.disable();
        //this.formUser.get("activity_value_cost")?.enable();
        //this.formUser.controls['activity_mark_id'].setValue("");
        this.isQualityValue = false;
        break;
      default:
        this.isActivityQuantity = false;
        this.isActivityMeasurementUnit = false;
        this.isQualityValue = true;
        if (!this.isEdit) {
          this.formUser.controls['value_chain_main_id'].setValue("");
        }

        //this.formUser.controls['activity_mark_id'].setValue("");
        break;
    }
  }
  /**** SHOW VALUE CHAIN FIELDS ON YES/NO */
  onValueChainImpactChange(event: any) {
    if (event.value == '1') {
      this.valueChainFields = true;
    } else {
      this.valueChainFields = false;
    }
  }
  /*
* GET VALUE CHAIN VALUES
*/
  value_chain_infra_label: string = '';
  valuechainValues: any = [];
  onValueChainChange(event: any) {
    if (event.value == '66666666-6666-6666-6666-666666666662') {
      this.value_chain_infra_label = 'Corporate Departments/Units';
    }
    else if (event.value == '66666666-6666-6666-6666-666666666663') {
      this.value_chain_infra_label = 'Firm Infrastructure';
    }
    let selectedvaluechain = this.valuechain.filter((x: any) => x.id == event.value);
    if (selectedvaluechain.length > 0) {
      this.valuechainValues = selectedvaluechain[0].value_chain_values;
    }
  }
  /*
  * CALCULATE TOTAL FINANCE AND INVESTMENT VALUE
  */
  calculateFinanceAndInvestment() {
    let value = Number(this.formUser.get('activity_total_investment')?.value) * Number(this.formUser.get('activity_investment_mark_cost')?.value);
    this.formUser.get('activity_finance_investment_sellable_value')?.setValue(value);
  }
  onSave() {
    this.submitted = true;
    this.removeValidation();
    this.saveData(1);

  }

  onSubmit() {
    this.submitted = true;
    this.addValidation();
    this.saveData(2);
  }

  /*
* Help Text PopUp Modal
*/
  msg = '';
  helpTextPopup(modal: any, content: any) {
    this.msg = content;
    this.modalService.open(modal, { centered: true });
  }
  onActivityMarkChange(event: any, helpTextPopupModal: any) {
    if (event.value == '3b4e4105-463e-456e-8e5e-97ac397168e5') {
      this.msg = 'Enter value into CAR record line.';
      this.modalService.open(helpTextPopupModal, { centered: true });
    }
  }

  private createDocument(): FormGroup {
    return this.fb.group({
      document_path: [''],
      file_name: [''],
    });
  }
  public addSupportDocuments(): void {
    const documents = this.formUser.get('support_documents') as FormArray;
    documents.push(this.createDocument());
  }

  supportDocuments(): FormArray {
    return this.formUser.get("support_documents") as FormArray
  }
  removeDocument(i: number) {
    this.supportDocuments().removeAt(i);
  }

  /*
* Get Uploaded Logo
*/
  fileName: string = '';
  fileToUpload: any = null;
  getUploadedFile(files: any) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  myFiles: any = [];
  getUploadedDocuments(files: any, i: any) {
    this.fileToUpload = files.item(0);
    //this.fileName = this.fileToUpload.name;
    const companies = this.formUser.get('support_documents') as FormArray;
    //this.companyDetails.static_docs[i].file_name = this.fileToUpload.name;
    for (var j = 0; j < files.length; j++) {
      this.myFiles.push(files[j]);
    }
  }

  isEdit: boolean = false;
  //Get Car Data By Id
  getCarDataById(id: any, cr_type: any) {
    this._service.getCarById(id, cr_type).subscribe(
      (response: any) => {
        if (response.success) {
          this.isEdit = true;

          this.formUser.patchValue({
            id: response.result.id,
            goal_id: response.result.goal_id,
            goal_value_id: response.result.goal_value_id,
            category_id: response.result.category_id,
            category_activity_id: response.result.category_activity_id,
            activity_description: response.result.activity_description,
            activity_quantity: response.result.activity_quantity,
            activity_unit: response.result.activity_unit,
            is_ghg_param: response.result.is_ghg_param,
            value_chain_main_id: response.result.value_chain_main_id,
            value_chain_id: response.result.value_chain_id,
            facility_decription: response.result.facility_decription,
            building: response.result.building,
            green_tarrif_tonnes: response.result.product_service_green_tarrif,
            ghg_emission_unit: response.result.ghg_emission_unit,
            activity_emmission_factor: response.result.activity_emmission_factor,
            administartion: response.result.administartion,
            emp_number: response.result.emp_number,
            target_month_date: response.result.target_month_datetime,

            activity_measurement_unit: response.result.activity_measurement_unit,
            activity_mark_id: response.result.activity_mark_id,
            activity_quality: response.result.activity_quality,
            activity_value_cost: response.result.activity_value_cost,
            activity_total_investment: response.result.activity_total_investment,
            activity_investment_mark_cost: response.result.activity_investment_mark_cost,
            activity_finance_investment_sellable_value: response.result.activity_finance_investment_sellable_value,
            activity_value_saving: response.result.activity_value_saving,
          });

          // this.rowData = response.result.supported_documents;

          this.getValueChainByMainValueChainId(response.result.value_chain_main_id);

          this.activateActivityData();

          let value = Number(response.result.activity_quantity) * Number(response.result.activity_emmission_factor);
          this.formUser.get('ghg_emmission_tonnes')?.setValue(value);

          this.onEditGoalChange(response.result.goal_id)
          this.onEditCategoryChange(response.result.category_id);
        }
      });
  }

  onEditGoalChange(goal_id: any) {
    let selectedGoal = this.goals.filter((x: any) => x.id == goal_id);
    if (selectedGoal.length > 0) {
      this.goalValues = selectedGoal[0].goal_values;
    }


    if (goal_id == '54444444-4444-4444-4444-444444444451') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e7", { onlySelf: true });
      this.formUser.controls['activity_mark_id'].disable();
      this.isActivityValueCost = true;
      this.isActivityValueSaving = false;
      this.formUser.get("activity_value_cost")?.enable();
      this.formUser.get("activity_value_saving")?.disable();
    } else if (goal_id == '54444444-4444-4444-4444-444444444452') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e5", { onlySelf: true });
      this.formUser.controls['activity_mark_id'].disable();
      this.isActivityValueCost = false;
      this.isActivityValueSaving = true;
      this.formUser.get("activity_value_saving")?.enable();
      this.formUser.get("activity_value_cost")?.disable();
    }
  }
  onEditCategoryChange(category_id: any) {
    let selectedCat = this.categories.filter((x: any) => x.id == category_id);
    if (selectedCat.length > 0) {
      this.categoryValues = selectedCat[0].category_values;
    }

    this.activateActivityData();
  }
  getValueChainByMainValueChainId(value_chain_id: any) {
    if (value_chain_id == '66666666-6666-6666-6666-666666666662') {
      this.value_chain_infra_label = 'Corporate Departments/Units';
    }
    else if (value_chain_id == '66666666-6666-6666-6666-666666666663') {
      this.value_chain_infra_label = 'Firm Infrastructure';
    }
    let selectedvaluechain = this.valuechain.filter((x: any) => x.id == value_chain_id);
    if (selectedvaluechain.length > 0) {
      this.valuechainValues = selectedvaluechain[0].value_chain_values;
    }
  }

  saveData(status: any) {
    if (this.formUser.invalid) {
      console.log(this.formUser.controls);
      return;
    }
    let data = this.formUser.getRawValue();
    data.file_name = this.fileToUpload;

    let formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) {
      if (this.myFiles[i]) {
        formData.append("uploaded_files_" + i, this.myFiles[i]);
      }
    }
    let formattedDate = formatDate(data.target_month_date, 'yyyy-MM-dd', 'en-US');
    formData.append("id", data.id ? data.id : "");
    formData.append("totaldocscount", this.myFiles.length);
    formData.append("goal_id", data.goal_id);
    formData.append("goal_value_id", data.goal_value_id);
    formData.append("category_id", data.category_id);
    formData.append("category_activity_id", data.category_activity_id);
    formData.append("activity_mark_id", data.activity_mark_id);
    formData.append("activity_description", data.activity_description);
    formData.append("activity_total_investment", data.activity_total_investment);
    formData.append("activity_investment_mark_cost", data.activity_investment_mark_cost);
    // formData.append("activity_finance_investment_sellable_value", data.activity_finance_investment_sellable_value);
    formData.append("activity_quantity", data.activity_quantity);
    formData.append("activity_unit", data.activity_unit);
    // formData.append("activity_quality", data.activity_quality);
    formData.append("activity_value_cost", data.activity_value_cost);
    formData.append("activity_measurement_unit", data.activity_measurement_unit);
    formData.append("value_chain_main_id", data.value_chain_main_id);
    formData.append("value_chain_id", data.value_chain_id);
    formData.append("facility_decription", data.facility_decription);
    formData.append("building", data.building);
    formData.append("activity_value_saving", data.activity_value_saving);
    formData.append("cr_type", "2");
    formData.append("target_month_datetime", formattedDate);
    formData.append("company_id", this.company_id);
    formData.append("branch_id", this.branch_id);
    formData.append("status", status);

    this._service.saveCarData(formData)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
          this.formUser.reset();
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }

  addValidation() {
    this.formUser.get('goal_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('goal_id')?.updateValueAndValidity();

    this.formUser.get('goal_value_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('goal_value_id')?.updateValueAndValidity();

    this.formUser.get('category_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('category_id')?.updateValueAndValidity();

    this.formUser.get('category_activity_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('category_activity_id')?.updateValueAndValidity();

    this.formUser.get('activity_quantity')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_quantity')?.updateValueAndValidity();

    this.formUser.get('activity_unit')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_unit')?.updateValueAndValidity();

    this.formUser.get('value_chain_main_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('value_chain_main_id')?.updateValueAndValidity();

    this.formUser.get('activity_measurement_unit')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_measurement_unit')?.updateValueAndValidity();

    this.formUser.get('activity_mark_id')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_mark_id')?.updateValueAndValidity();

    this.formUser.get('activity_value_cost')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_value_cost')?.updateValueAndValidity();

    this.formUser.get('activity_total_investment')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_total_investment')?.updateValueAndValidity();

    this.formUser.get('activity_investment_mark_cost')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_investment_mark_cost')?.updateValueAndValidity();

    this.formUser.get('activity_finance_investment_sellable_value')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_finance_investment_sellable_value')?.updateValueAndValidity();

    this.formUser.get('activity_value_saving')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('activity_value_saving')?.updateValueAndValidity();
  }

  removeValidation() {
    this.formUser.get('goal_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('goal_id')?.updateValueAndValidity();

    this.formUser.get('goal_value_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('goal_value_id')?.updateValueAndValidity();

    this.formUser.get('category_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('category_id')?.updateValueAndValidity();

    this.formUser.get('category_activity_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('category_activity_id')?.updateValueAndValidity();

    this.formUser.get('activity_quantity')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_quantity')?.updateValueAndValidity();

    this.formUser.get('activity_unit')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_unit')?.updateValueAndValidity();

    this.formUser.get('value_chain_main_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('value_chain_main_id')?.updateValueAndValidity();

    this.formUser.get('activity_measurement_unit')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_measurement_unit')?.updateValueAndValidity();

    this.formUser.get('activity_mark_id')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_mark_id')?.updateValueAndValidity();

    this.formUser.get('activity_value_cost')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_value_cost')?.updateValueAndValidity();

    this.formUser.get('activity_total_investment')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_total_investment')?.updateValueAndValidity();

    this.formUser.get('activity_investment_mark_cost')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_investment_mark_cost')?.updateValueAndValidity();

    this.formUser.get('activity_finance_investment_sellable_value')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_finance_investment_sellable_value')?.updateValueAndValidity();

    this.formUser.get('activity_value_saving')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('activity_value_saving')?.updateValueAndValidity();
  }
  addminussign(event: any) {
    if (event.target.value) {
      //this.formUser.controls['activity_value_saving'].setValue("-"+event.target.value);
      this.formUser.get('activity_value_saving')?.clearValidators(); // 6. Clear All Validators
      this.formUser.get('activity_value_saving')?.updateValueAndValidity();
    }
  }
}
