import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot,Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private sessionSt: LocalStorageService, protected router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot){

    if (!this.sessionSt.retrieve('login_token')) {
      this.router.navigateByUrl("/");
    }

    let user = this.sessionSt.retrieve('userinfo');
    if(user != null && user.id != null){
      return true;
    }
    return false;
  }
}