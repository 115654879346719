import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ForgotPasswordService } from './forgot-password.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login/login.component.scss','../../layouts/full/full.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassowrdForm: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder, private service: ForgotPasswordService, private router: Router,
    private confirmDialogService: ConfirmDialogService,private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    this.sessionSt.clear();
    this.router.navigate(['/auth/forgot-password'])
    this.forgotPassowrdForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }


  get f() { return this.forgotPassowrdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPassowrdForm.invalid) {
      return;
    }
    
    let params: any = {
      email: this.forgotPassowrdForm.get('email')?.value
    };
    this.service.postDataFun(params)
      .subscribe(
        (response) => {          
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }
          
        },
        (error) => {
          console.log(error);
        }
      );



  }




}