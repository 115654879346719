import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-plan-cfr',
  templateUrl: './plan-cfr.component.html',
  styleUrls: ['./plan-cfr.component.css']
})
export class PlanCfrComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal,
    private sessionSt: LocalStorageService) { }

  formUser: FormGroup;
  cr_months: FormArray;
  goals: any = [];
  categories: any = [];
  valuechain: any = [];
  targets: any = [];
  activitymark: any = [];
  plantype: any = [];
  submitted = false;
  currentItem: boolean = false;
  planList: any = [];
  company_id: any = "";
  branch_id: any = "";

  monthArray = {
    1: 'Month 1',
    2: 'Month 2',
    3: 'Month 3',
    4: 'Month 4',
    5: 'Month 5',
    6: 'Month 6',
    7: 'Month 7',
    8: 'Month 8',
    9: 'Month 9',
    10: 'Month 10',
    11: 'Month 11',
    12: 'Month 12'
  };

  ngOnInit(): void {
    // GET GOALS
    this._service.getCRGoals(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.goals = response.result;
        }
      })
    // GET CATEGORIES
    this._service.getCRCategories(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
        }
      })
    // GET VALUE CHAIN
    this._service.getCRValueChain().subscribe(
      (response: any) => {
        if (response.success) {
          this.valuechain = response.result;
        }
      })

    // GET ACTIVITY TARGET
    this._service.getCSRTargetActivity(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.targets = response.result;
        }
      })

    // GET ACTIVITY MARK
    this._service.getCSRActivityMark(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.activitymark = response.result;
        }
      })


    // GET PLANS
    this._service.getPlanData().subscribe(
      (response: any) => {
        if (response.success) {
          this.plantype = response.result;
          let selectedPlan = this.plantype.filter((x: any) => x.id == '11111111-1111-1111-1111-111111111111');
          if (selectedPlan.length > 0) {
            this.subPlanType = selectedPlan[0].cr_subplan_type;
          }
        }
      })


    let user = this.sessionSt.retrieve('userinfo');
    if (user != null) {
      this.branch_id = user.company.id;
      this.company_id = user.company.parent_id;
    }

    this.formUser = this.fb.group({
      id: [''],
      cr_months: this.fb.array([this.bindMonthDetails()]),
      //plan_id: [''],
      sub_plan_id: ['', Validators.required],
      cost_performance: ['', Validators.required],
      cost_performance_annual_total: ['', Validators.required],
      cfr_plan_id: [''],
      goal_id: [''],
      goal_value_id: [''],
      category_id: [''],
      activity_mark_id: [''],
      activity_mark_cost: ['0:00'],
      car_data: [],
      user_company_id: this.company_id,
      user_branch_id: this.branch_id,
      IsFinanceAndInvestmet:['', Validators.required],
      is_submitted:[],
      target_month_date: [''],
    });

    this.onPlanChange();
    this.getPlanDataByRefId(this.route.snapshot.params.id);
  }
  get f() { return this.formUser.controls; }

  /*
 * Access form control values
 */
  get frmCtrl() {
    return this.formUser.controls;
  }

  get monthArr() { return this.f.cr_months as FormArray; }

  /*
  * GET SUb PLAN TYPE ON PLAN CHANGE VALUE
  * Corporate Responsibilities (CR) Performance Plan ---- 11111111-1111-1111-1111-111111111111
  * Corporate Responsibilities (CR) Activity Record Plan ---- 11111111-1111-1111-1111-111111111112
  */
  subPlanType: any = [];
  onPlanChange() {
    let selectedPlan = this.plantype.filter((x: any) => x.id == '11111111-1111-1111-1111-111111111111');
    if (selectedPlan.length > 0) {
      this.subPlanType = selectedPlan[0].cr_subplan_type;
    }
    // if(event.value == '11111111-1111-1111-1111-111111111111'){
    //   this.formUser.get("cost_performance")?.enable();
    // }
    // else if(event.value == '11111111-1111-1111-1111-111111111112'){
    //   this.formUser.get("cost_performance")?.disable();
    // }
    this.formUser.controls['cost_performance'].setValue("");
  }
  /**
   * SHOW HIDE TEXT BOX ON CHANGE COST TYPE
   * 
   */

  /**
   * 22222222-2222-2222-2222-222222222221 - CFR-Cost Performance
   * 22222222-2222-2222-2222-222222222222 - CFR–Saving  Performance
   * 22222222-2222-2222-2222-222222222223 - CSR–Cost  Performance
   * 22222222-2222-2222-2222-222222222225 - CER–Cost  Performance
   * 22222222-2222-2222-2222-222222222226 - CAR-Carbon Offset Performance
   * 22222222-2222-2222-2222-222222222227 - CAR-Green Tariff Performance
   * 22222222-2222-2222-2222-222222222228 - CAR-GHG Performance
   */

  cost_performance_label: string = '';
  onchangeSubPlan:any;
  onSublanChange(event: any) {
    let planType = event.value;
    this.onchangeSubPlan = event.value;
    this.getDataByPlanId(planType,this.year);
    switch (planType) {
      case '22222222-2222-2222-2222-222222222221':
        this.cost_performance_label = "CFR-Cost Performance";
        break;
      case '22222222-2222-2222-2222-222222222222':
        this.cost_performance_label = "CFR–Saving  Performance";
        break;
      case '22222222-2222-2222-2222-222222222223':
        this.cost_performance_label = "CSR–Cost  Performance";
        break;
      case '22222222-2222-2222-2222-222222222225':
        this.cost_performance_label = "CER–Cost  Performance";
        break;
      case '22222222-2222-2222-2222-222222222226':
        this.cost_performance_label = "CAR-Carbon Offset Tonnes CO2e Performance";
        break;
      case '22222222-2222-2222-2222-222222222227':
        this.cost_performance_label = "CAR-Green Tariff Tonnes CO2e Performance";
        break;
      case '22222222-2222-2222-2222-222222222228':
        this.cost_performance_label = "CAR-GHG Tonnes CO2e Performance";
        break;
    }
    this.formUser.controls['cost_performance'].setValue("");
    this.formUser.controls['cost_performance_annual_total'].setValue("");
  }

  onCostPerformance(TotalCost: any) {
    //let TotalCost = event.target.value;
    let perMonthCost = TotalCost / 12;
    this.monthArr.controls.forEach((res, index) => {
      var upto2decimal = this.roundTo(perMonthCost, 2);
      res.get("value")?.setValue(this.roundTo(perMonthCost, 2));
    })
  }

  isAnnualTotal: boolean;
  onChangeMonthlyValue(event: any) {
    //this.cost_performance_label = "";
    let monthVal: Number = 0;
    this.monthArr.controls.forEach((res, index) => {
      monthVal += res.get("value")?.value;
    })
    if (monthVal > 0) {
      this.isAnnualTotal = true;
    }
    else {
      this.isAnnualTotal = false;
    }
    this.formUser.controls['cost_performance_annual_total'].setValue(monthVal);
  }
  /*
* GET GHG & TARGET ON GOAL CHANGE
* Optimum Profit/Surplus ---- 54444444-4444-4444-4444-444444444451
* Optimum Share/Public Value ---- 54444444-4444-4444-4444-444444444452
*/
  mark_cost_label: string = '';
  goalValues: any = [];
  goalValueLabel: string = '';
  onGoalChange(event: any) {
    let selectedGoal = this.goals.filter((x: any) => x.id == event.value);
    if (selectedGoal.length > 0) {
      this.goalValues = selectedGoal[0].goal_values;
    }


    if (event.value == '54444444-4444-4444-4444-444444444451') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e7", { onlySelf: true });
      this.mark_cost_label = "Activity Value Cost";
    } else if (event.value == '54444444-4444-4444-4444-444444444452') {
      this.formUser.controls['activity_mark_id'].setValue("444e4105-463e-456e-8e5e-97ac397168e5", { onlySelf: true });
      this.mark_cost_label = "Activity Value Saving";
    }
  }
  saveData() {
    //this.submitted = true;
    if (this.formUser.invalid) {
      console.log(this.formUser.controls);
      return;
    }
    let data = this.formUser.getRawValue();
    //data.target_month_date = formatDate(data.target_month_date, 'yyyy-MM-dd', 'en-US');
    data.target_month_date = this.year;
    this._service.savePlanData(data)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }
  bindMonthDetails(): FormGroup {
    return this.fb.group({
      id: [],
      cr_plan_id: [],
      month_number: [],
      value: ["", [Validators.required]],
    });
  }
  onCostPerformanceChange() {
    //this.formUser.controls['cost_performance_annual_total'].setValue("");
    (this.formUser.controls['cr_months'] as FormArray).clear();
    for (let i = 1; i <= 12; i++) {
      this.monthArr.push(this.bindMonthDetails());
    }
    this.monthArr.controls.forEach((res, index) => {
      res.get("value")?.setValue("");
      res.get("month_number")?.setValue(index + 1);
    })
  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  getDataByPlanId(planId: any,year:any) {
    this._service.getPlanDataById(planId,year)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
          this.formUser.patchValue({
            id: response.planData.id,
            cost_performance: response.planData.cost_performance,
            cost_performance_annual_total: response.planData.cost_performance_annual_total,
            IsFinanceAndInvestmet:response.planData.IsFinanceAndInvestmet,
            target_month_date: response.planData.created_at,

          });
          this.onCostPerformanceChange();
          //this.onCostPerformance(response.planData.cost_performance_annual_total);
          this.getDataOnEdit(response.month_plan_data);
        } else {
          this.formUser.patchValue({
            id: "",
            cost_performance: "",
            cost_performance_annual_total: ""

          });
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }

  getPlanDataByRefId(id: any) {
    this._service.getPlanDataByRefId(id).subscribe(
      (response: any) => {
        if (response.success) {
          this.formUser.patchValue({
            sub_plan_id: response.planData.sub_plan_id,
          });
          this.getDataByPlanId(response.planData.sub_plan_id,this.year);
          switch (response.planData.sub_plan_id) {
            case '22222222-2222-2222-2222-222222222221':
              this.cost_performance_label = "CFR-Cost Performance";
              break;
            case '22222222-2222-2222-2222-222222222222':
              this.cost_performance_label = "CFR–Saving  Performance";
              break;
            case '22222222-2222-2222-2222-222222222223':
              this.cost_performance_label = "CSR–Cost  Performance";
              break;
            case '22222222-2222-2222-2222-222222222225':
              this.cost_performance_label = "CER–Cost  Performance";
              break;
            case '22222222-2222-2222-2222-222222222226':
              this.cost_performance_label = "CAR-Carbon Offset Tonnes CO2e Performance";
              break;
            case '22222222-2222-2222-2222-222222222227':
              this.cost_performance_label = "CAR-Green Tariff Tonnes CO2e Performance";
              break;
            case '22222222-2222-2222-2222-222222222228':
              this.cost_performance_label = "CAR-GHG Tonnes CO2e Performance";
              break;
          }
        }
      });
  }
  onSave() {
    this.submitted = true;
    this.removeValidation();
    this.formUser.get('is_submitted')?.setValue(1);
    this.saveData();

  }

  onSubmit(helpTextPopupModal:any) {
    this.submitted = true;
    this.addValidation();
    this.formUser.get('is_submitted')?.setValue(2);
    let IsFinanceAndInvestmet = this.formUser.get('IsFinanceAndInvestmet')?.value;
    if(IsFinanceAndInvestmet == 2){
      this.helpTextPopup(helpTextPopupModal,"To submit add finance and investment data to plan.");
    }
    else{
      this.saveData();
    }
  }
  getDataOnEdit(monthData: any) {
    this.monthArr.controls.forEach((res, index) => {
      var mnumber = index + 1;
      monthData.forEach((mdata: any, i: any) => {
        if (mdata.month_number == mnumber) {
          res.get("value")?.setValue(this.roundTo(mdata.value, 2));
        }
      })
    })
  }

  addValidation() {
    this.formUser.get('IsFinanceAndInvestmet')?.setValidators([Validators.required]); // 6. Clear All Validators
    this.formUser.get('IsFinanceAndInvestmet')?.updateValueAndValidity();
  }

  removeValidation() {
    this.formUser.get('IsFinanceAndInvestmet')?.clearValidators(); // 6. Clear All Validators
    this.formUser.get('IsFinanceAndInvestmet')?.updateValueAndValidity();
  }

      /*
* Help Text PopUp Modal
*/
msg = '';
helpTextPopup(modal: any, content: any) {
  this.msg = content;
  this.modalService.open(modal, { centered: true });
}
onClickNo(event: any, helpTextPopupModal: any) {
    if (event.target.value == '2') {
      this.msg = 'Enter value into CR record line.';
      this.modalService.open(helpTextPopupModal, { centered: true });
    }
  }
  selectedDate:any= Date;
  addEvent(event:any) {
    console.log(event.target.value);
    this.selectedDate = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
  }
  year:any;
  yearChange(event:any) {
    this.year = event;
    if(this.year !=null && this.year != ''){
      this.getDataByPlanId(this.onchangeSubPlan , this.year);

    }
  }
}
