import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  submitted = false;
  formUser = this.fb.group({
    account_number: ['', Validators.required],
    username: ['', Validators.required],
    user_subscription: ['', Validators.required],
    user_access: ['', Validators.required],
    user_prompt_entries: ['', Validators.required],
    contact_vendor: ['', Validators.required],
  });
    constructor(private fb: FormBuilder) { }
  
    ngOnInit(): void {
    }
  
    get f() { return this.formUser.controls; }
  
    onSave(){
      this.submitted = true;
    }
  
  }