import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.css']
})
export class AddContractComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private route: ActivatedRoute,
    private sessionSt: LocalStorageService, private router: Router) { }

  submitted = false;
  company_id: string = "";
  branch_id:string ="";
  userList:[];
  isUserList:boolean;

  formUser = this.fb.group({
    contract_notes: ['', Validators.required],
    contract_doc_path: [''],
    status: [''],
    id: '',
    contract_user_id:''
  });

  ngOnInit(): void {
    this.company_id = this.route.snapshot.params.company_id;
    // Edit Contarct By Id
    this._service.getContractDataById(this.route.snapshot.params.id).subscribe(
      (response: any) => {
        if (response.success) {
          this.contractfileToUpload = response.result.contract_doc_path;
          this.formUser.patchValue({
            contract_notes: response.result.contract_notes,
            contract_doc_path: response.result.contract_doc_path,
            status: response.result.status,
            id: response.result.id,
            contract_user_id: response.result.contract_user_id,
          });
        }
      }
    )

    this._service.getESGMSUserListByType().subscribe(
      (response: any) => {
        if(response.success){
          this.userList = response.result;
          if(this.userList.length > 0)
          {
             this.isUserList = true;
          }
        }
      }
    );
  }

  get f() { return this.formUser.controls; }

  /*
* Get Uploaded Logo
*/
  contarctfileName: string = '';
  contractfileToUpload: any = null;
  getContractUploadedFile(files: any) {
    this.contractfileToUpload = files.item(0);
    this.contarctfileName = this.contractfileToUpload.name;
  }

  onSave() {
    this.submitted = true;
    if (this.formUser.invalid) {
      return;
    }

    let data = this.formUser.getRawValue();
    data.object_id = this.company_id;
    data.contract_doc_path = this.contractfileToUpload;
    let formData = new FormData();
    formData.append("id",data.id);
    formData.append("object_id",this.company_id);
    formData.append("contract_doc_path", data.contract_doc_path);
    formData.append("contract_notes", data.contract_notes);
    formData.append("contract_user_id", data.contract_user_id);
    if(data.status){
      formData.append("status", "1");
    }
    
    this._service.saveContractData(formData)?.subscribe
      (
        (response: any) => {
          if (response.success) {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
            this.router.navigate(['/finacial', this.company_id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        },
        (error: any) => {
          this._snackBar.open('Failed to save data. Please try again later.', '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      );
  }

}
