import { Component, OnInit, AfterContentInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CRDailyData } from './cr-daily-data';
import { DailyData } from './daily-data';
@Component({
  selector: 'app-cr',
  templateUrl: './cr.component.html',
  styleUrls: ['./cr.component.css']
})
export class CrComponent implements OnInit {

  constructor(private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) { }

  formUser: FormGroup;
  submitted = false;
  categories: any = [];
  cr_daily: FormArray;
  dailyDataDetails = new CRDailyData();
  categoryId: string;
  dailyDataList: any = [];

  totalentryCount: Number = 0;
  cfrSavingVal: any = 0;
  cfrcosttotal: any = 0;
  csrCostVal: any = 0;
  cerCostVal: any = 0;

  carbonOffsetVal: Number = 0;
  greenTariffVal: Number = 0;
  ghgCalVal: number = 0;

  todaydate: Date = new Date;

  ngOnInit(): void {

    // GET CATEGORIES
    this._service.getCRCategories(2).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
          this.categories.forEach((res: any, index: any) => {
            if (res.name != null) {
              this.dailyDataDetails.daily_data.push({
                category_name: res.name,
                entry_count: "0",
                cfr_saving: "0",
                cfr_cost: "0",
                csr_cost: "0",
                cer_cost: "0",
                carbon_offset: "0",
                green_tariffs: "0",
                ghg_calculation: "0",
                entry_count_total: "0",
                category_id: res.id,
              });
            }
          });
        }
      });



    // GET CATEGORIES
    this._service.getDailytDataList(new Date().getFullYear()).subscribe(
      (response: any) => {
        if (response.success) {
          this.dailyDataList = response.result;
          if (this.dailyDataList.length > 0) {
            this.dailyDataList.forEach((res: any, index: any) => {
              this.totalentryCount = Number(this.totalentryCount) + Number(res.totalEntryCount);

              this.cfrSavingVal = this.roundTo(this.cfrSavingVal, 2) + this.roundTo(res.cfr_saving, 2);
              this.cfrcosttotal = this.roundTo(this.cfrcosttotal, 2) + this.roundTo(res.cfr_cost, 2);
              this.csrCostVal = this.roundTo(this.csrCostVal, 2) + this.roundTo(res.csr_cost, 2);
              this.cerCostVal = this.roundTo(this.cerCostVal, 2) + this.roundTo(res.cer_cost, 2);

              this.carbonOffsetVal = Number(this.carbonOffsetVal) + Number(res.carbon_offset);
              this.greenTariffVal = Number(this.greenTariffVal) + Number(res.green_tariffs);
              this.ghgCalVal = Number(this.ghgCalVal) + Number(res.ghg);
            });
          }

        }
      })



  }

  AfterContentInit() {
    if (this.dailyDataList.length > 0) {
      this.dailyDataList.forEach((res: any, index: any) => {
        this.totalentryCount = Number(this.totalentryCount) + Number(res.totalEntryCount);

        this.cfrSavingVal = this.roundTo(this.cfrSavingVal, 2) + this.roundTo(res.cfr_saving, 2);
        this.cfrcosttotal = this.roundTo(this.cfrcosttotal, 2) + this.roundTo(res.cfr_cost, 2);
        this.csrCostVal = this.roundTo(this.csrCostVal, 2) + this.roundTo(res.csr_cost, 2);
        this.cerCostVal = this.roundTo(this.cerCostVal, 2) + this.roundTo(res.cer_cost, 2);

        this.carbonOffsetVal = Number(this.carbonOffsetVal) + Number(res.carbon_offset);
        this.greenTariffVal = Number(this.greenTariffVal) + Number(res.green_tariffs);
        this.ghgCalVal = Number(this.ghgCalVal) + Number(res.ghg);
      });

    }
  }

  onSubmit(f: NgForm) {
    console.log(this.dailyDataDetails);
    let fdata: any = this.dailyDataDetails;
    console.log(fdata);
    this._service.saveDailyData(fdata)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }

  onChangeEntryCountValue(event: any) {
    let entryCountVal: Number = 0;
    this.dailyDataDetails.daily_data.forEach((res: any, index: any) => {
      entryCountVal = Number(entryCountVal) + Number(res.entry_count);
    })
    this.dailyDataDetails.entry_count_total = Number(entryCountVal);
  }

  onChangeCFRSaving(event: any) {
    let cfrSavingVal: any = 0;
    let cfrcosttotal: any = 0;
    let csrCostVal: any = 0;
    let cerCostVal: any = 0;

    let carbonOffsetVal: Number = 0;
    let greenTariffVal: Number = 0;
    let ghgCalVal: number = 0;

    this.dailyDataDetails.daily_data.forEach((res: any, index: any) => {
      cfrSavingVal = this.roundTo(cfrSavingVal, 2) + this.roundTo(res.cfr_saving, 2);
      cfrcosttotal = this.roundTo(cfrcosttotal, 2) + this.roundTo(res.cfr_cost, 2);
      csrCostVal = this.roundTo(csrCostVal, 2) + this.roundTo(res.csr_cost, 2);
      cerCostVal = this.roundTo(cerCostVal, 2) + this.roundTo(res.cer_cost, 2);

      carbonOffsetVal = Number(carbonOffsetVal) + Number(res.carbon_offset);
      greenTariffVal = Number(greenTariffVal) + Number(res.green_tariffs);
      ghgCalVal = Number(ghgCalVal) + Number(res.ghg_calculation);
    })
    this.dailyDataDetails.cfr_saving_total = this.roundTo(cfrSavingVal, 2);
    this.dailyDataDetails.cfr_cost_total = this.roundTo(cfrcosttotal, 2);
    this.dailyDataDetails.csr_cost_total = this.roundTo(csrCostVal, 2);
    this.dailyDataDetails.cer_cost_total = this.roundTo(cerCostVal, 2);

    this.dailyDataDetails.carbon_offset_total = Number(carbonOffsetVal);
    this.dailyDataDetails.green_tarrif_total = Number(greenTariffVal);
    this.dailyDataDetails.ghg_calculation_total = Number(ghgCalVal);

  }
  roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  onClear() {
    this._service.clearDailyData('')?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });

          this._service.getDailytDataList(new Date().getFullYear()).subscribe(
            (response: any) => {
              if (response.success) {
                this.dailyDataList = response.result;
                this.totalentryCount = 0;

                this.cfrSavingVal = 0;
                this.cfrcosttotal = 0;
                this.csrCostVal = 0;
                this.cerCostVal = 0;

                this.carbonOffsetVal = 0;
                this.greenTariffVal = 0;
                this.ghgCalVal = 0;

              }
            })
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }
      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );
  }
  year: any;
  yearChange(event: any) {
    this.totalentryCount = 0;
    this.cfrSavingVal = 0;
    this.cfrcosttotal = 0;
    this.csrCostVal = 0;
    this.cerCostVal = 0;
    this.carbonOffsetVal = 0;
    this.greenTariffVal = 0;
    this.ghgCalVal = 0;

    if (event != null && event != "") {
      this.year = event;
    }
    else {
      this.year = new Date().getFullYear()
    }

    // GET CATEGORIES
    this._service.getDailytDataList(this.year).subscribe(
      (response: any) => {
        if (response.success) {
          this.dailyDataList = response.result;
          if (this.dailyDataList.length > 0) {
            this.dailyDataList.forEach((res: any, index: any) => {
              this.totalentryCount = Number(this.totalentryCount) + Number(res.totalEntryCount);

              this.cfrSavingVal = this.roundTo(this.cfrSavingVal, 2) + this.roundTo(res.cfr_saving, 2);
              this.cfrcosttotal = this.roundTo(this.cfrcosttotal, 2) + this.roundTo(res.cfr_cost, 2);
              this.csrCostVal = this.roundTo(this.csrCostVal, 2) + this.roundTo(res.csr_cost, 2);
              this.cerCostVal = this.roundTo(this.cerCostVal, 2) + this.roundTo(res.cer_cost, 2);

              this.carbonOffsetVal = Number(this.carbonOffsetVal) + Number(res.carbon_offset);
              this.greenTariffVal = Number(this.greenTariffVal) + Number(res.green_tariffs);
              this.ghgCalVal = Number(this.ghgCalVal) + Number(res.ghg);
            });
          }

        }
      })
  }
}
