import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";

@Component({
  selector: 'app-plan-csr',
  templateUrl: './plan-csr.component.html',
  styleUrls: ['./plan-csr.component.css']
})
export class PlanCsrComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) { }
    
    ngOnInit(): void {

    }

}
