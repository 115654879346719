// import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { Routes, RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderInterceptorService } from './services/interceptors/loader-interceptor.service';
import { NumbersDirective } from './directives/numbers.directive';
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { ChartsModule } from 'ng2-charts';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { SharedService } from './services/shared.service';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { CustomMaterialModule } from './confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AgentComponent } from './accounts/registration/agent/agent.component';
import { BusinessComponent } from './accounts/registration/business/business.component';
import { ResellerComponent } from './accounts/registration/reseller/reseller.component';
import { RegisterComponent } from './users/register/register.component';
import { AccountComponent } from './users/account/account.component';
import { ReportComponent } from './users/report/report.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import { BusinessListComponent } from './accounts/registration/business/business-list/business-list.component';
import {LoginGuard} from './auth/auth.guard';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AgGridModule } from 'ag-grid-angular';
import { ResellerListComponent } from './accounts/registration/reseller/reseller-list/reseller-list.component';
import { AgentListComponent } from './accounts/registration/agent/agent-list/agent-list.component';
import { UserListComponent } from './users/register/user-list/user-list.component';
import {MatSelectModule} from '@angular/material/select';
import { WithoutloginComponent } from './layouts/withoutlogin/withoutlogin/withoutlogin.component';
import { CarReportComponent } from './car/car-report/car-report.component';
import { LeadUserRegistrationComponent } from './auth/lead-user-registration/lead-user-registration.component';
import { LeadsListComponent } from './accounts/registration/leads/leads-list/leads-list.component';
import { EditLeadComponent } from './accounts/registration/leads/edit-lead/edit-lead.component'; 

import { AddRecordComponent } from './car/add-record/add-record.component';
import { CarAnalysisComponent } from './car/car-analysis/car-analysis.component';
import { CfrAnalysisComponent } from './car/cfr-analysis/cfr-analysis.component';
import { CsrAnalysisComponent } from './car/csr-analysis/csr-analysis.component';
import { CerAnalysisComponent } from './car/cer-analysis/cer-analysis.component';

import { CfrReportComponent } from './car/cfr-report/cfr-report.component';
import { CsrReportComponent } from './car/csr-report/csr-report.component';
import { CerReportComponent } from './car/cer-report/cer-report.component';
import { CrComponent } from './cr/cr/cr.component';
import { CfrComponent } from './cr/cfr/cfr.component';
import { PlanCfrComponent } from './cr/plan-cfr/plan-cfr.component';
import { CsrComponent } from './cr/csr/csr.component';
import { PlanCsrComponent } from './cr/plan-csr/plan-csr.component';
import { CerComponent } from './cr/cer/cer.component';
import { PlanCerComponent } from './cr/plan-cer/plan-cer.component';
import { PlanCarComponent } from './car/plan-car/plan-car.component';
import { BusinessAccountManagementComponent } from './accounts/registration/agent/business-account-management/business-account-management.component';
import { AccountLanguagesComponent } from './accounts/account-languages/account-languages.component';
import { AccountAccessComponent } from './accounts/account-access/account-access.component';
import { AccountReportComponent } from './accounts/account-report/account-report.component';
import { AccountServiceDeskComponent } from './accounts/account-service-desk/account-service-desk.component';
import { AccountSearchComponent } from './accounts/account-search/account-search.component';
import { AccountFinancialComponent } from './accounts/account-financial/account-financial.component';
import { CrInprogressComponent } from './cr/cr-inprogress/cr-inprogress.component';
import { QuarterDataReportComponent } from './cr/quarter-data-report/quarter-data-report.component';
import { CorporatePlanComponent } from './cr/corporate-plan/corporate-plan.component';
import { CorporateDailyDataComponent } from './cr/corporate-daily-data/corporate-daily-data.component';
import { CscppAnalysisComponent } from './car/cscpp-analysis/cscpp-analysis.component';
import { CommuterTravelComponent } from './cr/commuter-travel/commuter-travel.component';
import { InternalComponent } from './cr-tracker/internal/internal/internal.component';
import { ExternalComponent } from './cr-tracker/internal/external/external.component';
import { FinancialComponent } from './financial/financial/financial.component';
import { AddFinancialComponent } from './financial/add_financials/add-financial/add-financial.component';
import { ContractComponent } from './view-contract/contract/contract.component';
import { AddContractComponent } from './view-contract/add-contract/add-contract/add-contract.component';
import { UpdateLeadFormComponent } from './auth/lead-form/update-lead-form/update-lead-form.component';
import { EsgmsuserComponent } from './users/register/esgms-user/esgmsuser/esgmsuser.component';
import { EsgmsuserListComponent } from './users/register/esgms-user/esgmsuser-list/esgmsuser-list.component';

import { AgentLeadListComponent } from './accounts/registration/agent/agent-lead-list/agent-lead-list.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    SidebarComponent,
    BreadcrumbComponent,
    AgentComponent,
    BusinessComponent,
    ResellerComponent,
    RegisterComponent,
    AccountComponent,
    ReportComponent,
    LoginComponent,
    LogoutComponent,
    BusinessListComponent,
    ConfirmDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResellerListComponent,
    AgentListComponent,
    UserListComponent,
    NumbersDirective,
    DecimalNumbersDirective,
    WithoutloginComponent,
    CarReportComponent,
    LeadUserRegistrationComponent,
    LeadsListComponent,
    EditLeadComponent,
    CarAnalysisComponent,
    CfrAnalysisComponent,
    CsrAnalysisComponent,
    CerAnalysisComponent,
    CfrReportComponent,
    CsrReportComponent,
    CerReportComponent,
    CrComponent,
    CfrComponent,
    PlanCfrComponent,
    CsrComponent,
    PlanCsrComponent,
    CerComponent,
    PlanCerComponent,
    PlanCarComponent,
    BusinessAccountManagementComponent,
    AccountLanguagesComponent,
    AccountAccessComponent,
    AccountReportComponent,
    AccountServiceDeskComponent,
    AccountSearchComponent,
    AccountFinancialComponent,
    CrInprogressComponent,
    AddRecordComponent,
    QuarterDataReportComponent,
    CorporatePlanComponent,
    CorporateDailyDataComponent,
    CscppAnalysisComponent,
    CommuterTravelComponent,
    InternalComponent,
    ExternalComponent,
    FinancialComponent,
    AddFinancialComponent,
    ContractComponent,
    AddContractComponent,
    UpdateLeadFormComponent,
    EsgmsuserComponent,
    EsgmsuserListComponent,
    AgentLeadListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    PerfectScrollbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    NgbModule,
    CustomMaterialModule,
    MatSnackBarModule,
    RouterModule.forRoot(Approutes, { useHash: false, relativeLinkResolution: 'legacy' }),
    AgGridModule.withComponents([]),
    MatCheckboxModule,
    MatSelectModule,
    RouterModule.forChild(Approutes), ChartsModule
    
  ],
  providers: [
    SharedService,ConfirmDialogService,LoginGuard,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
