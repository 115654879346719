import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-esgmsuser-list',
  templateUrl: './esgmsuser-list.component.html',
  styleUrls: ['./esgmsuser-list.component.css']
})
export class EsgmsuserListComponent implements OnInit {

  userList:[]
  branch_id:any;
  company_id:any;
  isSuperAdmin:boolean;
  customerId:any;

  constructor(private _service: SharedService, private router: Router,
    private route: ActivatedRoute, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    this.branch_id = "11111111-1111-1111-1111-b5cc47fafb95";
    this._service.getESGMSUserListByType().subscribe(
      (response: any) => {
        if(response.success){
          this.userList = response.result;
        }
      }
    );
  
  }

      //Grid For Branch Data
      gridOptions: GridOptions = {
        onGridReady: () => {
          this.gridOptions.api?.sizeColumnsToFit();
        }
      };
      columnDefs = [
        {
          headerName: 'User Name', width: 107,
          cellRenderer: function (params:any) {
            if (params.data.first_name) {
              return params.data.first_name + ' ' + params.data.last_name;
            } else {
              return null;
            }
          },
          sortable: true, filter: true
        },
        {
          headerName: 'Email', sortable: true,field: 'username', width: 107
        },
        {
          headerName: 'Status', width: 107,
          cellRenderer: function (params:any) {
            if (params.data.status == 1) {
              return "Active";
            } else {
              return "Inactive";
            }
          },
          sortable: true, filter: true
        },
        {
          headerName: 'Action', width: 50,
          cellRenderer: function (params:any) {
            let links = '<a  title="Edit User" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
            return links;
          }
        },
      ];

        /*
  * GRID BUTTONS ACTION
  */
    msg: string = '';
    quote_id: string = '';
    rowIndex: number = 0;

    onGridRowClicked(e: any) {
      if (e) {
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
            case "edit":
            {
              this.router.navigate(['/addesgmsuser',e.data.id]);
              break;
            }
            case "delete":
            {
              // this.msg = 'Are you sure you want to delete this quote?';
              // this.modalService.open(content, { centered: true });
              // break;
            }
        }
      }
    }
}
