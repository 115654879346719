import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cr-inprogress',
  templateUrl: './cr-inprogress.component.html',
  styleUrls: ['./cr-inprogress.component.css']
})
export class CrInprogressComponent implements OnInit {

  carDataList: [];

  constructor(private fb: FormBuilder, private _service: SharedService,
    private router: Router,
    private route: ActivatedRoute, private sessionSt: LocalStorageService,
    private modalService: NgbModal, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllCarData();
    this.getCRPlanData();
    this.getCFRDataInProgress();
    this.getCARDataInProgress();
    this.getCSRDataInProgress();
    this.getCERDataInProgress();
  }

  //Grid For Branch Data
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api?.sizeColumnsToFit();
    }
  };

  gridOptions1: GridOptions = {
    onGridReady: () => {
      this.gridOptions1.api?.sizeColumnsToFit();
    }
  };
  gridOptions2: GridOptions = {
    onGridReady: () => {
      this.gridOptions2.api?.sizeColumnsToFit();
    }
  };
  gridOptions3: GridOptions = {
    onGridReady: () => {
      this.gridOptions3.api?.sizeColumnsToFit();
    }
  };
  gridOptions4: GridOptions = {
    onGridReady: () => {
      this.gridOptions4.api?.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Activities Data Entry Date', sortable: true, field: 'name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.created_at) {

          return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'CFR Performance Data', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.activity_value_cost) {
          return params.data.activity_value_cost;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Owner ESGMS No', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.esgms_number_prefix) {
          return params.data.esgms_number_prefix + "" + params.data.esgms_number;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Update', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];

  columnDefs1 = [
    {
      headerName: 'Activities Data Entry Date', sortable: true, field: 'name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.created_at) {

          return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'CAR Performance Data', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.activity_value_cost) {
          return params.data.carbon_offset_tonnes;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Owner ESGMS No', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.esgms_number_prefix) {
          return params.data.esgms_number_prefix + "" + params.data.esgms_number;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Update', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];
  columnDefs2 = [
    {
      headerName: 'Activities Data Entry Date', sortable: true, field: 'name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.created_at) {

          return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'CSR Performance Data', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.activity_value_cost) {
          return params.data.activity_value_cost;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Owner ESGMS No', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.esgms_number_prefix) {
          return params.data.esgms_number_prefix + "" + params.data.esgms_number;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Update', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];
  columnDefs3 = [
    {
      headerName: 'Activities Data Entry Date', sortable: true, field: 'name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.created_at) {

          return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'CER Performance Data', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.activity_value_cost) {
          return params.data.activity_value_cost;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Owner ESGMS No', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.esgms_number_prefix) {
          return params.data.esgms_number_prefix + "" + params.data.esgms_number;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Update', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];
  columnDefs4 = [
    {
      headerName: 'Activities Data Entry Date', sortable: true, field: 'name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.created_at) {

          return formatDate(params.data.created_at, 'dd-MM-yyyy', 'en-US');
        } else {
          return null;
        }
      }
    },
    {
      headerName: 'CR Plan Data', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.cost_performance_annual_total) {
          return params.data.cost_performance_annual_total;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Owner ESGMS No', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.esgms_number_prefix) {
          return params.data.esgms_number_prefix + "" + params.data.esgms_number;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Update', width: 50,
      cellRenderer: function (params: any) {
        let links = '';
        links += '<a  title="Edit Lead" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        return links;
      }
    },
  ];
  /*
  * GRID BUTTONS ACTION
  */
  msg: string = '';
  company_id: string = '';
  rowIndex: number = 0;

  onGridRowClicked(e: any, content: any) {
    if (e) {
      this.company_id = e.data.id;
      this.rowIndex = e.rowIndex;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            if (e.data.cr_type == 1) {
              this.router.navigate(['/cr_record', e.data.id, e.data.cr_type]);
            }
            else if (e.data.cr_type == 2) {
              this.router.navigate(['/cfr_data', e.data.id, e.data.cr_type]);
            }
            else if (e.data.cr_type == 3) {
              this.router.navigate(['/csr_data', e.data.id, e.data.cr_type]);
            }
            else if (e.data.cr_type == 4) {
              this.router.navigate(['/cer_data', e.data.id, e.data.cr_type]);
            }
            else{
              this.router.navigate(['/plan_cfr_data', e.data.id]);
            }
            break;
          }
      }
    }
  }

  /** Get ALL Leads Detail
    **/
  getAllCarData() {
    this._service.getCARList().subscribe(
      (response: any) => {
        if (response.success) {
          this.carDataList = response.result;
        }
      }
    );
  }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'modal-default', size: 'md', centered: true });
  }

  /** Get ALL Leads Detail
  **/
  allPlanData: any = [];
  getCRPlanData() {
    this._service.getALLCRPlanData(1).subscribe(
      (response: any) => {
        if (response.success) {
          this.allPlanData = response.result;
        }
      }
    );
  }
  /** Get Actual Data
**/
  allActualData: any = [];
  getActualData() {
    this._service.getActualDataList().subscribe(
      (response: any) => {
        if (response.success) {
          this.allActualData = response.result;
        }
      }
    );
  }

  //CFR DATA In Progress
  cfrDataList: [];
  getCFRDataInProgress() {
    this._service.getCFRDataInProgress(2).subscribe(
      (response: any) => {
        if (response.success) {
          this.cfrDataList = response.result;
        }
      }
    );
  }
  //CAR DATA In Progress
  cardataList: [];
  getCARDataInProgress() {
    this._service.getCFRDataInProgress(1).subscribe(
      (response: any) => {
        if (response.success) {
          this.cardataList = response.result;
        }
      }
    );
  }

  //CSR DATA In Progress
  csrdataList: [];
  getCSRDataInProgress() {
    this._service.getCFRDataInProgress(3).subscribe(
      (response: any) => {
        if (response.success) {
          this.csrdataList = response.result;
        }
      }
    );
  }
  //CER DATA In Progress
  cerdataList: [];
  getCERDataInProgress() {
    this._service.getCFRDataInProgress(4).subscribe(
      (response: any) => {
        if (response.success) {
          this.cerdataList = response.result;
        }
      }
    );
  }
}
