import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder,FormGroup,FormArray,FormControl, Validators } from '@angular/forms';
import { Business } from '../../model/business/business.model';
import { Router,ActivatedRoute } from "@angular/router";
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { LeadUserRegistrationService } from './lead-user-registration.service';

@Component({
  selector: 'app-lead-user-registration',
  templateUrl: './lead-user-registration.component.html',
  styleUrls: ['./lead-user-registration.component.css','../../layouts/full/full.component.scss']
})
export class LeadUserRegistrationComponent implements OnInit {

  submitted = false;
  company=new Business();
  formUser: FormGroup;
  businessList: any[] = [];
  checkedProduct: any[] = [];
  productList: any[] = [];
  selectProductList: any[] = [];
  isChecBox:boolean
  isEdit:boolean=false;
  roles: any[] = [];
  imagePath: string = environment.imageURL;
  logoPath:any;
  countriesList: any[] = [];
  continentsList: any[] = [];
  defaultCountry: string = 'GB';
  defaultContinent:string= 'EU';
  businessAccount: any[] = [];
  branchNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  agentId:any;

  constructor(private fb: FormBuilder,private router: Router,
    private confirmDialogService: ConfirmDialogService, private route: ActivatedRoute,
     private _snackBar: MatSnackBar,private sessionSt: LocalStorageService,private leadUserRegistrationService:LeadUserRegistrationService) {}

    ngOnInit(): void {
      // this.sessionSt.clear();
      // this.router.navigate(['/auth/leads_reg'])
     //Get All Roles
     this.leadUserRegistrationService.getRoles().subscribe(
       (response: any)=> {
         if(response.success){
          this.roles = response.result.filter((x: any) => x.id != '6b4e4105-463e-456e-8e5e-97ac397168e5');
         }
     })

      //Get All Product List
      this.leadUserRegistrationService.getProductList().subscribe(
        (response: any) => {
          if(response.success){
            this.productList = response.result;
          }
        });

      //Get All Countries List
      this.leadUserRegistrationService.getCountriesList().subscribe(
        (response: any) => {
          if(response.success){
            this.countriesList = response.result;
          }
        });

      //Get All Continent List
      this.leadUserRegistrationService.getContinentList().subscribe(
        (response: any) => {
          if(response.success){
            this.continentsList = response.result;
          }
        });

            //Get All Roles
    this.leadUserRegistrationService.getBusinessAccountType().subscribe(
      (response: any) => {
        if (response.success) {
          this.businessAccount = response.result;
        }
      });

      this.formUser = this.fb.group({
   
        //role_id:'2b4e4105-463e-456e-8e5e-97ac397168e5',
        status:0,
        main_contact_first_name:['', Validators.required],
        main_contact_last_name:['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        website: [''],
        name:['',Validators.required],
        address_1: [''],
        address_2: [''],
        address_3: [''],
        county: ['', Validators.required],
        postcode: [''],
        country: ['', Validators.required],
        continent: ['', Validators.required],
        phone: ['', Validators.required],
        role_id: ['',Validators.required],
        created_at: [''],
        id:'',
        address_id:'',
        products:this.fb.array([]),
        logo_path:[''],
        branch_number: ['', Validators.required],
      });

      this.formUser.controls['country'].setValue(this.defaultCountry, {onlySelf: true});
      this.formUser.controls['continent'].setValue(this.defaultContinent, {onlySelf: true});
      this.agentId = this.route.snapshot.params.id;
      if(this.agentId !=null){
        this.formUser.controls['role_id'].setValue("2b4e4105-463e-456e-8e5e-97ac397168e5", { onlySelf: true });

      }
    }
  

    
    get f() { return this.formUser.controls; }
  
  /*
  * Get Uploaded Logo
  */
    fileName: string = '';
    fileToUpload: any = null;
    getUploadedFile(files:any) {
      this.fileToUpload = files.item(0);
      this.fileName = this.fileToUpload.name;
    }

    //Save Data
    onSave(){
      this.submitted = true;
      if (this.formUser.invalid) {
        this.scrollToError();
        return;
      }

      let data = this.formUser.getRawValue();
      data.product_ids = data.products.toString();
      data.logo_path = this.fileToUpload;

      let formData = new FormData();

     
      formData.append("logo_path",data.logo_path);
      formData.append("main_contact_first_name",data.main_contact_first_name);
      formData.append("main_contact_last_name",data.main_contact_last_name);
      formData.append("email",data.email);
      formData.append("website",data.website);
      formData.append("name",data.name);
      formData.append("address_1",data.address_1);
      formData.append("address_2",data.address_2 ? data.address_2:'');
      formData.append("address_3",data.address_3 ? data.address_3:'');
      formData.append("county",data.county);
      formData.append("postcode",data.postcode);
      formData.append("country",data.country);
      formData.append("continent",data.continent);
      formData.append("phone",data.phone);

      formData.append("role_id",data.role_id);
      formData.append("product_ids", data.products.toString());
      formData.append("process_status_id", '9ad8c0bb-b933-4f28-bc70-b5cc47fafb95');
      formData.append("branch_number", data.branch_number);

      this.leadUserRegistrationService.saveLeadUserData(formData)?.subscribe(
          (response: any) => {
            if (response.success) {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-success'
              });
              //this.router.navigate(['/business_list'])
              window.location.reload();
              this.formUser.reset();
            } else {
              this._snackBar.open(response.message, '', {
                duration: 5000,
                panelClass: 'text-danger'
              });
            }
  
          },
          (error:any) => {
            this._snackBar.open('Failed to save data. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });     
          }
        );

    }
  
    // Get Product Checked
    updateChkbxArray(id:any, isChecked:any) {
      const products: FormArray = this.formUser.get('products') as FormArray;
      if (isChecked) {
        products.push(new FormControl(id));
      } else {
        let i: number = 0;
        products.controls.forEach((item: any) => {
          if (item.value == id) {
            products.removeAt(i);
            return;
          }
          i++;
        });
      }
    }

    scrollTo(el:any): void {
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    scrollToError(): void {
     const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
      this.scrollTo(firstElementWithError);
    }

      /***
  * Get Countries List on the base of the continet
  */
  onChangeContinent(event: any) {
    this.continentsList.forEach((res: any) => {
      if (res.code == event) {
        this.countriesList = res.countries;
      }
    });

  }

}
