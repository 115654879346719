import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Observer } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';



@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  logo_path: string = "";
  imagePath: string = environment.imageURL;
  isLogo: boolean;
  public isLoggedIn: boolean = false;
  userEmail: string = "";
  userName: string = "";
  isCompanyUser: boolean = false;
  isBranchUser: boolean = false;
  isBranchStaff:boolean=false;
  isSuperAdmin: boolean = false;
  userType: string = "";
  companyName: string = "";
  countryName: string = "";
  branchName:string=";"
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private sessionSt: LocalStorageService,
    private _service: SharedService,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe((event: Data) => {

        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });
  }
  ngOnInit() {
    let userinfo = this.sessionSt.retrieve('userInfo');
    if (userinfo.company.logo_path != null) {
      this.isLogo = true;
      this.logo_path = userinfo.company.logo_path;

    }
    else {
      this.isLogo = false;
    }

    if (userinfo.company.name != null) {
      this.companyName = userinfo.company.name;
    }

    if (userinfo.address.country != null) {

      if (userinfo.address.country == "GB") {
        this.countryName = "United Kingdom";
      }
      else if (userinfo.address.country == "US") {
        this.countryName = "United States of America";
      }
    }

    if (userinfo && userinfo.id) {
      this.userEmail = userinfo.username;
      if (userinfo.userinfo != null && userinfo.userinfo.first_name != null) {
        this.userName = userinfo.userinfo.first_name + " " + userinfo.userinfo.last_name;
      }
      else {
        this.userName = "Default";
      }
      // if (userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5"
      //   || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
      //   || userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") {
      //   if (userinfo.company.id != "" && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
      //     this.isCompanyUser = true;
      //   }
      //   this.isCompanyUser = false;
      // }
      if (userinfo.company.roles.id == "1b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.isSuperAdmin = true;
      }
      else if ((userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5" || userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5") && (userinfo.company.id != "" && userinfo.company.parent_id == null && userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95')) {
        this.isCompanyUser = true;
      }
      else if ((userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") && (userinfo.company.id != "" && userinfo.company.parent_id != null && (userinfo.group_user_linking.group_id == '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95'))) {
        this.isBranchUser = true;
      }
      else if((userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") && (userinfo.company.id != "" && userinfo.company.parent_id != null && (userinfo.group_user_linking.group_id == '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95'))){
        //this.isBranchUser = true;
        this.isBranchStaff = true;

      }
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    if (userinfo != null) {

      if (userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.userType = "Corporate User";
      }
      else if (userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.userType = "Branch User";
      }
      else if (userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.userType = "Reseller User";
      }
      else if (userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5") {
        this.userType = "Agent User";
      }
      else {
        this.userType = "Super Admin";
      }
    }

    if (userinfo.company.parent_id != null) {
        this.getParentCompanyById(userinfo.company.parent_id);
        this.branchName = userinfo.company.name;
    }

  }

    // Edit Reseller Data
    getParentCompanyById(company_id:any){
      this._service.getComapnyById(company_id).subscribe(
        (response: any) => {
          if (response.success) {
            this.logo_path = response.result.logo_path;
            if(this.logo_path !=null){
              this.isLogo = true;
            }
            else{
              this.companyName = response.result.name;
              this.isLogo = false;
            }
          }
        }
      )
    }
}
