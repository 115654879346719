import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  headers: any;
  UserType = 0;

  constructor(private httpClient: HttpClient, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {

  }

  getUserType() {
    if (this.sessionSt.retrieve('user_group') == 'Staff') {
      this.UserType = 1;
    }
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.UserType = 2;
    }
    if (this.sessionSt.retrieve('company_role') == 'Broker' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.UserType = 3;
    }

    return this.UserType;
  }

  apiURL() {
    return environment.apiURL;
  }
  apiHeaders() {
    let login_token = '';
    let BrokerId = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    if (this.sessionSt.retrieve('al_broker_company_id') != null) {
      BrokerId = this.sessionSt.retrieve('al_broker_company_id');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',
      'Authorization': login_token,
      'Retry-After': '3600',
      'BrokerId': BrokerId
    }
    );
    return headers;
  }
  // GET AJAX CALL
  getAPI(url: any): Observable<string> {
    const headers = this.apiHeaders();
    url = environment.apiURL + url;
    return this.httpClient.get<string>(url, { headers: headers }).pipe(
      retry(3),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      }), shareReplay()
    );
  }

  // GET AJAX CALL
  getOtherAPI(url: any) {
    return this.httpClient.get(url);
  }

  // POST AJAX CALL
  postAPI(url: any, data: any) {
    try {
      const headers = this.apiHeaders();
      url = environment.apiURL + url;
      return this.httpClient.post(url, JSON.stringify(data), { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  postPaymentAPI(url: any, data: any) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json;',
        'Authorization': "T_S_32a8a3a2-a7a5-46bc-a525-924841483aa4",
      });
      return this.httpClient.post(url, JSON.stringify(data), { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  // POST FORM DATA AJAX CALL
  postFormData(url: any, formData: any) {
    try {
      const headers = new HttpHeaders({
        'Authorization': this.sessionSt.retrieve('login_token')
      });
      url = environment.apiURL + url;
      return this.httpClient.post(url, formData, { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  // POST FORM DATA AJAX CALL WITHOUT HEADERS
  postFormDataWithoutLogin(url: any, formData: any) {
    try {
      const headers = new HttpHeaders({
        'Authorization': this.sessionSt.retrieve('login_token')
      });
      url = environment.apiURL + url;
      return this.httpClient.post(url, formData);
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }


  download(url: string) {
    url = environment.apiURL + url;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': this.sessionSt.retrieve('login_token'),
      })
    };
    return this.httpClient.get(url, httpOptions).pipe(
      retry(3),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      }), shareReplay()
    );
  }


  postDownload(url: any, data: any) {
    let login_token = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',

      'Authorization': login_token
    });
    return this.httpClient.post(
      environment.apiURL + url,
      data,
      { headers: headers, responseType: 'blob' });
  }
  downloadDocs(fileDetails: any) {
    return this.download('download/' + fileDetails.id)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        let fileExt = fileDetails.file_name.substr(fileDetails.file_name.lastIndexOf('.') + 1);
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', fileDetails.title + "." + fileExt);
        document.body.appendChild(downloadLink);
        downloadLink.click()
      },
        error => console.log('oops', error)
      );
  }
  contarctdownloadDocs(fileDetails: any) {
    return this.download('contract_download/' + fileDetails.id)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        let fileExt = fileDetails.file_name.substr(fileDetails.file_name.lastIndexOf('.') + 1);
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', fileDetails.title + "." + fileExt);
        document.body.appendChild(downloadLink);
        downloadLink.click()
      },
        error => console.log('oops', error)
      );
  }
  /*
  * Sort Date Column Of AG-GRID
  */
  agGridDteSortingComparator(date1: any, date2: any) {
    return new Date(date1) < new Date(date2) ? -1 : 1;
  }

  // Save Business Quote
  saveData(data: any) {
    return this.postFormData('company', data);
  }
  // Get Company All Data
  getCompanyDetails(company_id: any) {
    return this.getAPI('companies/' + company_id);
  }
  // Get Company By Id
  getComapnyById(company_id: any) {
    return this.getAPI('company/' + company_id);
  }
  //Get Products List
  getProductList() {
    return this.getAPI('product');
  }
  //Get Branch List By Company Id
  getBranchListByCompanyId(company_id: any) {
    return this.getAPI('companyallbranches/' + company_id);
  }
  //Get Roles
  getRoles() {
    return this.getAPI('roles');
  }

  //Save User Data
  saveUser(data: any) {
    return this.postAPI('user', data);
  }
  // Get User By Id
  getUserById(user_id: any) {
    return this.getAPI('user/' + user_id);
  }
  //GetUser List By Company Id
  getUserListByCompanyId(company_id: any) {
    return this.getAPI('users/' + company_id);
  }
  // Delete Company
  deleteCompany(data: any) {
    return this.postAPI('company/delete', data);
  }
  //Get Groups
  getUserGroups() {
    return this.getAPI('groups');
  }

  // GET CAR GOALS
  getCRGoals(type: any) {
    return this.getAPI('goal/' + type);
  }
  // GET CAR CATEGORIES
  getCRCategories(type: any) {
    return this.getAPI('category/' + type);
  }
  // GET CAR VALUE CHAIN
  getCRValueChain() {
    return this.getAPI('valuechain');
  }
  // GET CURRENCY
  getCurrency() {
    return this.getAPI('currency');
  }
  // GET REPORTING YEARS
  getReportingYearValues() {
    return this.getAPI('reportingyearvalue');
  }
  // SAVE CAR DATA
  saveCarData(data: any) {
    return this.postFormData('car', data);
  }
  // GET ALL LEADS LIST DATA
  getLeadsDetails() {
    return this.getAPI('leads');
  }
  // UPDATE LEAD COMPANY STATUS
  UpdateLeadStatus(data: any) {
    return this.postAPI('company/status', data);
  }
  // GET ACTIVE PROCESS STATUS LIST
  getProcessStatusList() {
    return this.getAPI('processstatus/' + 1);
  }
  // GET COUNTRIES LIST
  getCountriesList() {
    return this.getAPI('countries');
  }
  // GET CONTINENT LIST
  getContinentList() {
    return this.getAPI('continents');
  }
  // GET ALL CAR DATA LIST
  getCARList() {
    return this.getAPI('car');
  }
  // Get Company By Id
  getCarById(car_id: any, cr_type: any) {
    return this.getAPI('car/' + car_id + '/' + cr_type);
  }
  // GET CSR TARGET ACTIVITY
  getCSRTargetActivity(type: any) {
    return this.getAPI('activity-target/' + type);
  }
  // GET CSR ACTIVITY MARK
  getCSRActivityMark(type: any) {
    return this.getAPI('activity-mark/' + type);
  }
  // GET PLAN TYPE
  getPlanData() {
    return this.getAPI('plan');
  }
  // SAVE PLAN DATA
  savePlanData(data: any) {
    return this.postAPI('plan', data);
  }
  // GET PLAN LIST DATA
  getPlanListData() {
    return this.getAPI('planList');
  }
  // SAVE DAILY DATA
  saveDailyData(data: any) {
    return this.postAPI('dailyData', data);
  }
  // GET PLAN LIST DATA
  getDailytDataList(year:any) {
    return this.getAPI('dailyDatalist/' +year);
  }
  // GET QUARTER LIST DATA
  getQuarterDataList() {
    return this.getAPI('quarterDataList');
  }
  //Clear Data
  clearDailyData(data: any) {
    return this.postAPI('deleteCarData', data);
  }
  // GET QUARTER LIST DATA
  getPlanDataById(plan_id: any,date:any) {
    return this.getAPI('getPlanData/' + plan_id +"/" +date);
  }
  // GET MONITOR DATA
  getDashboardMonitorData(year:any) {
    return this.getAPI('monitorData/' + year);
  }
  // GET MONITOR DATA
  getDashboardCorporateMonitorData(year:any) {
    return this.getAPI('corporatemonitorData/' + year);
  }
  // GET ALL CAR DATA LIST
  getALLCRPlanData(is_submitted: any) {
    return this.getAPI('crplandata/' + is_submitted);
  }
  // GET ALL CAR DATA LIST
  getActualDataList() {
    return this.getAPI('actualdata');
  }
  // GET ALL CAR DATA LIST
  getCorporatePlanDataByPLanId(plan_id: any,date:any) {
    return this.getAPI('corporatePalnData/' + plan_id +"/" +date);
  }
  // GET COPORATE DAILY LIST DATA
  getCRDailytDataList() {
    return this.getAPI('corporatedailyDatalist');
  }
  // GET ALL CAR DATA LIST
  getCARListById(category_id: any) {
    return this.getAPI('getdatabyid/' + category_id);
  }
  //GET CFR DATA IN PROGRESS
  getCFRDataInProgress(cr_type: any) {
    return this.getAPI('cfrDatainprogress/' + cr_type);
  }
  //GET DATA BY PLAN ID
  getPlanDataByRefId(plan_id: any) {
    return this.getAPI('palndatabyid/' + plan_id);
  }
  //GET CSR DATA
  getCsrReportData(data: any) {
    return this.postDownload('csrdata', data);
  }
  //GET CER REPORT DATA
  getCERReportData(data: any) {
    return this.postDownload('cerreportdata', data);
  }
  //GET CFR REPORT DATA
  getCFRReportData(data: any) {
    return this.postDownload('cfrreportdata', data);
  }
  //GET CFR REPORT DATA
  getCARReportData(data: any) {
    return this.postDownload('carreportdata', data);
  }
  //GET Year List
  getYearList() {
    return this.getAPI('getyearlist');
  }
  // Get Company Branches By Id
  getComapnyBranchesById(company_id: any) {
    return this.getAPI('companybranches/' + company_id);
  }
  //GET CFR REPORT DATA
  getCSCPPReportData(data: any) {
    return this.postDownload('cscppdatalist', data);
  }
  //GET CSR DATA
  getCsrAnalysisData(data: any) {
    return this.postDownload('csranalysis', data);
  }
  //GET CER Analysis DATA
  getCerAnalysisData(data: any) {
    return this.postDownload('ceranalysis', data);
  }
  //GET CER Analysis DATA
  getCfrAnalysisData(data: any) {
    return this.postDownload('cfranalysis', data);
  }
  //GET CER Analysis DATA
  getCARAnalysisData(data: any) {
    return this.postDownload('caranalysis', data);
  }
  //GET CR TRACKER DATA Internal
  getCRTrackerInternalData(data: any) {
    return this.postAPI('crtrackersearch', data);
  }

  getCRTrackerInternalCorporateData(data: any) {
    return this.postAPI('crtrackercorporatesearch', data);
  }
  //GET CR TRACKER DATA Internal
  getCRTrackerExternalData(data: any) {
    return this.postAPI('crtrackerexternalbranchsearch', data);
  }

  getCRTrackerExternalCorporateData(data: any) {
    return this.postAPI('crtrackerexternalcorporatesearch', data);
  }
  //GET BRANCHES BY COUNTRY ID
  getBranchListByCountryId(country_id: any) {
    return this.getAPI('getBranchesByCountryId/' + country_id);
  }
  //GET BUSINESS ACCOUNT TYPE
  getBusinessAccountType() {
    return this.getAPI('getBusinessAccountType');
  }
  //GET BRANCHES BY COUNTRY ID
  getPriceListByCountryId(country_id: any) {
    return this.getAPI('getPriceListByCountryId/' + country_id);
  }
  // Get Company All Data
  getBusinessDetails(company_id: any) {
    return this.getAPI('business/' + company_id);
  }
  //Industries
  getIndustries() {
    return this.getAPI('industries');
  }
  //Get Branch List By Company Id
  getFinancialListByCompanyId(company_id: any) {
    return this.getAPI('getallfinacials/' + company_id);
  }
  // Save Business Quote
  saveFinancialData(data: any) {
    return this.postFormData('financialsave', data);
  }

  // GET QUARTER LIST DATA
  getFiancialDataById(id: any) {
    return this.getAPI('getfinacialbyid/' + id);
  }

  // Contract 

  //Get Contract List By Company Id
  getContractListByCompanyId(company_id: any) {
    return this.getAPI('getallcontracts/' + company_id);
  }

  // Save Contract Data
  saveContractData(data: any) {
    return this.postFormData('contractsave', data);
  }

  // GET Contract BY ID
  getContractDataById(id: any) {
    return this.getAPI('getcontractbyid/' + id);
  }
  //SEND EMAIL TO USER
  SendEmmailToLead(data: any) {
    return this.postAPI('company/resendemail', data);
  }
  // Get Company All Data
  getBusinessList(company_id: any) {
    return this.getAPI('businessList/' + company_id);
  }

  // GET QUARTER LIST DATA
  getFiancialDataByCompanyId(id: any) {
    return this.getAPI('getfinancialbycompanyid/' + id);
  }

  // SEND EMAIL TO BRANCH USER
  sendEmailToBranch(data: any) {
    return this.postAPI('company/sendEmailToBranch', data);
  }
  // SEND EMAIL TO ACTIVE CONTARCT
  sendEmailToActiveContract(data: any) {
    return this.postAPI('company/sendwelcomemail', data);
  }

  //ESGMS USER
  saveESGMSUser(data: any) {
    return this.postAPI('save/esgmsuser', data);
  }
  //GetUser List By Company Id
  getESGMSUserListByCompanyId(company_id: any) {
    return this.getAPI('getesgmsusers/' + company_id);
  }
  getESGMSUserListByType() {
    return this.getAPI('easgmsUserListByType');
  }
  // Get Company Branches By Id
  getAgentLeadsByCompanyId(company_id: any) {
    return this.getAPI('agentLeads/' + company_id);
  }
  //GET CURRENCY NAME BY CURRENCY BY ID
  getCurrencyNameById(id:any){
    return this.getAPI('currencyName/' + id);
  }
}
