import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-commuter-travel',
  templateUrl: './commuter-travel.component.html',
  styleUrls: ['./commuter-travel.component.css']
})
export class CommuterTravelComponent implements OnInit {

  constructor(private fb: FormBuilder, private _service: SharedService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private modalService: NgbModal,
    private sessionSt: LocalStorageService) { }

  categories: any = [];
  categoryValues: any = [];
  company_id: any = "";
  branch_id: any = "";

  formCommuterTravel: FormGroup;
  commuter_travels_array: FormArray;

  ngOnInit(): void {
    // GET CATEGORIES
    this._service.getCRCategories(1).subscribe(
      (response: any) => {
        if (response.success) {
          this.categories = response.result;
          let selectedCat = response.result.filter((x: any) => x.id == '33333333-3333-3333-3333-333333333339');
          if (selectedCat.length > 0) {
            this.categoryValues = selectedCat[0].category_values;
          }
        }
      });

    this.formCommuterTravel = this.fb.group({
      id: [''],
      activity_id: ['', Validators.required],
      commuter_travels: this.fb.array([this.bindCTDetails()]),
      number_of_days_per_week: ['', Validators.required],
      distance_traveled: ['', Validators.required],
      user_company_id: this.company_id,
      user_branch_id: this.branch_id,
    });
    
    for(let i = 1; i <= this.categoryValues.length; i++) {
      this.ctArr.push(this.bindCTDetails());
    }
  }
  get f() { return this.formCommuterTravel.controls; }
  get ctArr() { return this.f.commuter_travels_array as FormArray; }

  bindCTDetails(): FormGroup {
    return this.fb.group({
      id: [],
      activity_id: [],
      number_of_days_per_week: [],
      distance_traveled: ["", [Validators.required]],
    });
  }
}
