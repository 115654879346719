import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AgentComponent } from './accounts/registration/agent/agent.component';
import { BusinessComponent } from './accounts/registration/business/business.component';
import { ResellerComponent } from './accounts/registration/reseller/reseller.component';
import { RegisterComponent } from './users/register/register.component';
import { AccountComponent } from './users/account/account.component';
import { ReportComponent } from './users/report/report.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { BusinessListComponent } from './accounts/registration/business/business-list/business-list.component';
import { LoginGuard } from './auth/auth.guard';

import { BranchesComponent } from './branches/branches.component';
import { CerRecordsComponent } from './car/cer-records/cer-records.component';
import { CsrRecordsComponent } from './pages//csr-records/csr-records.component';
import { AddRecordComponent } from './car/add-record/add-record.component';

import { ResellerListComponent } from './accounts/registration/reseller/reseller-list/reseller-list.component';
import { AgentListComponent } from './accounts/registration/agent/agent-list/agent-list.component';
import { UserListComponent } from './users/register/user-list/user-list.component';
import { WithoutloginComponent } from './layouts/withoutlogin/withoutlogin/withoutlogin.component';
import { CarReportComponent } from './car/car-report/car-report.component'; 
import { LeadUserRegistrationComponent } from './auth/lead-user-registration/lead-user-registration.component';
import { LeadsListComponent } from './accounts/registration/leads/leads-list/leads-list.component';
import { EditLeadComponent } from './accounts/registration/leads/edit-lead/edit-lead.component';

import { CarAnalysisComponent } from './car/car-analysis/car-analysis.component';
import { CfrAnalysisComponent } from './car/cfr-analysis/cfr-analysis.component';
import { CsrAnalysisComponent } from './car/csr-analysis/csr-analysis.component';
import { CerAnalysisComponent } from './car/cer-analysis/cer-analysis.component';

import { CfrReportComponent } from './car/cfr-report/cfr-report.component';
import { CsrReportComponent } from './car/csr-report/csr-report.component';
import { CerReportComponent } from './car/cer-report/cer-report.component';

import { CrComponent } from './cr/cr/cr.component';
import { CfrComponent } from './cr/cfr/cfr.component';
import { PlanCfrComponent } from './cr/plan-cfr/plan-cfr.component';
import { CsrComponent } from './cr/csr/csr.component';
import { PlanCsrComponent } from './cr/plan-csr/plan-csr.component';
import { CerComponent } from './cr/cer/cer.component';
import { PlanCerComponent } from './cr/plan-cer/plan-cer.component';
import { PlanCarComponent } from './car/plan-car/plan-car.component';

import { BusinessAccountManagementComponent } from './accounts/registration/agent/business-account-management/business-account-management.component';
import { AccountLanguagesComponent } from './accounts/account-languages/account-languages.component';
import { AccountAccessComponent } from './accounts/account-access/account-access.component';
import { AccountReportComponent } from './accounts/account-report/account-report.component';
import { AccountServiceDeskComponent } from './accounts/account-service-desk/account-service-desk.component';
import { AccountSearchComponent } from './accounts/account-search/account-search.component';
import { AccountFinancialComponent } from './accounts/account-financial/account-financial.component';

import { CrInprogressComponent } from './cr/cr-inprogress/cr-inprogress.component';
import { QuarterDataReportComponent } from './cr/quarter-data-report/quarter-data-report.component';
import { CorporatePlanComponent } from './cr/corporate-plan/corporate-plan.component';

import { CorporateDailyDataComponent } from './cr/corporate-daily-data/corporate-daily-data.component';
import { CscppAnalysisComponent } from './car/cscpp-analysis/cscpp-analysis.component';

import { CommuterTravelComponent } from './cr/commuter-travel/commuter-travel.component';

import { InternalComponent } from './cr-tracker/internal/internal/internal.component';
import { ExternalComponent } from './cr-tracker/internal/external/external.component';
import { FinancialComponent } from './financial/financial/financial.component';
import { AddFinancialComponent } from './financial/add_financials/add-financial/add-financial.component';
import { ContractComponent } from './view-contract/contract/contract.component';
import { AddContractComponent } from './view-contract/add-contract/add-contract/add-contract.component';
import { UpdateLeadFormComponent } from './auth/lead-form/update-lead-form/update-lead-form.component';

import { EsgmsuserComponent } from './users/register/esgms-user/esgmsuser/esgmsuser.component';
import { EsgmsuserListComponent } from './users/register/esgms-user/esgmsuser-list/esgmsuser-list.component';
import { AgentLeadListComponent } from './accounts/registration/agent/agent-lead-list/agent-lead-list.component';

export const Approutes: Routes = [
	{
		path: '',
		component: WithoutloginComponent,
		children: [
			{ path: '', component: LoginComponent },
			{ path: 'auth/forgot-password', component: ForgotPasswordComponent },
			{ path: 'auth/reset-password', component: ResetPasswordComponent },
			{ path: 'register', component: LeadUserRegistrationComponent},
			{ path: 'agentregister/:id', component: LeadUserRegistrationComponent},
			{ path: 'registerleaduser', component: UpdateLeadFormComponent},
		]
	},
	{
		path: '',
		component: FullComponent,
		children: [
			{
				path: 'dashboard', canActivate: [LoginGuard],
				loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'component', canActivate: [LoginGuard],
				loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
			},
			{
				path: 'pages', canActivate: [LoginGuard],
				loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
			},
			{ path: 'agent', component: AgentComponent, canActivate: [LoginGuard] },
			{ path: 'agent/:id', component: AgentComponent, canActivate: [LoginGuard] },
			{ path: 'business', component: BusinessComponent, canActivate: [LoginGuard] },
			{ path: 'business/:id', component: BusinessComponent, canActivate: [LoginGuard] },
			{ path: 'reseller', component: ResellerComponent, canActivate: [LoginGuard] },
			{ path: 'reseller/:id', component: ResellerComponent, canActivate: [LoginGuard] },
			{ path: 'user/:branch_id', component: RegisterComponent, canActivate: [LoginGuard] },
			{ path: 'user/:branch_id/:id', component: RegisterComponent, canActivate: [LoginGuard] },
			//   { path: 'user_account', component: AccountComponent , canActivate: [LoginGuard]},
			//   { path: 'user_account_lsit/:id', component: AccountComponent, canActivate: [LoginGuard]},
			{ path: 'branch/:company_id', component: AccountComponent, canActivate: [LoginGuard] },
			{ path: 'branch/:company_id/:id', component: AccountComponent, canActivate: [LoginGuard] },
			{ path: 'user_report', component: ReportComponent, canActivate: [LoginGuard] },
			{ path: 'business_list', component: BusinessListComponent, canActivate: [LoginGuard] },
			{ path: 'reseller_list', component: ResellerListComponent, canActivate: [LoginGuard] },
			{ path: 'agent_list', component: AgentListComponent, canActivate: [LoginGuard] },
			{ path: 'user_list/:id', component: UserListComponent, canActivate: [LoginGuard] },
			{ path: 'leads', component: LeadsListComponent, canActivate: [LoginGuard] },
			{ path: 'add_lead', component: EditLeadComponent, canActivate: [LoginGuard] },
			{ path: 'edit_lead/:id', component: EditLeadComponent, canActivate: [LoginGuard] },
			{ path: 'cr_record/:id/:cr_type', component: AddRecordComponent, canActivate: [LoginGuard] },
            //Agent Business Account
			{ path: 'business_account', component: BusinessAccountManagementComponent, canActivate: [LoginGuard] },
			{ path: 'account_languages', component: AccountLanguagesComponent, canActivate: [LoginGuard] },
			{ path: 'account_access', component: AccountAccessComponent, canActivate: [LoginGuard] },
			{ path: 'account_report', component: AccountReportComponent, canActivate: [LoginGuard] },
            { path: 'account_service_desk', component: AccountServiceDeskComponent, canActivate: [LoginGuard] },
			{ path: 'account_search', component: AccountSearchComponent, canActivate: [LoginGuard] },
            { path: 'account_financials', component: AccountFinancialComponent, canActivate: [LoginGuard] },
			{ path: 'quarter_report', component: QuarterDataReportComponent, canActivate: [LoginGuard] },

			{ path: 'cfr_data/:id/:cr_type', component: CfrComponent, canActivate: [LoginGuard] },

			{ path: 'csr_data/:id/:cr_type', component: CsrComponent, canActivate: [LoginGuard] },

			{ path: 'cer_data/:id/:cr_type', component: CerComponent, canActivate: [LoginGuard] },

			{ path: 'corporate_plan', component: CorporatePlanComponent, canActivate: [LoginGuard] },

			{ path: 'commuter_travel', component: CommuterTravelComponent, canActivate: [LoginGuard] },

			{ path: 'crtracker/internal', component: InternalComponent, canActivate: [LoginGuard] },

			{ path: 'crtracker/external', component: ExternalComponent, canActivate: [LoginGuard] },

			{ path: 'financial/:company_id', component: FinancialComponent, canActivate: [LoginGuard] },
			
			{ path: 'add_financial/:company_id', component: AddFinancialComponent, canActivate: [LoginGuard] },

			{ path: 'add_financial/:company_id/:id', component: AddFinancialComponent, canActivate: [LoginGuard] },

			{ path: 'view_contract/:company_id', component: ContractComponent, canActivate: [LoginGuard] },

			{ path: 'add_contract/:company_id', component: AddContractComponent, canActivate: [LoginGuard] },
			
			{ path: 'add_contract/:company_id/:id', component: AddContractComponent, canActivate: [LoginGuard] },

			{ path: 'addesgmsuser', component: EsgmsuserComponent, canActivate: [LoginGuard] },

			{ path: 'esgmsuserslist', component: EsgmsuserListComponent, canActivate: [LoginGuard] },

			{ path: 'addesgmsuser/:id', component: EsgmsuserComponent, canActivate: [LoginGuard] },

			{ path: 'agentleads', component: AgentLeadListComponent, canActivate: [LoginGuard] },

			{
				path: 'branches/:id',
				component: BranchesComponent,
				data: {
					title: 'Branches',
					urls: [
						{ title: 'Branches', url: '/branches/:id' },
					]
				}
			},
			{
				path: 'cr_records_list',
				component: CerRecordsComponent,
				data: {
					title: 'CER ACTIVITY RECORDS',
					urls: [
						{ title: 'CER ACTIVITY RECORDS', url: '/cr_records' },
					]
				}
			},
			{
				path: 'car_report',
				component: CarReportComponent,
				data: {
					title: 'Climate Action Responsibility (CAR) Report',
					urls: [
						{ title: 'Climate Action Responsibility (CAR) Report', url: '/car_report' },
					]
				}
			},
			{
				path: 'csr_records',
				component: CsrRecordsComponent,
				data: {
					title: 'Corporate Social Responsibility (CSR) Report',
					urls: [
						{ title: 'Corporate Social Responsibility (CSR) Report', url: '/cr_records_list' },
					]
				}
			},
			{
				path: 'cr_record',
				component: AddRecordComponent,
				data: {
					title: 'Climate Action Responsibility (CAR)',
					urls: [
						{ title: 'Climate Action Responsibility (CAR) Performance Record', url: '/cr_record' },
					]
				}
			},
			{
				path: 'cr_analysis',
				component: CarAnalysisComponent,
				data: {
					title: 'Climate Action Responsibility (CAR) Performance Analysis',
					urls: [
						{ title: 'Climate Action Responsibility (CAR) Performance Analysis', url: '/cr_analysis' },
					]
				}
			},
			{
				path: 'cfr_analysis',
				component: CfrAnalysisComponent,
				data: {
					title: 'Corporate Financial Responsibility (CFR) Performance Analysis',
					urls: [
						{ title: 'Corporate Financial Responsibility (CFR) Performance Analysis', url: '/cfr_analysis' },
					]
				}
			},
			{
				path: 'csr_analysis',
				component: CsrAnalysisComponent,
				data: {
					title: 'Corporate Social Responsibility (CSR) Performance Analysis',
					urls: [
						{ title: 'Corporate Social Responsibility (CSR) Performance Analysis', url: '/csr_analysis' },
					]
				}
			},
			{
				path: 'cer_analysis',
				component: CerAnalysisComponent,
				data: {
					title: 'Corporate Enviourmental Responsibility (CER) Performance Analysis',
					urls: [
						{ title: 'Corporate Enviourmental Responsibility (CER) Performance Analysis', url: '/cer_analysis' },
					]
				}
			},
			{
				path: 'cfr_report',
				component: CfrReportComponent,
				data: {
					title: 'Corporate Financial Responsibility (CFR) Performance Report',
					urls: [
						{ title: 'Corporate Financial Responsibility (CFR) Performance Report', url: '/cfr_report' },
					]
				}
			},
			{
				path: 'csr_report',
				component: CsrReportComponent,
				data: {
					title: 'Corporate Social Responsibility (CSR) Performance Report',
					urls: [
						{ title: 'Corporate Social Responsibility (CSR) Performance Report', url: '/csr_report' },
					]
				}
			},
			{
				path: 'cer_report',
				component: CerReportComponent,
				data: {
					title: 'Corporate Enviourmental Responsibility (CER) Performance Report',
					urls: [
						{ title: 'Corporate Enviourmental Responsibility (CER) Performance Report', url: '/cer_report' },
					]
				}
			},
			{
				path: 'cr_data',
				component: CrComponent,
				data: {
					title: 'Corporate Responsibility (CR) Performance -Actual Record',
					urls: [
						{ title: 'Corporate Responsibility (CR) Performance -Actual Record', url: '/cr_data' },
					]
				}
			},
			{
				path: 'cr_daily_data',
				component: CorporateDailyDataComponent,
				data: {
					title: 'Corporate Responsibility (CR) Performance -Actual Record',
					urls: [
						{ title: 'Corporate Responsibility (CR) Performance -Actual Record', url: '/cr_daily_data' },
					]
				}
			},
			{
				path: 'cfr_data',
				component: CfrComponent,
				data: {
					title: 'Corporate Financial Responsibility (CFR) Performance -Actual Record',
					urls: [
						{ title: 'Corporate Financial Responsibility (CFR) Performance -Actual Record', url: '/cfr_data' },
					]
				}
			},
			{
				path: 'plan_cfr_data',
				component: PlanCfrComponent,
				data: {
					title: 'Corporate Financial Responsibility (CFR) Performance -Plan Record',
					urls: [
						{ title: 'Corporate Financial Responsibility (CFR) Performance -Plan Record', url: '/plan_cfr_data' },
					]
				}
			},
			{
				path: 'csr_data',
				component: CsrComponent,
				data: {
					title: 'Corporate Social Responsibility (CSR) Performance -Actual Record',
					urls: [
						{ title: 'Corporate Social Responsibility (CSR) Performance -Actual Record', url: '/csr_data' },
					]
				}
			},
			{
				path: 'plan_csr_data',
				component: PlanCsrComponent,
				data: {
					title: 'Corporate Social Responsibility (CSR) Performance -Paln Record',
					urls: [
						{ title: 'Corporate Social Responsibility (CSR) Performance -Paln Record', url: '/plan_csr_data' },
					]
				}
			},
			{
				path: 'cer_data',
				component: CerComponent,
				data: {
					title: 'Corporate Enviourmental Responsibility (CER) Performance -Actual Record',
					urls: [
						{ title: 'Corporate Enviourmental Responsibility (CER) Performance -Actual Record', url: '/cer_data' },
					]
				}
			},
			{
				path: 'plan_cer_data',
				component: PlanCerComponent,
				data: {
					title: 'Corporate Enviourmental Responsibility (CER) Performance -Plan Record',
					urls: [
						{ title: 'Corporate Enviourmental Responsibility (CER) Performance -Paln Record', url: '/plan_cer_data' },
					]
				}
			},
			{
				path: 'plan_car_data',
				component: PlanCarComponent,
				data: {
					title: 'Corporate Action Responsibility (CAR) Performance -Plan Record',
					urls: [
						{ title: 'Corporate Action Responsibility (CAR) Performance -Paln Record', url: '/plan_car_data' },
					]
				}
			},
			{
				path: 'cr_data_progress',
				component: CrInprogressComponent,
				data: {
					title: 'Corporate Responsibilities (CR) Record In Progress ',
					urls: [
						{ title: 'Corporate Responsibilities (CR) Record In Progress ', url: '/cr_data_progress' },
					]
				}
			},
			{
				path: 'cs_cpp_analysis',
				component: CscppAnalysisComponent,
				data: {
					title: 'Corporate Sustainability and Climate Protection Performance (CS& CPP) Analysis',
					urls: [
						{ title: 'Corporate Sustainability and Climate Protection Performance (CS& CPP) Analysis', url: '/cs_cpp_analysis' },
					]
				}
			},
		]
	},
	{ path: 'logout', component: LogoutComponent },
	// {
	//   path: '**',
	//   redirectTo: '/dashboard'
	// }
];
@NgModule({
	imports: [
		RouterModule.forRoot(Approutes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }