import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router,ActivatedRoute } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cscpp-analysis',
  templateUrl: './cscpp-analysis.component.html',
  styleUrls: ['./cscpp-analysis.component.css']
})
export class CscppAnalysisComponent implements OnInit {

  constructor(private _service: SharedService, private sessionSt: LocalStorageService,private fb: FormBuilder,private _snackBar: MatSnackBar) { }

  formCSCPPReport: FormGroup;
  yearsList:any=[];
  submitted = false;
  isCSCPPTab = false;
  is12MonthTab = false;
  isTabularTab = false;
  isChartTab = false;

  ngOnInit(): void {
    this._service.getYearList()?.subscribe(
      (response: any) => {
        this.yearsList = response.result;
      },
    );

    this.formCSCPPReport = this.fb.group({
      report_type:[''],
      year:['', Validators.required],
    })

  }

  get f() { return this.formCSCPPReport.controls; }

  CFReportData(){
    this.submitted = true;
    if (this.formCSCPPReport.invalid) {
      console.log(this.formCSCPPReport.controls)
      return;
    }
    let tdata = this.formCSCPPReport.getRawValue();
    console.log(tdata);
    let data: any = '';
    data = {
      report_type:tdata.report_type,
      year:tdata.year,
    };
    this._service.getCSCPPReportData(data)?.subscribe(
      (response: any) => {
          var blob=new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(blob);
          link.download="cs_cpp.xlsx";
          link.click(); 
      },
    );
  }
  onSubmit(){
    this.CFReportData();
  }
  reset(){
    window.location.reload();
  }
  reportType(event:any){
     //alert(event.currentTarget.innerText);
     this.formCSCPPReport.get("report_type")?.setValue(event.currentTarget.innerText);
     if(event.currentTarget.innerText == "Tabular Analysis"){
      this.isTabularTab = true;
      this.isChartTab = false;
   }
   else{
     this.isTabularTab = false;
     this.isChartTab = true;
   }
  }
  CSCPPAnnual(event:any){
    this.isCSCPPTab = true
  }
  btnmonth(event:any){
    this.is12MonthTab = true
  }
}
