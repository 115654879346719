<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Responsibilities (CR) Tracker</h2>
        </div>
    </div>
</div>
<!-- <div class="container-fluid mt-4"> -->
<div>
    <div class="card shadow">
        <div class="card-body">
            <div class="form" [formGroup]="formCfrReport">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row form-group">
                            <div class="col-md-5">
                                <label>Business Name:</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" name="business_name" formControlName="business_name"
                                    class="form-control form-control-alternative">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Branch:</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="branch" class="form-control form-control-alternative"
                                        name="branch">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let b of branchList" [value]="b.id">
                                            {{b.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Country:</label>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="country" class="form-control form-control-alternative">
                                        <mat-option [value]="">--Select--</mat-option>
                                        <mat-option *ngFor="let country of countriesList" [value]="country.code">
                                            {{country.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="row form-group">
                            <div class="col-md-5">
                                <label>Locality:</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" name="locality" formControlName="locality"
                                    class="form-control form-control-alternative">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Industry:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" name="industry" formControlName="industry"
                                    class="form-control form-control-alternative">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 80px; height: 33px;" type="button" class="btn btn-save"
                            (click)="onSubmit()">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-2">
    <div class="row" style="background-color: #fff;">
        <div class="col-md-2">

        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="align-items-center mb-4">
                        <div>
                            <p style="text-align: center;">Corporate Monitor</p>
                        </div>
                    </div>
                    <div class="row">
                        <!-- column -->
                        <div class="col-lg-12">
                            <div style="height: 300px;">
                                <canvas baseChart height="130" [datasets]="barChartData" [labels]="barChartLabels"
                                    [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                        <!-- column -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="align-items-center mb-4">
                        <div>
                            <p style="text-align: center;">Branch Monitor</p>
                            <!-- <h5 class="card-subtitle">Overview of Latest Month</h5> -->
                        </div>
                    </div>
                    <div class="row">
                        <!-- column -->
                        <div class="col-lg-12">
                            <div style="height: 300px;">
                                <canvas baseChart height="130" [datasets]="corporateBarChartData"
                                    [labels]="corporateBarChartLabels" [options]="barChartOptions"
                                    [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                        <!-- column -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">

        </div>
    </div>
</div>