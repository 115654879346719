<div class="header bg-gradient-primary" *ngIf="item">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2> Climate Action Responsibility (CAR) Performance - Actual Record</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div [ngClass]="(item)?'container-fluid mt-4':''">
    <div [ngClass]="(item)?'card shadow':''">
        <div [ngClass]="(item)?'card-body':''">
            <form [formGroup]="formUser">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Target Month Date</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="target_month_date"
                            class="form-control form-control-alternative datepicker" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker"
                            [ngClass]="{ 'is-invalid': (f.target_month_date.touched || submitted) && f.target_month_date.errors?.required }">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(f.target_month_date.touched || submitted) && f.target_month_date.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Climate Action Goal</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="goal_id" class="form-control form-control-alternative"
                                name="goal_id" required (selectionChange)="onGoalChange($event)"
                                [ngClass]="{ 'is-invalid': (f.goal_id.touched || submitted) && f.goal_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let g of goals" [value]="g.id">
                                    {{g.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.goal_id.touched || submitted) && f.goal_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.goal_id.value">
                    <div class="col-md-3">
                        <label>{{ goalValueLabel }}</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="goal_value_id" class="form-control form-control-alternative"
                                name="goal_value_id" required (selectionChange)="onGoalValueChange($event)"
                                [ngClass]="{ 'is-invalid': (f.goal_value_id.touched || submitted) && f.goal_value_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let g of goalValues" [value]="g.id">
                                    {{g.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.goal_value_id.touched || submitted) && f.goal_value_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Category</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="category_id" class="form-control form-control-alternative"
                                name="category_id" required (selectionChange)="onCategoryChange($event)"
                                [ngClass]="{ 'is-invalid': (f.category_id.touched || submitted) && f.category_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let c of categories" [value]="c.id">
                                    {{c.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.category_id.touched || submitted) && f.category_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group"
                    *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333340'">
                    <div class="col-md-3">
                        <label>Activity Generate Green Tariff</label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="generate_green_tariff" formControlName="generate_green_tariff"
                            value="1"> Yes
                        <input type="radio" name="generate_green_tariff" formControlName="generate_green_tariff"
                            value="2" (change)="onClickGreenTariff($event,helpTextPopupModal)"> No
                        <div class="invalid-feedback"
                            *ngIf="(f.generate_green_tariff.touched || submitted) && f.generate_green_tariff.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div *ngIf="isFormEnabled || f.generate_green_tariff.value == '1'">
                    <div *ngIf="f.category_id.value">
                        <div *ngIf="activity_fields">
                            <div class="row formFieldsBg form-group">
                                <div class="col-md-3">
                                    <label>Activity</label>
                                </div>
                                <div class="col-md-5">
                                    <mat-form-field class="w-100">
                                        <mat-select formControlName="category_activity_id"
                                            (selectionChange)="onActivitiesChange($event)"
                                            class="form-control form-control-alternative" name="category_activity_id"
                                            required
                                            [ngClass]="{ 'is-invalid': (f.category_activity_id.touched || submitted) && f.category_activity_id.errors?.required }">
                                            <mat-option [value]="">-Select-</mat-option>
                                            <mat-option *ngFor="let c of categoryValues" [value]="c.id">
                                                {{c.name}}
                                            </mat-option>
                                        </mat-select>
                                        <div class="invalid-feedback"
                                            *ngIf="(f.category_activity_id.touched || submitted) && f.category_activity_id.errors?.required">
                                            This field is required
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333335' && f.category_activity_id.value == '31111111-1111-1111-1111-111111111133'">
                                <div class="col-md-3">
                                    <label>Sub Category</label>
                                </div>
                                <div class="col-md-5">
                                    <mat-form-field class="w-100">
                                        <mat-select formControlName="category_activity_other"
                                            class="form-control form-control-alternative"
                                            name="category_activity_other">
                                            <mat-option [value]="">-Select-</mat-option>
                                            <mat-option *ngFor="let sc of subCategoryValues" [value]="sc.id">
                                                {{sc.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group">
                                <div class="col-md-3">
                                    <label>Activity Description</label>
                                </div>
                                <div class="col-md-5">
                                    <textarea formControlName="activity_description" name="activity_description"
                                        class="form-control form-control-alternative"></textarea>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333331' || 
                        f.category_id.value == '33333333-3333-3333-3333-333333333334' || f.category_id.value == '33333333-3333-3333-3333-333333333336'">
                                <div class="col-md-3">
                                    <label>Activity Quality Value (Deliverables/ Benefits)</label>
                                </div>
                                <div class="col-md-5">
                                    <textarea formControlName="activity_quality_value" name="activity_quality_value"
                                        class="form-control form-control-alternative"></textarea>
                                </div>
                            </div>

                            <div class="row formFieldsBg form-group" *ngIf="isActivityCost">
                                <div class="col-md-3">
                                    <label>Activity Value Cost</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_value_cost" appNumbers
                                        formControlName="activity_value_cost" required min="0"
                                        class="form-control form-control-alternative"
                                        [ngClass]="{ 'is-invalid': (f.activity_value_cost.touched || submitted) && f.activity_value_cost.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_value_cost.touched || submitted) && f.activity_value_cost.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>

                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333340'">
                                <div class="col-md-3">
                                    <label>Total Finance & Investment for Sale</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_total_investment" appNumbers
                                        formControlName="activity_total_investment" required min="0"
                                        class="form-control form-control-alternative"
                                        [ngClass]="{ 'is-invalid': (f.activity_total_investment.touched || submitted) && f.activity_total_investment.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_total_investment.touched || submitted) && f.activity_total_investment.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333340' || f.category_id.value == '33333333-3333-3333-3333-333333333341'">
                                <div class="col-md-3">
                                    <label>{{finance_and_investment_sale_buy_label}}</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_investment_mark_cost" appNumbers
                                        formControlName="activity_investment_mark_cost" required min="0"
                                        class="form-control form-control-alternative"
                                        (change)="calculateFinanceAndInvestment()"
                                        [ngClass]="{ 'is-invalid': (f.activity_investment_mark_cost.touched || submitted) && f.activity_investment_mark_cost.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_investment_mark_cost.touched || submitted) && f.activity_investment_mark_cost.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333340'">
                                <div class="col-md-3">
                                    <label>Balance Finance & Investment Available for Sale</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_finance_investment_sellable_value"
                                        formControlName="activity_finance_investment_sellable_value" min="0"
                                        class="form-control form-control-alternative" readonly>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333332' && f.category_activity_id.value == '31111111-1111-1111-1111-111111111116'">
                                <div class="col-md-3">
                                    <label>Sustainable Procurement/Consumption</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="radio" name="sustainable_procurement"
                                        formControlName="sustainable_procurement" value="1"> Yes
                                    <input type="radio" name="sustainable_procurement"
                                        formControlName="sustainable_procurement" value="2"> No
                                    <div class="invalid-feedback"
                                        *ngIf="(f.sustainable_procurement.touched || submitted) && f.sustainable_procurement.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isActivityQuantity">
                                <div class="col-md-3">
                                    <!-- <label>Activity Quantity Value</label> -->
                                    <label>{{activity_quantity_value_label}}</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_quantity" appNumbers
                                        formControlName="activity_quantity" required min="0"
                                        class="form-control form-control-alternative"
                                        (change)="calculateGHGEmmissionTonnes()"
                                        [ngClass]="{ 'is-invalid': (f.activity_quantity.touched || submitted) && f.activity_quantity.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_quantity.touched || submitted) && f.activity_quantity.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isNumberOfDaysPerWeek">
                                <div class="col-md-3">
                                    <!-- <label>Activity Quantity Value</label> -->
                                    <label>Number Of Days Per Week</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="number_of_days_per_week" appNumbers
                                        formControlName="number_of_days_per_week" required min="0"
                                        class="form-control form-control-alternative"
                                        [ngClass]="{ 'is-invalid': (f.number_of_days_per_week.touched || submitted) && f.number_of_days_per_week.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.number_of_days_per_week.touched || submitted) && f.number_of_days_per_week.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isActivityQuantityMeasurement">
                                <div class="col-md-3">
                                    <!-- Activity Quantity Value Measurement Unit -->
                                    <!-- <label>Activity Value Measurement Unit</label> -->
                                    <label>{{activity_value_measurement_unit_label}}</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" name="activity_unit" formControlName="activity_unit" required
                                        class="form-control form-control-alternative"
                                        [ngClass]="{ 'is-invalid': (f.activity_unit.touched || submitted) && f.activity_unit.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_unit.touched || submitted) && f.activity_unit.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isCurrencyMeasurementUnit">
                                <div class="col-md-3">
                                    <!-- Activity Currency Measurement Unit -->
                                    <label>Activity Value Currency</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" name="activity_currency_measurement_unit"
                                        formControlName="activity_currency_measurement_unit" required readonly
                                        class="form-control form-control-alternative"
                                        [ngClass]="{ 'is-invalid': (f.activity_currency_measurement_unit.touched || submitted) && f.activity_currency_measurement_unit.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_currency_measurement_unit.touched || submitted) && f.activity_currency_measurement_unit.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isActivityEmissionFactor">
                                <div class="col-md-3">
                                    <label>{{emmission_factor}} </label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="activity_emmission_factor" appNumbers
                                        formControlName="activity_emmission_factor" required min="0"
                                        class="form-control form-control-alternative"
                                        (change)="calculateGHGEmmissionTonnes()"
                                        [ngClass]="{ 'is-invalid': (f.activity_emmission_factor.touched || submitted) && f.activity_emmission_factor.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.activity_emmission_factor.touched || submitted) && f.activity_emmission_factor.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group" *ngIf="isGHG_EFPD">
                            <div class="col-md-3">
                                <label>Green House Gas (GHG) Tonnes CO2e</label>
                            </div>
                            <div class="col-md-5">
                                <input type="number" name="ghg" formControlName="ghg" appNumbers
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.ghg.touched || submitted) && f.ghg.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.ghg.touched || submitted) && f.ghg.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div *ngIf="calculated_formular_options">
                            <div class="row formFieldsBg form-group" *ngIf="isGHGEmission">
                                <div class="col-md-3">
                                    <label>{{calculated_formular_label}} </label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="ghg_emmission_tonnes"
                                        formControlName="ghg_emmission_tonnes" (change)="addminussign($event)"
                                        class="form-control form-control-alternativ"
                                        class="form-control form-control-alternative">
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="greenTravelCarbon">
                                <div class="col-md-3">
                                    <label>Green Travel Carbon Offset Tonnes</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="green_travel_carbon_offset"
                                        (change)="addminussignGreenTravel($event)"
                                        formControlName="green_travel_carbon_offset"
                                        class="form-control form-control-alternativ"
                                        class="form-control form-control-alternative">
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="(f.category_id.value == '33333333-3333-3333-3333-333333333332' && f.sustainable_procurement.value == 1)">
                                <div class="col-md-3">
                                    <label>Green Tariff (Tonnes Co2e) </label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="green_tarrif_tonnes"
                                        formControlName="green_tarrif_tonnes" required
                                        class="form-control form-control-alternativ appNumbers" required
                                        (change)="addminussignGreenTariff($event)"
                                        [ngClass]="{ 'is-invalid': (f.green_tarrif_tonnes.touched || submitted) && f.green_tarrif_tonnes.errors?.required }"
                                        class="form-control form-control-alternative">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.green_tarrif_tonnes.touched || submitted) && f.green_tarrif_tonnes.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isCarbonOffsetTonnes">
                                <div class="col-md-3">
                                    <label>Carbon Offset Tonnes</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="ghg_carbon_offset_tonnes" appNumbers
                                        formControlName="ghg_carbon_offset_tonnes"
                                        class="form-control form-control-alternative" required
                                        [ngClass]="{ 'is-invalid': (f.ghg_carbon_offset_tonnes.touched || submitted) && f.ghg_carbon_offset_tonnes.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.ghg_carbon_offset_tonnes.touched || submitted) && f.ghg_carbon_offset_tonnes.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isCarbonOffsetTonnes">
                                <div class="col-md-3">
                                    <label>Net Carbon Offset Tonnes</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="ghg_net_carbon_offset_tonnes" appNumbers
                                        formControlName="ghg_net_carbon_offset_tonnes"
                                        class="form-control form-control-alternative" required
                                        [ngClass]="{ 'is-invalid': (f.ghg_net_carbon_offset_tonnes.touched || submitted) && f.ghg_net_carbon_offset_tonnes.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.ghg_net_carbon_offset_tonnes.touched || submitted) && f.ghg_net_carbon_offset_tonnes.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group" *ngIf="isCarbonOffsetUnits">
                                <div class="col-md-3">
                                    <label>{{carbon_offset_label}}</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="number" name="ghg_carbon_offset_units" appNumbers
                                        formControlName="ghg_carbon_offset_units"
                                        class="form-control form-control-alternative" required
                                        [ngClass]="{ 'is-invalid': (f.ghg_carbon_offset_units.touched || submitted) && f.ghg_carbon_offset_units.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.ghg_carbon_offset_units.touched || submitted) && f.ghg_carbon_offset_units.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="(f.category_id.value != '33333333-3333-3333-3333-333333333339') && ((f.ghg_emmission_tonnes.value) && (isEmissionUnit))">
                                <div class="col-md-3">
                                    <label>{{emmission_parameter_desc_label}} </label>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" name="ghg_emmission_unit" formControlName="ghg_emmission_unit"
                                        class="form-control form-control-alternative" required
                                        [ngClass]="{ 'is-invalid': (f.ghg_emmission_unit.touched || submitted) && f.ghg_emmission_unit.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.ghg_emmission_unit.touched || submitted) && f.ghg_emmission_unit.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333339'">
                                <div class="col-md-3">
                                    <label>{{emmission_parameter_desc_label}} </label>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" name="ghg_emmission_unit" formControlName="ghg_emmission_unit"
                                        class="form-control form-control-alternative" required
                                        [ngClass]="{ 'is-invalid': (f.ghg_emmission_unit.touched || submitted) && f.ghg_emmission_unit.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.ghg_emmission_unit.touched || submitted) && f.ghg_emmission_unit.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row formFieldsBg form-group">
                                <div class="col-md-3">
                                    <label>{{ radio_parameter_desc_label }}</label>
                                </div>
                                <div class="col-md-5">
                                    <input type="radio" name="is_ghg_param" formControlName="is_ghg_param" [value]="1">
                                    Yes
                                    <input type="radio" name="is_ghg_param" formControlName="is_ghg_param" [value]="2">
                                    No
                                    <div class="invalid-feedback"
                                        *ngIf="(f.is_ghg_param.touched || submitted) && f.is_ghg_param.errors?.required">
                                        This field is required
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-3">
                                <label>Emission Factor Parameter Description</label>
                            </div>
                            <div class="col-md-5">
                                <textarea formControlName="ghg_emmission_factor_parameter_desc"
                                    name="ghg_emmission_factor_parameter_desc"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.ghg_emmission_factor_parameter_desc.touched || submitted) && f.ghg_emmission_factor_parameter_desc.errors?.required }"></textarea>
                                <div class="invalid-feedback"
                                    *ngIf="(f.ghg_emmission_factor_parameter_desc.touched || submitted) && f.ghg_emmission_factor_parameter_desc.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <!-- <label>Emission factor Parameter Description</label>
                        <div class="row formFieldsBg form-group" *ngIf="isGHG_EFPD">
                            <div class="col-md-3">
                                <label>Green House Gas (GHG)</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="ghg" formControlName="ghg" appNumbers
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.ghg.touched || submitted) && f.ghg.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.ghg.touched || submitted) && f.ghg.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group" *ngIf="isCarbonOffset_EFPD">
                            <div class="col-md-3">
                                <label>Carbon Offset</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="carbon_offset" formControlName="carbon_offset"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.carbon_offset.touched || submitted) && f.carbon_offset.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.carbon_offset.touched || submitted) && f.carbon_offset.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div> 
                        <div class="row formFieldsBg form-group" *ngIf="isGT_EFPD">
                            <div class="col-md-3">
                                <label>Green Tariffs</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="green_tariffs" formControlName="green_tariffs"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.green_tariffs.touched || submitted) && f.green_tariffs.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.green_tariffs.touched || submitted) && f.green_tariffs.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group" *ngIf="isGTCF_EFPD">
                            <div class="col-md-3">
                                <label>Green Travel Carbon Offset</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="green_travel_carbon_offset" formControlName="green_travel_carbon_offset"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.green_travel_carbon_offset.touched || submitted) && f.green_travel_carbon_offset.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.green_travel_carbon_offset.touched || submitted) && f.green_travel_carbon_offset.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>-->

                    </div>
                    <div *ngIf="valueChainFields">
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-3">
                                <label>Value Chain Impact</label>
                            </div>
                            <div class="col-md-5">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="value_chain_main_id"
                                        class="form-control form-control-alternative" name="value_chain_main_id"
                                        required (selectionChange)="onValueChainChange($event)"
                                        [ngClass]="{ 'is-invalid': (f.value_chain_main_id.touched || submitted) && f.value_chain_main_id.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let v of valuechain" [value]="v.id">
                                            {{v.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.value_chain_main_id.touched || submitted) && f.value_chain_main_id.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.value_chain_main_id.value && f.value_chain_main_id.value !='66666666-6666-6666-6666-666666666661'">
                            <div class="col-md-3">
                                <label>{{value_chain_infra_label}}</label>
                            </div>
                            <div class="col-md-5">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="value_chain_id"
                                        class="form-control form-control-alternative" name="value_chain_id">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                            {{v.name}}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <div class="invalid-feedback"
                                    *ngIf="(f.value_chain_values.touched || submitted) && f.value_chain_values.errors?.required">
                                    This field is required
                                </div> -->
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.value_chain_main_id.value =='66666666-6666-6666-6666-666666666663' && f.value_chain_id.value =='41111111-1111-1111-1111-111111111119'">
                            <div class="col-md-3">
                                <label>Facility Description</label>
                            </div>
                            <div class="col-md-5">
                                <input formControlName="facility_decription" type="text" placeholder=""
                                    class="form-control form-control-alternative" ngbAutofocus />
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.value_chain_main_id.value =='66666666-6666-6666-6666-666666666663' && f.value_chain_id.value =='41111111-1111-1111-1111-111111111110'">
                            <div class="col-md-3">
                                <label>Building Description</label>
                            </div>
                            <div class="col-md-5">
                                <input formControlName="building" type="text" placeholder=""
                                    class="form-control form-control-alternative" ngbAutofocus />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="employeeDetails">
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-3">
                                <label>Administration</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="administartion" formControlName="administartion"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.administartion.touched || submitted) && f.administartion.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.administartion.touched || submitted) && f.administartion.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-3">
                                <label>Employee ESGMS Number</label>
                            </div>
                            <div class="col-md-5">
                                <input type="text" name="emp_number" formControlName="emp_number"
                                    class="form-control form-control-alternative" required
                                    [ngClass]="{ 'is-invalid': (f.emp_number.touched || submitted) && f.emp_number.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.emp_number.touched || submitted) && f.emp_number.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="isEdit">
                        <div class="card-body p-1">
                            <ag-grid-angular style="width: 100%; height: 180px; background-color: #3b6c54 !important"
                                class="ag-theme-bootstrap" [gridOptions]="gridOptions" [rowData]="rowData"
                                [columnDefs]="columnDefs" animateRows pagination paginationPageSize=10 rowHeight=27
                                headerHeight=35 (rowClicked)='onGridRowClicked($event,content)'>
                            </ag-grid-angular>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="item">
                        <!-- <div class="col-md-3">
                        <label>Support Document</label>
                    </div>
                    <div formArrayName="support_documents">
                        <div class="form-group">
                            <button type="button" style="float: right;" class="btn btn-add-more-docs btn-sm" (click)="addSupportDocuments()">Add More
                                Documents</button>
                        </div>
                        <div *ngFor="let item of supportDocuments().controls; let i = index;">
                            <div class="row">
                                <div>
                                    <label>Support Document {{i+1}}</label>
                                </div>
                                <div class="col-md-12">
                                    <input type="file" name="document_path_{{supportDocuments().controls.length + i}}" (change)="getUploadedDocuments($event.target.files,i)"
                                        class="form-control form-control-alternative">
                                </div>
                            </div>
                        </div>
                    </div> -->
                        <table class="table" formArrayName="support_documents">
                            <tr>
                                <th colspan="2">Add Multiple Documents:</th>
                                <th width="150px"><button type="button" (click)="addSupportDocuments()"
                                        class="btn btn-primary btn-sm">Add More</button></th>
                            </tr>
                            <tr *ngFor="let item of supportDocuments().controls; let i=index" [formGroupName]="i">
                                <td>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Support Document {{i + 1}}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="file"
                                                name="document_path_{{supportDocuments().controls.length + i}}"
                                                (change)="getUploadedDocuments($event.target.files,i)"
                                                class="form-control form-control-alternative">
                                        </div>
                                    </div>
                                    <!-- Support Document :
                              <input type="file" name="document_path_{{supportDocuments().controls.length + i}}" (change)="getUploadedDocuments($event.target.files,i)"
                                        class="form-control form-control-alternative"> -->
                                </td>
                                <td *ngIf="i==0" style="width: 100px;">

                                </td>
                                <td *ngIf="i>0">
                                    <button (click)="removeDocument(i)" class="btn btn-danger btn-sm">Remove</button>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <!-- <div class="row mt-1" *ngIf="item">
                        <div class="">
                            <button type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                            <button type="button" [routerLink]="['/cr_records_list']" class="btn btn-back-to-list">Back
                                To List</button>
                            <button type="button" [routerLink]="['/cr_data_progress']" class="btn btn-back-to-list">Back
                                To List</button>
                        </div>
                    </div> -->
                    <div class="row mt-1" *ngIf="item">
                        <div class="">
                            <button type="button" class="btn btn-save" (click)="onSave()">Save</button>
                            <button type="button" class="btn btn-save" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #helpTextPopupModal let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="msg"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>