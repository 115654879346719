import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { ResetPasswordService } from './reset-password.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss','../../layouts/full/full.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPassowrdForm: FormGroup;
  submitted = false;
  accessToken:any;
  apiMSG:any;
  validLink = 1;
  apiID:any ="";
  apiSecret:any = "";

  constructor(private formBuilder: FormBuilder, private resetPassService: ResetPasswordService, private activatedRoute: ActivatedRoute, private router: Router,
    private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.accessToken = params.token;
    });
    let params: any = {
      link: this.accessToken
    };
    this.resetPassService.requestpassword(params)?.subscribe(
        (response:any) => {
          let apiSuccess = (response as any).success;
          if (apiSuccess) {
            this.apiID = (response as any).result.id;
            this.apiSecret = (response as any).result.secret;
            this.validLink = 1;
          } else {
            this.validLink = 0;
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          } 
        },
        (error:any) => console.log(error)
      );


    this.resetPassowrdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirm_password')
  });
  }

  get f() { return this.resetPassowrdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassowrdForm.invalid) {
      return;
    }
    let params: any = {
      id: this.apiID,
      code: this.apiSecret,
      password: this.resetPassowrdForm.get('password')?.value
    };
    this.resetPassService.resetpassword(params)?.subscribe(
        (response:any) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
            this.router.navigate(['/']);
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }
          
        },
        (error:any) => {
          console.log(error);
        }
      );
  }

}
