<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            User - Actual records
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">User Records</h3>
                </div>
                <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/addesgmsuser']">Add New User</a>
                </div>
            </div>
        </div>
        <div class="card-body p-1">
            <ag-grid-angular
            style="width: 100%; height: 380px; background-color: #3b6c54 !important"
            class="ag-theme-bootstrap"
            [gridOptions]="gridOptions"
            [rowData]="userList"
            [columnDefs]="columnDefs"
            animateRows
            pagination
            paginationPageSize = 10
            rowHeight = 27
            headerHeight=35
            (rowClicked)='onGridRowClicked($event)'
            >
          </ag-grid-angular>
          </div>
    </div>
</div>
