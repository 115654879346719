import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Business } from '../../../model/business/business.model';
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmDialogService } from '../../../confirm-dialog/confirm-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { UpdateLeadFormService } from './update-lead-form.service';

@Component({
  selector: 'app-update-lead-form',
  templateUrl: './update-lead-form.component.html',
  styleUrls: ['./update-lead-form.component.css']
})
export class UpdateLeadFormComponent implements OnInit {

  submitted = false;
  company = new Business();
  formUser: FormGroup;
  businessList: any[] = [];
  checkedProduct: any[] = [];
  productList: any[] = [];
  selectProductList: any[] = [];
  isChecBox: boolean
  isEdit: boolean = false;
  roles: any[] = [];
  imagePath: string = environment.imageURL;
  logoPath: any;
  countriesList: any[] = [];
  continentsList: any[] = [];
  defaultCountry: string = 'GB';
  defaultContinent: string = 'EU';
  businessAccount: any[] = [];
  branchNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  accessToken: any;
  currencyList: any = []
  financialYearList: any = []
  defaultReportingYear: string = '7axyc0bb-b933-4f28-bc70-b5cc47fafb95';
  defaultCSCPPReportingYear: string = '6axyc0bb-b933-4f28-bc70-b5cc47fafb95';
  industries: any[] = [];
  isFormSubmittedByUser: boolean;
  employeeList: any = [];

  valuechain: any = [];
  valuechainValues: any = [];

  //CORPORATE USER
  is_contact_system_corporate: any
  is_email_use_for_corporate: any
  is_address_use_for_corporate: any
  is_corporate_department:any

  corporate_user_first_name: string = ""
  corporate_user_last_name: string = ""
  corporate_user_email: string = ""
  corporate_user_address1: string = ""
  corporate_user_address2: string = ""
  corporate_user_address3: string = ""
  corporate_user_id: string = ""


  constructor(private fb: FormBuilder, private router: Router,
    private confirmDialogService: ConfirmDialogService, private route: ActivatedRoute,
    private _snackBar: MatSnackBar, private sessionSt: LocalStorageService, private updateLeadFormService: UpdateLeadFormService) { }

  ngOnInit(): void {

    // USE THIS AS NUMBER OF EMPLOYEES
    this.getAllPriceByCountryId("GB");

    this.updateLeadFormService.getCurrency().subscribe((response: any) => {
      if (response.success) {
        this.currencyList = response.result;
      }
    });

    this.updateLeadFormService.getReportingYearValues().subscribe((response: any) => {
      if (response.success) {
        this.financialYearList = response.result;
      }
    });
    //Get All Roles


    this.route.queryParams.subscribe(params => {
      this.accessToken = params.token;
    });
    let params: any = {
      link: this.accessToken
    };





    this.updateLeadFormService.getRoles().subscribe(
      (response: any) => {
        if (response.success) {
          this.roles = response.result;
        }
      })

    //Get All Industries
    this.updateLeadFormService.getIndustries().subscribe(
      (response: any) => {
        if (response.success) {
          this.industries = response.result;
        }
      });

    //Get All Product List
    this.updateLeadFormService.getProductList().subscribe(
      (response: any) => {
        if (response.success) {
          this.productList = response.result;
        }
      });

    //Get All Countries List
    this.updateLeadFormService.getCountriesList().subscribe(
      (response: any) => {
        if (response.success) {
          this.countriesList = response.result;
        }
      });

    //Get All Continent List
    this.updateLeadFormService.getContinentList().subscribe(
      (response: any) => {
        if (response.success) {
          this.continentsList = response.result;
        }
      });

    //Get All Roles
    this.updateLeadFormService.getBusinessAccountType().subscribe(
      (response: any) => {
        if (response.success) {
          this.businessAccount = response.result;
        }
      });

    // GET VALUE CHAIN
    this.updateLeadFormService.getCRValueChain().subscribe(
      (response: any) => {
        if (response.success) {
          this.valuechain = response.result.filter((x: any) => x.id == '66666666-6666-6666-6666-666666666662');
          this.valuechainValues = this.valuechain[0].value_chain_values;
        }
      })

    if (params != null) {
      this.updateLeadFormService.getLeadDataByEmailOrId(params)?.subscribe(
        (response: any) => {
          let apiSuccess = (response as any).success;
          if (apiSuccess) {
            this.selectProductList = response.result.company_product;
            // Checkbox Checked
            if ((this.productList.length > 0) && (response.result.company_product.length > 0)) {
              this.productList.forEach((res: any) => {
                response.result.company_product.forEach((spd: any) => {
                  if (res.id == spd.product_id) {
                    res.isChecBox = true;
                    this.checkedProduct.push(spd.product_id);
                    this.updateChkbxArray(spd.product_id, true);
                  }
                })
              });
            }

            if (response.result.process_status_id != "4ad8c0bb-b933-4f28-bc70-b5cc47fafb95") {
              this.isFormSubmittedByUser = true;
            }
            else {
              this.isFormSubmittedByUser = false;
            }

            if (response.result.corporate_user != null) {

              this.is_contact_system_corporate = response.result.corporate_user.is_contact_system_corporate_administrator;
              this.is_email_use_for_corporate = response.result.corporate_user.is_email_use_for_administrator_purposes;
              this.is_address_use_for_corporate = response.result.corporate_user.is_address_use_for_administrator_office;
              this.is_corporate_department = response.result.corporate_user.is_corporate_department_administrator;

              this.corporate_user_first_name = response.result.corporate_user.corporate_administrator_first_name;
              this.corporate_user_last_name = response.result.corporate_user.corporate_administrator_last_name;
              this.corporate_user_email = response.result.corporate_user.corporate_administrator_email;
              this.corporate_user_address1 = response.result.corporate_user.corporate_administrator_address1;
              this.corporate_user_address2 = response.result.corporate_user.corporate_administrator_address2;
              this.corporate_user_address3 = response.result.corporate_user.corporate_administrator_address3;
              this.corporate_user_id = response.result.corporate_user.id;
            }

            this.formUser.patchValue({
              name: response.result.name,
              main_contact_first_name: response.result.main_contact_first_name,
              main_contact_last_name: response.result.main_contact_last_name,
              phone: response.result.phone,
              email: response.result.email,
              website: response.result.website,
              logo_path: response.result.logo_path,
              id: response.result.id,
              role_id: response.result.role_id,
              process_status_id: response.result.process_status_id,
              postcode: response.result.address.postcode,
              address_1: response.result.address.address1,
              address_2: response.result.address.address2,
              address_3: response.result.address.address3,
              county: response.result.address.county,
              country: response.result.address.country,
              continent: response.result.address.continent,
              address_id: response.result.address.id,
              business_account_type: this.company.business_account_type,
              branch_number: this.company.branch_number,
              industry: response.result.industry,
              industry_sector_id: response.result.industry_sector_id,
              // locality:response.result.locality,
              reporting_currency: response.result.reporting_currency,
              car_reporting_year: response.result.car_reporting_year,
              financial_reporting_year: response.result.financial_reporting_year,
              //contract_doc_path: response.result.contract_doc_path,
              //price_id:response.result.price.id,
              //contract_notes: response.result.contract_notes,
              price: response.result.price,
              new_customer_discount: response.result.new_customer_discount,
              negotiated_discount: response.result.negotiated_discount,
              plan_discount: response.result.plan_discount,
              total_subscription_per_month: response.result.total_subscription_per_month,
              total_number_of_employee: response.result.total_number_of_employee,

              corporate_user_id: this.corporate_user_id,
              is_contact_system_corporate_administrator: this.is_contact_system_corporate,
              is_email_use_for_administrator_purposes: this.is_email_use_for_corporate,
              is_address_use_for_administrator_office: this.is_address_use_for_corporate,
              is_corporate_department_administrator:this.is_contact_system_corporate,

              corporate_administrator_first_name: this.corporate_user_first_name,
              corporate_administrator_last_name: this.corporate_user_last_name,
              corporate_administrator_email: this.corporate_user_email,
              corporate_administrator_address1: this.corporate_user_address1,
              corporate_administrator_address2: this.corporate_user_address3,
              corporate_administrator_address3: this.corporate_user_address3
            });

          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }
        },
        (error: any) => console.log(error)
      );
    }

    this.formUser = this.fb.group({

      //role_id:'2b4e4105-463e-456e-8e5e-97ac397168e5',
      status: 0,
      main_contact_first_name: ['', Validators.required],
      main_contact_last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: [''],
      name: ['', Validators.required],
      address_1: [''],
      address_2: [''],
      address_3: [''],
      county: ['', Validators.required],
      postcode: ['', Validators.required],
      country: ['', Validators.required],
      continent: ['', Validators.required],
      phone: ['', Validators.required],
      role_id: ['', Validators.required],
      created_at: [''],
      id: '',
      address_id: '',
      products: this.fb.array([]),
      logo_path: [''],
      reporting_currency: ['', Validators.required],
      financial_reporting_year: ['', Validators.required],
      car_reporting_year: ['', Validators.required],
      branch_number: ['', Validators.required],
      industry_sector_id: ['', Validators.required],
      industry: ['', Validators.required],
      total_number_of_employee: ['', Validators.required],
      is_contact_system_corporate_administrator: ['', Validators.required],
      is_email_use_for_administrator_purposes: ['', Validators.required],
      is_address_use_for_administrator_office: ['', Validators.required],
      corporate_administrator_first_name: ['', Validators.required],
      corporate_administrator_last_name: ['', Validators.required],
      corporate_administrator_email: ['', Validators.required],
      corporate_administrator_address1: [''],
      corporate_administrator_address2: [''],
      corporate_administrator_address3: [''],
      corporate_user_id: '',
      //is_corporate_department_administrator: ['', Validators.required],
      corporate_department_unit_id: ['', Validators.required],
    });

    this.formUser.controls['country'].setValue(this.defaultCountry, { onlySelf: true });
    this.formUser.controls['continent'].setValue(this.defaultContinent, { onlySelf: true });
  }



  get f() { return this.formUser.controls; }

  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: any = null;
  getUploadedFile(files: any) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  //Save Data
  onSave() {
    this.submitted = true;
    let IscorporateAdministrator = this.formUser.get('is_contact_system_corporate_administrator')?.value;
    if (IscorporateAdministrator == 2) {
      this.formUser.get("corporate_administrator_first_name")?.enable();
      this.formUser.get("corporate_administrator_last_name")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_first_name")?.disable();
      this.formUser.get("corporate_administrator_last_name")?.disable();
    }


    let IsCorporateAdministratorEmail = this.formUser.get('is_email_use_for_administrator_purposes')?.value;

    if (IsCorporateAdministratorEmail == 2) {
      this.formUser.get("corporate_administrator_email")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_email")?.disable();
    }

    // let IsCorporateDepartment = this.formUser.get('is_corporate_department_administrator')?.value;
    // if (IsCorporateDepartment == 2) {
    //   this.formUser.get("corporate_department_unit_id")?.enable();
    // }
    // else {
    //   this.formUser.get("corporate_department_unit_id")?.disable();
    // }

    if (this.formUser.invalid) {
      console.log(this.formUser.controls);
      this.scrollToError();
      return;
    }

    let data = this.formUser.getRawValue();
    data.product_ids = data.products.toString();
    data.logo_path = this.fileToUpload;

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("logo_path", data.logo_path);
    formData.append("main_contact_first_name", data.main_contact_first_name);
    formData.append("main_contact_last_name", data.main_contact_last_name);
    formData.append("email", data.email);
    formData.append("website", data.website);
    formData.append("name", data.name);
    formData.append("address_1", data.address_1);
    formData.append("address_2", data.address_2 ? data.address_2 : '');
    formData.append("address_3", data.address_3 ? data.address_3 : '');
    formData.append("county", data.county);
    formData.append("postcode", data.postcode);
    formData.append("country", data.country);
    formData.append("continent", data.continent);
    formData.append("phone", data.phone);

    formData.append("role_id", data.role_id);
    formData.append("product_ids", data.products.toString());
    formData.append("process_status_id", data.process_status_id ? data.process_status_id : '4ad8c0bb-b933-4f28-bc70-b5cc47fafb95');

    formData.append("reporting_currency", data.reporting_currency);
    formData.append("car_reporting_year", data.car_reporting_year);
    formData.append("financial_reporting_year", data.financial_reporting_year);
    formData.append("branch_number", data.branch_number);
    formData.append("industry_sector_id", data.industry_sector_id);
    formData.append("industry", data.industry);
    formData.append("total_number_of_employee", data.total_number_of_employee);

    formData.append("is_contact_system_corporate_administrator", data.is_contact_system_corporate_administrator);
    formData.append("is_email_use_for_administrator_purposes", data.is_email_use_for_administrator_purposes);
    formData.append("is_address_use_for_administrator_office", data.is_address_use_for_administrator_office);
    formData.append("is_corporate_department_administrator", "1");

    formData.append("corporate_department_unit_id", data.corporate_department_unit_id);

    formData.append("corporate_administrator_first_name", data.corporate_administrator_first_name);
    formData.append("corporate_administrator_last_name", data.corporate_administrator_last_name);
    formData.append("corporate_administrator_email", data.corporate_administrator_email);

    formData.append("corporate_administrator_address1", data.corporate_administrator_address1);
    formData.append("corporate_administrator_address2", data.corporate_administrator_address2);
    formData.append("corporate_administrator_address3", data.corporate_administrator_address3);


    this.updateLeadFormService.saveLeadUserData(formData)?.subscribe(
      (response: any) => {
        if (response.success) {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-success'
          });
          //this.router.navigate(['/business_list'])
          //window.location.reload();
          this.formUser.reset();
        } else {
          this._snackBar.open(response.message, '', {
            duration: 5000,
            panelClass: 'text-danger'
          });
        }

      },
      (error: any) => {
        this._snackBar.open('Failed to save data. Please try again later.', '', {
          duration: 5000,
          panelClass: 'text-danger'
        });
      }
    );

  }

  // Get Product Checked
  updateChkbxArray(id: any, isChecked: any) {
    const products: FormArray = this.formUser.get('products') as FormArray;
    if (isChecked) {
      products.push(new FormControl(id));
    } else {
      let i: number = 0;
      products.controls.forEach((item: any) => {
        if (item.value == id) {
          products.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  scrollTo(el: any): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }


  getAllPriceByCountryId(value: any) {
    //Get All Branches List
    this.updateLeadFormService.getPriceListByCountryId(value).subscribe(
      (response: any) => {
        if (response.success) {
          this.employeeList = response.result;
        }
      });
  }

  /***
* Get Countries List on the base of the continet
*/
  onChangeContinent(event: any) {
    this.continentsList.forEach((res: any) => {
      if (res.code == event) {
        this.countriesList = res.countries;
      }
    });

  }

  onClickContactAdmin(event: any) {
    if (event == '2') {
      this.formUser.get("corporate_administrator_first_name")?.enable();
      this.formUser.get("corporate_administrator_last_name")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_first_name")?.disable();
      this.formUser.get("corporate_administrator_last_name")?.disable();
    }
  }
  onClickEmailAdmin(event: any) {
    if (event == '2') {
      this.formUser.get("corporate_administrator_email")?.enable();
    }
    else {
      this.formUser.get("corporate_administrator_email")?.disable();
    }
  }
}
