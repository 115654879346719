import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.css']
})
export class BusinessListComponent implements OnInit {
  businessList: []


  constructor(private fb: FormBuilder, private _service: SharedService, private router: Router,
    private route: ActivatedRoute, private sessionSt: LocalStorageService, private modalService: NgbModal, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCompanyList();
  }

  //Grid For Branch Data
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api?.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Name', sortable: true, field: 'name', width: 107
    },
    {
      headerName: 'Country', sortable: true, field: 'country_name', width: 107
    },
    {
      headerName: 'Contact Name', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.main_contact_first_name) {
          return params.data.main_contact_first_name + ' ' + params.data.main_contact_last_name;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Email', sortable: true, field: 'email', width: 107
    },
    {
      headerName: 'Website', sortable: true, field: 'website', width: 107
    },
    {
      headerName: 'Phone', sortable: true, field: 'phone', width: 107
    },
    {
      headerName: 'Status', width: 107,
      cellRenderer: function (params: any) {
        if (params.data.status == 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
      sortable: true, filter: true
    },
    {
      headerName: 'Action', width: 150,
      cellRenderer: function (params: any) {
        let links = '<a  title="Edit Company" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        links += '<a title="View Branchs" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-source-branch" data-action-type="branches" id="' + params.data.id + '"></i></a>';
        links += '<a title="View Users" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-account-multiple-outline" data-action-type="users" id="' + params.data.id + '"></i></a>';
        links += '<a title="View Financial" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-bank" data-action-type="financial" id="' + params.data.id + '"></i></a>';
        links += '<a title="View Contract" style="font-size: 18px;cursor: pointer;"> <i class="mdi mdi-file-document" data-action-type="contract" id="' + params.data.id + '"></i></a>';
        if ((params.data.contract_status == 1) && (params.data.financial_status == 1)){
          links += '<a  title="Send Email" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-email" data-action-type="sendemail" id="' + params.data.id + '"></i></a>';
        }
        
        links += '<a title="Delete Company" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
        return links;
      }
    },
  ];


  /*
  * GRID BUTTONS ACTION
  */
  msg: string = '';
  company_id: string = '';
  rowIndex: number = 0;

  onGridRowClicked(e: any, content: any, emailcontent: any) {
    if (e) {
      this.company_id = e.data.id;
      this.rowIndex = e.rowIndex;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/business', e.data.id]);
            break;
          }
        case "branches":
          {
            this.router.navigate(['/branches', e.data.id]);
            this.sessionSt.store('customer_id', '2b4e4105-463e-456e-8e5e-97ac397168e5');
            this.sessionSt.store('company_id', e.data.id);
            break;
          }
        case "users":
          {
            this.router.navigate(['/user_list', e.data.id]);
            this.sessionSt.store('customer_id', '2b4e4105-463e-456e-8e5e-97ac397168e5');
            break;
          }
        case "financial":
          {
            this.router.navigate(['/financial', e.data.id]);
            this.sessionSt.store('customer_id', '2b4e4105-463e-456e-8e5e-97ac397168e5');
            this.sessionSt.store('company_id', e.data.id);
            break;
          }
        case "contract":
          {
            this.router.navigate(['/view_contract', e.data.id]);
            this.sessionSt.store('customer_id', '2b4e4105-463e-456e-8e5e-97ac397168e5');
            this.sessionSt.store('company_id', e.data.id);
            break;
          }
        case "delete":
          {
            this.msg = 'Are you sure you want to delete this company?';
            this.modalService.open(content, { centered: true });
            break;
          }
        case "sendemail":
          {
            this.msg = 'Are you sure you want to send email ?';
            this.modalService.open(emailcontent, { centered: true });
            break;
          }
      }
    }
  }

  /*
  * DELETE COMPANY
  */
  deleteCompany(company_id: any, rowIndex: any) {
    let data: any = '';
    data = {
      id: company_id
    };

    this._service.deleteCompany(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            // this.businessList.splice(rowIndex, 1);
            // this.gridOptions.api?.setRowData(this.businessList)
            this.getCompanyList();
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Get Company List
  */
  getCompanyList() {
    let company_id = '2b4e4105-463e-456e-8e5e-97ac397168e5';
    this._service.getBusinessList(company_id).subscribe(
      (response: any) => {
        this.businessList = response.result;
      }
    );
  }


  /*
* Active Lead User
*/
  SendEmail(company_id: any) {
    let data: any = '';
    data = {
      id: company_id,
    };

    this._service.sendEmailToActiveContract(data)
      ?.subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.getCompanyList();
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  //  generatePDF() {
  //   var data = document.getElementById('contentToConvert');
  //   html2canvas(data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jsPDF('p', 'mm', 'a4');
  //     var position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     pdf.save('newPDF.pdf');
  //   });
  // }

}
