import { Component, OnInit, OnDestroy,EventEmitter,Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from "@angular/router";
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,private service: LoginService, private sessionSt: LocalStorageService, private router: Router,
    private confirmDialogService: ConfirmDialogService) {}


  ngOnInit() {
    // REDIRECT TO DASHBOARD SCREEN IF LOGGED IN
    if (this.sessionSt.retrieve('login_token') != null) {
      this.router.navigate(["/dashboard"]);
      //this.router.navigateByUrl("/dashboard");
    }
    // VALIDATIONS
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      password: ['', Validators.required]
    });
  }
  ngOnDestroy() {
  }

  // BIND ERRORS TO ALL FORM FIELDS
  get f() { return this.loginForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let login: any = this.loginForm.value;
    this.service.create(login)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.sessionSt.store('userInfo', response.data.user);
            this.sessionSt.store('login_token', response.login_token);
            this.router.navigate(['/dashboard']);
            
            // if(response.data.user.company.roles.id == "1b4e4105-463e-456e-8e5e-97ac397168e5"){
            //   this.router.navigate(['/dashboard'])
            //   .then(() => {
            //       window.location.reload();
            //   });
            // }
            // else if(response.data.user.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5" 
            // || response.data.user.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5" 
            // || response.data.user.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
            // || response.data.user.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5"){
            //   if(response.data.user.company.parent_id !=null){
            //     this.router.navigate(['/branches',response.data.user.company.parent_id])
            //     .then(() => {
            //         window.location.reload();
            //     });
            //   }
            //   else{
            //     this.router.navigate(['/branches',response.data.user.company.id])
            //     .then(() => {
            //         window.location.reload();
            //     });
            //   }

            // }       

          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false,
            };
            this.confirmDialogService.open(options);
          }

        },
        (error:any) => {
          console.log(error);
          const options = {
            title: '',
            message: error,
            imageType: 'error',
            yesNoBtns: false,
          };
          this.confirmDialogService.open(options);          
        }
      );
  }
}
