import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userList:[]
  branch_id:any;
  company_id:any;
  isSuperAdmin:boolean;
  customerId:any;

  constructor(private _service: SharedService, private router: Router,
    private route: ActivatedRoute, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {

    let userinfo = this.sessionSt.retrieve('userInfo');
    if(userinfo !=null){
      if(userinfo.company.roles.id == "5b4e4105-463e-456e-8e5e-97ac397168e5" 
      || userinfo.company.roles.id == "4b4e4105-463e-456e-8e5e-97ac397168e5" 
      || userinfo.company.roles.id == "2b4e4105-463e-456e-8e5e-97ac397168e5"
      || userinfo.company.roles.id == "3b4e4105-463e-456e-8e5e-97ac397168e5"){
        this.isSuperAdmin = false;
      }
      else{
        this.isSuperAdmin = true;
      }
    }
    else{
      this.isSuperAdmin = true;
    }


    this.company_id = this.sessionSt.retrieve('company_id');
    this.branch_id = this.route.snapshot.params.id;
    this.customerId = this.sessionSt.retrieve('customer_id');

    /**** GET USER LIST BY BRANCH */
    this._service.getUserListByCompanyId(this.branch_id).subscribe(
      (response: any) => {
        if(response.success){
          this.userList = response.result;
        }
      }
    );
  }

    //Grid For Branch Data
    gridOptions: GridOptions = {
      onGridReady: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    };
    columnDefs = [
      {
        headerName: 'User Name', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.userinfo.first_name) {
            return params.data.userinfo.first_name + ' ' + params.data.userinfo.last_name;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Email', sortable: true,field: 'username', width: 107
      },
      // {
      //   headerName: 'Department', width: 107,
      //   cellRenderer: function (params:any) {
      //     if (params.data.userinfo.department) {
      //       return params.data.userinfo.department;
      //     } else {
      //       return null;
      //     }
      //   },
      //   sortable: true, filter: true
      // },
      {
        headerName: 'Employee Number', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.userinfo.employee_number) {
            return params.data.userinfo.employee_number;
          } else {
            return null;
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Status', width: 107,
        cellRenderer: function (params:any) {
          if (params.data.status == 1) {
            return "Active";
          } else {
            return "Inactive";
          }
        },
        sortable: true, filter: true
      },
      {
        headerName: 'Action', width: 50,
        cellRenderer: function (params:any) {
          let links = '<a  title="Edit User" style="font-size: 18px;cursor: pointer;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          return links;
        }
      },
    ];


  /*
  * GRID BUTTONS ACTION
  */
    msg: string = '';
    quote_id: string = '';
    rowIndex: number = 0;

    onGridRowClicked(e: any) {
      if (e) {
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
            case "edit":
            {
              this.router.navigate(['/user',this.branch_id ,e.data.id]);
              break;
            }
            case "delete":
            {
              // this.msg = 'Are you sure you want to delete this quote?';
              // this.modalService.open(content, { centered: true });
              // break;
            }
        }
      }
    }

}
