import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
//declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();
  public isLoggedIn:boolean=false;
  userEmail:string="";

  public showSearch = false;

  constructor(public router: Router, private sessionSt: LocalStorageService) {}
  ngOnInit() {
       let user = this.sessionSt.retrieve('userinfo');
    if (user && user.id) {
      this.userEmail = user.username;
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }


  }
}
