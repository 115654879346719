<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            Climate Action Responsibility (CAR) Performance - Actual record
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
                <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/cr_record']">Add record</a>
                </div>
            </div>
        </div>
        <div class="card-body p-1">
            <ag-grid-angular
            style="width: 100%; height: 380px; background-color: #3b6c54 !important"
            class="ag-theme-bootstrap"
            [gridOptions]="gridOptions"
            [rowData]="carDataList"
            [columnDefs]="columnDefs"
            animateRows
            pagination
            paginationPageSize = 10
            rowHeight = 27
            headerHeight=35
            (rowClicked)='onGridRowClicked($event,content)'
            >
          </ag-grid-angular>
          </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-body">
        {{msg}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="deleteCompany(company_id,rowIndex)">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<!-- Page content -->
<!-- <div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Activity Records</h3>
                </div>
                <div class="col text-right">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/cr_record']">Add record</a>
                </div>
            </div>
        </div>
        <div class="table-responsive">
             Projects table
            <table class="table align-items-center table-flush">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Area</th>
                        <th scope="col">Goal</th>
                        <th scope="col">Category</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            Title
                        </th>
                        <td>
                            Area
                        </td>
                        <td>
                            Goal
                        </td>
                        <td>
                            Category
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <span class="mr-2">60%</span>
                                <div>
                                    <div class="progress">
                                        <div class="progress-bar bg-gradient-primary" role="progressbar"
                                            aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                                            style="width: 60%;"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-template #coModal let-c="close" let-d="dismiss">
    <div class="modal-content">
        <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">CER Activity</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body formUser">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Title</small>
                            <input formControlName="name" type="text" placeholder=""
                                class="form-control form-control-alternative" ngbAutofocus />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Area of corporate
                                responsibility</small>
                            <select formControlName="operator_code" class="form-control form-control-alternative">
                                <option>-Select-</option>
                            </select>
                            <div *ngIf="submitted && f.operator_code.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Goal</small>
                            <select formControlName="operator_code" class="form-control form-control-alternative">
                                <option>-Select-</option>
                            </select>
                            <div *ngIf="submitted && f.exec_code.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Category</small>
                            <select formControlName="operator_code" class="form-control form-control-alternative">
                                <option>-Select-</option>
                            </select>
                            <div *ngIf="submitted && f.exec_code.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Department</small>
                            <input formControlName="exec_code" type="text" placeholder=""
                                class="form-control form-control-alternative" />
                            <div *ngIf="submitted && f.exec_code.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <small class="d-block text-uppercase font-weight-bold ">Deliverables</small>
                            <input formControlName="exec_code" type="text" placeholder=""
                                class="form-control form-control-alternative" />
                            <div *ngIf="submitted && f.exec_code.errors" class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
        </div>
    </div>
</ng-template> -->