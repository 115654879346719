<!-- Page content -->
<div class="container  pb-5 container-height container-width">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-5 my-auto" style="padding-top: 43px;">
      <div class="">
        <div class="px-lg-5 py-lg-5 mt-3">
          <div class="text-center mb-4">
            <h1 class="mt-5">Login</h1>
          </div>
          <form role="form" class="login_from" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group mb-0">
              <!-- <div class="input-group input-group-alternative"> -->
              <!-- <div class="input-group-prepend">
                  <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                </div> -->
              <span><img src="assets/img/username-icon.png"></span>
              <input class="form-control" placeholder="" type="text" name="email"
              formControlName="username"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              <!-- </div> -->
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Email is required</div>
            </div>
            </div>
            <div class="form-group">
              <span><img src="assets/img/password-icon.png"></span>
              <input class="form-control" placeholder="" type="password"
              formControlName="password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            </div>
            <div class="row mt-3">
              <div class="col-1">
                <!-- <input type="checkbox" class="checkbox-round">
               Remember<a href="javascript:void(0)" routerLink="/register" style="color: #9a9da0 !important;
                font-weight: 500;"> Register Here</a> -->
                <div class="round">
                  <input type="checkbox" checked id="checkbox" />
                  <label for="checkbox"></label>
                </div>
              </div>
              <div class="col-2">
                <label class="text-font-color"> Remember</label>
              </div>
              <div class="col-9 text-right">
                <a href="javascript:void(0)" routerLink="/auth/forgot-password" class="text-font-color">Forgot password?</a>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-warning my-4">Log on</button>
              <br>
              Don't have a account?<a href="javascript:void(0)" routerLink="/register" class="text-font-color"> Register Here</a>
            </div>
          </form>
          
        </div>
      </div>

    </div>
  </div>
  <p class="text-center about text-dark">
    ESGMS Powers Corporate Sustainability and Climate Protection Performance Information for Planet Earth Sustainable Present & Future.
  </p>
</div>