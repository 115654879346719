import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { GridOptions } from "ag-grid-community";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-csr-analysis',
  templateUrl: './csr-analysis.component.html',
  styleUrls: ['./csr-analysis.component.css']
})


export class CsrAnalysisComponent implements OnInit {

  constructor(private _service: SharedService, private sessionSt: LocalStorageService, private fb: FormBuilder) { }


  formCsrReport: FormGroup;
  yearsList: any = [];
  branchList: any = [];
  company_id: string;
  submitted = false;

  ngOnInit(): void {
    let user = this.sessionSt.retrieve('userinfo');
    if (user != null) {
      this._service.getComapnyBranchesById(user.company_id)?.subscribe(
        (response: any) => {
          this.branchList = response.result;
        },
      );
    }
    this._service.getYearList()?.subscribe(
      (response: any) => {
        this.yearsList = response.result;
      },
    );


    this.formCsrReport = this.fb.group({
      csr_type: [''],
      csr_month: [''],
      year: ['', Validators.required],
      branch: ['', Validators.required],
      report_type: ['', Validators.required],
      month_name:['', Validators.required]
    })

  }
  get f() { return this.formCsrReport.controls; }
  div_1: boolean = true;
  div_2: boolean = true;
  div_3: boolean = true;
  div_4: boolean = true;
  div_5: boolean = true;
  div_6: boolean = false;
  div_7: boolean = false;
  div_8: boolean = false;
  div_9: boolean = false;
  div_10: boolean = false;
  div_11: boolean = false;
  div_5_m1: boolean = false;
  div_5_m2: boolean = false;


  isJanCurrentTab = false;
  isFabCurrentTab = false;
  isMarchCurrentTab = false;
  isAprilTab = false;
  isMayCurrentTab = false;
  isJuneCurrentTab = false;
  isJulyCurrentTab = false;
  isAugustCurrentTab = false;
  isSeptCurrentTab = false;
  isOctCurrentTab = false;
  isNoveCurrentTab = false;
  isDeceCurrentTab = false;
  is3MonthTab = false;
  is6MonthTab = false;
  is12MonthTab = false;

  isTabularTab = false;
  isChartTab = false;

  isCsrPerformanceTab = false;
  isCsrsdgPerformance = false;
  isCsrActivity = false;
  isSustainableProcurement = false;


  csrPerformance() {
    this.div_2 = false;
    this.div_3 = false;
    this.div_4 = false;
    this.div_5 = false;
    this.div_6 = true;
    this.div_7 = false;
    this.div_8 = false;
    this.div_9 = true;
    this.div_10 = true;
    this.div_11 = true;
    this.formCsrReport.get("csr_type")?.setValue(1);

    this.isCsrPerformanceTab = true;
    this.isCsrsdgPerformance = false;
    this.isCsrActivity = false;
    this.isSustainableProcurement = false;
  }
  csrsdgPerformance() {
    this.formCsrReport.get("csr_type")?.setValue(2);
    this.div_1 = false
    this.div_2 = true;
    this.div_3 = false;
    this.div_4 = false;
    this.div_5 = false;
    this.div_6 = true;
    this.div_7 = true;
    this.div_8 = false;
    this.div_9 = true;
    this.div_10 = true;
    this.div_11 = true;

    this.isCsrPerformanceTab = false;
    this.isCsrsdgPerformance = true;
    this.isCsrActivity = false;
    this.isSustainableProcurement = false;

  }
  csrActivity() {
    this.formCsrReport.get("csr_type")?.setValue(3);
    this.div_1 = false
    this.div_2 = false;
    this.div_3 = true;
    this.div_4 = false;
    this.div_5 = false;
    this.div_6 = true;
    this.div_7 = true;
    this.div_8 = false;
    this.div_9 = true;
    this.div_10 = true;
    this.div_11 = true;

    this.isCsrPerformanceTab = false;
    this.isCsrsdgPerformance = false;
    this.isCsrActivity = true;
    this.isSustainableProcurement = false;

  }
  csrOption4() {
    this.formCsrReport.get("csr_type")?.setValue(4);
    this.div_1 = false
    this.div_2 = false;
    this.div_3 = false;
    this.div_4 = true;
    this.div_5 = false;
    this.div_6 = true;
    this.div_7 = true;
    this.div_8 = false;
    this.div_9 = true;
    this.div_10 = true;
    this.div_11 = true;
    this.div_11 = true;

    this.isCsrPerformanceTab = false;
    this.isCsrsdgPerformance = false;
    this.isCsrActivity = false;
    this.isSustainableProcurement = true;

  }

  csrdata: any = [];

  CSReportData() {
    this.submitted = true;
    if (this.formCsrReport.invalid) {
      console.log(this.formCsrReport.controls)
      this.scrollToError();
      return;
    }
    let tdata = this.formCsrReport.getRawValue();
    console.log(tdata);
    let data: any = '';
    data = {
      start_date: "",
      end_date: "",
      csr_type: tdata.csr_type,
      csr_month: tdata.csr_month,
      year: tdata.year,
      branch_id: tdata.branch,
      report_type: tdata.report_type
    };
    this._service.getCsrAnalysisData(data)?.subscribe(
      (response: any) => {
        var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "csr_analysis.xlsx";
        link.click();
      },
    );
  }
  onSubmit() {
    this.CSReportData();
  }
  reset() {
    window.location.reload();
  }
  csrmonth(event: any) {
    this.formCsrReport.get("csr_month")?.setValue(event.currentTarget.innerText);
    this.formCsrReport.get("month_name")?.setValue(event.currentTarget.innerText);
    if (event.currentTarget.innerText == "January") {
      this.isJanCurrentTab = true;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "February") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = true;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "March") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = true;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "April") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = true;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "May") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = true;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "June") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = true;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "July") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = true;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "August") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = true;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "September") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = true;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "October") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = true;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "November") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = true;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "December") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = true;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "3 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = true;
      this.is6MonthTab = false;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "6 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = true;
      this.is12MonthTab = false;
    }
    else if (event.currentTarget.innerText == "12 Month") {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
      this.is3MonthTab = false;
      this.is6MonthTab = false;
      this.is12MonthTab = true;
    }
    else {
      this.isJanCurrentTab = false;
      this.isFabCurrentTab = false;
      this.isMarchCurrentTab = false;
      this.isAprilTab = false;
      this.isMayCurrentTab = false;
      this.isJuneCurrentTab = false;
      this.isJulyCurrentTab = false;
      this.isAugustCurrentTab = false;
      this.isSeptCurrentTab = false;
      this.isOctCurrentTab = false;
      this.isNoveCurrentTab = false;
      this.isDeceCurrentTab = false;
    }
  }
  reportType(event: any) {
    this.formCsrReport.get("report_type")?.setValue(event.currentTarget.innerText);
    if (event.currentTarget.innerText == "Tabular Analysis") {
      this.isTabularTab = true;
      this.isChartTab = false;
    }
    else {
      this.isTabularTab = false;
      this.isChartTab = true;
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
     this.scrollTo(firstElementWithError);
   }
   scrollTo(el:any): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
