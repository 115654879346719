<div class="header bg-gradient-primary" *ngIf="item">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Corporate Social Responsibility (CSR) Performance -Actual Record</h2>
        </div>
    </div>
</div>

<!-- <div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body cr-data-hegiht">
            <div class="form">

              </div>
        </div>
    </div>
</div> -->
<div [ngClass]="(item)?'container-fluid mt-4':''">
    <div [ngClass]="(item)?'card shadow':''">
        <div [ngClass]="(item)?'card-body':''">
            <form [formGroup]="formUser">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Target Month Date</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" formControlName="target_month_date" class="form-control form-control-alternative datepicker" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" 
                            [ngClass]="{ 'is-invalid': (f.target_month_date.touched || submitted) && f.target_month_date.errors?.required }">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle><mat-datepicker #myDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(f.target_month_date.touched || submitted) && f.target_month_date.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Sustainable Development Goals</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="goal_id" class="form-control form-control-alternative"
                                name="goal_id" required
                                [ngClass]="{ 'is-invalid': (f.goal_id.touched || submitted) && f.goal_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let g of goals" [value]="g.id">
                                    {{g.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.goal_id.touched || submitted) && f.goal_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Target</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="target_id" class="form-control form-control-alternative"
                                name="target_id" required (selectionChange)="onGoalChange($event)"
                                [ngClass]="{ 'is-invalid': (f.target_id.touched || submitted) && f.target_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let t of targets" [value]="t.id">
                                    {{t.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.target_id.touched || submitted) && f.target_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Category</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="category_id" class="form-control form-control-alternative"
                                name="category_id" required (selectionChange)="onCategoryChange($event)"
                                [ngClass]="{ 'is-invalid': (f.category_id.touched || submitted) && f.category_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let c of categories" [value]="c.id">
                                    {{c.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.category_id.touched || submitted) && f.category_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Activity Mark</label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="activity_mark_id" class="form-control form-control-alternative"
                                name="activity_mark_id" required
                                (selectionChange)="onActivityMarkChange($event,helpTextPopupModal)"
                                [ngClass]="{ 'is-invalid': (f.activity_mark_id.touched || submitted) && f.activity_mark_id.errors?.required }">
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let am of activitymark" [value]="am.id">
                                    {{am.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.activity_mark_id.touched || submitted) && f.activity_mark_id.errors?.required">
                                This field is required
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div
                    *ngIf="f.activity_mark_id.value && f.activity_mark_id.value !='3b4e4105-463e-456e-8e5e-97ac397168e5'">
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Activity</label>
                        </div>
                        <div class="col-md-5">
                            <mat-form-field class="w-100">
                                <mat-select formControlName="category_activity_id"
                                    (selectionChange)="onActivitiesChange($event)"
                                    class="form-control form-control-alternative" name="category_activity_id" required
                                    [ngClass]="{ 'is-invalid': (f.category_activity_id.touched || submitted) && f.category_activity_id.errors?.required }">
                                    <mat-option [value]="">-Select-</mat-option>
                                    <mat-option *ngFor="let c of categoryValues" [value]="c.id">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(f.category_activity_id.touched || submitted) && f.category_activity_id.errors?.required">
                                    This field is required
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group"
                    *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333332' && f.category_activity_id.value == '41111111-1111-1111-1111-111111111116'">
                        <div class="col-md-3">
                            <label>Sustainable Procurement/Consumption</label>
                        </div>
                        <div class="col-md-5">
                            <input type="radio" name="sustainable_procurement"
                                formControlName="sustainable_procurement" value="1"> Yes
                            <input type="radio" name="sustainable_procurement"
                                formControlName="sustainable_procurement" value="2"> No
                            <div class="invalid-feedback"
                                *ngIf="(f.sustainable_procurement.touched || submitted) && f.sustainable_procurement.errors?.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Activity Description</label>
                        </div>
                        <div class="col-md-5">
                            <textarea formControlName="activity_description" name="activity_description"
                                class="form-control form-control-alternative"></textarea>
                        </div>
                    </div>
                    <div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333333'">
                            <div class="col-md-3">
                                <label>Total Finance & Investment for Sale</label>
                            </div>
                            <div class="col-md-5">
                                <input type="number" name="activity_total_investment" appNumbers
                                    formControlName="activity_total_investment" required min="0"
                                    class="form-control form-control-alternative"
                                    [ngClass]="{ 'is-invalid': (f.activity_total_investment.touched || submitted) && f.activity_total_investment.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.activity_total_investment.touched || submitted) && f.activity_total_investment.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333333' || f.category_id.value == '33333333-3333-3333-3333-333333333337'">
                            <div class="col-md-3">
                                <label>{{finance_and_investment_sale_buy_label}}</label>
                            </div>
                            <div class="col-md-5">
                                <input type="number" name="activity_investment_mark_cost" appNumbers
                                    formControlName="activity_investment_mark_cost" required min="0"
                                    class="form-control form-control-alternative"
                                    (change)="calculateFinanceAndInvestment()"
                                    [ngClass]="{ 'is-invalid': (f.activity_investment_mark_cost.touched || submitted) && f.activity_investment_mark_cost.errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(f.activity_investment_mark_cost.touched || submitted) && f.activity_investment_mark_cost.errors?.required">
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="row formFieldsBg form-group"
                            *ngIf="f.category_id.value == '33333333-3333-3333-3333-333333333333'">
                            <div class="col-md-3">
                                <label>Balance Finance & Investment Available for Sale</label>
                            </div>
                            <div class="col-md-5">
                                <input type="number" name="activity_finance_investment_sellable_value"
                                    formControlName="activity_finance_investment_sellable_value" min="0"
                                    class="form-control form-control-alternative" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row formFieldsBg form-group" *ngIf="isActivityQuantity">
                        <div class="col-md-3">
                            <label>{{quantity_value_label}}</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="activity_quantity" appNumbers formControlName="activity_quantity"
                                required class="form-control form-control-alternative" placeholder="0"
                                [ngClass]="{ 'is-invalid': (f.activity_quantity.touched || submitted) && f.activity_quantity.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(f.activity_quantity.touched || submitted) && f.activity_quantity.errors?.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="isActivityMeasurementUnit">
                        <div class="col-md-3">
                            <label>Activity Quantity Measurement Unit</label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" name="activity_unit" formControlName="activity_unit" required
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.activity_unit.touched || submitted) && f.activity_unit.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(f.activity_unit.touched || submitted) && f.activity_unit.errors?.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="isQualityValue">
                        <div class="col-md-3">
                            <label>{{quality_value_label}}</label>
                        </div>
                        <div class="col-md-5">
                            <textarea formControlName="activity_quality_value" name="activity_quality_value"
                                class="form-control form-control-alternative"></textarea>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group"
                        *ngIf="f.category_id.value != '33333333-3333-3333-3333-333333333333'">
                        <div class="col-md-3">
                            <label>{{mark_cost_label}}</label>
                        </div>
                        <div class="col-md-5">
                            <input type="number" name="activity_value_cost" appNumbers
                                formControlName="activity_value_cost" required min="0"
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.activity_value_cost.touched || submitted) && f.activity_value_cost.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(f.activity_value_cost.touched || submitted) && f.activity_value_cost.errors?.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Activity Value Currency</label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" name="activity_measurement_unit"
                                formControlName="activity_measurement_unit" required readonly
                                class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.activity_measurement_unit.touched || submitted) && f.activity_measurement_unit.errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(f.activity_measurement_unit.touched || submitted) && f.activity_measurement_unit.errors?.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Value Chain Impact</label>
                        </div>
                        <div class="col-md-5">
                            <mat-form-field class="w-100">
                                <mat-select formControlName="is_value_chain"
                                    class="form-control form-control-alternative" name="is_value_chain" required
                                    (selectionChange)="onValueChainImpactChange($event)"
                                    [ngClass]="{ 'is-invalid': (f.is_value_chain.touched || submitted) && f.is_value_chain.errors?.required }">
                                    <mat-option [value]="">-Select-</mat-option>
                                    <mat-option [value]="1">Yes</mat-option>
                                    <mat-option [value]="2">No</mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(f.is_value_chain.touched || submitted) && f.is_value_chain.errors?.required">
                                    This field is required
                                </div>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div>
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-3">
                                <label>Value Chain Impact</label>
                            </div>
                            <div class="col-md-5">
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="value_chain_main_id"
                                        class="form-control form-control-alternative" name="value_chain_main_id"
                                        required (selectionChange)="onValueChainChange($event)"
                                        [ngClass]="{ 'is-invalid': (f.value_chain_main_id.touched || submitted) && f.value_chain_main_id.errors?.required }">
                                        <mat-option [value]="">-Select-</mat-option>
                                        <mat-option *ngFor="let v of valuechain" [value]="v.id">
                                            {{v.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(f.value_chain_main_id.touched || submitted) && f.value_chain_main_id.errors?.required">
                                        This field is required
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div
                            *ngIf="f.value_chain_main_id.value && f.value_chain_main_id.value !='66666666-6666-6666-6666-666666666661'">
                            <div class="row formFieldsBg form-group">
                                <div class="col-md-3">
                                    <label>{{value_chain_infra_label}}</label>
                                </div>
                                <div class="col-md-5">
                                    <mat-form-field class="w-100">
                                        <mat-select formControlName="value_chain_id"
                                            class="form-control form-control-alternative" name="value_chain_id">
                                            <mat-option [value]="">-Select-</mat-option>
                                            <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                                {{v.name}}
                                            </mat-option>
                                        </mat-select>
                                        <!-- <div class="invalid-feedback"
                                            *ngIf="(f.value_chain_values.touched || submitted) && f.value_chain_values.errors?.required">
                                            This field is required
                                        </div> -->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.value_chain_id.value && f.value_chain_id.value =='41111111-1111-1111-1111-111111111119'">
                                <div class="col-md-3">
                                    <label>Facility Description</label>
                                </div>
                                <div class="col-md-5">
                                    <input formControlName="facility_decription" type="text" placeholder=""
                                        class="form-control form-control-alternative" ngbAutofocus />
                                </div>
                            </div>
                            <div class="row formFieldsBg form-group"
                                *ngIf="f.value_chain_id.value && f.value_chain_id.value =='41111111-1111-1111-1111-111111111110'">
                                <div class="col-md-3">
                                    <label>Building Description</label>
                                </div>
                                <div class="col-md-5">
                                    <input formControlName="building" type="text" placeholder=""
                                        class="form-control form-control-alternative" ngbAutofocus />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row formFieldsBg form-group" *ngIf="item">
                        <table class="table" formArrayName="support_documents">
                            <tr>
                                <th colspan="2">Add Multiple Documents:</th>
                                <th width="150px"><button type="button" (click)="addSupportDocuments()"
                                        class="btn btn-primary btn-sm">Add More</button></th>
                            </tr>
                            <tr *ngFor="let item of supportDocuments().controls; let i=index" [formGroupName]="i">
                                <td>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Support Document {{i + 1}}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="file"
                                                name="document_path_{{supportDocuments().controls.length + i}}"
                                                (change)="getUploadedDocuments($event.target.files,i)"
                                                class="form-control form-control-alternative">
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="i==0" style="width: 100px;">

                                </td>
                                <td *ngIf="i>0">
                                    <button (click)="removeDocument(i)" class="btn btn-danger btn-sm">Remove</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row mt-1" *ngIf="item">
                    <div class="">
                        <button type="button" class="btn btn-save" (click)="onSave()"
                            [disabled]="f.activity_mark_id.value =='3b4e4105-463e-456e-8e5e-97ac397168e5'">Save</button>
                        <button type="button" class="btn btn-save"
                            [disabled]="f.activity_mark_id.value =='3b4e4105-463e-456e-8e5e-97ac397168e5'"
                            (click)="onSubmit()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #helpTextPopupModal let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="msg"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>