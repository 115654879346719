<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Commuter Travel</h2>
        </div>
    </div>
</div>
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form>
                <div class="row"  *ngFor="let activity of categoryValues; let i=index">
                  <div class="col-md-4">
                    <div>
                        <div class="row formFieldsBg form-group">
                            <div class="col-md-4">
                                <label>{{activity.name}}</label>
                            </div>
                            <div class="col-md-4">
                                <!-- <input type="hidden" formControlName="number_of_days_per_week" name="number_of_days_per_week"
                                    [value]="1" appNumbers> -->
                                <!-- <input type="number" name="number_of_days_per_week" appNumbers formControlName="number_of_days_per_week" required
                                    min="0"
                                    class="form-control form-control-alternative"> -->
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </form>
        </div>
    </div>
</div>