<div class="header bg-gradient-primary">
    <div class="container-fluid">
        <div class="header-body text-center">
            <h2>Business Account Registration</h2>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt-4">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="formUser" (ngSubmit)="onSave()">
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Customer<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="role_id"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.role_id.touched || submitted) && f.role_id.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let role of roles" [value]="role.id">
                                    {{role.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.role_id.touched || submitted) && f.role_id.errors?.required">
                            Customer is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contact First Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_first_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_first_name.touched || submitted) && f.main_contact_first_name.errors?.required">
                            Contact First name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contact Last Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="main_contact_last_name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.main_contact_last_name.touched || submitted) && f.main_contact_last_name.errors?.required">
                            Contact Last name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="isCorporateUser">
                    <div class="col-md-3">
                        <label>Is-Contact System Corporate Administrator ?</label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="is_contact_system_corporate_administrator"
                            formControlName="is_contact_system_corporate_administrator" [value]="1" (change)="onClickContactAdmin(1)">
                        Yes
                        <input type="radio" name="is_contact_system_corporate_administrator"
                            formControlName="is_contact_system_corporate_administrator" [value]="2" (change)="onClickContactAdmin(2)">
                        No
                        <div class="invalid-feedback"
                            *ngIf="(f.is_contact_system_corporate_administrator.touched || submitted) && f.is_contact_system_corporate_administrator.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div *ngIf="f.is_contact_system_corporate_administrator.value == '2'">
                    <div class="row formFieldsBg form-group"  >
                        <div class="col-md-3">
                            <label>System Corporate Administrator Contact First Name<span
                                    class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="corporate_administrator_first_name" type="text" placeholder=""
                                required class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.corporate_administrator_first_name.touched || submitted) && f.corporate_administrator_first_name.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.corporate_administrator_first_name.touched || submitted) && f.corporate_administrator_first_name.errors?.required">
                                Contact First name is required
                            </div>
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>System Corporate Administrator Contact Last Name<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="corporate_administrator_last_name" type="text" placeholder=""
                                required class="form-control form-control-alternative"
                                [ngClass]="{ 'is-invalid': (f.corporate_administrator_last_name.touched || submitted) && f.corporate_administrator_last_name.errors?.required }" />
                            <div class="invalid-feedback"
                                *ngIf="(f.corporate_administrator_last_name.touched || submitted) && f.corporate_administrator_last_name.errors?.required">
                                Contact Last name is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Corporate Department/Unit<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">

                        <mat-form-field class="w-100">
                            <mat-select formControlName="corporate_department_unit_id"
                                class="form-control form-control-alternative" name="corporate_department_unit_id" required
                                [ngClass]="{ 'is-invalid': (f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required }" >
                                <mat-option [value]="">-Select-</mat-option>
                                <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                    {{v.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required">
                            Corporate department/unit is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Email Address<span class="mandatory-field-color">*</span></label>
                    </div>
                    <!-- <div class="col-md-5">
                        <input type="email" name="email" class="form-control form-control-alternative"
                            formControlName="email" required
                            pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                            [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors?.required && f.email.errors?.pattern }">
                        <div class="invalid-feedback" *ngIf="f.email.touched || submitted">
                            <div *ngIf="f.email.errors?.required">Email is required.</div>
                            <div *ngIf="f.email.errors?.pattern">Please provide a valid email
                                address</div>
                        </div>
                    </div> -->
                    <div class="col-md-5">
                        <input type="text" class="form-control form-control-alternative"
                            formControlName="email" required
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="isCorporateUser">
                    <div class="col-md-3">
                        <label>Is-Email Use For Administration Purposes ?</label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="is_email_use_for_administrator_purposes"
                            formControlName="is_email_use_for_administrator_purposes" [value]="1" (change)="onClickEmailAdmin(1)">
                        Yes
                        <input type="radio" name="is_email_use_for_administrator_purposes"
                            formControlName="is_email_use_for_administrator_purposes" [value]="2" (change)="onClickEmailAdmin(2)">
                        No
                        <div class="invalid-feedback"
                            *ngIf="(f.is_email_use_for_administrator_purposes.touched || submitted) && f.is_email_use_for_administrator_purposes.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="f.is_email_use_for_administrator_purposes.value == '2'">
                    <div class="col-md-3">
                        <label>Corporate Branch Email Address<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control form-control-alternative" name="corporate_administrator_email"
                            formControlName="corporate_administrator_email" required
                            [ngClass]="{ 'is-invalid': submitted && f.corporate_administrator_email.errors }">
                        <div *ngIf="submitted && f.corporate_administrator_email.errors" class="invalid-feedback">
                            <div *ngIf="f.corporate_administrator_email.errors.required">Email is required</div>
                            <div *ngIf="f.corporate_administrator_email.errors.email">Email must be a valid email address
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Website<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="website" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.website.touched || submitted) && f.website.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.website.touched || submitted) && f.website.errors?.required">
                            Website is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Company/Business Name<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="name" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.name.touched || submitted) && f.name.errors?.required">
                            Business name is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 1<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_1" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.address_1.touched || submitted) && f.address_1.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.address_1.touched || submitted) && f.address_1.errors?.required">
                            Address 1 is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 2</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_2" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Address 3</label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="address_3" type="text" placeholder=""
                            class="form-control form-control-alternative" />
                    </div>
                </div>
                <div class="row formFieldsBg form-group" *ngIf="isCorporateUser">
                    <div class="col-md-3">
                        <label>Is Address for Corporate Office/ Branch ?</label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="is_address_use_for_administrator_office"
                            formControlName="is_address_use_for_administrator_office" [value]="1">
                        Yes
                        <input type="radio" name="is_address_use_for_administrator_office"
                            formControlName="is_address_use_for_administrator_office" [value]="2">
                        No
                        <div class="invalid-feedback"
                            *ngIf="(f.is_address_use_for_administrator_office.touched || submitted) && f.is_address_use_for_administrator_office.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div *ngIf="f.is_address_use_for_administrator_office.value == '2'">
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Corporate Office/ Branch Address 1</label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="corporate_administrator_address1" type="text" placeholder=""
                                class="form-control form-control-alternative" />
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Corporate Office/ Branch Address 2</label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="corporate_administrator_address2" type="text" placeholder=""
                                class="form-control form-control-alternative" />
                        </div>
                    </div>
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Corporate Office/ Branch Address 3</label>
                        </div>
                        <div class="col-md-5">
                            <input formControlName="corporate_administrator_address3" type="text" placeholder=""
                                class="form-control form-control-alternative" />
                        </div>
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Is Corporate Department for Administrator  ?<span
                            class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input type="radio" name="is_corporate_department_administrator"
                            formControlName="is_corporate_department_administrator" [value]="1">
                        Yes
                        <input type="radio" name="is_corporate_department_administrator"
                            formControlName="is_corporate_department_administrator" [value]="2">
                        No
                        <div class="radio-btn-cls"
                            *ngIf="(f.is_corporate_department_administrator.touched || submitted) && f.is_corporate_department_administrator.errors?.required">
                            This field is required
                        </div>
                    </div>
                </div> -->
                <!-- <div *ngIf="f.is_corporate_department_administrator.value == '2'">
                    <div class="row formFieldsBg form-group">
                        <div class="col-md-3">
                            <label>Corporate Department/Unit<span class="mandatory-field-color">*</span></label>
                        </div>
                        <div class="col-md-5">
    
                            <mat-form-field class="w-100">
                                <mat-select formControlName="corporate_department_unit_id"
                                    class="form-control form-control-alternative" name="corporate_department_unit_id" required
                                    [ngClass]="{ 'is-invalid': (f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required }" >
                                    <mat-option [value]="">-Select-</mat-option>
                                    <mat-option *ngFor="let v of valuechainValues" [value]="v.id">
                                        {{v.name}}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                *ngIf="(f.corporate_department_unit_id.touched || submitted) && f.corporate_department_unit_id.errors?.required">
                                Corporate department/unit is required
                            </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Industry Sector<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="industry_sector_id"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.industry_sector_id.touched || submitted) && f.industry_sector_id.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let industry of industries" [value]="industry.id">
                                    {{industry.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.industry_sector_id.touched || submitted) && f.industry_sector_id.errors?.required">
                            Industry is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Industry<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="industry" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.industry.touched || submitted) && f.industry.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.industry.touched || submitted) && f.industry.errors?.required">
                            Industryis required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>County/State<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="county" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.county.touched || submitted) && f.county.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.county.touched || submitted) && f.county.errors?.required">
                            County is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Zip/Postcode<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input formControlName="postcode" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.postcode.touched || submitted) && f.postcode.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.postcode.touched || submitted) && f.postcode.errors?.required">
                            Postcode is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Continent<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="continent"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.continent.touched || submitted) && f.continent.errors?.required }" (selectionChange)="onChangeContinent($event.value)">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let cnt of continentsList" [value]="cnt.code">
                                    {{cnt.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.continent.touched || submitted) && f.continent.errors?.required">
                            Continent is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Country<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="country"
                                class="form-control form-control-alternative" required (selectionChange)="getAllPriceByCountryId($event.value)"
                                [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let country of countriesList" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.country.touched || submitted) && f.country.errors?.required">
                            Country is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Branch Located in Country<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="branch_number"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.branch_number.touched || submitted) && f.branch_number.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let branch of branchNumbers" [value]="branch">
                                    {{branch}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.branch_number.touched || submitted) && f.branch_number.errors?.required">
                            Branch is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Phone Number<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <input name="phone" formControlName="phone" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required">
                            Phone is required
                        </div>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Products Option<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <div class="col-md-6" *ngFor="let product of productList; let i=index" formArrayName="products">
                            <mat-checkbox required 
                                (change)="updateChkbxArray(product.id, $event.checked)"  value="{{product.id}}" [checked]="product.isChecBox">
                                    {{product.name}}
                            </mat-checkbox>
                        </div>
                       <div class="text-danger" style="font-size: 13px;"
                        *ngIf="(this.formUser.controls['products'].touched || submitted) && this.formUser.controls['products'].errors?.required">
                            Please select a product.
                        </div>
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Date Added</label>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group datepicker_field">
                            <input type="text" readonly required name="agent_date" formControlName="agent_date"
                                class="form-control form-control-alternative datepicker" matInput
                                [ngClass]="{ 'is-invalid': (f.agent_date.touched || submitted) && f.agent_date.errors?.required }"
                                (focus)="agDatepicker.open()" [matDatepicker]="agDatepicker">
                            <mat-datepicker-toggle [for]="agDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #agDatepicker></mat-datepicker>
                            <div class="invalid-feedback"
                                *ngIf="(f.agent_date.touched || submitted) && f.agent_date.errors?.required">
                                Date is required
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Reporting Currency<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="reporting_currency"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                                    {{currency.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.reporting_currency.touched || submitted) && f.reporting_currency.errors?.required">
                            Reporting currency is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Company Financial Reporting Year<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="financial_reporting_year"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let fy of financialYearList" [value]="fy.id">
                                    {{fy.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.financial_reporting_year.touched || submitted) && f.financial_reporting_year.errors?.required">
                            Company financial reporting year is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>CSCPP Reporting Year<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="w-100">
                            <mat-select formControlName="car_reporting_year"
                                class="form-control form-control-alternative" required
                                [ngClass]="{ 'is-invalid': (f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required }">
                                <mat-option [value]="">--Select--</mat-option>
                                <mat-option *ngFor="let cary of financialYearList" [value]="cary.id">
                                    {{cary.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                            *ngIf="(f.car_reporting_year.touched || submitted) && f.car_reporting_year.errors?.required">
                            CAR reporting year is required
                        </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Upload Logo</label>
                    </div>
                    <div class="col-md-5">
                        <div class="custom-file">
                            <input type="file" (change)="getUploadedFile($event.target.files)"
                                class="custom-file-input" id="customFile">
                            <label class="vertical custom-file-label" for="customFile">{{fileName}}</label>
                        </div>
                        <small>Please upload the logo of size 180*100 for best resolution.</small>
                    </div>
                    <div class="col-md-2" *ngIf="isEdit" style="padding-top: 1px;">
                        <img [src]="imagePath + logoPath" class="img-fluid" style="width: 50px;height: 50px;">                          
                    </div>
                </div>
                <!-- <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Upload Contract File</label>
                    </div>
                    <div class="col-md-5">
                        <div class="custom-file">
                            <input type="file" (change)="getContractUploadedFile($event.target.files)"
                                class="custom-file-input" id="customFile">
                            <label class="vertical custom-file-label" for="customFile">{{contarctfileName}}</label>
                        </div>
                        <small>Please upload the file CSV,PDF,EXCEL.</small>
                    </div>
                </div>
                <div class="row formFieldsBg form-group">
                    <div class="col-md-3">
                        <label>Contract Notes<span class="mandatory-field-color">*</span></label>
                    </div>
                    <div class="col-md-5">
                        <textarea formControlName="contract_notes" type="text" placeholder="" required
                            class="form-control form-control-alternative"
                            [ngClass]="{ 'is-invalid': (f.contract_notes.touched || submitted) && f.contract_notes.errors?.required }"></textarea>
                        <div class="invalid-feedback"
                            *ngIf="(f.contract_notes.touched || submitted) && f.contract_notes.errors?.required">
                            Negotiated discount is required
                        </div>
                    </div>
                </div> -->
                <div class="row formFieldsBg form-group" *ngIf="isEdit">
                    <div class="col-md-3">
                        <label>Date Added</label>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group datepicker_field">
                            <input type="text" readonly name="created_at" formControlName="created_at"
                                class="form-control form-control-alternative datepicker">
                        </div>
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="">
                        <button *ngIf="!isCompanyUser" type="button" class="btn btn-new-primary" (click)="onSave()">Save</button>
                        <button *ngIf="!isCompanyUser" type="button"[routerLink]="['/business_list']"  class="btn btn-back-to-list">Back To List</button>
                        <button *ngIf="isCompanyUser" type="button"[routerLink]="['/business_account']"  class="btn btn-back-to-list">Back To List</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>